import * as _ from "lodash-es";
import { HttpResponse } from "@angular/common/http";
import { MaybeStaleData, MaybeStaleDataV1, MaybeStaleDataV2 } from "../types/stale-data-types";

const staleHeaderRegex = /^110 - "stale(?::((?:[a-z0-9-]+,?)+))?"$/i;

export function asMaybeStaleData<T>(response: HttpResponse<T>): MaybeStaleData<T> {
    let isStale = false;
    let jobs: string[] | null = null;
    let subscriptionId: string | null = null;

    if (response.headers != null) {
        const warnings = response.headers.getAll("Warning");
        const staleDataWarning = _.find(warnings, x => {
            const val = staleHeaderRegex.exec(x);
            return val != null ? val : false; 
        });
        isStale = staleDataWarning != null;
        jobs = staleDataWarning?.[1]?.split(",");
        subscriptionId = response.headers.get("lg-subscription-id");
    }

    if (subscriptionId != null) {
        // MaybeStaleDataV2
        return {
            data: response.body as T,
            isStale,
            subscriptions: [subscriptionId],
            isStalePerSubscription: new Map<string, boolean>([[subscriptionId, isStale]])
        } as MaybeStaleDataV2<T>;

    } else if (jobs != null) {
        // MaybeStaleDataV1
        return {
            data: response.body as T,
            isStale,
            jobs
        } as MaybeStaleDataV1<T>;

    }

    // If no subscription header was provided and no warning header either, we assume the data is not stale,
    // but handle it in the V2 way - with subscriptions.
    return {
        data: response.body as T,
        isStale,
        subscriptions: [],
        isStalePerSubscription: new Map<string, boolean>(),
    } as MaybeStaleDataV2<T>;
}
