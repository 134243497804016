import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { CoreModule } from "@logex/framework/core";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLocalizationModule } from "@logex/framework/lg-localization";

import { CalculationProgressComponent, StaleDataHandlerDirective, StaleDataIndicatorComponent } from "./stale-data";

@NgModule({
  declarations: [
      CalculationProgressComponent,
      StaleDataHandlerDirective,
      StaleDataIndicatorComponent
  ],
  imports: [
      CoreModule,
      UiCoreModule,
      LgLocalizationModule,
      CommonModule
  ],
  exports: [
      CalculationProgressComponent,
      StaleDataHandlerDirective,
      StaleDataIndicatorComponent
  ]
})
export class LoadManagerModule { }
