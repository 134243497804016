export enum Tabs {
    Exports,
    Queue
}

export class PageBlocks {
    static QUEUE = "queue";
    static DATA = "data";
}

export class DataSets {
    static EXPORTS = "exports";
    static EXPORT_GROUPS = "exportGroups";
    static EXPORT_TYPES = "exportTypes";
}

export enum ExportErrorTypes {
    OneSubmissionBatchAtTimeError = "OneSubmissionBatchAtTimeError",
    ScenarioMustBeLockedError = "ScenarioMustBeLockedError",
    UnknownSubmissionRelease = "UnknownSubmissionRelease"
}
