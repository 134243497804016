import {
    ChangeDetectionStrategy,
    Component,
    effect,
    EventEmitter,
    inject,
    Input,
    Output,
    signal
} from "@angular/core";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { PivotTableType } from "../../../../../../types/configuration";
import { MAX_LIMIT_ROWS } from "../../../../../../types";
import { dropdownFlat } from "@logex/framework/utilities";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { TableRow } from "../../pivot-table-configuration-dialog.types";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";

@Component({
    standalone: false,
    selector: "lgflex-pivot-table-level-properties",
    templateUrl: "./pivot-table-level-properties.component.html",
    styleUrls: ["./pivot-table-level-properties.component.scss"],
    providers: [...useTranslationNamespace("_Flexible.PivotTableConfiguration.LevelProperties")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LevelPropertiesComponent {
    _lgTranslate = inject(LgTranslateService);
    @Input("orderByItemDropdown") orderByItemDropdown = null;
    @Output() public readonly levelUpdate = new EventEmitter<TableRow | null>();

    get level(): TableRow {
        return this._level();
    }

    @Input("level") set level(value: TableRow) {
        this._level.set(value ?? {});
    }

    protected _tableTypeDropdown: IDropdownDefinition<PivotTableType> = dropdownFlat({
        entryId: "code",
        entryName: "name",
        entries: [
            {
                code: "table",
                name: this._lgTranslate.translate(".Table")
            },
            {
                code: "pivot",
                name: this._lgTranslate.translate(".Pivot")
            }
        ]
    });

    protected _maxLimitRows = MAX_LIMIT_ROWS;

    private _selectedSorting = new Map();
    constructor() {
        effect(() => {
            if (this._level().limitRows === 0 || !this._level().limitRows) {
                this._level().limitRows = MAX_LIMIT_ROWS;
                this._level().orderBy = [];
            }
            if (this._level().orderBy && this._level().orderBy.length > 0) {
                this._checkOrderByDropdownDisabling();
            }
        });
    }

    protected _level = signal(null);

    protected _onTablePropertyChange(value: string, property: any): void {
        this._level()[property as string] = value;

        this.levelUpdate.emit(this._level());
    }

    protected _onLimitRowsSliderChange(value: number): void {
        this._level().limitRows =
            value === Infinity || value > MAX_LIMIT_ROWS ? MAX_LIMIT_ROWS : value;

        this.levelUpdate.emit(this._level());
    }

    protected _orderByItemAdd(): void {
        this.levelUpdate.emit(this._level());
    }

    protected _addSorting() {
        this._level.update((prevValue: TableRow) => ({
            ...prevValue,
            orderBy: [...prevValue.orderBy, { item: null, type: null }]
        }));
        this.levelUpdate.emit(this._level());
    }

    protected _removeOrderBySorting(index: number) {
        this._level.update(level => {
            const { orderBy } = level;
            orderBy.splice(index, 1);

            return {
                ...level,
                orderBy
            };
        });

        this.levelUpdate.emit(this._level());
    }

    protected _onSortingDrop(event: CdkDragDrop<{ item: string; type: string }>) {
        moveItemInArray(this._level().orderBy, event.previousIndex, event.currentIndex);

        this.levelUpdate.emit(this._level());
    }

    private _checkOrderByDropdownDisabling() {
        if (!this._level().limitRows || this._level().limitRows === 0) return;

        this._selectedSorting.clear();
        this._level()?.orderBy.forEach(item => {
            this._selectedSorting.set(item.item, "");
        });

        this.orderByItemDropdown.groups.forEach(group => {
            const entryId = this.orderByItemDropdown.entryId;
            group.entries.forEach(entry => {
                entry.disabled = this._selectedSorting.has(entry[entryId]);
            });
        });
    }
}
