import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    standalone: false,
    selector: "lgflex-dialog-grid-panel-header-buttons",
    templateUrl: "./panel-header-buttons.component.html",
    styleUrls: ["./panel-header-buttons.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogGridPanelHeaderButtonsComponent {}
