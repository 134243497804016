import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    standalone: false,
    selector: "lgflex-form-section-column",
    templateUrl: "./form-section-column.component.html",
    styleUrls: ["./form-section-column.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSectionColumnComponent {}
