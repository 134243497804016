import { find, map } from "lodash-es";
import {
    ChangeDetectionStrategy,
    Component,
    ContentChild,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BehaviorSubject, firstValueFrom, Observable } from "rxjs";

import { LgSimpleChanges } from "@logex/framework/types";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { IDrilldownBreadcrumbEntry, LgFiltersPanelService } from "@logex/framework/lg-layout";

import {
    FlexibleLayout,
    FlexibleLayoutStorageService
} from "../../services/flexible-layout-storage";
import { FlexibleAnalyticsService } from "../../services/flexible-analytics/flexible-analytics.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { FlexibleLayoutComponent } from "../flexible-layout/flexible-layout.component";
import { LayoutVariant } from "../../types";
import { FiltersLayoutGroup } from "../../services/flexible-filter-factory/flexible-filter-factory.types";
import { FlexDataClientMetadataArguments } from "../../services/flex-data-client/types/types";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LG_LOCALIZATION_SETTINGS, LgTranslateService } from "@logex/framework/lg-localization";
import { LG_APP_SESSION, LgNavigationService } from "@logex/framework/lg-application";
import { PageContext } from "../flexible-layout/flexible-layout.types";
import { AppLgLocalizationSettings } from "@logex/fadp";

// ----------------------------------------------------------------------------------
interface PageQueryParams {
    layoutId: number | undefined;
}

@Component({
    standalone: false,
    selector: "lgflex-flexible-layout-host",
    templateUrl: "./lgflex-flexible-layout-host.component.html",
    styles: [],
    host: {
        class: "flex-flexible flexcol flexcol--full"
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlexibleLayoutHostComponent implements OnChanges {
    private _router = inject(Router);
    private _route = inject(ActivatedRoute);
    private _filtersPanelService = inject(LgFiltersPanelService);
    private _flexibleLayoutStorage = inject(FlexibleLayoutStorageService);
    private _promptDialog = inject(LgPromptDialog);
    private _lgTranslate = inject(LgTranslateService);
    protected _service = inject(FlexibleAnalyticsService);
    protected _pageReferences = inject(PageReferencesService);
    private readonly _navigationService = inject(LgNavigationService);
    private readonly _session = inject(LG_APP_SESSION);
    private readonly _localizationSettings =
        inject<AppLgLocalizationSettings>(LG_LOCALIZATION_SETTINGS);

    constructor() {
        this._service.allWidgetsReady$.subscribe(() => {
            this.allWidgetsReady.emit();
        });

        this._service.layoutIdChange$.subscribe(async layoutId => {
            await this._onLayoutIdChange(layoutId);
        });

        this._service.filterChange$.subscribe(filter => {
            this.filterChange.emit(filter);
        });
    }

    // Fields
    private _initializedSubject = new BehaviorSubject(false);
    protected _initialized$ = this._initializedSubject.asObservable();

    @ViewChild("flexibleLayout")
    protected get _flexibleLayoutComponent(): FlexibleLayoutComponent | undefined {
        return this._service.flexibleLayoutComponent;
    }

    protected set _flexibleLayoutComponent(val: FlexibleLayoutComponent | undefined) {
        this._service.flexibleLayoutComponent = val;
    }

    private _breadcrumbsConfig: IDrilldownBreadcrumbEntry<any> | undefined = undefined;
    private _filterStateStorageKey: string | undefined = undefined;

    // Attributes
    @Input() pageId: string | undefined = undefined;
    @Input() defaultLayoutId: number | null = null;
    @Input() defaultReferences: Array<string | null> = [];
    @Input("ready") ready = false;
    @Input() layoutVariants: LayoutVariant[] = [];
    @Input() filtersLayout: FiltersLayoutGroup[] = [];
    /***
     * Arguments object is evaluated only at the start of the component and connected services, so you cannot
     * update it without destroying the component.
     */
    @Input() flexDataClientArguments: FlexDataClientMetadataArguments = {};
    @Input() slideoutPanelId = "slideout-panel";
    @Input() maxRecordsLimit: number | undefined;
    @Input() reloadTrigger: Observable<void> | undefined = undefined;

    @Input() get filterStateStorageKey(): string | undefined {
        return this._filterStateStorageKey;
    }

    set filterStateStorageKey(val: string | undefined) {
        this._filterStateStorageKey = val;
        if (val !== undefined) {
            this._filtersPanelService.setKey(val);
        }
    }

    @Output() readonly allWidgetsReady = new EventEmitter<void>();
    @Output() readonly layoutIdChange = new EventEmitter<number | undefined>();
    @Output() readonly filterChange = new EventEmitter<LgFilterSet<any, any>>();

    // @ContentChild(LgFiltersPanelComponent) protected _filtersPanelTemplate: TemplateRef<any> | undefined = undefined;
    @ContentChild("filtersPanel") protected _filtersPanelTemplate: TemplateRef<any> | undefined =
        undefined;

    // Properties
    get layout(): FlexibleLayout | undefined {
        return this._service.layout;
    }

    get breadcrumbsConfig(): IDrilldownBreadcrumbEntry<any> | undefined {
        return this._breadcrumbsConfig;
    }

    get filter(): LgFilterSet<any, any> | undefined {
        return this._service.filters;
    }

    get context(): PageContext {
        return {
            pageName: this._navigationService.getNodeByIdSync(this.pageId)?.name,
            layoutName: this._service.layout?.name,
            clientId: this._session.client.id,
            clientName: this._session.client.name,
            currency: this._localizationSettings.currency
        } as PageContext;
    }

    // ----------------------------------------------------------------------------------
    // Methods

    async ngOnChanges(changes: LgSimpleChanges<FlexibleLayoutHostComponent>): Promise<void> {
        if (changes.ready) {
            // TODO: Handling of OFF behavior
            if (changes.ready.currentValue) {
                await this._init();
                this._initializedSubject.next(true);
            }
            // TODO: Add support for changing all the panel's parameters
        }

        if (changes.reloadTrigger) {
            this._service.setReloadTrigger(changes.reloadTrigger.currentValue);
        }
    }

    private async _init(): Promise<void> {
        // TODO: Check prerequisites
        if (this.pageId == null) {
            throw Error("pageId is not defined");
        }

        if (this.filterStateStorageKey == null) {
            throw Error("filterStateStorageKey is not defined");
        }

        // Start the service
        await this._service.start({
            pageId: this.pageId,
            filterStateStorageKey: this.filterStateStorageKey,
            layoutVariants: this.layoutVariants,
            filtersLayout: this.filtersLayout,
            metadataArguments: this.flexDataClientArguments,
            defaultReferences: this.defaultReferences,
            maxRecordsLimit: this.maxRecordsLimit
        });

        this._route.queryParams.subscribe(async params => {
            await this._onQueryParamsUpdate(params as PageQueryParams);
        });
    }

    private async _onQueryParamsUpdate(params: PageQueryParams) {
        const layoutId = params.layoutId != null ? Number(params.layoutId) : null;
        /*
            Temporary layout navigation scenarios:
                1. Saving/editing tmp layout
                2. Navigation back from another path
                3. Reload page(F5)
                
         */

        //  1. Saving/editing tmp layout
        if (this._service.layoutId === layoutId) {
            return;
        }

        if (this._flexibleLayoutStorage.temporaryLayoutInProgress()) {
            const tmpLayoutId = this._flexibleLayoutStorage.getTemporaryLayout()?.id;
            if (layoutId === tmpLayoutId) {
                // 3. Reload page(F5)
                if (this._service.layoutId !== layoutId) {
                    await this._service.setLayout(layoutId, this.defaultLayoutId, false);
                }
                return;
            } else if (layoutId == null && tmpLayoutId) {
                //  2. Navigation back from another path
                await this._router.navigate([], {
                    queryParams: { layoutId: tmpLayoutId },
                    replaceUrl: true
                });
                return;
            }

            const result = await this._promptDialog.confirm(
                this._lgTranslate.translate("_Flexible.Dialogs.ConfirmUnsavedChangesTitle"),
                this._lgTranslate.translate("_Flexible.Dialogs.ConfirmUnsavedChangesText")
            );

            if (result === "cancel") {
                if (tmpLayoutId) {
                    await this._router.navigate([], {
                        queryParams: { layoutId: tmpLayoutId },
                        replaceUrl: true
                    });
                }
                return;
            } else {
                await this._flexibleLayoutStorage.revertTemporaryLayout();
            }
        }

        await this._service.setLayout(layoutId, this.defaultLayoutId, false);
    }

    private async _onLayoutIdChange(layoutId: number | undefined): Promise<void> {
        await this._updateQueryParams({ layoutId });

        // TODO: Check if it should be rather done one layout save
        await this._reconfigureBreadcrumbsDrilldown();

        this.layoutIdChange.emit(layoutId);
    }

    private async _updateQueryParams(params: PageQueryParams): Promise<void> {
        const currentParams = await firstValueFrom(this._route.queryParams);
        await this._router.navigate(["."], {
            relativeTo: this._route,
            queryParams: {
                ...params
            },
            queryParamsHandling: "merge",
            replaceUrl: !("layoutId" in currentParams)
        });
    }

    private async _reconfigureBreadcrumbsDrilldown(): Promise<void> {
        const allLayouts = (
            await firstValueFrom(this._flexibleLayoutStorage.getCategorizedLayouts())
        )
            .map(x => x.layouts)
            .flat();

        this._breadcrumbsConfig = {
            sortById: "noSort",
            getOptions: () =>
                map(allLayouts, x => ({
                    id: x.id,
                    name: x.name
                })),
            getCurrent: () => this._service.layoutId,
            mapToName: id => {
                const layout = find(allLayouts, { id });
                return layout?.name ?? "-";
            },
            onSet: id => {
                this._service.setLayout(id, this.defaultLayoutId, false);
            }
        } as IDrilldownBreadcrumbEntry<any>;
    }

    protected async _changeLayout(layoutId: number | null, layoutUpdated: boolean): Promise<void> {
        await this._service.setLayout(layoutId, this.defaultLayoutId, layoutUpdated);
    }

    storeFiltersState() {
        this._service.storeFilterState();
    }
}
