import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";

import { IHttpRequestOptions, ServerGatewayBase } from "@logex/framework/lg-application";
import { MaybeStaleData } from "../types/stale-data-types";
import { asMaybeStaleData } from "../helpers/asMaybeStaleData";


export type MaybeStaleDataRequestOptions = {
    // undefined means that we don't have a subscription id yet, but we want to subscribe.
    // null means that we don't want to subscribe.
    subscriptionId: string | undefined | null;
}

function addStaleDataHeaders(options: IHttpRequestOptions & MaybeStaleDataRequestOptions) {
    options.headers = options.headers || {};
    options.headers["Cache-Control"] = "max-stale=1"; // return stale data and start calculation

    // If lg-subscription-id header is present in the request, the call is getting a subscription
    // No header - no subscription.
    if (options.subscriptionId !== null) {
        options.headers["lg-subscription-id"] = options.subscriptionId !== undefined ? options.subscriptionId : "";
    }
}


@Injectable()
export class MaybeStaleServerGatewayBase extends ServerGatewayBase {

    // ----------------------------------------------------------------------------------
    // Stale data support for Calculation Service v2

    protected _getMaybeStaleV2<TResult>(
        url: string,
        options: IHttpRequestOptions & MaybeStaleDataRequestOptions
    ): Observable<MaybeStaleData<TResult>> {
        addStaleDataHeaders(options);

        return this._get<TResult>(url, {
            ...options,
            observe: "response"
        }).pipe(map(response => asMaybeStaleData(response)));
    }

    protected _postMaybeStaleQueryV2<TResult>(
        url: string,
        options: IHttpRequestOptions & MaybeStaleDataRequestOptions,
    ): Observable<MaybeStaleData<TResult>> {
        addStaleDataHeaders(options);

        return this._postQuery<TResult>(url, {
            ...options,
            observe: "response"
        }).pipe(map(response => asMaybeStaleData(response)));
    }
}
