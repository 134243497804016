import * as _ from "lodash-es";
import { ChangeDetectorRef, Component, ElementRef } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { mixins } from "@logex/mixin-flavors";

import { LgConsole } from "@logex/framework/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { NgOnDestroyMixin, PopupMixin } from "@logex/mixins";

import { FieldInfo, PivotTableColumnDifferenceMode } from "../../types";
import {
    DifferenceColumnConfiguration,
    PopupArguments,
    PopupResult
} from "./difference-column-header.types";
import { ReferenceInSlot } from "../../services/page-references/page-references.types";

// ----------------------------------------------------------------------------------
export interface FlexiblePivotDifferenceColumnHeaderPopupComponent
    extends PopupMixin,
        NgOnDestroyMixin {}

const hostAttributes = {
    "[@state]": "_visibility",
    "(@state.done)": "_animationDone( $event )",
    "(document:keydown.escape)": "onEscKey( $event )"
};

// eslint-disable-next-line @angular-eslint/use-component-selector
@Component({
    standalone: false,
    templateUrl: "./difference-column-header-popup.component.html",
    animations: [PopupMixin.Animations],
    host: {
        ...hostAttributes,
        class: "mi-comparison-source-menu-popup lg-menu-popup lg-menu-popup--bottom lg-menu-popup--compact lg-menu-popup--no-scrollbar"
    },
    viewProviders: [useTranslationNamespace("_Flexible.DifferenceColumn.Popup")]
})
@mixins(PopupMixin, NgOnDestroyMixin)
export class FlexiblePivotDifferenceColumnHeaderPopupComponent {
    constructor(
        private _elementRef: ElementRef,
        private _changeDetectorRef: ChangeDetectorRef,
        private _lgConsole: LgConsole
    ) {
        this._initMixins();
    }

    // ----------------------------------------------------------------------------------
    _args: PopupArguments | undefined;

    _fields: FieldInfo[] = [];
    _selectedReferences: ReferenceInSlot[] = [];

    private _mode: PivotTableColumnDifferenceMode = "diff";
    private _field: string | undefined;
    private _referenceLeft: number | undefined;
    private _referenceRight: number | undefined;

    _form: UntypedFormGroup | undefined;

    // _isTop: boolean;
    // _selectedComparisonType: ComparisonType;
    // _showComparisonTypeSwitch: boolean;
    // _sources$: Observable<IStringLookup<MiComparisonSource[]>>;
    // _differenceTypes: LgComparisonSourceDifferenceTypeItem[] = [];
    // _differenceTypesTitle: string;
    // _currentDifferenceTypeId: string | number;
    // _sourcesReadOnly: boolean;
    //
    // private _initialComparisonType: ComparisonType;

    private _result = new Subject<PopupResult>();

    // ----------------------------------------------------------------------------------
    init(args: PopupArguments): Observable<PopupResult> {
        this._visibility = "visible";

        this._lgConsole.debug("Difference popup init", args);

        this._args = args;
        this._selectedReferences = args.selectedReferences;
        this._fields = args.fields;

        this._mode = args.mode;
        this._field = args.field;
        this._referenceLeft = args.referenceLeft;
        this._referenceRight = args.referenceRight;
        this._configureForm();

        return this._result.asObservable();
    }

    private _configureForm(): void {
        this._form = new UntypedFormGroup({
            referenceLeft: new UntypedFormControl(this._referenceLeft),
            referenceRight: new UntypedFormControl(this._referenceRight)
        });
        this._form.valueChanges
            .pipe(takeUntil(this._destroyed$))
            .subscribe(({ referenceLeft, referenceRight }) => {
                this._referenceLeft = referenceLeft;
                this._referenceRight = referenceRight;
            });
    }

    _isFieldEqual(field: string): boolean {
        return this._field === field;
    }

    _onFieldSelect(field: FieldInfo): void {
        this._field = field.field;
    }

    _isModeEqual(type: PivotTableColumnDifferenceMode): boolean {
        return type === this._mode;
    }

    _onModeSelect(type: PivotTableColumnDifferenceMode): void {
        this._mode = type;

        if (!this._args!.showOkButton) {
            this._onSubmit();
        }
    }

    attemptClosePopup(): void {
        this._result.next({ type: "exit" });
        this._result.complete();
    }

    _onFakeRadioSelect(radioGroupIdentifier: string): void {
        const radioButton = (this._elementRef.nativeElement as HTMLElement).querySelector(
            `.${radioGroupIdentifier}`
        ) as HTMLInputElement;
        radioButton.click();
        this._changeDetectorRef.markForCheck();
    }

    // ----------------------------------------------------------------------------------
    _onSubmit(): void {
        const result: Partial<DifferenceColumnConfiguration> = _.omitBy(
            {
                mode: ifChanged(this._mode, this._args!.mode),
                field: ifChanged(this._field, this._args!.field),
                referenceLeft: ifChanged(this._referenceLeft, this._args!.referenceLeft),
                referenceRight: ifChanged(this._referenceRight, this._args!.referenceRight)
            },
            x => x === undefined
        );

        if (!_.isEmpty(result)) {
            this._result.next({
                type: "submit",
                ...result
            });
        } else {
            this._result.next({ type: "exit" });
        }

        // ---
        function ifChanged<T>(value: T, oldValue: T): T | undefined {
            return value !== oldValue ? value : undefined;
        }
    }
}
