import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import {
    ChangeDetectorRef,
    EventEmitter,
    Inject,
    Input,
    OnDestroy,
    OnInit,
    Output
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { asapScheduler, BehaviorSubject, EMPTY, Subject, Subscription } from "rxjs";
import { first, observeOn, switchMap, takeUntil } from "rxjs/operators";
import { mixins } from "@logex/mixin-flavors";

import { IDropdownDefinition, LgFormatTypePipe, LgMarkSymbolsPipe } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { LgConsole, LgFormatterFactoryService } from "@logex/framework/core";
import { dropdownFlat } from "@logex/framework/utilities";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import {
    INormalizedLogexPivotDefinition,
    IOrderByPerLevelSpecification,
    LgPivotInstance,
    LogexPivotService
} from "@logex/framework/lg-pivot";
import { NgOnDestroyMixin } from "@logex/mixins";

import {
    applyPivotExpanded,
    extractPivotExpanded,
    getColumnFieldName,
    translateNullableName
} from "../../../utilities";
import { PageReferencesService } from "../../../services/page-references/page-references.service";
import {
    COLUMN_WIDTH_DEFAULT,
    ColumnAndFieldInfo,
    customColumnId,
    DrilldownKeyItem,
    FdpCell,
    FdpCellVisualizationWidget,
    FdpColumnDefinition,
    FdpColumnHeader,
    FdpColumnHeaderDefault,
    FdpColumnHeaderDifference,
    FdpColumnHeaderFormula,
    FdpColumnHeaderGroup,
    FdpDifferenceColumnChangeEvent,
    FdpLevel,
    FieldInfo,
    FlexibleDrilldownPivotTableState,
    ICON_COLUMN_WIDTH,
    PERCENTAGE_FORMATTER_LIMIT,
    PivotTableColumn,
    PivotTableColumnDefault,
    PivotTableColumnDifference,
    PivotTableColumnFormula,
    PivotTableColumnFormulaType,
    pivotTableColumnFormulaTypeLabels,
    PivotTableColumnWidget,
    PivotTableLevel
} from "../../../types";
import { WidgetTypesRegistry } from "../../../services/widget-types-registry";
import { DifferenceColumnConfiguration } from "../../flexible-pivot-difference-column/difference-column-header.types";
import { LgSimpleChanges } from "@logex/framework/types";
import { ReferenceInSlot } from "../../../services/page-references/page-references.types";
import { getItemNameFromField } from "../../../utilities/getItemNameFromField";
import { getFormatTypeFromValueField } from "../../../utilities/getFormatTypeFromValueField";

export interface FlexibleDrilldownBaseComponent extends NgOnDestroyMixin {}

@mixins(NgOnDestroyMixin)
export abstract class FlexibleDrilldownBaseComponent implements OnInit, OnDestroy {
    constructor(
        protected _lgTranslate: LgTranslateService,
        protected _lgConsole: LgConsole,
        protected _formatter: LgFormatTypePipe,
        protected _lgMarkSymbols: LgMarkSymbolsPipe,
        protected _fmtDate: DatePipe,
        @Inject(LG_APP_DEFINITIONS) protected _definitions: IDefinitions<any>,
        protected _pivotService: LogexPivotService,
        private _formatterFactory: LgFormatterFactoryService,
        protected _changeDetectorRef: ChangeDetectorRef
    ) {
        this._initMixins();
    }

    // ----------------------------------------------------------------------------------
    @Input() scheme!: FieldInfo[];
    @Input() levels!: string[][];

    @Input() columns!: PivotTableColumn[];
    @Input() pivots!: LgPivotInstance[];

    @Input() set pageReferences(value: PageReferencesService | undefined) {
        // Setter is empty - changes are handled in ngOnChanges()
    }

    get pageReferences(): PageReferencesService | undefined {
        return this._pageReferences;
    }

    @Input() filters?: LgFilterSet | undefined;

    @Output() readonly drillChange = new EventEmitter<DrilldownKeyItem[][]>();

    _schemaLookup: Dictionary<FieldInfo> = {};

    _pageReferences: PageReferencesService | undefined;
    _pageReferencesSubscription: Subscription | null = null;
    _referencesDropdown: IDropdownDefinition<string> | undefined;

    // Pivot model
    _selectedKeys: DrilldownKeyItem[][] = [];

    // Pivots definitions
    _headerColumnDef: FdpColumnDefinition[] = [];
    _headerRow: FdpColumnHeader[] = [];
    _totalsColumnDef: FdpColumnDefinition[] = [];

    _maxVisibleLevelIdx: number | undefined;
    _maxVisibleLevel: FdpLevel | undefined;
    _totalsRow: FdpCell[] = [];

    // Other
    _isModelBuilt = false;
    private _showPivotSubject = new BehaviorSubject(false);
    _showPivot$ = this._showPivotSubject.asObservable();

    readonly _filterSetSubject = new Subject<LgFilterSet | undefined>();

    // ----------------------------------------------------------------------------------
    ngOnInit(): void {
        // Empty
    }

    async ngOnChanges(changes: LgSimpleChanges<FlexibleDrilldownBaseComponent>): Promise<void> {
        if (changes.scheme) {
            this._processSchemeChanges(this.scheme);
        }

        if (changes.filters) this._filterSetSubject.next(this.filters);

        if (changes.scheme || changes.levels || changes.columns || changes.pivots) {
            if (!changes.pivots?.currentValue || changes.pivots?.currentValue.length === 0) {
                return;
            }

            await this._buildPivotModel();
            this._updateVisibility(this.filters, this.pivots, this.levels);
            this._showPivotSubject.next(true);
        }

        if (changes.pageReferences) {
            if (this._pageReferencesSubscription !== null) {
                this._pageReferencesSubscription.unsubscribe();
                this._pageReferencesSubscription = null;
            }

            this._pageReferences = changes.pageReferences.currentValue;
            this._updateReferencesDropdown(this.pageReferences);
            if (this._pageReferences !== undefined) {
                this._pageReferencesSubscription = this._pageReferences.stateChange.subscribe(
                    () => {
                        this._updateReferencesDropdown(this.pageReferences);
                    }
                );
            }
        }
    }

    protected abstract _updateVisibleLevels(): void;

    getState(): FlexibleDrilldownPivotTableState | null {
        if (!this._isModelBuilt) return null;
        return {
            version: 1,
            levels: _.cloneDeep(this.levels),
            selectedKeys: _.cloneDeep(this._selectedKeys),
            orderBy: this.pivots.map(level => _.cloneDeep(level.orderBy)),
            expanded: this.pivots.map(pivot => extractPivotExpanded(pivot))
        };
    }

    setState(state: FlexibleDrilldownPivotTableState | null): boolean {
        if (state === null) {
            this._selectedKeys = [];
            this._updateVisibleLevels();
            this.drillChange.next(this._selectedKeys);
            return true;
        } else {
            if (state.version !== 1) return false; // update when relevant
            this._setStateDo(state, this.levels, this.drillChange);
            return true;
        }
    }

    _setStateDo(
        state: FlexibleDrilldownPivotTableState,
        levels: string[][],
        drillChange: EventEmitter<DrilldownKeyItem[][]>
    ): void {
        if (state.version !== 1) return;
        this._selectedKeys = [];
        for (let i = 0; i < state.selectedKeys.length; ++i) {
            if (i >= levels.length) break;
            const levelState = state.selectedKeys[i];
            const sublevels = levels[i];
            if (sublevels.length !== levelState.length) break;
            let failed = false;
            for (let j = 0; j < levelState.length; ++j) {
                const part = levelState[j];
                if (part.fieldName !== sublevels[j]) {
                    failed = true;
                    break;
                }
            }
            if (failed) break;
            this._selectedKeys.push(_.cloneDeep(state.selectedKeys[i]));
        }

        for (let i = 0; i <= state.orderBy.length; ++i) {
            if (!_.isEqual(state.levels[i], levels[i]) || !this.pivots[i]) break;
            if (state.orderBy[i]) {
                // we could be perfect by verifying that the targeted columns exist, but mismatch here seems to be relatively harmless
                this.pivots[i].orderBy = _.cloneDeep(state.orderBy[i]);
            }
            if (state.expanded[i]) {
                this.pivots[i].onBuild$
                    .pipe(observeOn(asapScheduler), first(), takeUntil(this._destroyed$))
                    .subscribe(() => {
                        applyPivotExpanded(this.pivots[i], state.expanded[i]);
                    });
            }
        }

        this._updateVisibleLevels();
        drillChange.next(this._selectedKeys);
    }

    _updateReferencesDropdown(pageReferences: PageReferencesService | undefined): void {
        this._referencesDropdown = dropdownFlat({
            entryId: "code",
            entryName: "name",
            entries: [
                ..._.chain(pageReferences?.references)
                    .orderBy(x => x.code)
                    .map(x => ({
                        ...x,
                        name: translateNullableName(this._lgTranslate, x.name, x.nameLc)
                    }))
                    .value()
            ]
        });
    }

    protected async _buildPivotModel(): Promise<void> {
        this._isModelBuilt = false;

        await Promise.resolve();

        if (this._isModelBuilt) return;

        this._doBuildPivotModel();
        this._isModelBuilt = true;

        // To show immediately in chart widget while switching to pivot mode.
        this._changeDetectorRef.markForCheck();

        this._lgConsole.debug("Model built");
    }

    abstract _doBuildPivotModel(): void;

    _processSchemeChanges(scheme: FieldInfo[]): void {
        this._schemaLookup = _.keyBy(scheme, x => x.field);
    }

    _validateColumns(columnsAndFields: ColumnAndFieldInfo[]): void {
        const seenReferences = new Set<number>();
        let prevReference: number | undefined = undefined;
        for (const { column, field } of columnsAndFields) {
            if (column.type === "default") {
                if (field?.isReferenceBound) {
                    if (!_.isNumber(column.referenceIdx)) {
                        throw Error(
                            `Column references a reference-bound field "${column.field}", but doesn't have "referenceIdx" set.`
                        );
                    }

                    // Check that all reference-bound fields are grouped together
                    if (prevReference !== column.referenceIdx) {
                        if (seenReferences.has(column.referenceIdx)) {
                            throw Error(
                                `There are more than one group of fields bound to reference ${column.referenceIdx}`
                            );
                        }
                        seenReferences.add(column.referenceIdx);
                    }
                    prevReference = column.referenceIdx;
                } else {
                    if (column.referenceIdx !== undefined) {
                        throw Error(
                            `Field "${column.field}" is not bound to a reference, so column cannot have "referenceIdx" set.`
                        );
                    }

                    prevReference = undefined;
                }
            }
        }
    }

    protected _addDefaultColumn(column: PivotTableColumnDefault, field: FieldInfo, index: number) {
        const name = getColumnFieldName(column);

        this._headerColumnDef.push({
            id: `${index}`,
            columnCls: "right-align flexbox flexcol--center table__column--right-align-content",
            width: column.width ?? COLUMN_WIDTH_DEFAULT
        });

        const header: FdpColumnHeaderDefault = {
            type: "default",
            id: `${index}`,
            column,
            header: column.title ?? field.name ?? (field.nameLc ? undefined : ""),
            headerLc: field.nameLc ?? undefined,
            orderBy: name
        };

        this._headerRow.push(header);
        // valueHeaders.push(header);

        this._totalsColumnDef.push({
            id: `${index}`,
            columnCls: "right-align flexbox flexcol--center table__column--right-align-content",
            width: column.width ?? COLUMN_WIDTH_DEFAULT
        });

        this._totalsRow.push({
            id: `${index}`,
            type: "default",
            fieldName: name,
            formatFn:
                column.aggregatedTotals === false
                    ? this._getEmptyValueFormatter()
                    : this._getValueFieldFormatter(field),
            formatType: getFormatTypeFromValueField(field)
        });
    }

    protected _addWidgetColumn(column: PivotTableColumnWidget, index: number): void {
        // TODO: Add support for columns with or w/o margins
        this._headerColumnDef.push({
            id: customColumnId(index),
            type: "icons",
            width: column.width ?? ICON_COLUMN_WIDTH,
            headerCls: "crop",
            columnCls: "crop"
        });

        this._headerRow.push({
            type: "widget",
            id: customColumnId(index),
            column
        });

        this._totalsColumnDef.push({
            id: customColumnId(index),
            type: "icons",
            width: column.width ?? ICON_COLUMN_WIDTH,
            columnCls: "crop"
        });

        this._totalsRow.push({
            type: "widget",
            id: customColumnId(index),
            widgetType: undefined,
            providers: undefined,
            config: null
        });
    }

    protected _addFormulaColumn(column: PivotTableColumnFormula, index: number): void {
        const name = getColumnFieldName(column);

        this._headerColumnDef.push({
            id: `${index}`,
            columnCls: "right-align flexbox flexcol--center table__column--right-align-content",
            width: column.width ?? COLUMN_WIDTH_DEFAULT
        });

        const header: FdpColumnHeaderFormula = {
            type: "formula",
            id: `${index}`,
            column,
            header: column.title ?? "",
            formula: column.formula,
            orderBy: name
        };

        this._headerRow.push(header);

        this._totalsColumnDef.push({
            id: `${index}`,
            columnCls: "right-align flexbox flexcol--center table__column--right-align-content",
            width: column.width ?? COLUMN_WIDTH_DEFAULT
        });

        this._totalsRow.push({
            id: `${index}`,
            type: "default",
            fieldName: name,
            formatFn:
                column.aggregatedTotals === false
                    ? this._getEmptyValueFormatter()
                    : this._getFormatter(
                          column.formatType,
                          column.formatPrecision,
                          column.formatForceSign
                      ),
            formatType: column.formatType
        });
    }

    protected _addDifferenceColumn(
        column: PivotTableColumnDifference,
        field: FieldInfo,
        index: number
    ) {
        const name = getColumnFieldName(column);

        this._headerColumnDef.push({
            id: `${index}`,
            columnCls: "right-align flexbox flexcol--center table__column--right-align-content",
            width: column.width ?? COLUMN_WIDTH_DEFAULT
        });

        const header: FdpColumnHeaderDifference = {
            type: "difference",
            id: `${index}`,
            column,
            header: column.title,
            orderBy: name,
            comparisonType: column.mode === "diff" ? "absolute" : "relative"
        };

        this._headerRow.push(header);
        // differenceHeaders.push(header);

        this._totalsColumnDef.push({
            id: `${index}`,
            columnCls: "right-align flexbox flexcol--center table__column--right-align-content",
            width: column.width ?? COLUMN_WIDTH_DEFAULT
        });

        this._totalsRow.push({
            id: `${index}`,
            type: "default",
            fieldName: name,
            formatFn:
                column.aggregatedTotals === false
                    ? this._getEmptyValueFormatter()
                    : this._getValueFieldFormatter(field),
            formatType: getFormatTypeFromValueField(field)
        });
    }

    _addToColumnsDef(columnsAndFields: ColumnAndFieldInfo[]): void {
        // ---
        const referenceGroups = new Map<number, [start: number, stop: number]>();

        for (const x of columnsAndFields) {
            switch (x.column.type) {
                case "default":
                    this._addDefaultColumn(x.column, x.field!, x.index);

                    // Record start and stop of reference groups
                    if (x.column.referenceIdx !== undefined) {
                        const l = this._headerRow.length - 1;
                        let referenceGroup = referenceGroups.get(x.column.referenceIdx);
                        if (referenceGroup == null) {
                            referenceGroup = [l, l];
                            referenceGroups.set(x.column.referenceIdx, referenceGroup);
                        }
                        referenceGroup[1] = l;
                    }

                    break;

                case "difference":
                    this._addDifferenceColumn(x.column, x.field!, x.index);
                    break;

                case "formula":
                    this._addFormulaColumn(x.column, x.index);
                    break;

                case "widget":
                    this._addWidgetColumn(x.column, x.index);
                    break;

                default:
                    throw Error();
            }
        }

        this._groupReferenceBoundHeaderCells(this._headerRow, referenceGroups);
    }

    private _numberColumnsUsedInComparison(
        valueHeaders: FdpColumnHeaderDefault[],
        differenceHeaders: FdpColumnHeaderDifference[]
    ): void {
        let fieldNumber = 1;
        for (const header of valueHeaders) {
            let fieldNeedsNumber = false;
            _.each(
                _.filter(differenceHeaders, x => x.column.field === header.column.field),
                diffHeader => {
                    const isLeftColumn =
                        diffHeader.column.referenceLeft === header.column.referenceIdx;
                    const isRightColumn =
                        diffHeader.column.referenceRight === header.column.referenceIdx;
                    if (isLeftColumn || isRightColumn) {
                        fieldNeedsNumber = true;
                        if (isLeftColumn) diffHeader.leftColumnHeader = header;
                        if (isRightColumn) diffHeader.rightColumnHeader = header;
                    }
                }
            );

            if (fieldNeedsNumber) {
                header.fieldNumber = `${fieldNumber++}`;
            }
        }
    }

    private _makeFormulaHeaders(differenceHeaders: FdpColumnHeaderDifference[]): void {
        for (const header of differenceHeaders) {
            if (header.leftColumnHeader !== undefined && header.rightColumnHeader !== undefined) {
                header.formula = `${header.rightColumnHeader.fieldNumber}${
                    header.column.mode === "diff" ? "-" : "/"
                }${header.leftColumnHeader.fieldNumber}`;
            }
        }
    }

    private _groupReferenceBoundHeaderCells(
        headersRow: FdpColumnHeader[],
        referenceGroups: Map<number, [start: number, stop: number]>
    ): void {
        const groupColumns: Array<[position: number, column: FdpColumnHeaderGroup]> = [];
        referenceGroups.forEach((rg, referenceIdx) => {
            groupColumns.push([
                rg[0],
                {
                    type: "group",
                    id: headersRow[rg[0]].id,
                    colspan: rg[1] - rg[0] + 1,
                    referenceIdx,
                    children: headersRow.slice(rg[0], rg[1] + 1) as any[]
                }
            ]);
        });
        for (let i = groupColumns.length - 1; i >= 0; i--) {
            const group = groupColumns[i];
            headersRow.splice(group[0], group[1].colspan, group[1]);
        }
    }

    _getValueCell(
        { column, field, index }: ColumnAndFieldInfo,
        widgetTypes: WidgetTypesRegistry
    ): FdpCell {
        let visualizationWidget: FdpCellVisualizationWidget | undefined;
        if (column.visualizationWidget !== undefined) {
            // TODO: consider handling missing widget if the validation logic permits it
            const widgetType = widgetTypes.get(column.visualizationWidget.type);
            visualizationWidget = {
                widgetType: widgetType.type,
                providers: widgetType.providers,
                config: column.visualizationWidget.config
            };
        }

        switch (column.type) {
            case "default": {
                const name = getColumnFieldName(column);
                return {
                    id: `${index}`,
                    type: "default",
                    fieldName: name,
                    formatFn: this._getValueFieldFormatter(field!),
                    formatType: getFormatTypeFromValueField(field!),
                    header: {
                        type: column.type,
                        header: field && field.name ? field.name : undefined,
                        headerLc: field && field.nameLc ? field.nameLc : undefined,
                        columnCls:
                            "right-align flexbox flexcol--center table__column--right-align-content",
                        width: column.width ?? COLUMN_WIDTH_DEFAULT
                    },
                    visualizationWidget,
                    conditionalFormatting: column.conditionalFormatting !== undefined
                };
            }

            case "difference": {
                const complimentaryColumn: PivotTableColumnDifference = {
                    ...column,
                    mode: column.mode === "diff" ? "growth" : "diff"
                };
                const name = getColumnFieldName(column);
                if (column.mode === "diff") {
                    return {
                        id: `${index}`,
                        type: "default",
                        fieldName: name,
                        formatFn: this._getValueFieldFormatter(field!),
                        formatType: getFormatTypeFromValueField(field!),
                        tooltip: {
                            fieldName: getColumnFieldName(complimentaryColumn),
                            formatFn: this._getPercentageTransformer()
                        },
                        header: {
                            type: column.type,
                            header: column.title,
                            column,
                            columnCls: "right-align",
                            width: column.width ?? COLUMN_WIDTH_DEFAULT
                        },
                        visualizationWidget,
                        conditionalFormatting: column.conditionalFormatting !== undefined
                    };
                } else {
                    return {
                        id: `${index}`,
                        type: "default",
                        fieldName: name,
                        formatFn: this._getPercentageTransformer(),
                        formatType: "percentage",
                        tooltip: {
                            fieldName: getColumnFieldName(complimentaryColumn),
                            formatFn: this._getValueFieldFormatter(field!)
                        },
                        header: {
                            type: column.type,
                            header: column.title,
                            column,
                            columnCls: "right-align",
                            width: column.width ?? COLUMN_WIDTH_DEFAULT
                        },
                        visualizationWidget,
                        conditionalFormatting: column.conditionalFormatting !== undefined
                    };
                }
            }

            case "formula": {
                const name = getColumnFieldName(column);
                return {
                    id: `${index}`,
                    type: "default",
                    fieldName: name,
                    formatFn: this._getFormatter(
                        column.formatType,
                        column.formatPrecision,
                        column.formatForceSign
                    ),
                    formatType: column.formatType,
                    header: {
                        type: column.type,
                        header: column.title,
                        formula: column.formula,
                        columnCls: "right-align",
                        width: column.width ?? COLUMN_WIDTH_DEFAULT
                    },
                    visualizationWidget,
                    conditionalFormatting: column.conditionalFormatting !== undefined
                };
            }

            case "widget": {
                const widgetType = widgetTypes.get(column.widgetType);

                return {
                    id: customColumnId(index),
                    type: "widget",
                    widgetType: widgetType.type,
                    providers: widgetType.providers,
                    config: null,
                    header: {
                        type: column.type,
                        header: field && field.name ? field.name : undefined,
                        headerLc: field && field.nameLc ? field.nameLc : undefined,
                        width: column.width ?? ICON_COLUMN_WIDTH,
                        columnCls: "crop"
                    }
                };
            }

            default:
                throw Error();
        }
    }

    private _getValueFieldFormatter(field: FieldInfo): (value: any) => string {
        if (!field.isValueField) throw Error("Must be value field");

        return this._getFormatter(field.type, field.numericPrecision);
    }

    _getFormatter(
        type: string,
        numericPrecision: number | null,
        forceSign?: boolean
    ): (value: any) => string {
        switch (type) {
            case "float":
                return (value: number) =>
                    this._formatter.transform(value, "float", numericPrecision ?? 0, forceSign);

            case "money":
                return (value: number) =>
                    this._lgMarkSymbols.transform(
                        this._formatter.transform(value, "money", numericPrecision ?? 0, forceSign)
                    );

            case "percentage":
                return (value: number) =>
                    this._lgMarkSymbols.transform(
                        this._formatterFactory
                            .getFormatter("percentage", {
                                decimals: numericPrecision ?? 0,
                                max: PERCENTAGE_FORMATTER_LIMIT,
                                min: -PERCENTAGE_FORMATTER_LIMIT,
                                forceSign
                            })
                            .format(value)
                    );

            default:
                throw Error(`Don't know how to format value type ${type}`);
        }
    }

    private _getPercentageTransformer() {
        return (value: number) =>
            this._lgMarkSymbols.transform(
                this._formatterFactory
                    .getFormatter("percentage", {
                        decimals: 2,
                        forceSign: true,
                        zeroDash: false,
                        max: PERCENTAGE_FORMATTER_LIMIT,
                        min: -PERCENTAGE_FORMATTER_LIMIT
                    })
                    .format(value)
            );
    }

    _getDimensionCell(
        field: FieldInfo,
        levelProperties: Dictionary<PivotTableLevel> | undefined
    ): FdpCell {
        return {
            id: field.field,
            type: "dimension",
            fieldName: field.field,
            formatFn: this._getDimensionFieldFormatter(field, levelProperties)
        };
    }

    private _getDimensionFieldFormatter(
        field: FieldInfo,
        levelProperties: Dictionary<PivotTableLevel> | undefined
    ): (value: any) => string {
        if (field.isValueField) throw Error("Must be dimension field");

        const definitionDisplayMode = levelProperties?.[field.field]?.displayMode;
        return value =>
            getItemNameFromField(value, field.type, this._definitions, definitionDisplayMode);
    }

    _getField(column: PivotTableColumn): FieldInfo | undefined {
        switch (column.type) {
            case "default":
            case "difference": {
                return this._getFieldByName(column.field);
            }
            case "formula": {
                if (!column.variables.a) throw Error(`Formula column is missing first variable`);
                if (column.variables.a.type === "constant") return undefined;
                return this._getFieldByName(column.variables.a.field);
            }
            default: {
                return undefined;
            }
        }
    }

    _getFieldByName(fieldName: string): FieldInfo {
        const field = this._schemaLookup[fieldName];

        if (field === undefined) {
            throw Error(`Field ${fieldName} does not exist in the flexible dataset scheme`);
        }

        return field;
    }

    // ----------------------------------------------------------------------------------
    _onOrderByChange(colId: string): void {
        // visible level is always defined, if there's anything to trigger the sort event
        const maxVisibleLevel = this._maxVisibleLevel!;
        const maxVisibleSubLevelIndex = maxVisibleLevel.maxVisibleSubLevel;

        const columnOrderId =
            maxVisibleLevel.pivot.orderBy[maxVisibleSubLevelIndex] === colId ? "-" + colId : colId;

        this.levels.forEach((pivotLevel, pivotIndex) => {
            this._sortDrilldownLevel(pivotLevel, pivotIndex, columnOrderId);
        });
    }

    protected _sortDrilldownLevel(
        dimensionFields: string[],
        levelIndex: number,
        orderByField: string
    ) {
        const pivot = this.pivots[levelIndex];
        pivot.orderBy = dimensionFields.map(() => orderByField);
        pivot.refilter();
    }

    _onLevelsOrderByChange(level: FdpLevel, orderBy: IOrderByPerLevelSpecification): void {
        level.pivot.orderBy = orderBy;
        level.pivot.refilter();
    }

    _onClearAllFilters(filters: LgFilterSet | undefined): void {
        filters?.clearAll();
    }

    _getReference(referenceIdx: number): ReferenceInSlot | undefined {
        return this._pageReferences?.selectedReferences[referenceIdx];
    }

    _onReferenceSelected(slotIdx: number, value: string): void {
        if (this._pageReferences === undefined) return;

        const selected = this._pageReferences.selected;
        selected.splice(slotIdx, 1, value);
        this._pageReferences.selected = selected;
    }

    _onDiffColumnChanged(
        column: PivotTableColumnDifference,
        changes: Partial<DifferenceColumnConfiguration>,
        differenceColumnChange: EventEmitter<FdpDifferenceColumnChangeEvent>
    ): void {
        differenceColumnChange.emit({ column, changes });
    }

    _prepareVisibilityUpdates(): void {
        this._filterSetSubject
            .asObservable()
            .pipe(
                switchMap(set => {
                    return set ? set.onChanged : EMPTY;
                }),
                takeUntil(this._destroyed$)
            )
            .subscribe(() => this._updateVisibility(this.filters, this.pivots, this.levels));
    }

    _updateVisibility(
        filters: LgFilterSet | undefined,
        pivots: LgPivotInstance[],
        levels: string[][]
    ): void {
        // without additional backend support, we cannot evaluate the visibility across levels, so support
        // it only with single level pivots.
        if (filters === undefined || pivots.length !== 1 || !pivots[0].all) return;

        const levelFilters = levels[0].map(fieldId => {
            if (!filters.isActive(fieldId)) return null;
            const definition = filters.getFilterDefinition(fieldId);
            if (definition.filterType !== "combo2") return null;
            const state = filters.filters[fieldId];
            return (row: { [x: string]: string | number }) => state[row[fieldId]];
        });

        const walkLevel = (
            rows: any[],
            definition: INormalizedLogexPivotDefinition,
            parentHidden: boolean
        ): boolean => {
            const filter = levelFilters[definition.$levelIndex];
            let allHidden = true;

            for (const row of rows) {
                let isHidden = parentHidden || (filter && !filter(row)) || false;
                if (definition.children) {
                    const childrenHidden = walkLevel(
                        row[definition.children.store],
                        definition.children,
                        isHidden
                    );
                    isHidden ||= childrenHidden;
                }
                row.$hidden = isHidden;
                allHidden &&= isHidden;
            }
            return allHidden;
        };

        walkLevel(pivots[0].all, pivots[0].definition, false);
    }

    private _getEmptyValueFormatter(): (value: any) => string {
        return () => this._formatter.transform("", "none");
    }
}

export function getFormulaLabel(formula: PivotTableColumnFormulaType): string | undefined {
    return pivotTableColumnFormulaTypeLabels[formula];
}
