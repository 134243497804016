<div class="calculation-progress__container" *ngIf="status != 'hidden'">
    <div
        class="calculation-progress__button lg-panel__header-icon"
        [class.calculation-progress-button--calculating]="status === 'calculating'"
        (click)="onClick()"
    >
        @if (status === 'waiting') {
            <lg-icon
                icon="icon-recalculation"
                width="20"
                height="20"
            ></lg-icon>
        }
        @else if (status === 'calculating') {
            @if (_progress < 1) {
                <svg
                    height="24"
                    width="24"
                    [lgTooltip]="tooltip"
                >
                    <path [attr.d]="_pathRest" id="path" stroke="#D6E3F0" stroke-width="2" fill="none"/>
                    <path [attr.d]="_path" id="path" stroke="#005de4" stroke-width="2" fill="none"/>

                    <ng-template #tooltip>
                        {{'_LoadManager.StaleDataLoading' | lgTranslate}} ({{_progressInPercent}}%)
                    </ng-template>
                </svg>
            } @else {
                <lg-icon
                    icon="icon-circle-check"
                ></lg-icon>
            }
        }
    </div>
</div>
