import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    Output
} from "@angular/core";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { DefinitionDisplayMode } from "@logex/framework/lg-application";
import { OrderType, DimensionRow } from "../../pivot-table-configuration-dialog.types";

@Component({
    standalone: false,
    selector: "lgflex-pivot-table-dimension-properties",
    templateUrl: "./pivot-table-dimension-properties.component.html",
    styleUrls: ["./pivot-table-dimension-properties.component.scss"],
    providers: [
        ...useTranslationNamespace("_Flexible.PivotTableConfiguration.DimensionsProperties")
    ],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DimensionPropertiesComponent {
    _lgTranslate = inject(LgTranslateService);

    @Input("orderByItemDropdown") orderByItemDropdown = null;
    @Input() set dimension(value: DimensionRow) {
        if (!value.orderBy) {
            value.orderBy = { item: null, type: null };
        }
        value.overrideFromParent = !!value.orderBy?.item;
        this._dimension = value;
    }

    get dimension() {
        return this._dimension;
    }

    @Input("disableSorting") disableSorting = false;
    @Input("schemaTitle") schemaTitle = "";

    @Output() public readonly dimensionUpdate = new EventEmitter<DimensionRow | null>();

    protected readonly _itemsFilterMaxCount = Infinity;
    protected readonly Infinity = Infinity;
    protected _dimension = null;
    protected _defaultDisplayMode: DefinitionDisplayMode = "code";
    protected _displayModeDropdown: IDropdownDefinition<DefinitionDisplayMode> = dropdownFlat({
        entryId: "code",
        entryName: "name",
        entries: [
            {
                code: "code",
                name: this._lgTranslate.translate(".Code")
            },
            {
                code: "name",
                name: this._lgTranslate.translate(".Name")
            },
            {
                code: "codeAndName",
                name: this._lgTranslate.translate(".CodeAndName")
            }
        ]
    });

    protected _orderByTypeDropdown: IDropdownDefinition<OrderType> = dropdownFlat({
        entryId: "code",
        entryName: "name",
        entries: [
            {
                code: "asc",
                name: this._lgTranslate.translate(".Ascending")
            },
            {
                code: "desc",
                name: this._lgTranslate.translate(".Descending")
            }
        ]
    });

    protected _onDimensionPropertyChange(value: string, property: any): void {
        if (property === "name") {
            if (value === "") {
                this.dimension[property as string] = this.schemaTitle;
                return;
            }
        }

        this.dimension[property as string] = value;

        if (!this.disableSorting && !this.dimension.overrideFromParent) {
            this.dimension.orderBy = { item: null, type: null };
        }

        this.dimensionUpdate.emit(this.dimension);
    }

    protected _orderByChange(): void {
        this.dimensionUpdate.emit(this.dimension);
    }
}
