import {
    Component,
    computed,
    inject,
    Inject,
    Injectable,
    signal,
    WritableSignal
} from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    LgPromptDialog,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";

import { CrosstabWidgetConfig } from "../../crosstab-widget.types";
import { FieldInfo, ReferenceSlot } from "../../../../types";
import { getAllowedFields, translateNullableName } from "../../../../utilities";
import { validateCrosstabConfiguration } from "./crosstab-configuration-validation";
import {
    getDropdownReferenceSlots,
    getReferenceDropdownDefinition
} from "../../../../utilities/references-helpers";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";
import { FlexibleLayoutDataSourcesService } from "../../../../services/flexible-layout-data-sources";

export interface CrosstabConfigurationDialogArguments extends CrosstabWidgetConfig {
    scheme: FieldInfo[];
    pageReferences: PageReferencesService;
    selectedReferences: ReferenceSlot[];
    dataSource: string;
}

export interface CrosstabConfigurationDialogResponse extends CrosstabWidgetConfig {}

export interface CrosstabConfigurationDialogComponent
    extends DialogMixin<CrosstabConfigurationDialogComponent>,
        ModalResultDialogMixin<
            CrosstabConfigurationDialogArguments,
            CrosstabConfigurationDialogResponse
        > {}

interface DropdownEntry<T = string> {
    id: T;
    name: string;
}

@Component({
    standalone: false,
    selector: "lgflex-crosstab-configuration-dialog",
    templateUrl: "./crosstab-configuration-dialog.component.html",
    providers: [...useTranslationNamespace("_Flexible._CrosstabConfigurationDialog")]
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class CrosstabConfigurationDialogComponent
    implements
        IDialogComponent<CrosstabConfigurationDialogComponent, CrosstabConfigurationDialogResponse>,
        DialogMixin<CrosstabConfigurationDialogComponent>
{
    protected readonly _layoutDataSources: FlexibleLayoutDataSourcesService = inject(
        FlexibleLayoutDataSourcesService
    );

    protected readonly _definitions: IDefinitions<any> = inject(LG_APP_DEFINITIONS);

    readonly _lgTranslate = inject(LgTranslateService);

    readonly _promptDialog = inject(LgPromptDialog);

    readonly _dialogRef = inject(LgDialogRef<CrosstabConfigurationDialogComponent>);

    constructor() {
        this._initMixins();
    }

    readonly CROSSTAB_CELL_MAX_WIDTH = 1000;

    _title = this._lgTranslate.translate(".DialogTitle");

    _dialogClass = "lg-dialog lg-dialog--5col lg-dialog--no-spacing";

    _widgetName: string | undefined;
    _widgetDescription: string | undefined;
    _widgetTooltip: string | undefined;
    _widgetTooltipLink: string | undefined;

    protected _horizontalDimension: WritableSignal<string | undefined> = signal(null);
    protected _verticalDimension: WritableSignal<string | undefined> = signal(null);
    protected _valueField: WritableSignal<string | undefined> = signal(null);
    protected _referenceSlot: WritableSignal<number | null> = signal(null);
    protected _referenceSlotDropdown: WritableSignal<IDropdownDefinition<number> | undefined> =
        signal(null);

    _sidebarWidth: number | undefined;
    _cellWidth: number | undefined;

    protected _dataSourceCode = signal(null);
    protected _scheme: WritableSignal<FieldInfo[]> = signal(null);
    protected _references = signal(null);
    protected _selectedReferences = signal(null);
    protected _isReferenceSlotsValid = signal(true);
    protected _isDefaultDataSource = computed(
        () => this._dataSourceCode() === this._layoutDataSources.defaultLayoutDataSourceCode()
    );

    protected _nonValueEntries = computed(() => {
        return getAllowedFields(
            this._scheme(),
            [this._horizontalDimension(), this._verticalDimension(), this._valueField()],
            false
        ).map(x => ({
            id: x.field,
            name: translateNullableName(this._lgTranslate, x.name, x.nameLc)
        }));
    });

    protected _horizontalDimensionDropdown = computed(() =>
        this._createDropdown(
            this._nonValueEntries().filter(x => x.id !== this._verticalDimension())
        )
    );

    protected _verticalDimensionDropdown = computed(() =>
        this._createDropdown(
            this._nonValueEntries().filter(x => x.id !== this._horizontalDimension())
        )
    );

    protected _valueFieldDropdown = computed(() => {
        return this._createDropdown(
            getAllowedFields(
                this._scheme(),
                [this._horizontalDimension(), this._verticalDimension(), this._valueField()],
                true
            ).map(x => ({
                id: x.field,
                name: translateNullableName(this._lgTranslate, x.name, x.nameLc)
            }))
        );
    });

    protected _enableReferenceDropdown = computed(
        () =>
            this._scheme().find(item => item.field === this._valueField())?.isReferenceBound ??
            false
    );

    // ----------------------------------------------------------------------------------
    //
    async _activate(): Promise<void> {
        this._widgetName = this._args.title ?? "";
        this._widgetDescription = this._args.description ?? "";
        this._widgetTooltip = this._args.tooltip ?? "";
        this._widgetTooltipLink = this._args.tooltipLink ?? "";

        this._referenceSlot.set(this._args.referenceIdx ?? null);

        await this._setDataSource(this._args.dataSource ?? null);
        this._onSelectedReferences(
            this._args.selectedReferences ?? [{ referenceCode: null, isLocked: false }]
        );
        this._horizontalDimension.set(this._args.horizontalDimension);
        this._verticalDimension.set(this._args.verticalDimension);
        this._valueField.set(this._args.value);

        this._sidebarWidth = this._args.sidebarWidth ?? 340;
        this._cellWidth = this._args.cellWidth ?? 110;
    }

    _createDropdown<T>(entries: Array<DropdownEntry<T>>): IDropdownDefinition<T> {
        return dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries
        });
    }

    _createHorizontalDimensionDropdown(dimension: string): void {
        this._horizontalDimension.set(dimension);
    }

    _createVerticalDimensionDropdown(dimension: string): void {
        this._verticalDimension.set(dimension);
    }

    _onValueFieldChange(value: string): void {
        this._valueField.set(value);
        if (this._enableReferenceDropdown() === false) {
            this._referenceSlot.set(null);
        }
    }

    // ----------------------------------------------------------------------------------
    //
    _isValid(): boolean {
        return (
            validateCrosstabConfiguration(
                {
                    title: this._widgetName,
                    description: this._widgetDescription,
                    horizontalDimension: this._horizontalDimension(),
                    verticalDimension: this._verticalDimension(),
                    value: this._valueField(),
                    referenceIdx: this._referenceSlot() ?? undefined,
                    sidebarWidth: this._sidebarWidth,
                    cellWidth: this._cellWidth
                },
                this._scheme(),
                !this._isDefaultDataSource()
                    ? this._selectedReferences()
                    : this._args.pageReferences.slots
            ) && this._isReferenceSlotsValid()
        );
    }

    // ----------------------------------------------------------------------------------
    //
    async _save(): Promise<boolean> {
        if (
            this._horizontalDimension == null ||
            this._verticalDimension == null ||
            this._valueField == null ||
            this._sidebarWidth == null ||
            this._cellWidth == null
        )
            throw Error("Some configuration parameters are undefined.");

        this._resolve({
            title: this._widgetName,
            description: this._widgetDescription,
            tooltip: this._widgetTooltip,
            tooltipLink: this._widgetTooltipLink,
            horizontalDimension: this._horizontalDimension(),
            verticalDimension: this._verticalDimension(),
            value: this._valueField(),
            referenceIdx: this._referenceSlot() ?? undefined,
            sidebarWidth: this._sidebarWidth,
            cellWidth: this._cellWidth,
            selectedReferences: this._selectedReferences(),
            dataSource: this._dataSourceCode()
        });

        return true;
    }

    protected async _onDataSourceChange(dataSource: string): Promise<void> {
        await this._setDataSource(dataSource);
        this._initializeReferences();
    }

    protected _onSelectedReferences(references: ReferenceSlot[]): void {
        this._selectedReferences.set(references);
        this._initializeReferences();
    }

    protected _onReferenceSlotChange(referenceSlot: number): void {
        this._referenceSlot.set(referenceSlot);
    }

    private _initializeReferences(): void {
        if (!this._args.pageReferences.isAllowed()) return;

        const slots = !this._isDefaultDataSource()
            ? this._selectedReferences()
            : this._args.pageReferences.slots;

        this._referenceSlotDropdown.set(
            getReferenceDropdownDefinition(
                getDropdownReferenceSlots(slots, this._references(), this._lgTranslate)
            )
        );
    }

    private async _setDataSource(dataSourceCode?: string): Promise<void> {
        if (!dataSourceCode) {
            dataSourceCode = this._layoutDataSources.defaultLayoutDataSourceCode();
        }

        this._dataSourceCode.set(dataSourceCode);
        const { scheme, references } = await this._layoutDataSources.getDataSource(dataSourceCode);

        this._scheme.set(scheme);
        this._references.set(references);
    }

    _onClose(): void {
        this._close();
    }
}

@Injectable()
export class CrosstabConfigurationDialog extends getDialogFactoryBase(
    CrosstabConfigurationDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
