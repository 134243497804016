<ng-container *lgPanelHeader="let context; order: -1">
    <div
        *ngIf="_chartCurrentLevel > 0"
        class="lg-reference-dropdown-side-icon lg-reference-dropdown-side-icon--round-corners"
    >
        <lg-icon
            [class.disabled]="_isLoading$ | async"
            icon="icon-go-back"
            (click)="_chartGoUp()"
            [clickable]="true"
        ></lg-icon>
    </div>
</ng-container>

<ng-container *lgPanelHeader="let context; order: 1">
    <lgflex-widget-common-header
        [config]="_config"
        [isDataComplete]="_isDataComplete"
        [isStale$]="_isStale$"
        [calculationProgress$]="_calculationProgress$"
        [calculationError$]="_calculationError$"
    ></lgflex-widget-common-header>
</ng-container>

<ng-container *lgPanelIcons="let context; order: 1; whenMinimized: false">
    <lgflex-widget-common-icons
        [exportDisabled]="_chartLevelData.length === 0"
        [isLoading$]="_isLoading$"
        [isAutoConverted]="_isAutoConverted"
        [isConfigValid]="_isConfigValid"
        (export)="_export($event)"
        (edit)="showConfigurationUi()"
    ></lgflex-widget-common-icons>
</ng-container>

<ng-container *ngIf="_isConfigValid">
    <lg-stale-data-handler [isCalculating]="_isCalculating$ | async" />

    <div class="flexcol" *ngIf="_pivot != null; else pivotIsNull">
        <lg-stale-data-handler [isCalculating]="_isCalculating$ | async" />

        <lg-rectangle
            class="flex-flexible"
            #rect
            style="overflow: hidden; display: flex; align-items: center; justify-content: center"
            [lgLoaderOverlay]="(_isLoading$ | async) ?? false"
            [lgLoaderOverlayDelay]="100"
        >
            <lg-icon-chart
                style="display: block"
                *ngIf="_chartLevelData.length > 0 && rect.width > 0 && rect.height > 0"
                [data]="_chartLevelData"
                [width]="rect.width || 800"
                [height]="_getMin([rect.height, 500])"
                [formatterType]="_chartValueFormatter"
                [formatterOptions]="{ decimals: _chartValueDecimals }"
                (itemClick)="_chartOnClick($event)"
                [clickable]="true"
                [itemsLimit]="10"
                [tooltipItemsLimit]="11"
                [valueOpacityFn]="_chartGetColumnOpacity"
                [icon]="_getIcon()"
            ></lg-icon-chart>

            <div *ngIf="_chartLevelData?.length === 0">
                {{ "_Flexible.Widgets.NoDataWarning" | lgTranslate }}
            </div>
        </lg-rectangle>
    </div>

    <ng-template #pivotIsNull>
        <div class="flexcol" [lgLoaderOverlay]="(_isLoading$ | async) ?? false"></div>
    </ng-template>
</ng-container>

<div
    class="flexcol flexcol--full flexcol--center"
    *ngIf="!_isConfigValid"
    style="justify-content: center"
>
    {{ "_Flexible.Widgets.WidgetValidationError" | lgTranslate }}
</div>
