import { Component, Input } from "@angular/core";
import { of, timer } from "rxjs";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "app-counter",
    template: ` {{ intervalHandler | async | fmtMsToTime: true }} `,
    standalone: false
})
export class CounterComponent {
    @Input() startedAt: Date;

    intervalHandler = timer(0, 1000).pipe(
        switchMap(() => of(Date.now() - this.startedAt.valueOf()))
    );
}
