import {
    computed,
    inject,
    Injectable,
    signal,
    Injector,
    INJECTOR,
    EnvironmentInjector,
    OnDestroy
} from "@angular/core";
import * as _ from "lodash-es";
import { firstValueFrom, ReplaySubject } from "rxjs";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { LgConsole } from "@logex/framework/core";
import { useStaleData } from "@logex/load-manager";
import { urlConcat } from "@logex/framework/utilities";
import { HandleErrorsMixin } from "@logex/mixins";
import { mixins } from "@logex/mixin-flavors";

import { FieldInfo, MAX_LIMIT_ROWS, ReferenceSlot } from "../../types";
import { FlexibleDataset, FlexibleDatasetDataArgumentsGetter } from "../flexible-dataset";
import {
    IFlexDataSources,
    LG_FLEX_DATA_SOURCES_SERVICE
} from "../flex-data-sources/flex-data-sources.types";
import { PageReferencesService } from "../page-references/page-references.service";
import { FlexDataClientGateway } from "../flex-data-client/gateway/flex-data-client-gateway";
import { isDefinitionTypeField } from "../../utilities/isDefinitionTypeField";
import { GetFlexibleDatasetSchemeResponse } from "../flex-data-client/gateway/flex-data-client-gateway.types";
import { DataSourceConfig } from "./flexible-layout-data-sources.types";
import { FlexDataClientMetadataArguments } from "../flex-data-client/types/types";

export interface FlexibleLayoutDataSourcesService extends HandleErrorsMixin {}

@Injectable()
@mixins(HandleErrorsMixin)
export class FlexibleLayoutDataSourcesService implements OnDestroy {
    private readonly _injector = inject(INJECTOR);
    private readonly _lgConsole = inject(LgConsole);
    private readonly _pageReferences = inject(PageReferencesService);
    private readonly _gateway: FlexDataClientGateway = inject(FlexDataClientGateway);
    private readonly _definitions: IDefinitions<any> = inject(LG_APP_DEFINITIONS);
    private readonly _dataSourcesService: IFlexDataSources = inject(LG_FLEX_DATA_SOURCES_SERVICE);
    // private readonly _flexDataClient = inject(FlexDataClientService);

    private _defaultMetadataArgs: FlexDataClientMetadataArguments = {};
    private readonly _loadedDataSources = new Map<string, DataSourceConfig>();
    private readonly _fieldDatasetsMap = new Map<
        string,
        Array<{ dataset: FlexibleDataset; dataSourceCode: string }>
    >();

    private readonly _dataSourcesBaseConfig = computed(
        () =>
            new Map(
                this._dataSourcesService
                    ?.dataSourcesSignal()
                    ?.map(item => [item.code, item.rootUrl]) ?? []
            )
    );

    private readonly _defaultLayoutDataSourceCodeSignal = signal(null);

    private readonly dataSourceLoadedSubject = new ReplaySubject<void>(1);

    // ----------------------------------------------------------------------------------
    public readonly dataSourceLoaded$ = this.dataSourceLoadedSubject.asObservable();

    public readonly defaultLayoutDataSourceCode =
        this._defaultLayoutDataSourceCodeSignal.asReadonly();

    public async loadMultipleDataSources(dataSourceCodes: string[]): Promise<void> {
        // Destroy all loaded data sources
        this._reset();

        await Promise.all(dataSourceCodes.map(code => this._loadDataSource(code)));
    }

    public updateDefaultLayoutDataSource(dataSourceCode: string): void {
        this._defaultLayoutDataSourceCodeSignal.set(dataSourceCode);
    }

    public updateDefaultMetadataArgs(metadataArguments: FlexDataClientMetadataArguments): void {
        this._defaultMetadataArgs = metadataArguments;
    }

    public async getDataSource(dataSourceCode: string): Promise<DataSourceConfig> {
        if (!this._loadedDataSources.has(dataSourceCode)) {
            await this._loadDataSource(dataSourceCode);
        }

        return this._loadedDataSources.get(dataSourceCode);
    }

    public updateDataSourceSelectedReferences(
        widgetId: string,
        dataSourceCode: string,
        selectedReferences: ReferenceSlot[]
    ) {
        const dataSource = this._loadedDataSources.get(dataSourceCode);

        if (dataSource) {
            dataSource.selectedReferences.set(widgetId, selectedReferences);
        }
    }

    public removeDataSourceSelectedReferences(widgetId: string) {
        this._loadedDataSources.forEach((value, key) => {
            const { selectedReferences } = value;

            selectedReferences.delete(widgetId);
        });
    }

    public updateDatasetDefaultArgs(dataArgs: FlexibleDatasetDataArgumentsGetter) {
        this._loadedDataSources.forEach((value, key) => {
            const { dataset } = value;

            dataset.dataArguments = dataArgs;
        });
    }

    // needed only for validation, since it runs out of component context
    public getDataSourceScheme(dataSourceCode: string): FieldInfo[] {
        return this._loadedDataSources.get(dataSourceCode)?.scheme ?? [];
    }

    public getAllDataSourcesSchemes(): FieldInfo[] {
        const allSchemes = new Map<string, FieldInfo>();
        this._loadedDataSources.forEach((value, key) => {
            const { scheme } = value;

            scheme.forEach(fieldInfo => {
                allSchemes.set(fieldInfo.field, fieldInfo);
            });
        });

        return [...allSchemes.values()];
    }

    public getDatasetForField(field: string): FlexibleDataset[] {
        const fieldDatasets = this._fieldDatasetsMap.get(field);

        // TODO: I have no idea why would we need this. Find out, and remove if not needed. --AM
        // if (!fieldDatasets) {
        //     this._flexDataClient.dataset.dataArguments.references = () =>
        //         this._pageReferences.selected;
        //
        //     return [this._flexDataClient.dataset];
        // }
        if (!fieldDatasets) {
            throw new Error(`No datasets found for field ${field}`);
        }

        const defaultLayoutDataSourceCode = this._defaultLayoutDataSourceCodeSignal();
        return fieldDatasets.map(({ dataset, dataSourceCode }) => {
            if (dataSourceCode !== defaultLayoutDataSourceCode) {
                const selectedReferences = this.getSelectedReferencesForDataSource(dataSourceCode);
                dataset.dataArguments.references = () => selectedReferences;
                return dataset;
            }

            dataset.dataArguments.references = () => this._pageReferences.selected;
            dataset.dataArguments.orderBy = () => []; // The ordering is taking place on the client anyway

            return dataset;
        });
    }

    private getSelectedReferencesForDataSource(dataSourceCode: string): string[] {
        const allReferences = new Set<string>();

        const { selectedReferences } = this._loadedDataSources.get(dataSourceCode);
        if (selectedReferences != null) {
            selectedReferences.forEach(references =>
                references.forEach(reference => allReferences.add(reference.referenceCode))
            );
        }

        return [...allReferences];
    }

    private async _loadSchemeAndReferences(
        dataSourceUrl: string
    ): Promise<GetFlexibleDatasetSchemeResponse> | undefined {
        try {
            return firstValueFrom(
                this._gateway.getScheme(dataSourceUrl, this._defaultMetadataArgs)
            );
        } catch (e) {
            this._onException(e);
            return undefined;
        }
    }

    private async _loadDefinitions(fields: FieldInfo[]): Promise<void> {
        const required = fields
            .map(x => (!x.isValueField && isDefinitionTypeField(x) ? x.type : null))
            .filter((x): x is string => x != null);
        try {
            await firstValueFrom(this._definitions.load(...required));
        } catch (err: any) {
            this._lgConsole.error("Error loading definitions", err);
            throw err;
        }
    }

    private _configureDataset(
        dataSourceUrl: string,
        fields: FieldInfo[]
    ): [FlexibleDataset, Injector] {
        const injector = Injector.create({
            parent: this._injector,
            providers: [
                useStaleData(),
                {
                    provide: FlexibleDataset,
                    useClass: FlexibleDataset
                }
            ]
        });

        const _dataset = injector.get<FlexibleDataset>(FlexibleDataset);
        _dataset.metadataArguments = {
            ..._.mapValues(this._defaultMetadataArgs, x => () => x)
        };
        _dataset.configure({
            dataUrl: urlConcat(dataSourceUrl, "data"),
            maxRecordsLimit: MAX_LIMIT_ROWS,
            fields
        });

        return [_dataset, injector];
    }

    private async _loadDataSource(dataSourceCode: string): Promise<void> {
        try {
            const dataSourceUrl = this._dataSourcesBaseConfig().get(dataSourceCode);
            const scheme = await this._loadSchemeAndReferences(dataSourceUrl);

            if (!scheme) {
                throw new Error(`Scheme for dataSourceCode ${dataSourceCode} is not loaded`);
            }

            await this._loadDefinitions(scheme.fields);

            const [dataset, injector] = this._configureDataset(dataSourceUrl, scheme.fields);

            this._loadedDataSources.set(dataSourceCode, {
                scheme: scheme.fields,
                references: scheme.references,
                url: dataSourceUrl,
                dataset,
                selectedReferences: new Map(),
                injector
            });

            // TODO: Fix the order here. This should be done after the dataset is configured.
            this.dataSourceLoadedSubject.next();
            this._configureFieldsDatasetMap(dataset, dataSourceCode, scheme.fields);
        } catch (e) {
            this._lgConsole.error(`Error loading data source with code ${dataSourceCode}`, e);
            throw e;
        }
    }

    private _configureFieldsDatasetMap(
        dataset: FlexibleDataset,
        dataSourceCode: string,
        scheme: FieldInfo[]
    ) {
        scheme.forEach(schemeItem => {
            const fieldDatasets = this._fieldDatasetsMap.get(schemeItem.field) || [];
            const dataSourceExists = fieldDatasets.some(
                item => item.dataSourceCode === dataSourceCode
            );

            if (!dataSourceExists) {
                fieldDatasets.push({ dataset, dataSourceCode });
                this._fieldDatasetsMap.set(schemeItem.field, fieldDatasets);
            }
        });
    }

    private _reset() {
        this._loadedDataSources.forEach((value, key) => {
            (<EnvironmentInjector>value.injector).destroy();
        });
        this._loadedDataSources.clear();

        this._fieldDatasetsMap.clear();
    }

    ngOnDestroy() {
        this._reset();
    }
}
