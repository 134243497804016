import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    inject,
    Inject,
    Injectable,
    INJECTOR,
    Injector,
    ViewChild,
    ViewContainerRef
} from "@angular/core";
import { BehaviorSubject } from "rxjs";

import {
    DefinitionDisplayMode,
    IDefinitions,
    LG_APP_DEFINITIONS
} from "@logex/framework/lg-application";
import { LgConsole } from "@logex/framework/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    IQuickSettingsMenuRegularItem,
    LgDialogFactory,
    LgDialogRef,
    LgFrameworkIcons,
    LgPromptDialog,
    QuickSettingsMenuType
} from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";
import { mixins } from "@logex/mixin-flavors";
import { Level } from "../../../../components/lg-pivot-levels-selector/lg-pivot-levels-selector.component";
import {
    Leaf,
    LeafWarningIcon
} from "../../../../components/lg-tree-selector/lg-tree-selector.component";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../../../services/widget-types-registry";
import {
    COLUMN_WIDTH_DEFAULT,
    EmbeddedWidgetContext,
    FieldInfo,
    ICON_COLUMN_WIDTH,
    PivotTableColumn,
    PivotTableColumnDefault,
    PivotTableColumnDifference,
    PivotTableColumnDifferenceMode,
    PivotTableColumnFormula,
    PivotTableColumnFormulaFormatting,
    PivotTableColumnFormulaType,
    PivotTableColumnWidget,
    PivotTableLevel,
    ReferenceInfo,
    ReferenceSlot,
    WidgetConfigLeafData,
    WidgetUsage
} from "../../../../types";
import {
    CalcAstNodeFunctionCall,
    parseCalculate,
    translateNullableName
} from "../../../../utilities";
import {
    validateDifferenceColumn,
    validateFormulaColumn,
    validateTableColumns
} from "../../../../utilities/widget-configuration-validations";
import {
    getAllowedFields,
    validateDrilldownPivotConfiguration
} from "./drilldown-pivot-configuration-validation";
import {
    getDropdownReferenceSlots,
    getReferenceDropdownDefinition
} from "../../../../utilities/references-helpers";
import { getEmbeddedWidgetConfiguration } from "../../../../utilities/configurator-helpers";
import { FlexibleLayoutUpgraderService } from "../../../../services/flexible-layout-upgrader";
import {
    CONDITIONAL_FORMATTING_ID,
    DEFAULT_CONDITIONAL_FORMATTING_CONFIG
} from "../../../../components/conditional-formatting/conditional-formattings.types";
import { getFormatTypeFromValueField } from "../../../../utilities/getFormatTypeFromValueField";

export interface FlexiblePivotConfigurationDialogSettings {
    forceIgnoreOwnFilters: boolean | null;
    allowDrilldown: boolean;
    isFlatTable: boolean;
}

export interface FlexiblePivotConfigurationDialogArguments {
    widgetName: string;
    widgetDescription: string;
    widgetTooltip: string;
    widgetTooltipLink: string;
    scheme: FieldInfo[];
    levels: string[][];
    levelProperties: Dictionary<PivotTableLevel>;
    isReadonly?: boolean;
    columns: PivotTableColumn[];
    pageReferences: PageReferencesService;
    ignoreOwnFilters?: boolean;
    isFlatTable?: boolean;
    allowDrilldown?: boolean;
    settings: FlexiblePivotConfigurationDialogSettings;
    defaultOrderBy?: string;
    dataSource?: string;
    selectedReferences?: ReferenceSlot[];
}

export interface FlexiblePivotConfigurationDialogResponse {
    widgetName: string;
    widgetDescription: string;
    widgetTooltip: string;
    widgetTooltipLink: string;
    levels: string[][];
    levelProperties: Dictionary<PivotTableLevel>;
    columns: PivotTableColumn[];
    ignoreOwnFilters: boolean;
    defaultOrderBy?: string;
}

export interface DrilldownPivotConfigurationDialogComponent
    extends DialogMixin<DrilldownPivotConfigurationDialogComponent>,
        ModalResultDialogMixin<
            FlexiblePivotConfigurationDialogArguments,
            FlexiblePivotConfigurationDialogResponse
        > {}

interface DropdownDefinitionEntry {
    code: string;
    name: string | null | undefined;
}

type OrderType = "asc" | "desc";

@Component({
    standalone: false,
    selector: "lgflex-drilldown-pivot-configuration-dialog",
    templateUrl: "./drilldown-pivot-configuration-dialog.component.html",
    providers: [...useTranslationNamespace("_Flexible._PivotConfigurationDialog")],
    styleUrls: ["./drilldown-pivot-configuration-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class DrilldownPivotConfigurationDialogComponent
    implements
        IDialogComponent<
            DrilldownPivotConfigurationDialogComponent,
            FlexiblePivotConfigurationDialogResponse
        >
{
    protected _lgConsole: LgConsole = inject(LgConsole);

    constructor(
        @Inject(INJECTOR) private _injector: Injector,
        @Inject(LG_APP_DEFINITIONS) public _definitions: IDefinitions<any>,
        public _lgTranslate: LgTranslateService,
        public _dialogRef: LgDialogRef<DrilldownPivotConfigurationDialogComponent>,
        public _promptDialog: LgPromptDialog,
        private _widgetTypes: WidgetTypesRegistry,
        private _changeDetectorRef: ChangeDetectorRef,
        private _viewContainerRef: ViewContainerRef,
        private _upgrader: FlexibleLayoutUpgraderService
    ) {
        this._initMixins();

        const visualizationWidgets = this._widgetTypes.getAllForUsage(WidgetUsage.Visualization);

        this._visualizationWidgetTypeDropdown = dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: [
                {
                    id: null,
                    name: "-"
                },
                {
                    id: CONDITIONAL_FORMATTING_ID,
                    name: this._lgTranslate.translate(
                        "_Flexible.ConditionalFormattingConfiguration.Title"
                    )
                },
                ...visualizationWidgets.map(x => ({
                    id: x.id,
                    name: this._lgTranslate.translate(x.nameLc)
                }))
            ]
        });
    }

    @ViewChild("visualizationInlineComponentContainer", { read: ViewContainerRef })
    private _visualizationInlineComponentContainer: ViewContainerRef | undefined;

    _dialogClass = "lg-dialog lg-dialog--6col lg-dialog--no-spacing";
    _title = this._lgTranslate.translate(".DialogTitle");

    _widgetName = "";
    _widgetDescription = "";
    _widgetTooltip = "";
    _widgetTooltipLink = "";
    _ignoreOwnFilters = false;
    _availableLevels: Level[] = [];
    _availableLevels$ = new BehaviorSubject<Level[]>(this._availableLevels);
    _selectedLevelsIds: string[][] = [];

    _columnsTree: Array<Leaf<WidgetConfigLeafData>> = [];
    _columnsTree$ = new BehaviorSubject<Array<Leaf<WidgetConfigLeafData>>>(this._columnsTree);

    _columns: PivotTableColumn[] = [];

    protected _currentColumnLeaf: Leaf<WidgetConfigLeafData> | null = null;
    _currentColumn: PivotTableColumn | null = null;
    _currentLevel: Level | null = null;
    _levelProperties: Dictionary<PivotTableLevel> = {};
    _hasDrilldown = false;

    _addColumnMenuDefinition: IQuickSettingsMenuRegularItem[] = [];

    _referencesDict: Dictionary<ReferenceInfo> = {};
    _referenceDropdown: IDropdownDefinition<number> | undefined;
    _fieldsDropdownDefinition: IDropdownDefinition<string> | undefined;

    protected _differenceModeDropdown: IDropdownDefinition<PivotTableColumnDifferenceMode> =
        dropdownFlat({
            entryId: "code",
            entryName: "name",
            entries: [
                {
                    code: "diff",
                    name: this._lgTranslate.translate(".Difference")
                },
                {
                    code: "growth",
                    name: this._lgTranslate.translate(".Growth")
                }
            ]
        });

    protected _pivotWidgetTypeDropdown: IDropdownDefinition<string> = dropdownFlat({
        entryId: "id",
        entryName: "name",
        entries: this._widgetTypes.getAllForUsage(WidgetUsage.Pivot).map(x => ({
            id: x.id,
            name: this._lgTranslate.translate(x.nameLc)
        }))
    });

    protected _visualizationWidgetTypeDropdown: IDropdownDefinition<string> | undefined;

    protected _displayModeDropdown: IDropdownDefinition<DefinitionDisplayMode> = dropdownFlat({
        entryId: "code",
        entryName: "name",
        entries: [
            {
                code: "code",
                name: this._lgTranslate.translate(".Code")
            },
            {
                code: "name",
                name: this._lgTranslate.translate(".Name")
            },
            {
                code: "codeAndName",
                name: this._lgTranslate.translate(".CodeAndName")
            }
        ]
    });

    _isDisplayModeDropdownVisible = false;
    _defaultDisplayMode: DefinitionDisplayMode = "code";

    _allowedValueFields: FieldInfo[] = [];
    _settings: FlexiblePivotConfigurationDialogSettings = {
        forceIgnoreOwnFilters: false,
        allowDrilldown: true,
        isFlatTable: false
    };

    _schemaLookup: Dictionary<FieldInfo> = {};

    protected _areReferencesAllowed = false;

    _orderByItemDropdown: IDropdownDefinition<string> = dropdownFlat({
        entryId: "code",
        entryName: "name",
        entries: []
    });

    _orderByTypeDropdown: IDropdownDefinition<OrderType> = dropdownFlat({
        entryId: "code",
        entryName: "name",
        entries: [
            {
                code: "asc",
                name: this._lgTranslate.translate(".Ascending")
            },
            {
                code: "desc",
                name: this._lgTranslate.translate(".Descending")
            }
        ]
    });

    _orderByItem: string | undefined = undefined;
    _orderByType: OrderType = "asc";
    _isReadOnly = false;

    // ----------------------------------------------------------------------------------
    //
    async _activate(): Promise<void> {
        this._settings = this._args.settings;
        this._widgetName = this._args.widgetName;
        this._widgetDescription = this._args.widgetDescription;
        this._widgetTooltip = this._args.widgetTooltip;
        this._widgetTooltipLink = this._args.widgetTooltipLink;
        this._isReadOnly = this._args.isReadonly ?? this._isReadOnly;
        const levelsSet = new Set<string>();
        this._args.levels.forEach(firstLevel =>
            firstLevel.forEach(secondLevel => levelsSet.add(secondLevel))
        );
        this._selectedLevelsIds = this._selectAvailableLevels(this._args.levels, this._args.scheme);
        this._levelProperties =
            this._args.levelProperties != null ? structuredClone(this._args.levelProperties) : {};
        this._ignoreOwnFilters =
            this._args.settings.forceIgnoreOwnFilters ?? this._args.ignoreOwnFilters ?? false;
        this._settings.allowDrilldown = this._args.allowDrilldown ?? this._settings.allowDrilldown;
        this._settings.isFlatTable = this._args.isFlatTable ?? this._settings.isFlatTable;
        this._schemaLookup = _.keyBy(this._args.scheme, x => x.field);
        this._areReferencesAllowed = this._args.pageReferences.isAllowed();
        this._normalizeColumns();

        this._referencesDict = _.keyBy(this._args.pageReferences.references, x => x.code);

        if (this._args.defaultOrderBy && this._args.defaultOrderBy[0] === "-") {
            this._orderByType = "desc";
            this._orderByItem = this._args.defaultOrderBy?.substring(1);
        } else {
            this._orderByItem = this._args.defaultOrderBy;
        }

        this._addColumnMenuDefinition = [
            {
                type: QuickSettingsMenuType.Choice,
                nameLC: ".Formula",
                onClick: () => {
                    this._addColumn({
                        id: 0,
                        title: this._lgTranslate.translate(".Formula"),
                        type: "formula",
                        formula: PivotTableColumnFormulaType.AMinusB,
                        variables: {
                            a: {
                                field: "",
                                variable: "a",
                                type: "variable"
                            },
                            b: {
                                field: "",
                                variable: "b",
                                type: "variable"
                            }
                        },
                        formatType: "float",
                        formatPrecision: 0,
                        formatForceSign: false
                    });
                    this._changeDetectorRef.markForCheck();
                }
            }
        ];

        if (this._pivotWidgetTypeDropdown.groups?.[0]?.entries?.length) {
            this._addColumnMenuDefinition.push({
                type: QuickSettingsMenuType.Choice,
                nameLC: ".Widget",
                onClick: () => {
                    this._addColumn({
                        title: this._lgTranslate.translate(".Widget"),
                        type: "widget"
                    } as PivotTableColumnWidget);
                    this._changeDetectorRef.markForCheck();
                }
            });
        }

        if (this._areReferencesAllowed) {
            this._addColumnMenuDefinition.push({
                type: QuickSettingsMenuType.Choice,
                nameLC: ".Difference",
                onClick: () => {
                    const initialField = this._allowedValueFields[0];
                    if (initialField === undefined) {
                        throw Error("No fields are available");
                    }

                    this._addColumn({
                        title: this._lgTranslate.translate(".Difference"),
                        type: "difference",
                        referenceLeft: 0,
                        referenceRight: 1,
                        field: initialField.field,
                        mode: "diff"
                    });
                    this._changeDetectorRef.markForCheck();
                }
            });
        }

        this._onLevelsChange();
    }

    // ----------------------------------------------------------------------------------
    //

    private _selectAvailableLevels(levels: string[][], scheme: FieldInfo[]): string[][] {
        const output = [];
        const deletedLvls = [];
        levels.forEach(level => {
            const resultLvl = [];
            level.forEach(code => {
                if (scheme.some(item => item.field === code)) {
                    resultLvl.push(code);
                } else {
                    deletedLvls.push(code);
                }
            });
            output.push(resultLvl);
        });
        if (deletedLvls.length !== 0) {
            this._lgConsole.warn(
                `Selected levels ${deletedLvls} have been removed from the configuration, because they do not exist in the fields scheme.`
            );
        }
        return output;
    }

    protected _normalizeColumns(): void {
        this._columns = validateTableColumns(
            this._args.columns,
            this._args.pageReferences,
            this._args.scheme
        )
            ? _.cloneDeep(this._args.columns)
            : [];
        const allColumns = this._args.scheme.filter(x => x.isValueField);

        const regularColumns = allColumns.filter(x => x.isValueField && !x.isReferenceBound);
        const regularColumnNames = regularColumns.map(x => x.field);

        const referenceColumns = allColumns.filter(x => x.isValueField && x.isReferenceBound);
        const referenceColumnNames = referenceColumns.map(x => x.field);

        // Remove columns that are not present in the scheme
        _.remove(
            this._columns,
            x =>
                x.type === "default" &&
                ((this._areReferencesAllowed &&
                    x.referenceIdx !== undefined &&
                    this._args.pageReferences.slots[x.referenceIdx] == null) ||
                    (x.referenceIdx === undefined && !regularColumnNames.includes(x.field)) ||
                    (x.referenceIdx !== undefined && !referenceColumnNames.includes(x.field)))
        );

        // Add missing columns
        const existingColumnNames = this._columns.map(x => x.type === "default" && x.field);
        const missingRegularColumns = regularColumns.filter(
            x => !existingColumnNames.includes(x.field)
        );

        _.times(this._args.pageReferences.slots.length, referenceIdx => {
            referenceColumns.forEach(refColumn => {
                if (
                    !this._columns.some(
                        x =>
                            x.type === "default" &&
                            x.referenceIdx === referenceIdx &&
                            x.field === refColumn.field
                    )
                ) {
                    this._columns.push({
                        type: "default",
                        referenceIdx,
                        field: refColumn.field,
                        isEnabled: false
                    });
                }
            });
        });

        this._columns.push(
            ...missingRegularColumns.map(x => ({
                type: "default" as const,
                field: x.field,
                isEnabled: false
            }))
        );

        // Group reference columns together
        this._rebuildTree();
    }

    private _setAllowedValueFields(): void {
        this._allowedValueFields = getAllowedFields(
            this._args.scheme,
            new Set(this._selectedLevelsIds.flat())
        );
    }

    private _createFieldsDropdownDefinition(): void {
        this._fieldsDropdownDefinition = dropdownFlat({
            entryId: "field",
            entryName: "name",
            entries: this._allowedValueFields.map(x => ({
                field: x.field,
                name: translateNullableName(this._lgTranslate, x.name, x.nameLc),
                isReferenceBound: x.isReferenceBound
            }))
        });
    }

    protected _refillLevels(): void {
        this._availableLevels = [
            ...this._args.scheme
                .filter(x => !x.isValueField)
                .map(x => ({
                    field: x.field,
                    name: this._renderNameWithTitle(
                        x.name,
                        x.nameLc,
                        this._levelProperties[x.field]?.title
                    ),
                    isBlocked: this._isLevelBlocked(x)
                }))
        ];
        this._availableLevels$.next(this._availableLevels);
    }

    protected _isLevelBlocked(field: Partial<FieldInfo>): boolean {
        if (!field.blockedBy || !field.blockedBy.length) return false;

        const selectedColumnNames = this._columns
            .filter((x): x is PivotTableColumnDefault => !!x.isEnabled && x.type === "default")
            .map(x => x.field);

        // Acknowledge fields participating in formulas
        const selectedColumnsWithCalculations = this._args.scheme.filter(
            x => selectedColumnNames.includes(x.field) && x.calculate
        );
        for (const column of selectedColumnsWithCalculations) {
            const parsed = parseCalculate(column.calculate!) as CalcAstNodeFunctionCall;
            selectedColumnNames.push(...parsed.params);
        }

        return !!_.intersection(field.blockedBy, selectedColumnNames).length;
    }

    protected _rebuildTree(): void {
        const columnsSorted = [];
        this._columnsTree = [];

        const sortedReferences: number[] = [];
        for (const column of this._columns) {
            if (column.type === "default" && column.referenceIdx != null) {
                // A reference column
                if (sortedReferences.includes(column.referenceIdx)) continue;

                const items = this._columns.filter(
                    (x): x is PivotTableColumnDefault =>
                        x.type === "default" && x.referenceIdx === column.referenceIdx
                );

                columnsSorted.push(...items);

                this._columnsTree.push({
                    name: this._renderReferenceName(column.referenceIdx),
                    isSelectable: false,
                    children: items.map(defaultColumn => ({
                        name: this._renderColumnName(defaultColumn),
                        isChecked: defaultColumn.isEnabled,
                        isDisabled: this._isColumnDisabled({
                            ...defaultColumn,
                            referenceIdx: column.referenceIdx
                        }),
                        data: {
                            key: {
                                field: defaultColumn.field,
                                referenceIdx: column.referenceIdx
                            },
                            column: defaultColumn
                        }
                    }))
                });

                sortedReferences.push(column.referenceIdx);
            } else {
                // Non-reference column
                this._columnsTree.push({
                    name: this._renderColumnName(column),
                    isChecked: column.isEnabled,
                    isDisabled: this._isColumnDisabled(column),
                    data: {
                        key: {
                            field:
                                column.type === "widget"
                                    ? column.widgetType
                                    : column.type === "formula"
                                      ? this._getFormulaFakeField(column)
                                      : column.field
                        },
                        column
                    },
                    warningIcons: this._getIconsForColumn(column)
                });

                columnsSorted.push(column);
            }
        }

        this._columnsTree$.next(this._columnsTree);
        this._columns = columnsSorted;
    }

    private _getFormulaFakeField(column: PivotTableColumnFormula): string {
        // this will only serve as the column identifier inside the data field of LeafData
        return column.formula + "_" + JSON.stringify(column.variables);
    }

    private _updateDrilldownFlag(): void {
        this._hasDrilldown = this._selectedLevelsIds.length > 1;
        if (this._hasDrilldown && this._settings.forceIgnoreOwnFilters !== true)
            this._ignoreOwnFilters = false;
    }

    private _renderColumnName(column: PivotTableColumn): string {
        const fieldInfo = this._args.scheme.find(
            x => column.type === "default" && x.field === column.field
        );

        return this._renderNameWithTitle(fieldInfo?.name, fieldInfo?.nameLc, column.title);
    }

    private _renderNameWithTitle(
        name: string | null | undefined,
        nameLc: string | null | undefined,
        title: string | undefined
    ): string {
        let titleRes: string | undefined = title?.trim();
        titleRes = titleRes === "" ? undefined : titleRes;
        const nameRes =
            name == null && nameLc == null
                ? undefined
                : translateNullableName(this._lgTranslate, name, nameLc);

        return nameRes && !titleRes
            ? nameRes
            : nameRes && titleRes
              ? nameRes + ` (${titleRes})`
              : !nameRes && titleRes
                ? titleRes
                : "-";
    }

    private _isColumnDisabled(column: PivotTableColumn): boolean {
        return (
            column.type === "default" &&
            !this._allowedValueFields.some(field => field.field === column.field)
        );
    }

    private _renderReferenceName(referenceIdx: number): string {
        if (this._args.pageReferences.slots[referenceIdx] === undefined) {
            return this._lgTranslate.translate(".Unknown");
        }

        const reference =
            this._referencesDict[this._args.pageReferences.slots[referenceIdx].referenceCode ?? ""];

        return (
            this._lgTranslate.translate(".Reference") +
            " " +
            (referenceIdx + 1) +
            (reference
                ? ` (${translateNullableName(this._lgTranslate, reference.name, reference.nameLc)})`
                : "")
        );
    }

    private _getIconsForColumn(column: PivotTableColumn): LeafWarningIcon[] {
        switch (column.type) {
            case "difference":
                if (
                    !validateDifferenceColumn(
                        column,
                        this._args.pageReferences,
                        this._allowedValueFields
                    )
                ) {
                    return [
                        {
                            icon: LgFrameworkIcons.Warning,
                            title: this._lgTranslate.translate(".Unconfigured")
                        }
                    ];
                }
                return [];
            case "formula":
                if (
                    !validateFormulaColumn(
                        column,
                        this._args.pageReferences,
                        this._allowedValueFields
                    )
                ) {
                    return [
                        {
                            icon: LgFrameworkIcons.Warning,
                            title: this._lgTranslate.translate(".Unconfigured")
                        }
                    ];
                }
                return [];
            case "default":
            default:
                return [];
        }
    }

    private _refillDropdownDefinitions(): void {
        if (
            this._currentColumn === null ||
            (this._currentColumn.type !== "difference" && this._currentColumn.type !== "formula") ||
            !this._areReferencesAllowed
        )
            return;

        this._referenceDropdown = getReferenceDropdownDefinition(
            getDropdownReferenceSlots(
                this._args.pageReferences.slots,
                this._args.pageReferences.references,
                this._lgTranslate
            )
        );
    }

    _onReferenceUpdate(reference: "left" | "right", selectedValue: number): void {
        if (this._currentColumn === null) throw Error("Current column shouldn't be undefined.");

        if (
            this._currentColumn.type === "difference" &&
            this._currentColumn.referenceLeft === this._currentColumn.referenceRight
        ) {
            const nextAvailableOption = _.times(this._args.pageReferences.slots.length)
                .filter(x => x !== selectedValue)
                .shift();

            if (nextAvailableOption !== undefined) {
                if (reference === "left") {
                    this._currentColumn.referenceRight = nextAvailableOption;
                }
                if (reference === "right") {
                    this._currentColumn.referenceLeft = nextAvailableOption;
                }
            }
        }
        this._onColumnPropertyChange();
    }

    protected _setPivotWidgetType(widgetTypeName: string): void {
        if (this._currentColumn === null || this._currentColumn.type !== "widget") return;

        const widgetType = this._widgetTypes.get(widgetTypeName);
        this._currentColumn.widgetType = widgetTypeName;
        this._currentColumn.title = this._lgTranslate.translate(widgetType.nameLc);
        this._onColumnPropertyChange();
    }

    protected _getCurrentVisualization(): string | null {
        if (this._currentColumn?.visualizationWidget) {
            return this._currentColumn.visualizationWidget.type;
        }
        if (this._currentColumn?.conditionalFormatting) {
            return CONDITIONAL_FORMATTING_ID;
        }
        return null;
    }

    protected async _setVisualizationWidgetType(visualizationId: string | null): Promise<void> {
        if (
            this._currentColumn === null ||
            this._visualizationInlineComponentContainer === undefined
        )
            return;

        const context: EmbeddedWidgetContext = {
            formatType: this._getColumnFormat(this._currentColumn)
        };

        this._currentColumn.visualizationWidget = await getEmbeddedWidgetConfiguration(
            this._injector,
            this._widgetTypes,
            this._args.pageReferences,
            this._upgrader,
            this._visualizationInlineComponentContainer,
            this._currentColumn.visualizationWidget,
            visualizationId,
            context
        );

        if (visualizationId === CONDITIONAL_FORMATTING_ID) {
            this._currentColumn.visualizationWidget = undefined;
            this._currentColumn.conditionalFormatting =
                this._currentColumn.conditionalFormatting ??
                structuredClone(DEFAULT_CONDITIONAL_FORMATTING_CONFIG);
        } else {
            this._currentColumn.conditionalFormatting = undefined;
        }
    }

    private _getColumnFormat(column: PivotTableColumn): PivotTableColumnFormulaFormatting {
        switch (column.type) {
            case "default":
                return getFormatTypeFromValueField(this._schemaLookup[column.field]);
            case "difference":
                if (column.mode === "diff") {
                    return getFormatTypeFromValueField(this._schemaLookup[column.field]);
                } else {
                    return "percentage";
                }
            case "formula":
                return column.formatType;
            default:
                return "float";
        }
    }

    // ----------------------------------------------------------------------------------
    //
    _onLevelsChange(): void {
        this._setAllowedValueFields();
        this._createFieldsDropdownDefinition();
        this._refillLevelProperties();
        this._refillLevels();
        this._rebuildTree();
        this._updateDrilldownFlag();
        this._updateOrderByItemDropdown();
    }

    // ----------------------------------------------------------------------------------
    //
    _onColumnSelect(selected: Leaf<WidgetConfigLeafData>): void {
        this._currentColumnLeaf = selected;

        if (!selected) {
            this._currentColumn = null;
            return;
        }

        this._currentColumn = selected.data?.column ?? null;
        if (this._currentColumn && this._currentColumn.width == null) {
            this._currentColumn.width =
                this._currentColumn.type === "widget" ? ICON_COLUMN_WIDTH : COLUMN_WIDTH_DEFAULT;
        }

        this._currentLevel = null;
        this._refillDropdownDefinitions();
        requestAnimationFrame(() => {
            this._setVisualizationWidgetType(this._getCurrentVisualization());
            this._changeDetectorRef.markForCheck();
        });
    }

    // ----------------------------------------------------------------------------------
    //
    _onLevelSelect(selectedId: string): void {
        if (selectedId == null) {
            this._currentLevel = null;
            return;
        }

        this._currentLevel = this._availableLevels.find(l => l.field === selectedId) ?? null;
        this._currentColumn = null;
        this._currentColumnLeaf = null;
        this._updateDisplayModeDropdownVisibility();
    }

    _onColumnToggle(leaf: Leaf<WidgetConfigLeafData>): void {
        if (leaf.data?.column) {
            leaf.data.column.isEnabled = leaf.isChecked;
        }

        if (leaf.children) {
            for (const child of leaf.children) {
                if (child.data == null) throw Error("Child data shouldn't be undefined");
                child.data.column.isEnabled = child.isChecked;
            }
        }

        this._refillLevels();
        this._updateOrderByItemDropdown();
    }

    _onColumnPropertyChange(): void {
        this._rebuildTree();
        this._setVisualizationWidgetType(this._getCurrentVisualization());
    }

    _onLevelPropertyChange(title: string, field: string): void {
        this._levelProperties[field].title = title;
        this._refillLevels();
    }

    _onLevelDisplayModeChange(displayMode: DefinitionDisplayMode, field: string): void {
        this._levelProperties[field].displayMode = displayMode;
    }

    _onColumnReordering(sourceLeafSiblings: Array<Leaf<WidgetConfigLeafData>>): void {
        const sourceLeavesFlatten = _.flatMap(sourceLeafSiblings, x => x.children || [x]);
        const affectedColumns = _.map(sourceLeavesFlatten, x => x.data?.column).filter(
            (x): x is PivotTableColumn => x != null
        );
        const firstElementPosition = Math.min(
            ..._.map(affectedColumns, column => _.findIndex(this._columns, column))
        );

        _.map(affectedColumns, key => _.remove(this._columns, key));

        this._columns.splice(firstElementPosition, 0, ...affectedColumns);
        this._rebuildTree();
    }

    // ----------------------------------------------------------------------------------
    //
    _addColumn(configuration: PivotTableColumn): void {
        const row: PivotTableColumn = Object.assign(
            {
                isEnabled: true
            },
            configuration
        );

        this._columns.push(row);
        this._rebuildTree();
    }

    protected _getSchemaFieldTitle(fieldName: string | undefined): string {
        if (fieldName == null) return "";

        const field = this._schemaLookup[fieldName];

        if (field === undefined) {
            this._lgConsole.error(
                `Field ${fieldName} does not exist in the flexible dataset scheme`
            );
            return "";
        }

        return (
            field.name ?? (field.nameLc != null ? this._lgTranslate.translate(field.nameLc) : "")
        );
    }

    // ----------------------------------------------------------------------------------
    //
    _isValid(): boolean {
        return validateDrilldownPivotConfiguration(
            this._injector,
            {
                title: this._widgetName,
                description: this._widgetDescription,
                levels: this._selectedLevelsIds,
                levelProperties: this._levelProperties,
                columns: this._columns,
                defaultOrderBy: (this._orderByType === "desc" ? "-" : "") + this._orderByItem
            },
            this._args.pageReferences,
            this._args.scheme,
            this._widgetTypes,
            this._settings.allowDrilldown
        );
    }

    // ----------------------------------------------------------------------------------
    //
    async _save(): Promise<boolean> {
        const columns = this._columns
            .filter(col => col.isEnabled)
            .map((col, index) => ({
                ...col,
                title: col.title !== "" ? col.title : undefined,
                id: index
            }));

        this._resolve({
            widgetName: this._widgetName,
            widgetDescription: this._widgetDescription,
            widgetTooltip: this._widgetTooltip,
            widgetTooltipLink: this._widgetTooltipLink,
            levels: this._selectedLevelsIds,
            levelProperties: this._levelProperties,
            columns,
            ignoreOwnFilters: this._ignoreOwnFilters,
            defaultOrderBy: (this._orderByType === "desc" ? "-" : "") + this._orderByItem
        });

        return true;
    }

    private _refillLevelProperties(): void {
        // TODO: flattening IDs from all levels could be a problem, because we can get duplicate IDs,
        //  and it also means that if several levels have the same dimension, then the dimension will have the same properties
        const levelIds = this._selectedLevelsIds.flat();
        // Add new levels
        for (const levelId of levelIds) {
            // Add missing levels
            if (this._levelProperties[levelId] == null) {
                this._levelProperties[levelId] = {
                    title: "",
                    displayMode: undefined
                };
            }
        }

        // Remove non-existing levels
        Object.keys(this._levelProperties)
            .filter(key => levelIds.indexOf(key) === -1)
            .forEach(key => {
                delete this._levelProperties[key];
            });
    }

    private _getFieldByName(fieldName: string): FieldInfo {
        const field = this._schemaLookup[fieldName];

        if (field === undefined) {
            throw Error(`Field ${fieldName} does not exist in the flexible dataset scheme`);
        }

        return field;
    }

    private _isFieldDefinitionType(field: FieldInfo): boolean {
        return field.type !== "string" && field.type !== "number";
    }

    private _updateDisplayModeDropdownVisibility(): void {
        if (this._currentLevel == null) {
            this._isDisplayModeDropdownVisible = false;
            return;
        }

        const levelField = this._getFieldByName(this._currentLevel.field);

        if (!this._isFieldDefinitionType(levelField)) {
            this._isDisplayModeDropdownVisible = false;
            return;
        }

        this._isDisplayModeDropdownVisible = true;
        this._defaultDisplayMode =
            this._definitions.getSection(levelField.type)?.displayMode ?? "code";
    }

    _onColumnWidthChange(width: number): void {
        if (!this._currentColumn) return;
        this._currentColumn.width = width;
    }

    _onLevelWidthChange(width: number, field: string): void {
        this._levelProperties[field].width = width;
    }

    private _updateOrderByItemDropdown(): void {
        const entries: DropdownDefinitionEntry[] = [];
        this._selectedLevelsIds.forEach(level => {
            level.forEach(code => {
                entries.push({
                    code,
                    name: this._availableLevels.find(id => id.field === code)?.name
                });
            });
        });

        this._columnsTree.forEach((column, i) => {
            column.children?.forEach(child => {
                const code =
                    (child.data?.column as PivotTableColumnDefault | PivotTableColumnDifference)
                        .field + i;
                if (
                    child.isChecked &&
                    !child.isDisabled &&
                    entries.find(i => i.code === code) === undefined
                )
                    entries.push({
                        code,
                        name: child.name + " - " + column.name
                    });
            });
        });

        if (entries.length === 0) this._orderByItem = undefined;
        else if (entries.find(i => i.code === this._orderByItem) === undefined)
            this._orderByItem = entries[0].code;

        this._orderByItemDropdown = dropdownFlat({
            entryId: "code",
            entryName: "name",
            entries
        });
    }
}

@Injectable()
export class DrilldownPivotConfigurationDialog extends getDialogFactoryBase(
    DrilldownPivotConfigurationDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
