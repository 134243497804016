import { BehaviorSubject, Observable } from "rxjs";
import { LgConsole } from "@logex/framework/core";
import { ILoadersCollection } from "../load-manager";
import { isMaybeStaleDataV2, MaybeStaleData, CalculationError, CalculationProgress , CalculationErrorDto, CalculationProgressDto, TableStatusDto } from "./types";
import { combineCalculationProgress } from "./helpers/combineCalculationProgress";

type SubscriptionIsStaleInfo = {
    isStale: boolean;
    staleTables: Set<string>;
};

export class DatasetInfoV2 {
    constructor(
        public id: string,
        private _loaders: ILoadersCollection,
        private _lgConsole: LgConsole,
    ) {
    }

    // requiredDatasets: (() => DatasetInfoV2[]) | null = null;
    queryReloadCallback: ((wasStale: boolean, isStale: boolean) => boolean) | null = null;

    // Depending on the loader function there could be either one subscriptionId or an array of IDs.
    // If subscriptionId is undefined, it means that the dataset was not loaded yet.
    subscriptions: string[] = [];
    private _isStalePerSubscription = new Map<string, SubscriptionIsStaleInfo>();
    private _calculationProgressPerSubscription = new Map<string, CalculationProgress | null>();
    private _calculationErrorPerSubscription = new Map<string, CalculationErrorDto | null>();

    private _isStaleSubject = new BehaviorSubject<boolean>(false);
    private _staleTablesSubject = new BehaviorSubject<Set<string>>(new Set());
    private _isCalculatingSubject = new BehaviorSubject<boolean>(false);
    private _calculationProgressSubject = new BehaviorSubject<CalculationProgress | null>(null);
    private _calculationErrorSubject = new BehaviorSubject<CalculationError[] | null>(null);
    private _prevArgs: any[] | null = null;

    // TODO: Support aggregation of "is stale" flags from multiple subscriptions

    // ----------------------------------------------------------------------------------
    public onLoadingStart(args: any[]): void {
        this.cancel();
        this._prevArgs = args;
    }

    public onDataReceived(data: MaybeStaleData<any>): void {
        if (!isMaybeStaleDataV2(data)) {
            throw new Error("DatasetInfoV2 expects data to be in the V2 format");
        }

        this._lgConsole.debug(`Data received by "${this.id}"`, { isStale: data.isStale, subscriptions: data.subscriptions });

        this.subscriptions = data.subscriptions;

        this._isStalePerSubscription = new Map<string, SubscriptionIsStaleInfo>();
        this._calculationProgressPerSubscription = new Map<string, CalculationProgress>();
        this._calculationErrorPerSubscription = new Map<string, CalculationErrorDto>();
        for (const [subscription, isStale] of data.isStalePerSubscription) {
            this._isStalePerSubscription.set(subscription, { isStale, staleTables: new Set() });
            this._calculationProgressPerSubscription.set(subscription, null);
            // if (isStale) {
            //     this._calculationProgressPerSubscription.set(subscription, {
            //         percentage: 0,
            //         executingSteps: [],
            //     });
            // } else {
            //     this._calculationProgressPerSubscription.set(subscription, null);
            // }
        }

        this._updateIsStaleSubject(data.isStale);
        this._updateCalculationProgressSubject();
    }

    public onDataChanged(subscriptionId: string, changes: TableStatusDto[]): void {
        const staleTables = changes.filter(x => x.isStale).map(x => x.tableName);
        const isStale = staleTables.length > 0;
        this._isStalePerSubscription.set(subscriptionId, { isStale, staleTables: new Set(staleTables) });

        let combinedIsStale = false;
        const combinedStaleTables = new Set<string>();
        for (const x of this._isStalePerSubscription.values()) {
            combinedIsStale = combinedIsStale || x.isStale;
            for (const staleTable of x.staleTables) {
                combinedStaleTables.add(staleTable);
            }
        }

        this._lgConsole.debug(`Data changed for "${this.id}"`, { subscriptionId, changes, isStale, combinedIsStale, combinedStaleTables });

        const prevIsStale = this._isStaleSubject.value;
        this._updateIsStaleSubject(combinedIsStale);
        this._updateStaleTablesSubject(combinedStaleTables);
        this._updateCalculationProgressSubject();

        // Reload the dataset if required
        const queryReloadCallback = this.queryReloadCallback ?? this._defaultQueryReloadCallback;
        if (queryReloadCallback(prevIsStale, combinedIsStale)) {
            this._loaders.loadDatasets([this.id], true, this._prevArgs);
        }
    }

    // By default, we reload the dataset if it was stale, and now it is not stale
    private _defaultQueryReloadCallback(wasStale: boolean, isStale: boolean): boolean {
        return (wasStale && !isStale);
    }

    private _updateIsStaleSubject(isStale: boolean) {
        if (isStale !== this._isStaleSubject.value) {
            this._isStaleSubject.next(isStale);
        }
    }

    private _updateStaleTablesSubject(staleTables: Set<string>) {
        const prevStaleTables = this._staleTablesSubject.value;
        // TODO: Remove <any> cast when TS will declare new Set methods
        if ((<any>prevStaleTables).symmetricDifference(staleTables).size > 0) {
            this._staleTablesSubject.next(staleTables);
        }
    }

    public onCalculationProgress(subscriptionId: string, progress: CalculationProgressDto) {
        this._lgConsole.debug(`Calculation progress for "${this.id}"`, subscriptionId, progress);
        this._calculationProgressPerSubscription.set(subscriptionId, {
            percentage: progress.percentage,
            executingSteps: progress.executingSteps,
            isFinished: false,
        });
        this._updateCalculationProgressSubject();
    }

    public onCalculationFinished(subscriptionId: string) {
        this._lgConsole.debug(`Calculation finished for "${this.id}"`, subscriptionId);
        const currentProgress = this._calculationProgressPerSubscription.get(subscriptionId);
        if (currentProgress != null) {
            currentProgress.isFinished = true;
        } else {
            // Not really expected, but we can handle it
            this._calculationProgressPerSubscription.set(subscriptionId, {
                percentage: 100,
                executingSteps: [],
                isFinished: true,
            });
        }
        this._updateCalculationProgressSubject();
    }

    private _updateCalculationProgressSubject() {
        // If data is not stale, but there were a calculation going before, signal that calculation stopped
        if (!this._isStaleSubject.value) {
            if (this._calculationProgressSubject.value != null) {
                this._calculationProgressSubject.next({ percentage: 100, executingSteps: [], isFinished: true });
                this._isCalculatingSubject.next(false);
            }
            return;
        }

        const combinedProgress = combineCalculationProgress(Array.from(this._calculationProgressPerSubscription.values()));

        if (combinedProgress == null) {
            if (this._isCalculatingSubject.value) {
                this._isCalculatingSubject.next(false);
            }
            this._calculationProgressSubject.next(null);
            return;
        }

        // Turn on the "calculating" flag if the progress is between 0 and 100
        if (combinedProgress.percentage < 100 && !this._isCalculatingSubject.value) {
            this._isCalculatingSubject.next(true);
        }

        // Turn off the "calculating" flag if the progress is 100
        if (combinedProgress.percentage === 100 && this._isCalculatingSubject.value) {
            this._isCalculatingSubject.next(false);
        }

        this._calculationProgressSubject.next(combinedProgress);
    }

    public onCalculationError(subscriptionId: string, error: CalculationErrorDto) {
        this._lgConsole.error(`Calculation error for "${this.id}" ${subscriptionId}`, error);
        this._calculationErrorPerSubscription.set(subscriptionId, error);

        const errors: CalculationError[] = [];
        for (const [subscriptionId, error] of this._calculationErrorPerSubscription) {
            if (error == null ||
                errors.find(x => x.stepName === error.stepName) != null) continue;

            errors.push({
                stepName: error.stepName,
                errorMessage: error.errorMessage,
            });
        }

        if (errors.length === 0) {
            this._calculationErrorSubject.next(null);
        }

        this._calculationErrorSubject.next(errors);
    }

    get isStale(): boolean {
        return this._isStaleSubject.value;
    }

    get isStale$(): Observable<boolean> {
        return this._isStaleSubject.asObservable();
    }

    get staleTables$(): Observable<Set<string>> {
        return this._staleTablesSubject.asObservable();
    }

    get isCalculating(): boolean {
        return this._isCalculatingSubject.value;
    }

    get isCalculating$(): Observable<boolean> {
        return this._isCalculatingSubject.asObservable();
    }

    get calculationProgress$(): Observable<CalculationProgress | null> {
        return this._calculationProgressSubject.asObservable();
    }

    get calculationError$(): Observable<CalculationError[] | null> {
        return this._calculationErrorSubject.asObservable();
    }

    public reload() {
        const prevIsStale = this._isStaleSubject.value;
        const queryReloadCallback = this.queryReloadCallback ?? this._defaultQueryReloadCallback;
        if (queryReloadCallback(prevIsStale, prevIsStale)) {
            this._loaders.loadDatasets([this.id], true, this._prevArgs);
        }
    }

    cancel(): void {
        this._prevArgs = null;

        // if (this._isCalculating && this.jobs?.length > 0) {
        //     this._lgConsole.debug("Cancelling jobs", this.jobs);
        //     this._gateway.cancelJobs(this.jobs).subscribe();
        // }
    }
}
