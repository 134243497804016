import * as _ from "lodash-es";
import {
    Component,
    inject,
    Inject,
    Injectable,
    Injector,
    INJECTOR,
    OnDestroy,
    signal,
    ViewContainerRef
} from "@angular/core";
import { ReplaySubject, Subject, takeUntil } from "rxjs";
import {
    CdkDragDrop,
    CdkDragEnter,
    moveItemInArray,
    transferArrayItem
} from "@angular/cdk/drag-drop";

import { mixins } from "@logex/mixin-flavors";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { DialogMixin, ModalResultDialogMixin, TabbedMixin } from "@logex/mixins";
import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    LgDialogFactory,
    LgDialogRef,
    LgPromptDialog
} from "@logex/framework/ui-core";
import { LgConsole } from "@logex/framework/core";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import {
    LG_BOOKMARK_STORE,
    LgBookmarkModel,
    LgBookmarksStateService,
    LgFiltersPanelService,
    LgPanelGridColumnDef,
    LgPanelGridLeafDef,
    LgPanelGridRowDef
} from "@logex/framework/lg-layout";

import {
    DEFAULT_LAYOUTS,
    LayoutVariant,
    LayoutWidget,
    LayoutWidgetConfig,
    ReferenceSlot,
    WidgetConfigurator,
    WidgetTypeInfo,
    WidgetUsage,
    WIDGET_ICONS_MAP
} from "../../types";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../services/widget-types-registry";
import { extractPanelIds, translateNullableName } from "../../utilities";
import {
    FlexibleLayout,
    FlexibleLayoutLibraryEnum,
    FlexibleLayoutStorageService
} from "../../services/flexible-layout-storage";
import { LgIconSelectorOption } from "../lg-icon-selector/lg-icon-selector.component";
import { Block } from "./types/constants";
import { LibraryConfirmDialog } from "./components/library-confirm-dialog/library-confirm-dialog.component";
import { ReferenceSlotsEditorDialog } from "./components/reference-slots-editor-dialog/reference-slots-editor-dialog.component";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import {
    DataSource,
    IFlexDataSources,
    LG_FLEX_DATA_SOURCES_SERVICE
} from "../../services/flex-data-sources/flex-data-sources.types";
import { FlexibleLayoutUpgraderService } from "../../services/flexible-layout-upgrader";

import { FlexibleLayoutFilterFactory } from "../../services/flexible-filter-factory/flexible-layout-filter-factory";
import { LayoutBookmarksStore } from "../../services/layout-bookmarks-store";
import { FiltersLayoutGroup } from "../../services/flexible-filter-factory/flexible-filter-factory.types";
import { FlexDataClientMetadataArguments } from "../../services/flex-data-client/types/types";
import { getConfiguratorInstance } from "../../utilities/configurator-helpers";

import { FlexibleLayoutDataSourcesService } from "../../services/flexible-layout-data-sources";

// ----------------------------------------------------------------------------------
export interface LayoutEditorDialogArguments {
    metadataArguments: FlexDataClientMetadataArguments;
    layouts: LayoutVariant[];
    layoutId?: number;
    filtersLayout?: FiltersLayoutGroup[];
    defaultDataSource?: string;
}

export interface LayoutEditorDialogResponse {
    layoutId: number | null;
}

const FILTERSET_STATE_KEY = "layoutBookmarks";

interface WidgetConfig {
    id: string;
    type: string;
    config: LayoutWidgetConfig;
    configVersion: number;
    isValid: boolean;
    isUnknown: boolean;
    isConfigurable: boolean;
    deprecatedMessage: string | null;
    replacementDropdown?: IDropdownDefinition<string>;
    replacementDropdownActive?: boolean;
    convertedToMessage?: string;
}

interface WidgetTypeInfoWithDeprecation extends WidgetTypeInfo {
    deprecatedMessage: string | null;
}

export interface FlexibleLayoutEditorDialogComponent
    extends DialogMixin<FlexibleLayoutEditorDialogComponent>,
        ModalResultDialogMixin<LayoutEditorDialogArguments, LayoutEditorDialogResponse>,
        TabbedMixin {}

@Component({
    standalone: false,
    selector: "lgflex-flexible-layout-editor-dialog",
    templateUrl: "./flexible-layout-editor-dialog.component.html",
    styleUrls: ["./flexible-layout-editor-dialog.styles.scss"],
    providers: [
        FlexDataClientService,
        LgFiltersPanelService,
        FlexibleLayoutFilterFactory,
        LgBookmarksStateService,
        LayoutBookmarksStore,
        {
            provide: LG_BOOKMARK_STORE,
            useExisting: LayoutBookmarksStore,
            multi: true
        },
        ...useTranslationNamespace("_Flexible._LayoutEditor")
    ]
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class FlexibleLayoutEditorDialogComponent
    implements IDialogComponent<FlexibleLayoutEditorDialogComponent>, OnDestroy
{
    private _layoutDataSourcesService = inject(FlexibleLayoutDataSourcesService);

    constructor(
        @Inject(INJECTOR) private _injector: Injector,
        @Inject(LG_APP_DEFINITIONS) public _definitions: IDefinitions<any>,
        @Inject(LG_FLEX_DATA_SOURCES_SERVICE) private _dataSourcesService: IFlexDataSources,
        public _lgTranslate: LgTranslateService,
        public _promptDialog: LgPromptDialog,
        private _widgetTypes: WidgetTypesRegistry,
        public _dialogRef: LgDialogRef<FlexibleLayoutEditorDialogComponent>,
        private _referenceSlotsEditorDialog: ReferenceSlotsEditorDialog,
        private _flexibleLayoutStorage: FlexibleLayoutStorageService,
        private _libraryConfirmDialog: LibraryConfirmDialog,
        private _console: LgConsole,
        private _flexDataClient: FlexDataClientService,
        private _upgrader: FlexibleLayoutUpgraderService,
        viewRef: ViewContainerRef,
        private _filtersPanelService: LgFiltersPanelService,
        private _flexibleLayoutFilterFactory: FlexibleLayoutFilterFactory,
        private _layoutBookmarksStore: LayoutBookmarksStore,
        private _bookmarkState: LgBookmarksStateService
    ) {
        this._initMixins();
        this._promptDialog = this._promptDialog.bindViewContainerRef(viewRef);
        this._widgetTypeList = this._widgetTypes.getAllForUsage(WidgetUsage.Page).map(type => ({
            ...type,
            deprecatedMessage: this._getDepreciationMessage(type.id)
        }));
    }

    // Dialog configuration
    _dialogClass = "lg-dialog lg-dialog--7col";
    _title = this._getTitle;
    _dialogBodyClass = "flexible-layout-editor__body--no-spacing";
    _dialogButtons = null;
    _currentLayout: LayoutVariant | undefined;
    _layoutOptions: LgIconSelectorOption[] | undefined;

    _currentLayoutRows: Array<LgPanelGridRowDef | LgPanelGridLeafDef> = [];
    _currentLayoutRowsFactor = 0;

    _dataSourcesDropdown: IDropdownDefinition<string> | undefined;

    _dataSources: DataSource[] = [];
    _currentDataSourceCode: string | undefined;
    _currentDataSource: DataSource | undefined;

    _layoutId: number | null = null;
    _name = "";
    _description = "";
    _numReferences = 0;
    _referenceSlots: ReferenceSlot[] = [];

    _libraryId: number | null = null;

    _widgetConfigs: Record<string, WidgetConfig[]> = {};

    _dragOverPane: string | null = null;
    _draggingWidgetTypeIndex: number | undefined;

    _widgetTypeList: WidgetTypeInfoWithDeprecation[] = [];

    _isReadOnly = false;
    _isDefault = false;

    _isTemporary = false;
    private _parentLayout: FlexibleLayout | null = null;
    private _metadataArguments: FlexDataClientMetadataArguments = {};

    _isLoading$: Subject<boolean> = this._flexibleLayoutStorage.isLoading$;

    _arePageReferencesAllowed = false;
    _filterDefinition!: LgFilterSet;
    _currentBookmark: LgBookmarkModel | undefined;

    _isAnyFilterActive = false;

    private readonly _destroyed$ = new ReplaySubject<void>(1);
    private _pageReferences: PageReferencesService | null = null;
    protected _isReferenceSlotsValid = signal(true);

    // ----------------------------------------------------------------------------------
    async show(args: LayoutEditorDialogArguments): Promise<LayoutEditorDialogResponse> {
        this._metadataArguments = args.metadataArguments;

        // Fill layout selector options
        this._layoutOptions = this._args.layouts.map(x => ({
            id: x.code,
            icon: x.icon,
            name: this._lgTranslate.translate(x.nameLc)
        }));

        this._dataSources = this._dataSourcesService.dataSources;
        this._buildDataSourcesDropdown();

        // TODO: split the logic into two explicit scenarios: creating and editing layout
        let layout: FlexibleLayout | undefined;
        this._layoutId = args.layoutId ?? null;
        if (this._layoutId != null) {
            layout = this._flexibleLayoutStorage.getLayout(args.layoutId);
            if (layout?.libraryId === undefined) {
                throw Error("Layout library shouldn't be undefined.");
            }
            this._isReadOnly = this._flexibleLayoutStorage.isLibraryReadOnly(layout.libraryId);
        }
        const dataSourceCode =
            layout?.config?.dataSource ??
            args.defaultDataSource ??
            _.first(this._dataSources)?.code;
        if (dataSourceCode === undefined) {
            throw Error("Data sources are missing");
        }

        // Load source and references
        this._layoutDataSourcesService.updateDefaultLayoutDataSource(dataSourceCode);
        await this._loadDataSource(dataSourceCode);
        this._updateReferences(layout);

        if (layout !== undefined) {
            this._name = layout.name;
            this._description = layout.description;
            this._libraryId = layout.libraryId;

            this._isDefault = layout.isDefault;
            if (layout.isTemporary) {
                this._isTemporary = true;
                this._parentLayout =
                    layout.parentId != null
                        ? (this._flexibleLayoutStorage.getLayout(layout.parentId) ?? null)
                        : null;
            }
            const config = layout.config;
            if (config == null) throw Error("Layout config must be loaded.");

            this._widgetConfigs = _.chain(config.widgets)
                .cloneDeep()
                .tap(widgets => this._upgrader.migrateVersions(widgets))
                .map(x => {
                    return {
                        ...x,
                        isValid: false,
                        isUnknown: false,
                        deprecatedMessage: this._getDepreciationMessage(x.type),
                        replacementDropdown: this._getReplacementDropdown(x.type),
                        configVersion: x.configVersion,
                        isConfigurable: false,
                        convertedToMessage: undefined
                    };
                })
                .groupBy(x => x.panel)
                .value();

            await this._autoConvertUnknownWidgets();

            this._setPanelsLayout(config.panelsLayout);
        } else {
            this._widgetConfigs = {};
            const layoutCode = _.first(args.layouts)?.code;
            if (layoutCode === undefined) throw Error("No layouts are defined");
            this._setPanelsLayout(layoutCode);
        }

        this._validateWidgets();

        // TODO: Fix the mixin
        return null;
    }

    _getTitle(): string {
        let title = this._lgTranslate.translate(".DialogTitle");

        if (this._layoutId !== null && this._libraryId !== null) {
            title += " - " + this._name;
            const library = this._flexibleLayoutStorage.getLibrary(this._libraryId);
            title += ` (${this._lgTranslate.translate(".InLibrary")}: ${library?.name})`;

            if (!_.isEmpty(this._description)) {
                title += " - " + this._description;
            }
        }

        return title;
    }

    _getWidgetIcon(widgetType: string): string {
        return WIDGET_ICONS_MAP[widgetType] ?? "icon-user";
    }

    private _updateReferences(layout?: FlexibleLayout) {
        this._pageReferences = new PageReferencesService();
        this._pageReferences.references = this._flexDataClient.references;

        this._arePageReferencesAllowed = this._pageReferences.isAllowed();
        if (this._arePageReferencesAllowed && layout !== undefined) {
            this._referenceSlots = layout.config?.referenceSlots ?? this._referenceSlots;
        } else if (layout === undefined) {
            this._referenceSlots =
                this._pageReferences.slots.length > 0
                    ? _.clone(this._pageReferences.slots)
                    : [
                          {
                              isLocked: false,
                              referenceCode: this._pageReferences.references[0].code
                          }
                      ];
        }

        this._numReferences = this._referenceSlots.length;
        this._pageReferences.slots = this._referenceSlots;
    }

    private async _configureFlexibleDataset(): Promise<void> {
        if (this._currentDataSource == null) throw Error("Data source shouldn't be undefined.");

        await this._flexDataClient.init({
            dataSourceCode: this._currentDataSource.code,
            url: this._currentDataSource.rootUrl,
            metadataArguments: this._metadataArguments!,
            maxRecordsLimit: null
        });

        this._flexDataClient.dataset.metadataArguments = {
            ..._.mapValues(this._metadataArguments!, x => () => x)
        };

        this._flexDataClient.dataset.dataArguments = {
            filters: () => ({})
        };
    }

    private _processLayoutFilters(): void {
        if (!this._filterDefinition || this._layoutId == null) return;

        const layout = this._flexibleLayoutStorage.getLayout(this._layoutId);
        const layoutFilters = layout?.config?.filterSets;
        if (!layoutFilters) return;

        this._layoutBookmarksStore.setLayoutFilterSets(layoutFilters);
        const defaultBookmark = this._layoutBookmarksStore.getDefaultLayoutBookmark();
        if (defaultBookmark) {
            // we know only our store is active
            this._bookmarkState.selectBookmark(FILTERSET_STATE_KEY, 0, defaultBookmark.stateId!);
            this._filterDefinition.deserialize(defaultBookmark.parts, true);
        }
        this._bookmarkState
            .getCurrentBookmark(FILTERSET_STATE_KEY)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(bookmark => (this._currentBookmark = bookmark));
    }

    private _setFilterDefinition(): void {
        const scheme = this._layoutDataSourcesService.getAllDataSourcesSchemes();
        this._filterDefinition = this._flexibleLayoutFilterFactory
            .define()
            .configure(scheme, this._args.filtersLayout ?? [])
            .create(this);

        this._filterDefinition.onChanged
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => (this._isAnyFilterActive = this._filterDefinition.isAnyActive()));

        this._filtersPanelService.setSet(this._filterDefinition);
        this._filtersPanelService.setKey(FILTERSET_STATE_KEY);
        this._layoutBookmarksStore.setReadOnly(false);
        this._layoutBookmarksStore.setTextForEmptyGroup(
            this._lgTranslate.translate(".NoLayoutBookmarks")
        );
        this._processLayoutFilters();
    }

    // ----------------------------------------------------------------------------------

    //
    async _editReferences(): Promise<void> {
        const response = await this._referenceSlotsEditorDialog.show({
            numReferences: this._numReferences,
            referenceSlots: this._referenceSlots,
            availableReferences: this._pageReferences.references,
            widgets: this._getWidgets()
        });

        this._numReferences = response.numReferences;
        this._referenceSlots = response.referenceSlots;
        this._pageReferences.slots = this._referenceSlots;

        this._validateWidgets();
        this._isReferenceSlotsValid.set(true);
    }

    // ----------------------------------------------------------------------------------
    //
    _onLayoutChange(layout: string): void {
        this._setPanelsLayout(layout);
    }

    // ----------------------------------------------------------------------------------
    //
    private _convertWidget(widget: WidgetConfig, targetTypeId: string): void {
        const targetType = this._widgetTypes.get(targetTypeId);
        const newConfig = this._upgrader.replaceWidget(
            {
                id: "empty",
                panel: "empty",
                config: widget.config,
                configVersion: widget.configVersion,
                type: widget.type
            },
            targetTypeId
        );
        if (typeof newConfig === "string") {
            this._promptDialog.alertLc(".FailedConversion.Title", ".FailedConversion.Body", {
                localizationContext: { error: newConfig }
            });
        } else {
            widget.config = newConfig;
            widget.configVersion = targetType.configVersion;
            widget.type = targetTypeId;
            widget.isConfigurable = targetType.configurator !== undefined;
            widget.deprecatedMessage = null;
            this._validateWidget(widget);
            widget.replacementDropdown = this._getReplacementDropdown(targetTypeId);
        }
    }

    async _confirmAndConvertWidget(widget: WidgetConfig, targetTypeId: string): Promise<void> {
        const targetType = this._widgetTypes.get(targetTypeId);
        const choice = await this._promptDialog.confirmLc(
            ".ConfirmConversion.Title",
            ".ConfirmConversion.Body",
            { localizationContext: { target: this._lgTranslate.translate(targetType.nameLc) } }
        );
        if (choice === "cancel") return;
        this._convertWidget(widget, targetTypeId);
    }

    private _autoConvertUnknownWidgets(): void {
        for (const widget of Object.values(this._widgetConfigs).flat()) {
            if (!this._getWidgetConfigurator(widget)) {
                const replacements = this._upgrader.findPossibleReplacements(widget.type, false);
                if (replacements.length === 1) {
                    const targetTypeId = replacements.pop();
                    this._convertWidget(widget, targetTypeId);
                    widget.convertedToMessage = this._getConvertedToMessage(widget.type);
                }
            }
        }
    }

    private _getConvertedToMessage(widgetTypeId: string): string | null {
        const target = this._widgetTypes.tryGet(widgetTypeId);
        return target
            ? this._lgTranslate.translate(".WidgetConverted", {
                  target: this._lgTranslate.translate(target.nameLc)
              })
            : null;
    }

    private _setPanelsLayout(layout: string): void {
        this._currentLayout = _.find(DEFAULT_LAYOUTS, x => x.code === layout);

        if (this._currentLayout === undefined) throw Error("Specified layout not found.");

        const visiblePanelIds = [...extractPanelIds(this._currentLayout.layout)];

        for (const panelId of visiblePanelIds) {
            if (this._widgetConfigs[panelId] === undefined) {
                this._widgetConfigs[panelId] = [];
            }
        }

        const allPanelIds = _.keys(this._widgetConfigs);
        const disappearedPanels = _.difference(allPanelIds, visiblePanelIds);

        const lastVisiblePanelId = _.last(visiblePanelIds);

        if (lastVisiblePanelId === undefined) throw Error("Layout must have at least one panel");

        for (const panel of disappearedPanels) {
            const movedWidgets = this._widgetConfigs[panel];
            this._widgetConfigs[lastVisiblePanelId].push(...movedWidgets);
            this._widgetConfigs[panel] = [];
        }

        this._currentLayoutRows = this._getCurrentLayoutRows();
        this._currentLayoutRowsFactor = this._getCurrentLayoutRowsFactor();
    }

    // ----------------------------------------------------------------------------------
    //
    private _getWidgetConfigurator(config: WidgetConfig): WidgetConfigurator | undefined {
        const type = this._widgetTypes.tryGet(config.type);
        if (!type) return undefined;

        return getConfiguratorInstance(this._injector, type, this._pageReferences);
    }

    async _editWidget(pane: string, widgetIndex: number): Promise<void> {
        const widgetConfig = this._widgetConfigs[pane][widgetIndex];
        const configurator = this._getWidgetConfigurator(widgetConfig);

        widgetConfig.config = await configurator!.show(widgetConfig.config);
        this._validateWidget(widgetConfig);
    }

    _removeWidget(pane: string, widgetIndex: number): void {
        const [removedWidget] = this._widgetConfigs[pane].splice(widgetIndex, 1);
        this._layoutDataSourcesService.removeDataSourceSelectedReferences(removedWidget.id);
    }

    protected _duplicateWidget(pane: string, widgetIndex: number): void {
        const duplicatedWidget = structuredClone({
            ...this._widgetConfigs[pane][widgetIndex],
            id: this._generateWidgetId(),
            config: {
                ...structuredClone(this._widgetConfigs[pane][widgetIndex].config),
                title: `${this._widgetConfigs[pane][widgetIndex].config.title} Copy`
            }
        });
        this._widgetConfigs[pane].splice(widgetIndex + 1, 0, duplicatedWidget);
    }

    private _validateWidget(widgetConfig: WidgetConfig): void {
        const configurator = this._getWidgetConfigurator(widgetConfig);
        if (!configurator) {
            widgetConfig.isValid = false;
            widgetConfig.isUnknown = true;
            widgetConfig.isConfigurable = false;
        } else {
            widgetConfig.isValid = configurator.validate(widgetConfig.config);
            widgetConfig.isUnknown = false;
            widgetConfig.isConfigurable = true;
        }
    }

    private _validateWidgets(): void {
        Object.values(this._widgetConfigs)
            .flat()
            .forEach(widgetConfig => this._validateWidget(widgetConfig));
    }

    private _getWidgets(): LayoutWidget[] {
        return _.chain(this._widgetConfigs)
            .map((x, panel) =>
                x.map(y => ({
                    id: y.id,
                    panel,
                    type: y.type,
                    config: y.config,
                    configVersion: y.configVersion
                }))
            )
            .flatten()
            .value();
    }

    // ----------------------------------------------------------------------------------
    //
    _onEnter(pane: string, event: CdkDragEnter<WidgetConfig[]>): void {
        this._dragOverPane = pane;
    }

    _onLeave(): void {
        this._dragOverPane = null;
    }

    _onDrop(
        pane: string,
        event: CdkDragDrop<WidgetConfig[], WidgetConfig[] | WidgetTypeInfo[]>
    ): void {
        this._dragOverPane = null;

        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else if (event.previousContainer.id === Block.WidgetConfigs) {
            // assert
            if (this._draggingWidgetTypeIndex === undefined)
                throw Error(
                    "onDrop called without starting the drag operation (_widgetDragStart)."
                );

            const sourceType = event.previousContainer.data[
                this._draggingWidgetTypeIndex
            ] as WidgetTypeInfoWithDeprecation;

            this._widgetConfigs[pane].splice(event.currentIndex, 0, {
                id: this._generateWidgetId(),
                type: sourceType.id,
                config: {
                    title: this._lgTranslate.translate(sourceType.nameLc),
                    levels: [],
                    columns: [],
                    panelView: 1
                },
                isValid: false,
                isUnknown: false,
                isConfigurable: sourceType.configurator !== undefined,
                configVersion: sourceType.configVersion,
                deprecatedMessage: sourceType.deprecatedMessage
            });
        } else {
            transferArrayItem(
                event.previousContainer.data as WidgetConfig[],
                event.container.data,
                event.previousIndex,
                event.currentIndex
            );
        }
    }

    private _generateWidgetId(): string {
        return (
            Math.round(performance.now() * 1000000).toString(36) +
            Math.round(Math.random() * 10000).toString(36)
        );
    }

    _onDropBack(event: CdkDragDrop<WidgetTypeInfoWithDeprecation[], any>): void {
        // Do not remove if coming from itself
        if (event.previousContainer.id === Block.WidgetConfigs) return;

        transferArrayItem(
            event.previousContainer.data,
            [],
            event.previousIndex,
            event.currentIndex
        );
    }

    // We need these to preserve a fake item in the available configurations list

    _widgetDragStart(index: number): void {
        this._draggingWidgetTypeIndex = index;
        const el = this._widgetTypeList[index];
        this._widgetTypeList.splice(index, 0, el);
    }

    _widgetDrop(index: number): void {
        this._widgetTypeList.splice(index, 1);
    }

    // ----------------------------------------------------------------------------------
    //
    _getCurrentLayoutRows(): Array<LgPanelGridRowDef | LgPanelGridLeafDef> {
        if (this._currentLayout === undefined) {
            this._console.error("Current layout is undefined.");
            return [];
        }

        if ("rows" in this._currentLayout.layout) {
            return this._currentLayout.layout.rows;
        }

        if ("columns" in this._currentLayout.layout) {
            return [
                {
                    size: 12,
                    columns: this._currentLayout.layout.columns
                }
            ];
        }

        return this._currentLayout.layout;
    }

    _getCurrentLayoutRowsFactor(): number {
        return 12 / this._currentLayoutRows.length;
    }

    _currentLayoutCols(
        row: LgPanelGridRowDef | LgPanelGridLeafDef
    ): Array<LgPanelGridColumnDef | LgPanelGridLeafDef> {
        return "columns" in row ? row.columns : [row];
    }

    _currentColRows(
        col: LgPanelGridColumnDef | LgPanelGridLeafDef
    ): Array<LgPanelGridRowDef | LgPanelGridLeafDef> {
        return "rows" in col ? col.rows : [col];
    }

    _currentLayoutColsFactor(row: LgPanelGridRowDef | LgPanelGridLeafDef): number {
        return 12 / ("columns" in row ? row.columns.length : 1);
    }

    _currentLayoutColRowsFactor(col: LgPanelGridColumnDef | LgPanelGridLeafDef): number {
        return 12 / ("rows" in col ? col.rows.length : 1);
    }

    _isPaneHasWidgets(pane: string): boolean {
        return this._widgetConfigs?.[pane]?.length > 0;
    }

    _isDraggingOverPane(pane: string): boolean {
        return this._dragOverPane === pane;
    }

    // ----------------------------------------------------------------------------------
    //
    _isFormValid(): boolean {
        return (
            Object.keys(this._widgetConfigs).every(
                x =>
                    this._widgetConfigs[x].every(widget => widget.isValid) &&
                    (!this._arePageReferencesAllowed || this._referenceSlots.length > 0)
            ) && this._isReferenceSlotsValid()
        );
    }

    _showNormalLayoutButtons(): boolean {
        return this._layoutId !== null && !this._isReadOnly && !this._isTemporary;
    }

    _showTemporaryLayoutButtons(): boolean {
        return this._isTemporary;
    }

    _showTemporaryLayoutSaveButton(): boolean {
        return (
            this._isTemporary &&
            this._parentLayout !== null &&
            this._parentLayout.libraryId != null &&
            !this._flexibleLayoutStorage.isLibraryReadOnly(this._parentLayout.libraryId)
        );
    }

    // ----------------------------------------------------------------------------------
    //
    getLayoutToSave(): Omit<FlexibleLayout, "id" | "libraryId"> {
        if (this._currentDataSourceCode === undefined)
            throw Error("Data source code shouldn't be undefined.");
        if (this._currentLayout == null) throw Error("Current layout shouldn't be undefined.");

        return {
            name: this._name,
            description: this._description ?? "",
            config: {
                filterSets: this._layoutBookmarksStore.getStoreFilterSetsSync(),
                referenceSlots: this._referenceSlots,
                dataSource: this._currentDataSourceCode,
                panelsLayout: this._currentLayout.code,
                widgets: this._getWidgets()
            },
            isDefault: this._isDefault,
            parentId: this._parentLayout?.id ?? null,
            isTemporary: this._isTemporary,
            hasTemporary: false
        };
    }

    private async _bookmarksChangeConfirmation(): Promise<boolean> {
        if (this._currentBookmark?.wasModified) {
            const choice = await this._promptDialog.confirmLc(
                ".ConfirmUnsavedFilters.Title",
                ".ConfirmUnsavedFilters.Body"
            );
            if (choice === "cancel") return false;
        }
        return true;
    }

    async _saveLayout(): Promise<void> {
        if (this._layoutId == null) throw Error("Layout id shouldn't be undefined.");
        if (this._libraryId == null) throw Error("Library id shouldn't be undefined.");
        if (!(await this._bookmarksChangeConfirmation())) return;

        this._layoutId = await this._flexibleLayoutStorage.saveLayout({
            ...this.getLayoutToSave(),
            id: this._layoutId,
            libraryId: this._libraryId
        });

        this._resolve({
            layoutId: this._layoutId
        });

        this._close();
    }

    async _saveAsNewLayout(): Promise<void> {
        if (!(await this._bookmarksChangeConfirmation())) return;

        const libraries = this._flexibleLayoutStorage.libraries$.getValue();
        const defaultLibrary = libraries.find(
            lib => lib.typeId === FlexibleLayoutLibraryEnum.PERSONAL
        );
        const response = await this._libraryConfirmDialog.show({
            libraries,
            libraryId: this._libraryId ?? defaultLibrary?.id ?? undefined
        });

        this._libraryId = response.libraryId ?? null;
        this._name = response.name;
        this._description = response.description;

        const newLayoutId = await this._flexibleLayoutStorage.saveLayout({
            id: null,
            libraryId: this._libraryId,
            ...this.getLayoutToSave(),
            isTemporary: false,
            parentId: null
        });

        if (this._isTemporary) {
            await this._flexibleLayoutStorage.revertTemporaryLayout();
        }

        this._resolve({
            layoutId: newLayoutId
        });

        this._close();
    }

    async _deleteLayout(): Promise<void> {
        if (this._layoutId == null) throw Error("Layout id shouldn't be null");

        const result = await this._promptDialog.confirm(
            this._lgTranslate.translate(".ConfirmDelete.Title"),
            this._lgTranslate.translate(".ConfirmDelete.Body")
        );

        if (result === "ok") {
            await this._flexibleLayoutStorage.deleteLayout(this._layoutId);

            this._resolve({
                layoutId: this._flexibleLayoutStorage.getDefaultLayoutId()
            });

            this._close();
        }
    }

    // ----------------------------------------------------------------------------------
    //
    async _applyLayout(): Promise<void> {
        if (!(await this._bookmarksChangeConfirmation())) return;

        const id = await this._flexibleLayoutStorage.applyTemporaryLayout({
            ...this.getLayoutToSave(),
            id: this._layoutId,
            libraryId: this._libraryId
        });

        this._resolve({
            layoutId: id
        });

        this._close();
    }

    async _revertTemporaryLayout(): Promise<void> {
        await this._flexibleLayoutStorage.revertTemporaryLayout();

        this._resolve({
            layoutId: this._parentLayout?.id ?? this._flexibleLayoutStorage.getDefaultLayoutId()
        });

        this._close();
    }

    async _overrideByTemporaryLayout(): Promise<void> {
        if (this._layoutId == null) throw Error("Layout id shouldn't be undefined.");
        if (this._libraryId == null) throw Error("Library id shouldn't be undefined.");
        if (!(await this._bookmarksChangeConfirmation())) return;

        await this._flexibleLayoutStorage.overrideByTemporaryLayout({
            ...this.getLayoutToSave(),
            id: this._layoutId,
            libraryId: this._libraryId
        });

        this._resolve({
            layoutId: this._parentLayout?.id ?? this._layoutId
        });

        this._close();
    }

    // ----------------------------------------------------------------------------------
    //
    async _renameLayout(): Promise<void> {
        if (this._layoutId == null) throw Error("Layout id shouldn't be undefined.");

        const response = await this._libraryConfirmDialog.show({
            libraries: null,
            name: this._name,
            description: this._description
        });
        this._name = response.name;
        this._description = response.description;

        await this._flexibleLayoutStorage.renameLayout(
            this._layoutId,
            response.name,
            response.description
        );
    }

    private _buildDataSourcesDropdown(): void {
        this._dataSourcesDropdown = {
            groupId: "groupCode",
            groupName: "groupName",
            entryId: "code",
            entryName: "name",
            groups: _.chain(this._dataSources)
                .groupBy("groupCode")
                .map(dsg => ({
                    groupCode: dsg[0].groupCode,
                    groupName: translateNullableName(
                        this._lgTranslate,
                        dsg[0].groupName,
                        dsg[0].groupNameLc
                    ),
                    entries: dsg.map(ds => ({
                        code: ds.code,
                        name: translateNullableName(this._lgTranslate, ds.name, ds.nameLc)
                    }))
                }))
                .value()
        };
    }

    private _getDepreciationMessage(widgetTypeId: string): string | null {
        const type = this._widgetTypes.tryGet(widgetTypeId);
        if (!type?.deprecated) return null;
        const target = type.deprecatedReplacementId
            ? this._widgetTypes.tryGet(type.deprecatedReplacementId)
            : undefined;
        if (target) {
            return this._lgTranslate.translate(
                "_Flexible.Widgets.DeprecatedWidgetWithReplacement",
                { replacement: this._lgTranslate.translate(target.nameLc) }
            );
        } else {
            return this._lgTranslate.translate("_Flexible.Widgets.DeprecatedWidget");
        }
    }

    private _getReplacementDropdown(widgetTypeId: string): IDropdownDefinition<string> | undefined {
        if (this._isReadOnly) return undefined;
        const replacements = this._upgrader.findPossibleReplacements(widgetTypeId, false);
        if (replacements.length) {
            return {
                groups: [
                    {
                        entries: replacements.map(id => {
                            const targetType = this._widgetTypes.get(id);
                            return {
                                id,
                                name: this._lgTranslate.translate(".ConvertToWidget", {
                                    name: this._lgTranslate.translate(targetType.nameLc)
                                })
                            };
                        })
                    }
                ]
            };
        } else {
            return undefined;
        }
    }

    async onDataSourceChange(currentDataSourceCode: string): Promise<void> {
        this._layoutDataSourcesService.updateDefaultLayoutDataSource(currentDataSourceCode);
        await this._loadDataSource(currentDataSourceCode);
        const layout = this._flexibleLayoutStorage.getLayout(this._layoutId);
        this._updateReferences(layout);
        this._isReferenceSlotsValid.set(false);
    }

    async _loadDataSource(currentDataSourceCode: string): Promise<void> {
        this._currentDataSource = _.find(
            this._dataSources,
            source => source.code === currentDataSourceCode
        );
        if (this._currentDataSource === undefined) this._currentDataSource = this._dataSources[0];
        this._currentDataSourceCode = this._currentDataSource.code;
        await this._configureFlexibleDataset();
        await this._flexDataClient.loadRequiredDefinitions(this._definitions);

        this._setFilterDefinition();
        this._validateWidgets();
    }

    public _clearAllFilters(): void {
        if (this._filterDefinition) {
            this._filterDefinition.clearAll(false);
        }
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}

@Injectable()
export class LayoutEditorDialog extends getDialogFactoryBase(
    FlexibleLayoutEditorDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
