import { Directive, inject, OnDestroy, OnInit, ViewContainerRef } from "@angular/core";
import { BehaviorSubject, forkJoin, Observable, ReplaySubject, take, takeUntil } from "rxjs";
import { catchError } from "rxjs/operators";
import { mixins } from "@logex/mixin-flavors";
import { LgLoaderService } from "@logex/framework/lg-layout";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { HandleErrorsMixin } from "@logex/mixins";
import {
    IDefinitions,
    IScenario,
    LG_APP_DEFINITIONS,
    LG_APP_SESSION,
    LgStorageLoader
} from "@logex/framework/lg-application";
import { LgConsole } from "@logex/framework/core";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { AppSession } from "../common";

export type AppDefinitionsType<TAppDefinitions> = TAppDefinitions & IDefinitions<TAppDefinitions>;

export interface PageComponentBase<TAppDefinitions> extends HandleErrorsMixin {}

@Directive()
@mixins(HandleErrorsMixin)
export abstract class PageComponentBase<TAppDefinitions> implements OnInit, OnDestroy {
    protected _viewContainerRef = inject(ViewContainerRef);
    protected _lgLoaderService = inject(LgLoaderService);
    protected _storageLoader = inject(LgStorageLoader);
    // Not protected because they are used in mixins
    _lgTranslate = inject(LgTranslateService);
    _promptDialog: LgPromptDialog = inject(LgPromptDialog).bindViewContainerRef(
        this._viewContainerRef
    );

    _lgConsole = inject(LgConsole);

    protected _session = inject<AppSession<IScenario>>(LG_APP_SESSION);
    _definitions = inject<AppDefinitionsType<TAppDefinitions>>(LG_APP_DEFINITIONS);

    _initialized$ = new BehaviorSubject(false);
    _destroyed$ = new ReplaySubject<void>(1);

    get _initialized(): boolean {
        return this._initialized$.value;
    }

    protected abstract _activate(): void;

    protected abstract _getRequiredDefinitions(): any;

    public ngOnInit(): void {
        this._lgLoaderService.show(null, this._destroyed$);

        this._prepare()
            .pipe(take(1), takeUntil(this._destroyed$))
            .subscribe({
                next: () => {
                    this._lgLoaderService.hide();
                    this._initialized$.next(true);
                    this._activate();
                },
                error: err => {
                    this._lgLoaderService.hide();
                    this._lgConsole.error("Error initializing page", err);
                }
            });
    }

    public ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }

    protected _prepare(): Observable<any[]> {
        return forkJoin([
            this._definitions.load(...this._getRequiredDefinitions()),
            this._storageLoader.loadStorage(this)
        ]).pipe(
            catchError(err => {
                this._onServerFailure(err);
                throw err;
            })
        );
    }

    _translate(translationId: string, interpolateParams?: any): string;
    _translate(translationId: string[], interpolateParams?: any): { [key: string]: string };
    _translate(translationId: string | string[], interpolateParams?: any): any {
        return this._lgTranslate.translate(translationId as any, interpolateParams);
    }
}
