import { HttpErrorResponse } from "@angular/common/http";
import { inject, Injectable, OnDestroy, signal } from "@angular/core";
import { BehaviorSubject, combineLatest, firstValueFrom, Observable, Subject } from "rxjs";
import { map } from "rxjs/operators";
import { mixins } from "@logex/mixin-flavors";

import { LG_APP_SESSION } from "@logex/framework/lg-application";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { HandleErrorsMixin } from "@logex/mixins";
import { NullableBy, PageLayoutConfiguration } from "../../types";
import { FlexibleLayoutStorageGatewayService } from "./flexible-layout-storage-gateway.service";
import { CLIENT_LIBRARY_ID, PERSONAL_LIBRARY_ID } from "./types/constants";

import {
    CategorizedLayouts,
    FlexibleLayout,
    FlexibleLayoutLibrary,
    FlexibleLayoutLibraryEnum,
    GetFlexibleLayoutConfigResponse,
    GetFlexibleLayoutResponse,
    GetLibrariesResponse
} from "./types/types";
import { LgConsole } from "@logex/framework/core";
import {
    LayoutGroupItem,
    LayoutManagementGatewayAdapterService
} from "../../components/layout-management-dialog";
import { CatalogGroupLayout, CatalogLayout } from "../../components/lg-flexible-layouts-panel";
import { FlexDataClientMetadataArguments } from "../flex-data-client/types/types";

export type LayoutStorageSaveArguments = NullableBy<FlexibleLayout, "id" | "libraryId">;

export interface FlexibleLayoutStorageService extends HandleErrorsMixin {}

@Injectable()
@mixins(HandleErrorsMixin)
export class FlexibleLayoutStorageService implements OnDestroy {
    private _gateway = inject(FlexibleLayoutStorageGatewayService);
    private _layoutManagementGatewayAdapterService = inject(LayoutManagementGatewayAdapterService);
    private _session = inject(LG_APP_SESSION);
    _lgTranslate = inject(LgTranslateService);
    _promptDialog = inject(LgPromptDialog);
    _lgConsole = inject(LgConsole);

    constructor() {
        this._initMixins();
    }

    temporaryLayoutInProgress = signal(false);
    temporaryLayoutId: number | null = null;

    private _page: string | undefined;

    readonly libraries$ = new BehaviorSubject<FlexibleLayoutLibrary[]>([]);
    readonly layouts$ = new BehaviorSubject<FlexibleLayout[]>([]);
    readonly isLoading$ = new Subject<boolean>();

    private readonly _sharedGroupLayoutsSubject = new BehaviorSubject<LayoutGroupItem[]>([]);

    private _catalogLayoutsSubject = new BehaviorSubject<CatalogLayout[]>([]);
    private _metadataArgs: FlexDataClientMetadataArguments;
    readonly _catalogLayouts$ = this._catalogLayoutsSubject.asObservable();

    // -----------------------------------------------------------------------
    async load(page: string, metadataArgs: FlexDataClientMetadataArguments): Promise<void> {
        this.isLoading$.next(true);

        this._page = page;
        this._metadataArgs = metadataArgs;

        await Promise.all([
            this._loadLibraries(page),
            this._loadLayouts(),
            this.loadSharedCategorizedGroupLayouts()
        ]);

        this.isLoading$.next(false);
    }

    private async _loadLibraries(page: string): Promise<void> {
        let libraries: GetLibrariesResponse[];
        try {
            libraries = await firstValueFrom(
                this._gateway.getLibraries({
                    clientId: this._session.clientId,
                    scenarioId: this._session.scenarioId,
                    page
                })
            );
        } catch (e) {
            this._onServerFailure(e as HttpErrorResponse);
            throw e;
        }

        libraries.forEach(lib => {
            if (lib.typeId === FlexibleLayoutLibraryEnum.PERSONAL) {
                lib.name = this._lgTranslate.translate("_Flexible.Libraries.Personal");
                lib.id = PERSONAL_LIBRARY_ID;
            }
            if (lib.typeId === FlexibleLayoutLibraryEnum.CLIENT) {
                lib.name = this._lgTranslate.translate("_Flexible.Libraries.Client");
                lib.id = CLIENT_LIBRARY_ID;
            }
        });
        this.libraries$.next(libraries as FlexibleLayoutLibrary[]);
    }

    private async _loadLayouts(): Promise<void> {
        let layouts: GetFlexibleLayoutResponse[];
        try {
            layouts = await firstValueFrom(
                this._gateway.getLayouts({
                    ...this._metadataArgs,
                    page: this._page!
                })
            );
        } catch (e) {
            this._onServerFailure(e as HttpErrorResponse);
            throw e;
        }

        this.temporaryLayoutId = null;
        this.temporaryLayoutInProgress.set(false);

        layouts.forEach(layout => {
            layout.hasTemporary = layout.hasTemporary ?? false;
            if (layout.isTemporary) {
                const parent = layouts.find(l => l.id === layout.parentId);
                if (parent != null) {
                    parent.hasTemporary = true;
                }
                this.temporaryLayoutId = layout.id;
                this.temporaryLayoutInProgress.set(true);
            }

            if (layout.type === FlexibleLayoutLibraryEnum.PERSONAL) {
                layout.libraryId = PERSONAL_LIBRARY_ID;
            }

            if (layout.type === FlexibleLayoutLibraryEnum.CLIENT) {
                layout.libraryId = CLIENT_LIBRARY_ID;
            }
        });

        this.layouts$.next(layouts as FlexibleLayout[]);
    }

    // -----------------------------------------------------------------------
    async loadSharedCategorizedGroupLayouts(): Promise<void> {
        let sharedGroupLayouts: LayoutGroupItem[];
        try {
            sharedGroupLayouts = await firstValueFrom(
                this._layoutManagementGatewayAdapterService.loadSharedLayouts(
                    this._page,
                    this._metadataArgs
                )
            );
        } catch (e) {
            this._onServerFailure(e as HttpErrorResponse);
            throw e;
        }

        this._sharedGroupLayoutsSubject.next(sharedGroupLayouts as LayoutGroupItem[]);
    }

    getLibrary(id: number): FlexibleLayoutLibrary | undefined {
        const lib = this.libraries$.getValue().find(lib => lib.id === id);
        if (lib === undefined) {
            console.warn(`Couldn't find library with id: ${id}`);
        }
        return lib;
    }

    getCategorizedLayouts(): Observable<CategorizedLayouts[]> {
        return combineLatest([this.libraries$, this.layouts$]).pipe(
            map(([libs, layouts]) => {
                const sortedLibraries = libs.sort((a, b) => a.typeId - b.typeId);

                return sortedLibraries.map(lib => ({
                    libraryName: lib.name,
                    readOnly: lib.readOnly ?? false,
                    layouts: layouts
                        .filter(layout => layout.libraryId === lib.id && !layout.hasTemporary)
                        .sort((a, b) => a.name.localeCompare(b.name))
                }));
            })
        );
    }

    getSharedCategorizedGroupLayouts(): Observable<CatalogGroupLayout[]> {
        return combineLatest([
            this._sharedGroupLayoutsSubject.asObservable(),
            this.getCategorizedLayouts()
        ]).pipe(
            map(([sharedGroupLayouts, categorizedLayouts]) => {
                const sharedLayouts = categorizedLayouts.reduce((layouts, library) => {
                    const sharedLibraryLayouts = library.layouts
                        .filter(layout => layout.isShared && !layout.hasTemporary)
                        .map(layout => ({
                            ...layout,
                            readonly: library.readOnly
                        }));
                    return [...layouts, ...sharedLibraryLayouts];
                }, []);

                let temporaryLayoutParentId: undefined | number = undefined;
                const sharedGroupLayoutsMap = sharedLayouts.reduce((acc, sharedLayout) => {
                    if (sharedLayout.isTemporary) {
                        temporaryLayoutParentId = sharedLayout.parentId;
                    }
                    acc.set(sharedLayout.id, sharedLayout);
                    return acc;
                }, new Map<number, LayoutGroupItem>());

                const flatSharedGroupLayouts = sharedGroupLayouts
                    .map(layout => {
                        const isGroup =
                            layout.libraries === undefined && layout.dataSources === undefined;
                        if (isGroup) {
                            return layout;
                        } else {
                            const sharedGroupLayout =
                                temporaryLayoutParentId === layout.id
                                    ? sharedGroupLayoutsMap.get(this.temporaryLayoutId)
                                    : sharedGroupLayoutsMap.get(layout.id);
                            if (sharedGroupLayout === undefined) {
                                this._lgConsole.error(`Shared layout not found: ${layout.id}`);
                            }
                            return {
                                ...sharedGroupLayout,
                                sortOrder: layout.sortOrder,
                                groupId: layout.groupId,
                                showInNavigation: layout.showInNavigation
                            };
                        }
                    })
                    .sort((a, b) => a.sortOrder - b.sortOrder);

                const flatLayouts = flatSharedGroupLayouts.filter(layout => !layout.groupId);
                const layoutsInGroup = flatSharedGroupLayouts.filter(layout => layout.groupId);

                const personalLayouts = categorizedLayouts.reduce((layouts, library) => {
                    const libraryLayouts = library.layouts
                        .filter(layout => !layout.isShared && !layout.hasTemporary)
                        .map(layout => ({ ...layout, showInNavigation: false }));
                    return [...layouts, ...libraryLayouts];
                }, []);
                this._catalogLayoutsSubject.next([...personalLayouts, ...flatSharedGroupLayouts]);

                return flatLayouts.map(layout => {
                    const isGroup = layout.readonly === undefined;
                    if (isGroup) {
                        return {
                            id: layout.id,
                            name: layout.name,
                            sortOrder: layout.sortOrder,
                            layouts: layoutsInGroup.filter(item => item.groupId === layout.id)
                        };
                    }
                    return layout;
                });
            })
        );
    }

    async loadLayoutConfig(id: number | null): Promise<void> {
        if (id == null) {
            return;
        }
        this.isLoading$.next(true);

        const layout = this.getLayout(id);
        if (layout == null) {
            throw Error(`Cannot load config of unknown layout ${id}`);
        }

        const library = this.getLibrary(layout.libraryId);
        const clientId =
            library!.typeId === FlexibleLayoutLibraryEnum.CLIENT
                ? this._session.clientId
                : undefined;

        let config: GetFlexibleLayoutConfigResponse["config"] | undefined;
        try {
            config = (
                await firstValueFrom(this._gateway.getLayoutConfig({ ...this._metadataArgs, id }))
            )?.config;
        } catch (e) {
            this._onServerFailure(e as HttpErrorResponse);
            throw e;
        }

        if (config != null) {
            // Inject configVersion if missing
            config.widgets.forEach(widget => {
                if (widget.configVersion === undefined) widget.configVersion = 1;
            });
            // Inject filters if missing
            if (config.filterSets === undefined) {
                config.filterSets = [];
            }

            const layouts = this.layouts$.getValue();
            const layout = layouts.find(x => x.id === id);

            if (layout != null) {
                layout.config = config as PageLayoutConfiguration;
                this.layouts$.next(layouts);
            }
        }

        this.isLoading$.next(false);
    }

    // ----------------------------------------------------------------------------------
    async saveLayout(layout: LayoutStorageSaveArguments): Promise<number> {
        this.isLoading$.next(true);

        let libraryType: FlexibleLayoutLibraryEnum = FlexibleLayoutLibraryEnum.PERSONAL;

        if (layout.libraryId != null) {
            const lib = this.getLibrary(layout.libraryId);
            if (lib === undefined)
                throw Error(`Couldn't find assigned library: ${layout.libraryId}`);

            libraryType = lib.typeId;
        }

        let id: number;
        try {
            if (this._page === undefined) throw Error("Page shouldn't be undefined.");
            const response = await firstValueFrom(
                this._gateway.saveLayout({
                    ...layout,
                    type: libraryType,
                    page: this._page,
                    ...this._metadataArgs
                })
            );
            id = response.id;
        } catch (e) {
            this._onServerFailure(e as HttpErrorResponse);
            throw e;
        }

        await this._loadLayouts();

        this.isLoading$.next(false);

        return id;
    }

    getLayout(id: number): FlexibleLayout | undefined {
        const layout = this.layouts$.getValue().find(layout => layout.id === id);
        if (layout === undefined) console.warn(`Couldn't find layout: ${id}`);
        return layout;
    }

    getDefaultLayoutId(): number | null {
        const layouts = this.layouts$.getValue();

        if (layouts.length === 0) {
            return null;
        }

        const defaultLayout = layouts.find(layout => layout.isDefault);

        if (defaultLayout) return defaultLayout.id;

        // Get first layout sorted in library order
        const sortedLibraries = this.libraries$.getValue().sort((a, b) => a.typeId - b.typeId);

        for (const lib of sortedLibraries) {
            const layout = layouts.find(x => x.libraryId === lib.id);
            if (layout !== undefined) return layout.id;
        }

        throw Error("Shouldn't happen");
    }

    isLibraryReadOnly(libraryId: number): boolean {
        return this.getLibrary(libraryId)?.readOnly ?? false;
    }

    async deleteLayout(id: number): Promise<void> {
        this.isLoading$.next(true);
        const layout = this.getLayout(id);
        if (layout == null) {
            throw Error(`Cannot delete unknown layout ${id}`);
        }

        const library = this.getLibrary(layout.libraryId);
        const clientId =
            library!.typeId === FlexibleLayoutLibraryEnum.CLIENT
                ? this._session.clientId
                : undefined;

        try {
            await firstValueFrom(this._gateway.deleteLayout({ ...this._metadataArgs, id }));
        } catch (e) {
            this._onServerFailure(e as HttpErrorResponse);
            throw e;
        }

        await this._loadLayouts();

        this.isLoading$.next(false);
    }

    async renameLayout(id: number, name: string, description: string): Promise<void> {
        const layout = this.getLayout(id);
        if (layout === undefined) throw Error("Layout ID not found");
        layout.name = name;
        layout.description = description;
        await this.saveLayout(layout);
    }

    // ----------------------------------------------------------------------------------
    async applyTemporaryLayout(layout: LayoutStorageSaveArguments): Promise<number> {
        if (layout.id === null) {
            layout.name = this._lgTranslate.translate("_Flexible.NewLayout");
        }

        return this.saveLayout({
            ...layout,
            description: layout.description ?? "",
            id: layout.isTemporary ? layout.id : null,
            name: layout.isTemporary ? layout.name : layout.name + " *",
            isDefault: false,
            parentId: layout.isTemporary ? layout.parentId : layout.id,
            isTemporary: true,
            libraryId: null
        });
    }

    getTemporaryLayout(): FlexibleLayout | null {
        if (this.temporaryLayoutId === null) {
            console.warn("Temporary layout is not set.");
            return null;
        }
        return this.getLayout(this.temporaryLayoutId) ?? null;
    }

    findTemporaryLayout(parentId: number): number | null {
        return this.layouts$.getValue().find(x => x.parentId === parentId)?.id ?? null;
    }

    async overrideByTemporaryLayout(tempLayout: FlexibleLayout): Promise<void> {
        if (tempLayout.parentId === null) throw Error("Parent shouldn't be undefined");
        const original = this.getLayout(tempLayout.parentId);
        if (original === undefined) throw Error(`Can't find parent layout: ${tempLayout.parentId}`);

        original.config = tempLayout.config;
        await this.saveLayout(original);

        // TODO: shouldn't we reset temporaryLayoutId / temporaryLayoutInProgress here?
        await this.deleteLayout(tempLayout.id);
    }

    async revertTemporaryLayout(): Promise<void> {
        if (!this.temporaryLayoutInProgress()) return;
        if (this.temporaryLayoutId === null)
            throw Error("TemporaryLayoutId shouldn't be undefined");
        // TODO: shouldn't we reset temporaryLayoutId / temporaryLayoutInProgress here?
        await this.deleteLayout(this.temporaryLayoutId);
    }

    // ----------------------------------------------------------------------------------
    ngOnDestroy(): void {
        this.libraries$.complete();
        this.layouts$.complete();
    }
}
