import { isEqual, isEmpty, cloneDeep } from "lodash-es";
import {
    asyncScheduler,
    AsyncSubject,
    BehaviorSubject,
    connectable,
    Observable,
    of,
    scheduled,
    Subject,
    Subscription
} from "rxjs";
import { catchError, defaultIfEmpty, delay, finalize, multicast, switchMap, tap } from "rxjs/operators";

import { LgConsole } from "@logex/framework/core";

import { LoaderArguments, LoaderCallback, LoaderConfiguration } from "./configuration";
import { getConcreteArguments } from "./getConcreteArguments";


// ----------------------------------------------------------------------------------
//
enum LoaderLoadingState {
    Idle = 0,
    AwaitingSubscribers = 1,
    LoadingDependencies = 2,
    LoadingData = 3,
}


export interface ILoadersCollection {
    loadDatasets(names: string[], forceReload: boolean, argsOverride: LoaderArguments | null): Observable<any[]>;
}


export class Loader<TResult = any> {

    // ----------------------------------------------------------------------------------
    // Fields
    protected _loader: LoaderCallback<TResult>;
    protected _noCache: boolean;
    protected _storeResult: boolean;
    protected _defaultContext: any;
    protected _onCancel: ((loaderName: string) => void) | null;
    protected _onClearLastArgs: ((loaderName: string) => void) | null;

    protected _state: LoaderLoadingState = LoaderLoadingState.Idle;
    protected _loadingArgs: any[] | undefined;
    protected _lastArgs: any[] | undefined;
    protected _results: TResult | undefined = undefined;
    protected _connection: Subscription | null = null;
    protected _loadingObservable: Observable<TResult> | null = null;

    protected _dataSubject: Subject<TResult> | null = null;
    protected _dataObservable: Observable<TResult> | null = null;
    protected _dataObserversCount = 0;

    protected _isLoadingSubject: Subject<boolean> | null = null;
    protected _isLoadingObservable: Observable<boolean> | null = null;


    // ----------------------------------------------------------------------------------
    //
    constructor(
        protected _master: ILoadersCollection,
        public name: string,
        public cfg: LoaderConfiguration<TResult>,
        protected _console: LgConsole,
    ) {
        this._loader = cfg.loader;
        this._noCache = cfg.noCache ?? false;
        this._storeResult = cfg.storeResult ?? false;
        this._defaultContext = cfg.defaultContext;
        this._onCancel = cfg.onCancel ?? null;
        this._onClearLastArgs = cfg.onClearLastArgs ?? null;

        this._isLoadingSubject = new BehaviorSubject<boolean>(false);
        this._isLoadingObservable = this._isLoadingSubject.asObservable();
    }


    destroy(): void {
        if (this._isLoadingSubject != null) {
            this._isLoadingSubject.complete();
            this._isLoadingSubject = null;
            this._isLoadingObservable = null;
        }

        if (this._dataSubject != null) {
            this._dataSubject.complete();
            this._dataSubject = null;
            this._dataObservable = null;
        }

        this._results = undefined;

        this.cancel();
    }


    load(argsOverride: LoaderArguments | null, forceReload: boolean, context?: any): Observable<TResult | null> {

        // eslint-disable-next-line no-constant-binary-expression
        context = context ?? { ...this._defaultContext } ?? {};

        const args = getConcreteArguments(this.cfg.args || [], forceReload, argsOverride);

        this._console.debug(`Requested dataset: ${this.name}`, args);

        if (this._state > LoaderLoadingState.Idle) {
            // Check that we are loading with the same arguments.
            if (isEqual(args, this._loadingArgs)) {
                // If yes - return the same multicast observable
                this._console.debug(`"${this.name}" with such arguments is being loaded`);
                return this._loadingObservable!;
            } else {
                // If not - cancel loading
                this._console.debug(`"${this.name}" is being loaded with other arguments, cancelling`,
                    args, this._loadingArgs, isEqual(args, this._loadingArgs));
                this.cancel();
            }
        }

        if (!forceReload) {
            forceReload = this._noCache;
        }

        if (!forceReload && !this._isChangedArguments(args)) {
            this._console.debug(`"${this.name}" with such params was already loaded`);
            if (this._storeResult) {
                this._sendDataToObservers(this._results!);
                return scheduled(of(this._results!), asyncScheduler);
            } else {
                return scheduled(of(null), asyncScheduler);
            }
        }

        this._loadingArgs = args;
        this._state = LoaderLoadingState.AwaitingSubscribers;

        const data$ = of(true).pipe(
            tap(() => {
                this._console.debug("Start loading dependencies");
                this._state = LoaderLoadingState.LoadingDependencies;
                this._isLoadingSubject!.next(true);
            }),

            // Load requires
            switchMap(() => {
                // Required data sets
                const required = typeof this.cfg.require === "function" ? this.cfg.require() : this.cfg.require;
                const required$ = !isEmpty(required)
                    ? this._master.loadDatasets(required!, forceReload, argsOverride)
                    : of([]);

                return required$.pipe(defaultIfEmpty([]));
            }),

            delay(this.cfg.delay || 0),

            switchMap(() =>
                this._startLoading(args, forceReload, context)),

            tap(res => {
                this._onDataLoaded(res, context);
            }),

            catchError((err, _caught) => {
                this._console.debug(`Error loading "${this.name}"`, err);
                this._lastArgs = undefined;
                this._results = undefined;

                this._sendErrorToObservers(err);

                throw err;
            }),

            finalize(() => {
                this._reset();
                this._isLoadingSubject?.next(false);
                this._console.debug(`Finished loading "${this.name}"`);
            }),
        );

        const multicasted = multicast<TResult>(new AsyncSubject())(data$);

        let refCount = 0;
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;

        // Start loading
        const connection = multicasted.connect();
        this._connection = connection;
        self._console.debug(`Connected to loader`);

        this._loadingObservable = new Observable(subscriber => {
            ++refCount;
            self._console.debug(`Subscribed to loader ${this.name} (refCount == ${refCount})`);

            const res = multicasted.subscribe(subscriber);

            return {
                ...res,
                unsubscribe: () => {
                    refCount--;
                    self._console.debug(`Unsubscribing from loader ${this.name} (refCount == ${refCount})`);

                    res.unsubscribe();

                    if (refCount === 0) {
                        connection.unsubscribe();
                    }
                }
            };
        });

        return this._loadingObservable;
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected _startLoading(args: unknown[], forceReload: boolean, context: any): Observable<TResult> {
        this._console.debug(`Loading "${this.name}"`, args);
        this._state = LoaderLoadingState.LoadingData;
        this._loadingArgs = args;
        return this._loader(...args);
    }


    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    protected _onDataLoaded(res: TResult, context: any): void {
        this._console.debug(`Loaded "${this.name}"`);
        this._lastArgs = cloneDeep(this._loadingArgs);
        if (this._storeResult) {
            this._results = res;
        }
        this._sendDataToObservers(res);
    }


    protected _isChangedArguments(args: any[]): boolean {
        return this._lastArgs === undefined
            || !isEqual(args, this._lastArgs);
    }
    
    
    protected _isChangedDefaultArgumentsSinceLastCall(): boolean {
        if (this._lastArgs === undefined) return true;
        return this._isChangedArguments(getConcreteArguments(this.cfg.args || [], false));
    }


    public get isLoading(): boolean {
        return this._state >= LoaderLoadingState.LoadingDependencies;
    }


    public get isLoaded(): boolean {
        return !this.isLoading && this._lastArgs !== undefined;
    }


    public get isLoadingAsObservable(): Observable<boolean> {
        return this._isLoadingObservable!;
    }


    public get lastArgs(): any[] | undefined {
        return this._lastArgs;
    }


    public clearLastArgs(): void {
        this._lastArgs = undefined;

        if (this._onClearLastArgs != null) {
            this._onClearLastArgs(this.name);
        }
    }


    cancel(): void {
        if (this._state === LoaderLoadingState.Idle) return;

        if (this._connection != null) {
            this._connection.unsubscribe();
            this._connection = null;
        }

        this._reset();

        this._console.debug(`Cancelled loading of "${this.name}"`);

        if (this._onCancel != null) {
            this._onCancel(this.name);
        }
    }


    protected _reset(): void {
        this._state = LoaderLoadingState.Idle;
        this._loadingArgs = undefined;
        this._loadingObservable = null;
        this._connection?.unsubscribe();
        this._connection = null;
    }


    dataAsObservable(): Observable<TResult> {
        if (this._dataObservable == null) {
            this._createDataObservable();
        }

        return this._dataObservable!;
    }


    protected _createDataObservable(): void {
        if (!this._storeResult) {
            throw Error("dataAsObservable is available only when loader stores result (storeResult: true).");
        }

        this._dataObserversCount = 0;
        this._dataSubject = new Subject();

        const multicasted = connectable(this._dataSubject, {
            connector: () => new Subject<TResult>()
        });
        multicasted.connect();

        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const self = this;
        this._dataObservable = new Observable(subscriber => {
            ++self._dataObserversCount;
            self._console.debug(`Subscribed to data observable (refCount == ${self._dataObserversCount})`);

            if (!self.isLoading) {
                if (self._results !== undefined && !this._isChangedDefaultArgumentsSinceLastCall()) {
                    // If there are already results loaded and arguments didn't change - return old results
                    this._console.debug("Not the first subscriber - give last results.");
                    Promise.resolve().then(() => {
                        subscriber.next(self._results!);
                    });
                    
                } else if (self._dataObserversCount === 1) {
                    // If this is a first subscriber, there were no suitable data, and we are not loading - initiate loading
                    this._console.debug("First subscriber - trigger load()");
                    Promise.resolve().then(() => {
                        self.load(null, false);
                    });

                }
            } else {
                this._console.debug("Is loading already - wait to complete.");
            }

            const res = multicasted.subscribe(subscriber);

            return {
                ...res,
                unsubscribe() {
                    self._dataObserversCount--;
                    self._console.debug(`Unsubscribing from data observable (refCount == ${self._dataObserversCount})`);

                    res.unsubscribe();
                }
            };
        });
    }


    protected _sendDataToObservers(res: TResult): void {
        if (this._dataSubject != null) {
            this._console.debug(`Sending data to observers of "${this.name}"`);
            this._dataSubject.next(res);
        }
    }


    protected _sendErrorToObservers(err: unknown): void {
        if (this._dataSubject != null) {
            this._console.debug(`Sending error to observers of "${this.name}"`);
            this._dataSubject.error(err);
        }
    }


    public isObserved(): boolean {
        return this._dataObserversCount > 0;
    }


    public getStoredResult(): any {
        if (!this._storeResult) {
            throw Error("getStoredResult is available only when loader stores result (storeResult: true).");
        }
        return this._results;
    }
}

