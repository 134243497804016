import * as _ from "lodash";
import { Dictionary } from "lodash";
import { Component, HostBinding, inject, OnDestroy, ViewEncapsulation } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { filter, firstValueFrom, interval } from "rxjs";
import { takeUntil, tap } from "rxjs/operators";
import { mixins } from "@logex/mixin-flavors";

import { useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    IAppConfiguration,
    LG_APP_CONFIGURATION,
    useStorageNamespace
} from "@logex/framework/lg-application";
import { LgExportDialog } from "@logex/framework/ui-toolbox";
import { urlConcat } from "@logex/framework/utilities";
import {
    LoaderArgumentGetter,
    LoaderArgumentsMap,
    LoadManager,
    useStaleData
} from "@logex/load-manager";
import { LgPivotInstance, LgPivotInstanceFactory } from "@logex/framework/lg-pivot";
import { TabbedMixin } from "@logex/mixins";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { PageBlock, PageComponentLoadManagerBase } from "../../../../bases";

import { ExportPivot } from "./pivots/exports-pivot.service";
import {
    ExportPivotLevel1,
    ExportPivotLevel2,
    ExportPivotLevel3
} from "./pivots/export-pivot.types";
import { DataSets, ExportErrorTypes, PageBlocks, Tabs } from "./types/constants";
import { ExportGateway } from "./gateways/export-gateway";
import { ExportGroupItem, ExportItem } from "./gateways/export-gateway.types";
import { QueuePivotLevel1 } from "./pivots/queue-pivot.types";
import { QueuePivot } from "./pivots/queue-pivot";
import { DownloadExportService } from "../../services/download-export.service";
import { ExportTypeItem } from "../../gateways/exports-notifications-gateway.types";
import { ExportStatus } from "../../services/exports-notifications.types";

export interface ExportComponent<TAppDefinitions> extends TabbedMixin {
    // empty
}

@Component({
    selector: "app-export",
    templateUrl: "./export.component.html",
    standalone: false,
    encapsulation: ViewEncapsulation.None,
    providers: [
        useTranslationNamespace("FADP._Exports"),
        useStorageNamespace("exports"),
        LoadManager,
        useStaleData()
    ]
})
@mixins(TabbedMixin)
export class ExportComponent<TAppDefinitions>
    extends PageComponentLoadManagerBase<TAppDefinitions>
    implements OnDestroy
{
    @HostBinding("class") get class(): string {
        return "flex-flexible flexcol flexcol--full";
    }

    private _appConfiguration = inject<IAppConfiguration>(LG_APP_CONFIGURATION);
    protected override _gateway = inject(ExportGateway);
    private _pivotInstanceFactory = inject(LgPivotInstanceFactory);
    exportPivot = inject(ExportPivot);
    queuePivot = inject(QueuePivot);
    private _exportDialog = inject(LgExportDialog).bindViewContainerRef(this._viewContainerRef);
    private _downloadExport = inject(DownloadExportService);

    protected readonly PageBlocks = PageBlocks;

    constructor() {
        super();

        this._initMixins();

        this._mainPivot = this._pivotInstanceFactory.create<ExportPivotLevel1, any>(
            this.exportPivot,
            this
        );
        this._queuePivot = this._pivotInstanceFactory.create<QueuePivotLevel1, any>(
            this.queuePivot,
            this
        );
    }

    protected _filterStateStorageKey = null;

    _exportGroups: ExportGroupItem[];
    _exportTypes: ExportTypeItem[];
    _exportTypesDict: Dictionary<ExportTypeItem>;
    _exports: ExportItem[];

    _mainPivot: LgPivotInstance<ExportPivotLevel1>;
    _queuePivot: LgPivotInstance<QueuePivotLevel1>;

    _currentTab = Tabs.Exports;

    private _updateInterval = 4000;
    private _refreshIntervalHandler: number;

    override ngOnDestroy(): void {
        super.ngOnDestroy();

        clearInterval(this._refreshIntervalHandler);
    }

    protected override _getRequiredDefinitions(): any {
        return [];
    }

    protected override _getPageBlocks(): _.Dictionary<PageBlock> {
        return {
            ...super._getPageBlocks(),

            [PageBlocks.DATA]: {
                visible: () => true,
                dataSets: [DataSets.EXPORT_GROUPS, DataSets.EXPORT_TYPES, DataSets.EXPORTS]
            }
        };
    }

    protected _getLoaderArguments(): LoaderArgumentsMap {
        return {
            clientId: () => this._session.clientId,
            scenarioId: () => this._session.scenarioId
        };
    }

    protected _addLoaders(args: _.Dictionary<LoaderArgumentGetter>): void {
        this._loadManager.add({
            [DataSets.EXPORT_GROUPS]: {
                args: [args],
                loader: args =>
                    this._gateway.getExportGroups(args).pipe(
                        tap(async data => {
                            this._exportGroups = data;
                        })
                    )
            },

            [DataSets.EXPORT_TYPES]: {
                args: [args],
                loader: args =>
                    this._gateway.getExportTypes(args).pipe(
                        tap(async data => {
                            this._exportTypes = data;
                            this._exportTypesDict = _.keyBy(data, x => x.exportTypeCode);
                        })
                    )
            },

            [DataSets.EXPORTS]: {
                args: [args],
                require: [DataSets.EXPORT_GROUPS, DataSets.EXPORT_TYPES],
                loader: args =>
                    this._gateway.getExports(args).pipe(
                        tap(async data => {
                            this._exports = data;
                            await this._rebuildPivots();
                        })
                    )
            }
        });
    }

    protected override _onBootstrapDataLoaded(): void {
        super._onBootstrapDataLoaded();

        // Reload exports list periodically
        interval(this._updateInterval)
            .pipe(takeUntil(this._destroyed$))
            .pipe(
                filter(() => {
                    const allStatuses = this._exports.map(x => x.status);
                    return (
                        allStatuses.includes(ExportStatus.Queued) ||
                        allStatuses.includes(ExportStatus.InProgress)
                    );
                })
            )
            .subscribe(async () => {
                this._exports = await firstValueFrom(
                    this._gateway.getExports({
                        clientId: this._session.clientId,
                        scenarioId: this._session.scenarioId
                    })
                );

                await this._rebuildPivots();
            });
    }

    private async _rebuildPivots(): Promise<void> {
        const existingExportTypes = _.uniq(this._exports.map(x => x.exportTypeCode));
        const exportGroupsDict = _.keyBy(this._exportGroups, x => x.id);

        const sortingPriority = [
            "allDataTemplates",
            "snapshotExportModelingAndActivities",
            "snapshotExportProducts",
            "snapshotExportEncounters",
            "snapshotExportAnalyticsDimensions",
            "snapshotExportDiagnosisAndProcedures"
        ];

        const mainPivotData = [
            ...this._exportTypes
                .filter(x => {
                    return (
                        !existingExportTypes.includes(x.exportTypeCode) &&
                        (x.categoryName || x.categoryNameLc)
                    );
                })
                .map(x => ({
                    sortBy:
                        sortingPriority.indexOf(x.exportTypeCode) >= 0
                            ? `${sortingPriority.indexOf(x.exportTypeCode)}`
                            : x.categoryNameLc
                              ? `${sortingPriority.length}`
                              : x.exportTypeCode,
                    ...x
                })),
            ...this._exports.map(x => {
                const type = this._exportTypesDict[x.exportTypeCode];

                let categoryName = type.categoryName;
                let categoryNameLc = type.categoryNameLc;
                let name = type.name;
                let nameLc = type.nameLc;

                if (x.groupId != null) {
                    const group = exportGroupsDict[x.groupId];
                    const parentGroup = exportGroupsDict[group.parentId];
                    categoryName = parentGroup.name;
                    categoryNameLc = parentGroup.nameLc;
                    name = group.name;
                    nameLc = group.nameLc;
                }

                return {
                    categoryName,
                    categoryNameLc,
                    canRequest: x.groupId == null, // Check if it is not widget export
                    exportTypeCode: type.exportTypeCode,
                    isAsync: type.isAsync,
                    name,
                    nameLc,
                    sortBy:
                        sortingPriority.indexOf(x.exportTypeCode) >= 0
                            ? `${sortingPriority.indexOf(x.exportTypeCode)}`
                            : x.exportTypeCode,
                    url: type.url,
                    ...x
                };
            })
        ];
        this._mainPivot.build(mainPivotData, false, true);

        const queuePivotData = this._exports.filter(
            x => x.status === ExportStatus.Queued || x.status === ExportStatus.InProgress
        );
        this._queuePivot.build(queuePivotData, false, true);
    }

    async _requestExport(e: MouseEvent, row2: ExportPivotLevel2): Promise<void> {
        e.stopPropagation();

        const params = {
            clientId: this._session.clientId,
            scenarioId: this._session.scenarioId
        };

        if (row2.isAsync) {
            // Async export handling
            try {
                const info = await firstValueFrom(this._gateway.requestExport(row2.url, params));

                this._exports.push(info);
                await this._rebuildPivots();
                row2.$expanded = true;
            } catch (e: any) {
                const error = (<HttpErrorResponse>e).error;
                if (
                    error.ExceptionType ===
                    "LgBackend.Export.Abstractions.ExportRequestValidationException"
                ) {
                    let exceptionMessageLc: string;
                    switch (error.ExceptionMessage) {
                        case ExportErrorTypes.ScenarioMustBeLockedError:
                            exceptionMessageLc = "FADP._Exports.MustBeLockedError";
                            break;
                        case ExportErrorTypes.OneSubmissionBatchAtTimeError:
                            exceptionMessageLc = "FADP._Exports.OneSubmissionBatchAtTimeError";
                            break;
                        case ExportErrorTypes.UnknownSubmissionRelease:
                            exceptionMessageLc = "FADP._Exports.UnknownSubmissionRelease";
                            break;
                    }
                    await this._promptDialog.alert(
                        this._translate(".ExportValidationError"),
                        exceptionMessageLc
                            ? this._translate(exceptionMessageLc)
                            : error.ExceptionMessage,
                        {
                            columns: 5,
                            buttons: [LgPromptDialog.CLOSE_BUTTON]
                        }
                    );
                } else {
                    this._onServerFailure(e);
                }
            }
        } else {
            // Regular export handling
            const url = urlConcat(this._appConfiguration.applicationRoot, row2.url);
            this._exportDialog.show(url, params);
        }
    }

    _downloadExportDo(exportId: string): void {
        this._downloadExport.do(exportId);
    }

    _renderNameFromTypeCode(exportTypeCode: string): string {
        const exportTypesDict = _.keyBy(this._exportTypes, x => x.exportTypeCode);
        return (
            exportTypesDict[exportTypeCode]?.name ||
            this._translate(exportTypesDict[exportTypeCode]?.nameLc)
        );
    }

    async _showError(row3: ExportPivotLevel3): Promise<void> {
        await this._promptDialog.alert(this._translate(".Error"), row3.error, {
            columns: 5
        });
    }
}
