@if (column != null) {
    <lgflex-configuration-dialog-formula
        [column]="column"
        (columnChange)="columnPropertyChange.emit()"
        [fieldsDropdownDefinition]="fieldsDropdownDefinition"
        [areReferencesAllowed]="areReferencesAllowed"
        [referencesDropdownDefinition]="referencesDropdownDefinition"
        [formulas]="_formulas"
    >
    </lgflex-configuration-dialog-formula>

    <div class="form-row--input form-row">
        <div class="form-row__label">{{ ".Width" | lgTranslate }}:</div>
        <div class="form-row__controls">
            <input
                class="input"
                type="number"
                style="width: 100px"
                [(ngModel)]="column.width"
                (ngModelChange)="columnPropertyChange.emit()"
            />
        </div>
    </div>
}
