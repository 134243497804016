import { CalculationError } from "../types";

export function combineCalculationErrors(values: Array<CalculationError[] | null>): CalculationError[] | null {
    const result: CalculationError[] = [];
    for (const errors of values) {
        if (errors == null) continue;
        for (const error of errors) {
            if (result.find(x => x.stepName === error.stepName) != null) continue;
            result.push(error);
        }
    }
    return result.length > 0 ? result : null;
}
