import { inject, Pipe, PipeTransform } from "@angular/core";
import { TitleCasePipe } from "@angular/common";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Pipe({
    name: "modFormulaTypeLabel"
})
export class ModFormulaTypeLabelPipe implements PipeTransform {
    readonly _lgTranslate = inject(LgTranslateService);
    readonly _titleCasePipe = inject(TitleCasePipe);
    transform(value: string): string {
        const prefix = this._lgTranslate.translate(".Value");
        const suffix = this._lgTranslate.translate(".TypePostfix");
        const titleCasedValue = this._titleCasePipe.transform(value);

        return `${prefix} ${titleCasedValue} ${suffix}`;
    }
}
