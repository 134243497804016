import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { Component, computed, ElementRef, Inject, ViewChild } from "@angular/core";
import { takeUntil } from "rxjs/operators";

import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";
import {
    ComboFilterRenderer2,
    IComboFilter2Definition,
    LgFilterSet
} from "@logex/framework/lg-filterset";
import {
    ChartClickEvent,
    IIconChartInputDataItem,
    IIconChartItem
} from "@logex/framework/lg-charts";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { INodeStateStore, LgPivotInstanceFactory } from "@logex/framework/lg-pivot";

import {
    ExportFormat,
    FieldInfo,
    FieldInfoDefinition,
    IWidgetHost,
    LG_FLEX_LAYOUT_WIDGET_HOST,
    PivotTableColumn,
    PivotTableColumnDefault,
    Widget,
    WidgetUsage
} from "../../types";
import { FlexiblePivotDefinitionFactory } from "../../services/flexible-pivot/flexible-pivot-definition-factory";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { WidgetTypesRegistry } from "../../services/widget-types-registry/widget-types-registry.service";
import { FlexibleDatasetDataArgumentsGetter } from "../../services/flexible-dataset/types/types";
import { getColumnFieldName } from "../../utilities/getColumnFieldName";
import { ChartWidgetBaseComponent } from "../base/chart-widget-base/chart-widget-base.component";
import {
    ICON_CHART_WIDGET,
    IconChartWidgetConfig,
    IconChartWidgetState
} from "./types/icon-chart-widget.types";
import { IconChartWidgetConfigurator } from "./icon-chart-widget-configurator";
import { IconChartWidgetIcons } from "./types/icons.enum";
import { WidgetExportExcelService } from "../../services/widget-export/widget-export-excel.service";
import { exportSvg, exportSvgToPng } from "../../services/widget-export/widget-export-image";
import {
    cloneElementWithStyles,
    cloneStyles,
    getExportFilename
} from "../../services/widget-export/widget-export-utils";
import { getItemNameFromField } from "../../utilities/getItemNameFromField";

@Component({
    standalone: false,
    selector: "lgflex-icon-chart-widget",
    templateUrl: "./icon-chart-widget.component.html",
    host: {
        class: "flex-flexible flexcol flexcol--full"
    },
    viewProviders: [...useTranslationNamespace("_Flexible.IconChartWidget")]
})
@Widget({
    id: ICON_CHART_WIDGET,
    nameLc: "_Flexible.IconChartWidget.WidgetTitle",
    usage: WidgetUsage.Page,
    configurator: IconChartWidgetConfigurator,
    configVersion: 1
})
export class IconChartWidgetComponent extends ChartWidgetBaseComponent<IconChartWidgetConfig> {
    constructor(
        promptDialog: LgPromptDialog,
        lgTranslate: LgTranslateService,
        lgConsole: LgConsole,
        flexDataClient: FlexDataClientService,
        filters: LgFilterSet<any, any>,
        pageReferences: PageReferencesService,
        widgetTypes: WidgetTypesRegistry,
        pivotInstanceFactory: LgPivotInstanceFactory,
        flexiblePivotFactory: FlexiblePivotDefinitionFactory,
        @Inject(LG_FLEX_LAYOUT_WIDGET_HOST) widgetHost: IWidgetHost,
        configurator: IconChartWidgetConfigurator,
        exportService: WidgetExportExcelService,
        @Inject(LG_APP_DEFINITIONS) protected _definitions: IDefinitions<any>
    ) {
        super(
            promptDialog,
            lgTranslate,
            lgConsole,
            flexDataClient,
            filters,
            pageReferences,
            widgetTypes,
            pivotInstanceFactory,
            flexiblePivotFactory,
            widgetHost,
            configurator as any,
            exportService
        );
    }

    // ----------------------------------------------------------------------------------
    @ViewChild("rect", { read: ElementRef }) rectEl: ElementRef | undefined;

    // Chart fields
    _icon = IconChartWidgetIcons.Patient;
    _getIcon = (): string => this._icon.toString();

    private _chartValueField: string | undefined;
    _chartValueFieldNameLc: string | undefined;

    private _chartPath: Array<number | string> = [];
    _chartCurrentLevel = 0;
    _chartCurrentLevelKeyField: FieldInfo | undefined;
    _chartLevelData: IIconChartInputDataItem[] = [];

    private _chartReferenceSlot: number | undefined;

    _chartValueFormatter: "money" | "int" | "float" = "float";
    _chartValueDecimals = 0;

    protected _schemeLookup = computed(() => _.keyBy(this._scheme(), "field"));

    // ----------------------------------------------------------------------------------

    override async setConfig(config: IconChartWidgetConfig): Promise<void> {
        await super.setConfig(config);

        this._prepareChartFields(config.columns);
        this._icon = config.icon != null ? config.icon : this._icon;
    }

    // eslint-disable-next-line @angular-eslint/use-lifecycle-interface
    override ngOnInit(): void {
        super.ngOnInit();

        // Update the chart when filter changes
        this._filters.onChanged
            .pipe(takeUntil(this._destroyed$))
            .subscribe((filters: Array<string | number | symbol>) => {
                if (!_.isEmpty(_.intersection(filters, this._levels))) {
                    this._chartPrepareLevelData();
                }
            });
    }

    protected override _buildPivot(data: unknown[], state: INodeStateStore): void {
        super._buildPivot(data, state);

        this._chartPrepareLevelData();
    }

    override getState(): IconChartWidgetState | null {
        if (!this._isConfigValid) return null;
        const table = super.getState();
        if (!table) return null;
        return {
            ...table,
            version: 2
        };
    }

    override setState(state: IconChartWidgetState): boolean {
        if (!super.setState(state)) return false;
        this._chartPrepareLevelData();
        return true;
    }

    override getWidgetType(): string {
        return ICON_CHART_WIDGET;
    }

    // ----------------------------------------------------------------------------------
    // Chart
    _getMin(values: number[]): number {
        return Math.min(...values);
    }

    private _prepareChartFields(columns: PivotTableColumn[]): void {
        const enabledColumn = columns.find(
            x => x.type === "default" && x.isEnabled !== false
        ) as PivotTableColumnDefault;

        if (enabledColumn === undefined) {
            throw Error("IconChartWidget column configuration error");
        }

        const schemeField = this._schemeLookup()[enabledColumn.field];

        if (!schemeField.isValueField) {
            throw Error(`Field "${schemeField.field}" is not value field`);
        }

        if (this._pageReferences.isAllowed() && !schemeField.isReferenceBound) {
            throw Error(`Field "${schemeField.field}" is not reference-bound`);
        }

        this._chartReferenceSlot = enabledColumn.referenceIdx;
        this._chartValueField = getColumnFieldName(enabledColumn);
        this._chartValueFieldNameLc = schemeField.nameLc ?? "";

        switch (schemeField.type.toLowerCase()) {
            case "money":
                this._chartValueFormatter = "money";
                break;

            case "float":
                this._chartValueFormatter = "float";
                break;

            default:
                throw Error(`Unsupported field type "${schemeField.type}"`);
        }

        this._chartValueDecimals =
            schemeField.type === "float" && schemeField.numericPrecision != null
                ? schemeField.numericPrecision
                : 0;
    }

    private _chartPrepareLevelData(): void {
        const path: Array<number | string> = [];
        for (let i = 0; i < this._levels.length - 1; i++) {
            const filterValue = this._getSingleSelectedOption(this._levels[i], this._filters);
            if (filterValue == null) break;
            path.push(filterValue);
        }

        this._setCurrentPath(path);
        this._copyCurrentPivotLevel();
    }

    private _setCurrentPath(path: Array<number | string>): void {
        this._chartPath = path;
        this._chartCurrentLevel = path.length;

        const keyFieldName = this._levels[this._chartCurrentLevel];
        this._chartCurrentLevelKeyField = this._schemeLookup()[keyFieldName];
    }

    private _getSingleSelectedOption(filterName: string, filters: LgFilterSet<any, any>): any {
        const value = filters.filters[filterName];
        if (value == null || value.$empty) {
            return undefined;
        }

        const keys = _.keys(value);
        if (keys.length === 1) {
            const filterDefinition = filters.getFilterDefinition(filterName);
            let parseId = (x: any): void => x;
            if (filterDefinition.filterType === "combo2") {
                if ((filterDefinition as IComboFilter2Definition<any>).idType === "number") {
                    parseId = x => parseFloat(x);
                }
            }
            return parseId(keys[0]);
        } else {
            return undefined;
        }
    }

    private _copyCurrentPivotLevel(): void {
        const data = this._pivot?.filtered;

        if (data == null) {
            this._chartLevelData = [];
            return;
        }

        if (this._chartValueField == null) throw Error("Value field shouldn't be undefined.");

        let level: any[] = data;
        for (let i = 0; i < this._chartPath.length; i++) {
            const keyField = this._levels[i];
            const item = _.find(level, { [keyField]: this._chartPath[i] });
            if (item == null) {
                this._setCurrentPath(_.take(this._chartPath, i));
                break;
            }
            level = item.filteredChildren;
        }

        const levelField = this._levels[this._chartCurrentLevel];
        const levelFieldInfo = this._schemeLookup()[levelField] as FieldInfoDefinition;

        if (levelFieldInfo == null) {
            this._lgConsole.error(`Field ${levelField} is not found in the schema`);
            return;
        }
        const nameFormatter = (code: string | number): string => {
            return getItemNameFromField(code, levelFieldInfo.type, this._definitions);
        };

        this._chartLevelData = level
            .map(item => ({
                customItem: item,
                name: nameFormatter(item[levelField]),
                value: item[this._chartValueField!]
            }))
            .filter(item => item.value > 0)
            .sort((a, b) => b.value - a.value);
    }

    _chartGetColumnOpacity = (row: any): number => {
        const keyField = this._chartCurrentLevelKeyField?.field;
        if (keyField == null) throw Error("Field shouldn't be undefined.");

        if (!this._filters.isActive(keyField)) return 1;
        if (row == null) return 0.25;

        const key = row[keyField];
        return this._filters.filters[keyField][key] ? 1 : 0.25;
    };

    _chartOnClick(event: ChartClickEvent<any, IIconChartItem>): void {
        if (event.item == null) return;

        const keyField = this._chartCurrentLevelKeyField?.field;
        if (keyField == null) throw Error("Field shouldn't be undefined.");

        const filterHandler = this._filters.getRenderer<any, ComboFilterRenderer2<any>>(keyField);
        filterHandler.toggleItem(event.item[keyField]);
        this._filters.triggerOnChanged(keyField);
    }

    _chartGoUp(): void {
        if (this._chartCurrentLevel <= 0) return;

        this._filters.clear(this._levels[this._chartCurrentLevel - 1]);
    }

    // ----------------------------------------------------------------------------------
    override _export(option: ExportFormat): void {
        switch (option) {
            case ExportFormat.XLSX: {
                this._exportService.export(
                    this._pivot!,
                    this._flexDataClient.scheme,
                    this._levels,
                    this._visibleColumns,
                    this._pageReferences.selectedReferences,
                    this._config.title
                );
                break;
            }
            case ExportFormat.SVG: {
                exportSvg(this._buildExportSvg(), getExportFilename(this._config.title));
                break;
            }
            case ExportFormat.PNG: {
                exportSvgToPng(
                    this._buildExportSvg(),
                    2000,
                    getExportFilename(
                        this._config.title ?? this._lgTranslate.translate(".WidgetTitle")
                    )
                );
                break;
            }
        }
    }

    _buildExportSvg(): SVGElement {
        if (this.rectEl == null) throw Error("Rect element shouldn't be undefined.");

        const svgEl = cloneElementWithStyles(
            this.rectEl.nativeElement.getElementsByTagName("svg")[0]
        ) as SVGElement;
        const iconEl = document.getElementById(this._icon.toString())?.cloneNode(true);
        if (iconEl == null) throw Error("Can't find icon element.");
        svgEl.appendChild(iconEl);

        const rectWidth = document.defaultView?.getComputedStyle(this.rectEl.nativeElement).width;
        const rectHeight = document.defaultView?.getComputedStyle(this.rectEl.nativeElement).height;
        const svgWidth = svgEl.getAttribute("width");

        if (rectWidth == null || rectHeight == null || svgWidth == null)
            throw Error("Can't find svg dimensions attributes.");

        const foreignObjectEl = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "foreignObject"
        );
        const legendEl = this.rectEl.nativeElement.getElementsByClassName(
            "lg-icon-chart__legend-wrapper"
        )[0] as HTMLElement;
        const legendElWidth = document.defaultView?.getComputedStyle(legendEl).width;

        if (legendElWidth == null) throw Error("Can't get legend element width.");

        foreignObjectEl.setAttribute("x", svgWidth);
        foreignObjectEl.setAttribute("y", "0");
        foreignObjectEl.setAttribute("width", legendElWidth);
        foreignObjectEl.setAttribute("height", "100%");
        svgEl.appendChild(foreignObjectEl);

        const legendElClone = cloneElementWithStyles(legendEl) as HTMLElement;
        legendElClone.setAttribute("xmlns", "http://www.w3.org/1999/xhtml");
        foreignObjectEl.appendChild(legendElClone);
        cloneStyles(legendEl, legendElClone);
        legendElClone.style.height = rectHeight;

        svgEl.setAttribute("width", rectWidth);
        svgEl.setAttribute("height", rectHeight);

        const legendItemEls = svgEl.getElementsByClassName("lg-chart-legend__item__name");
        for (let i = legendItemEls.length; i--; ) {
            (legendItemEls[i] as HTMLElement).style.overflow = "visible";
        }

        return svgEl;
    }
}
