import {
    PivotTableGroupOrColumn,
    PivotTableWidgetConfig
} from "../pivot-table-widget.configuration.types";

export const migrateVersion7To8 = (config: PivotTableWidgetConfig) => {
    config.columns = config.columns.map(column => {
        if ("columns" in column) {
            column.columns = column.columns.map((c: any) => {
                if (c.type === "default" || c.type === "formula" || c.type === "difference") {
                    return { ...c, aggregatedTotals: c.aggregatedTotals ?? true };
                }
                return c;
            });
        } else if (
            column.type === "default" ||
            column.type === "formula" ||
            column.type === "difference"
        ) {
            column = {
                ...column,
                aggregatedTotals: column.aggregatedTotals ?? true
            } as PivotTableGroupOrColumn;
        }

        return column;
    });
    return 8;
};
