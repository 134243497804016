import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    standalone: false,
    selector: "lgflex-dialog-grid",
    templateUrl: "./dialog-grid.component.html",
    styleUrls: ["./dialog-grid.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogGridComponent {}
