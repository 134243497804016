import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { combineLatest, merge, Observable, of, Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";
import {
    Component,
    computed,
    inject,
    OnDestroy,
    OnInit,
    signal,
    ViewChild,
    WritableSignal
} from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgConsole } from "@logex/framework/core";
import {
    ILogexPivotDefinition,
    INodeStateStore,
    INormalizedLogexPivotDefinition,
    LgPivotInstance,
    LgPivotInstanceFactory,
    SelectedRow
} from "@logex/framework/lg-pivot";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { HandleErrorsMixin, NgOnDestroyMixin } from "@logex/mixins";
import {
    IColumnExportDefinition,
    LgExcelFactoryService,
    LogexXlsxApi
} from "@logex/framework/lg-exports";

import {
    DifferenceColumnChangeEvent,
    DrilldownKeyItem,
    ExportFormat,
    FieldInfo,
    FlexibleDrilldownPivotTableState,
    IWidgetHost,
    LG_FLEX_LAYOUT_WIDGET_HOST,
    MAX_FILENAME_LENGTH,
    MAX_LIMIT_ROWS,
    MAX_SHEET_NAME_LENGTH,
    PivotContext,
    PivotTableColumn,
    PivotTableLevel,
    ReferenceInfo,
    Widget,
    WidgetComponent,
    WidgetUsage
} from "../../types";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { FlexiblePivotDefinitionFactory } from "../../services/flexible-pivot/flexible-pivot-definition-factory";
import { WidgetTypesRegistry } from "../../services/widget-types-registry";
import { WidgetExportExcelService } from "../../services/widget-export/widget-export-excel.service";
import {
    PIVOT_TABLE_WIDGET_2,
    PivotTableGroupOrColumn,
    PivotTableWidgetColumn,
    PivotTableWidgetConfig,
    PivotTableWidgetState
} from "./pivot-table-widget.configuration.types";
import { ConditionalFormattingService } from "../../components/conditional-formatting/services/conditional-formatting.service";

import { PivotTableComponent } from "../../components/pivot-table";
import { getPivotFilter } from "../../utilities/filter";
import { FlexibleDatasetDataArgumentsGetter } from "../../services/flexible-dataset";
import { calculateToFormula, getColumnFieldName } from "../../utilities";
import { PivotTableWidgetConfigurator } from "./pivot-table-widget-configurator";
import { FlexiblePivotFlatDefinitionFactory } from "../../services/flexible-pivot/flexible-pivot-flat-definition-factory";
import { getExportFilename } from "../../services/widget-export/widget-export-utils";
import {
    Column,
    ColumnFormulaFormatting,
    ColumnFormulaInput,
    ColumnFormulaType,
    ColumnFormulaVariableNames,
    TableConfig
} from "../../types/configuration";
import { PivotTableDataService } from "./pivot-table-data.service";
import { LgFiltersPanelService } from "@logex/framework/lg-layout";
import {
    CalculationError,
    CalculationProgress,
    combineCalculationErrors,
    combineCalculationProgress
} from "@logex/load-manager";
import {
    ExportFieldInfo,
    ExportGroupInfo
} from "../../services/flex-data-client/gateway/flex-data-client-gateway.types";
import { PageContext } from "../../components/flexible-layout/flexible-layout.types";

export interface PivotTableWidgetComponent<TConfig> extends HandleErrorsMixin, NgOnDestroyMixin {}

@Component({
    standalone: false,
    selector: "lgflex-pivot-table-widget",
    templateUrl: "./pivot-table-widget.component.html",
    host: {
        class: "flex-flexible flexcol flexcol--full"
    },
    providers: [
        ConditionalFormattingService,
        PivotTableDataService,
        ...useTranslationNamespace("_Flexible.PivotTableWidget")
    ]
})
@Widget({
    id: PIVOT_TABLE_WIDGET_2,
    nameLc: "_Flexible.DrilldownBase.PivotTable",
    usage: WidgetUsage.Page,
    configurator: PivotTableWidgetConfigurator,
    configVersion: 10
})
@mixins(HandleErrorsMixin, NgOnDestroyMixin)
export class PivotTableWidgetComponent<TConfig> implements OnInit, OnDestroy, WidgetComponent {
    readonly _widgetHost: IWidgetHost = inject(LG_FLEX_LAYOUT_WIDGET_HOST);
    readonly _promptDialog = inject(LgPromptDialog);
    readonly _lgTranslate = inject(LgTranslateService);
    readonly _lgConsole = inject(LgConsole);
    readonly _flexDataClient = inject(FlexDataClientService);
    readonly _pageReferences = inject(PageReferencesService);
    readonly _widgetTypes = inject(WidgetTypesRegistry);
    readonly _flexibleTableFactory = inject(FlexiblePivotFlatDefinitionFactory);
    readonly _flexiblePivotFactory = inject(FlexiblePivotDefinitionFactory);
    readonly _exportService = inject(WidgetExportExcelService);
    readonly _conditionalFormattingService = inject(ConditionalFormattingService);
    readonly _pivotInstanceFactory = inject(LgPivotInstanceFactory);
    readonly _filters = inject(LgFilterSet);

    readonly _filtersPanelService = inject(LgFiltersPanelService);
    readonly _configurator = inject(PivotTableWidgetConfigurator);
    readonly _lgXlsxService = inject(LgExcelFactoryService);

    readonly _pivotTableDataService = inject(PivotTableDataService);

    // ----------------------------------------------------------------------------------
    private _isActivated = false;
    private _filtersSet: LgFilterSet = null;
    private _reconfigureSubject = new Subject<void>();
    private _onDrillSubject = new Subject<void>();
    private _id: string | undefined;
    private _context: PageContext;
    private _pivotBuildCount = 0;
    private _dataSubscription: Subscription[];
    private _lgXlsx: LogexXlsxApi;
    private _pivotsDefinition: Array<LgPivotInstance<unknown, unknown>> | null = null;
    private _flexDatasetArgumentsCache: FlexibleDatasetDataArgumentsGetter[] = [];

    protected _maxLimitRows = MAX_LIMIT_ROWS;
    protected _exportOptions = [ExportFormat.XLSX, ExportFormat.SERVER];
    protected _selectedLevelKeys: DrilldownKeyItem[][] = [];
    protected _config!: PivotTableWidgetConfig;
    protected _isConfigValid = false;
    protected _levels: string[][] = [];
    protected _levelProperties: Dictionary<PivotTableLevel> | undefined;
    protected _columnsAndGroups: PivotTableGroupOrColumn[] = [];
    protected _columnAndGroupsFlat: PivotTableWidgetColumn[] = [];
    protected _pivots: WritableSignal<Array<LgPivotInstance<unknown, unknown>> | null> =
        signal(null);

    protected _selectedRows: Array<SelectedRow<any, any>> = [];
    protected _isDataComplete = true;
    protected _isAutoConverted = false;
    protected _limitRowsCount = signal(0);

    protected _widgetReferences = computed(() =>
        this._pivotTableDataService.isDefaultDataSource()
            ? this._pageReferences.references
            : this._pivotTableDataService.references()
    );

    protected _selectedReferences = computed(() => {
        return this._pivotTableDataService.isDefaultDataSource()
            ? (this._pageReferences.selectedReferences as ReferenceInfo[])
            : this._config.selectedReferences.map(selectedReference => {
                  const { referenceCode } = selectedReference;

                  return this._pivotTableDataService
                      .references()
                      .find(ref => ref.code === referenceCode);
              });
    });

    protected _isLoading$: Observable<boolean>;
    protected _isStale$: Observable<boolean> | undefined;
    protected _isCalculating$: Observable<boolean> | undefined;
    protected _calculationProgress$: Observable<CalculationProgress | null> | undefined;
    protected _calculationError$: Observable<CalculationError[] | null> | undefined;
    protected _initialTableState: FlexibleDrilldownPivotTableState | null = null;
    protected _maxColumnValues: Record<string, number> = {};

    @ViewChild("table") _table: PivotTableComponent | undefined;

    constructor() {
        this._initMixins();
        this._dataSubscription = [];

        this._isLoading$ = of(true);

        this._lgXlsx = this._lgXlsxService.create();
        this._lgXlsx.setDefaultStyle(this._lgXlsx.styles.logex);
    }

    ngOnInit(): void {
        this._filtersPanelService.state$.subscribe(state => (this._filtersSet = state.filterSet));
        this._reconfigurePivots();
        this._pivotTableDataService.setSelectedReferences(
            this._config.selectedReferences,
            this._id
        );
        this._isActivated = true;

        // TODO: Possibly be needed soon while implementing widget configuration affecting page filters
        // this._filterPatchUnsubscribeHandler = this._widgetHost
        //     .addFilterPatchCallback( ( filters ) => {
        //         _.each( _.flatten( this._selectedLevelKeys ), x => {
        //             filters[x.fieldName] = [x.value];
        //         } );
        //     } );
    }

    ngOnDestroy(): void {
        // TODO: Possibly be needed soon while implementing widget configuration affecting page filters
        // this._filterPatchUnsubscribeHandler();
    }

    setContext(context: PageContext): void {
        this._context = context;
    }

    setId(id: string): void {
        this._id = id;
    }

    getState(): PivotTableWidgetState | null {
        if (!this._isConfigValid || !this._table) return null;
        return {
            version: 1,
            tableState: this._table.getState() ?? null
        };
    }

    setState(state: PivotTableWidgetState): boolean {
        if (state.version !== 1) return false; // in the future, upgrade
        if (this._table) {
            return this._table.setState(state.tableState);
        } else {
            this._initialTableState = state.tableState;
        }
        return true;
    }

    setConfig(config: PivotTableWidgetConfig): void {
        const dimensions = config.tablesConfig.map(t => t.dimensions.map(d => d.fieldId));
        this._levels = dimensions ?? [];
        this._columnsAndGroups = config.columns ?? [];
        this._initialTableState = null;
        this._config = config;
        this._isConfigValid = this._configurator.validate(config);
        this._levelProperties = config.tablesConfig.map(t => t.dimensions) as any;
        this.setVisibleColumns();
        this._clearDefaultWidth();
        this._clearReferenceCodes();
        // Reactivate

        if (this._isActivated) {
            this._reconfigurePivots();
            this._pivotTableDataService.setSelectedReferences(config.selectedReferences, this._id);
        }
    }

    getWidgetType(): string {
        return PIVOT_TABLE_WIDGET_2;
    }

    protected _clearReferenceCodes(): void {
        const selectedReferencesCodes = this._getSelectedReferencesCodes();
        this._config.columns.forEach((columnGroup, columnGroupIndex) => {
            if ("columns" in columnGroup) {
                if (
                    columnGroup.referenceCode &&
                    columnGroup.referenceCode !== selectedReferencesCodes[columnGroupIndex]
                ) {
                    columnGroup.referenceCode = null;
                }
            }
        });
    }

    // @TODO: hack fix for FLEX-285, FLEX-299
    protected _clearDefaultWidth(): void {
        this._config.tablesConfig = this._config.tablesConfig.map(table => ({
            ...table,
            dimensions: table.dimensions.map(dimension => ({
                ...dimension,
                width: undefined
            }))
        }));
    }

    protected _getLevelPivotService(table: TableConfig) {
        return table.type === "table" ? this._flexibleTableFactory : this._flexiblePivotFactory;
    }

    protected _getLevelOrderBy(table: any) {
        const { type, orderBy } = table;
        let defaultOrderBy = "";

        if (type === "table") {
            const [firstDimension] = table.dimensions;

            if (orderBy && orderBy.length > 0) {
                defaultOrderBy =
                    orderBy[0].type === "desc" ? `-${orderBy[0].item}` : orderBy[0].item;
            }

            if (firstDimension.overrideFromParent) {
                defaultOrderBy =
                    firstDimension.orderBy.type === "desc"
                        ? `-${firstDimension.orderBy.item}`
                        : firstDimension.orderBy.item;
            }
        }

        if (type === "pivot") {
            defaultOrderBy = table.dimensions.reduce((orderByMap, dimension) => {
                if (dimension.overrideFromParent) {
                    orderByMap[dimension.fieldId] =
                        dimension.orderBy.type === "desc"
                            ? `-${dimension.orderBy.item}`
                            : dimension.orderBy.item;
                }

                return orderByMap;
            }, {});
        }

        return defaultOrderBy;
    }

    protected _createPivot(
        scheme: FieldInfo[],
        numReferences: number,
        keyFields: string[],
        valueColumns: PivotTableWidgetColumn[],
        context: PivotContext,
        table: any
    ): LgPivotInstance {
        const pivotService = this._getLevelPivotService(table);
        const defaultOrderBy = this._getLevelOrderBy(table);

        const pivotDef = pivotService.getPivotDefinition({
            scheme,
            numReferences,
            levels: keyFields,
            columns: valueColumns as PivotTableColumn[]
        });

        // Apply defaultOrderBy per pivot row level
        let pivotRowDefinition = pivotDef;

        while (pivotRowDefinition != null) {
            pivotRowDefinition.defaultOrderBy =
                pivotRowDefinition.column && table.type === "pivot"
                    ? defaultOrderBy[pivotRowDefinition.column]
                    : defaultOrderBy;
            pivotRowDefinition = pivotRowDefinition.children;
        }

        this._addPivotFilters(pivotDef, keyFields);

        if (pivotDef.children) pivotDef.children.hidden = false;

        const tablePivot = this._pivotInstanceFactory.create(pivotDef, context);

        tablePivot.orderBy =
            typeof defaultOrderBy === "string" ? [defaultOrderBy] : Object.values(defaultOrderBy);

        if (this._initialTableState) {
            this._initialTableState.orderBy = [];
        }

        const selectedRow = this._getSelectedRow(tablePivot);
        context.selectedRow = selectedRow;
        context.pivot = tablePivot;

        this._selectedRows.push(selectedRow);

        return tablePivot;
    }

    protected _addPivotFilters(
        pivotDef: INormalizedLogexPivotDefinition,
        keyFields: string[]
    ): void {
        const bottomLevel = digToBottom(pivotDef);
        const originalBottomLevelFilters = bottomLevel.filters;

        const keyFieldFilters: Record<string, string> = keyFields.reduce((a, x) => {
            a[x] = x;
            return a;
        }, {});

        bottomLevel.filters = (context: PivotContext) => {
            const { selectedRow, filters } = context;

            if (selectedRow == null) throw Error("Selected row shouldn't be undefined.");

            return {
                ...(originalBottomLevelFilters != null ? originalBottomLevelFilters(context) : {}),
                ...getPivotFilter(
                    {
                        ...keyFieldFilters,
                        selectedRow
                    },
                    filters
                )
            };
        };

        // ---
        function digToBottom(pivotDef: ILogexPivotDefinition): ILogexPivotDefinition {
            return pivotDef.children != null ? digToBottom(pivotDef.children) : pivotDef;
        }
    }

    protected _getSelectedRow(pivot: LgPivotInstance): SelectedRow<any, any> {
        // In case of flat table the key of the row would be an array itself
        // If mergedKey == null it means only one lvl is selected
        return pivot.selectedRow(id => (pivot.definition.mergedKey == null ? id : [id]));
        // return pivot.selectedRow();
    }

    protected _onDrillChange(selectedLevelKeys: DrilldownKeyItem[][]): void {
        this._resubscribeToData(selectedLevelKeys, true);
    }

    protected _resubscribeToData(
        selectedLevelKeys: DrilldownKeyItem[][] = [],
        isDrillChange = false
    ): void {
        if (!this._isConfigValid) return;

        this._onDrillSubject.next();

        this._selectedLevelKeys = selectedLevelKeys;

        const resubscribeHandler = merge(
            this._destroyed$,
            this._reconfigureSubject,
            this._onDrillSubject
        );

        let fields: string[];
        let args: FlexibleDatasetDataArgumentsGetter;

        const isLoading: Array<Observable<boolean>> = [];
        const isStale: Array<Observable<boolean>> = [];
        const isCalculating: Array<Observable<boolean>> = [];
        const calculationProgress: Array<Observable<CalculationProgress | undefined>> = [];
        const calculationError: Array<Observable<CalculationError[] | undefined>> = [];

        for (let i = 0; i < selectedLevelKeys.length + 1; i++) {
            const tableIdx = i;
            const pivot = this._pivotsDefinition[i];

            const selectedRow = this._selectedRows[i];
            if (selectedLevelKeys[i] !== undefined) {
                selectedRow.key = selectedLevelKeys[i].map(x => x.value);
            } else if (!selectedRow.isEmpty) {
                selectedRow.key = undefined;
            }
            pivot.refilter();

            const nodeState = this._extractPivotState(pivot);
            const currentLevel = this._config.tablesConfig[i].dimensions.map(d => d.fieldId);

            fields = this._getRequiredField(currentLevel);
            args = this._getFlexDatasetArguments();

            this._pivotTableDataService.dataset().dataArguments = args;

            this._dataSubscription[i] = this._pivotTableDataService
                .dataset()
                .dataAsObservable(fields, args)
                .pipe(takeUntil(resubscribeHandler))
                .subscribe({
                    next: data => {
                        this._buildPivot(pivot, data.data, nodeState);
                        this._setConditionalFormattingData(currentLevel.at(-1)!, data.data);
                        this._isDataComplete = data.isComplete;
                        this._defineColumMaxValueForVisualizationWidget();

                        // If this pivot shows filtered out records, then we have to filter it's unfiltered collections
                        if (this._config.tablesConfig[tableIdx].ignoreOwnFilters) {
                            pivot.sort(false, true);
                        }
                        this._pivots.set(this._pivotsDefinition);
                    },
                    error: err => {
                        this._onServerFailure(err);
                        this._isDataComplete = true;
                    }
                });

            isLoading.push(this._pivotTableDataService.dataset().isLoading$(fields, args));

            isStale.push(this._pivotTableDataService.dataset().isStale$(fields, args));

            isCalculating.push(this._pivotTableDataService.dataset().isCalculating$(fields, args));

            calculationProgress.push(
                this._pivotTableDataService.dataset().calculationProgress$(fields, args)
            );

            calculationError.push(this._flexDataClient.dataset.calculationError$(fields, args));
        }

        // Make combined observables out of observables per dataset
        this._isLoading$ = combineLatest(isLoading).pipe(map(values => _.some(values)));

        this._isStale$ = combineLatest(isStale).pipe(map(values => _.some(values)));

        this._isCalculating$ = combineLatest(isCalculating).pipe(map(values => _.some(values)));

        this._calculationProgress$ = combineLatest(calculationProgress).pipe(
            map(values => combineCalculationProgress(values))
        );

        this._calculationError$ = combineLatest(calculationError).pipe(
            map(values => combineCalculationErrors(values))
        );
    }

    private _defineColumMaxValueForVisualizationWidget(): void {
        this._columnsAndGroups.forEach((group, groupIndex) => {
            if ("columns" in group) {
                group.columns.forEach(column => {
                    if (
                        column.visualizationWidget &&
                        column.visualizationWidget.type === "singleValueChartVisualizationWidget"
                    ) {
                        this.updateColumnMaxValue(
                            column,
                            this._pivotsDefinition[this._selectedLevelKeys.length]
                        );
                    }
                });
            } else if (
                group.visualizationWidget &&
                group.visualizationWidget.type === "singleValueChartVisualizationWidget"
            ) {
                this.updateColumnMaxValue(
                    group,
                    this._pivotsDefinition[this._selectedLevelKeys.length]
                );
            }
        });
    }

    private updateColumnMaxValue(column: Column, pivot: LgPivotInstance): void {
        const fieldName = getColumnFieldName(column as PivotTableColumn);

        if (this._maxColumnValues[fieldName] !== undefined) {
            let maxValue = 0;
            for (const row of pivot.all) {
                const value = Math.abs(row[fieldName]);
                if (maxValue < value) {
                    maxValue = value;
                }
            }
            this._maxColumnValues[fieldName] = maxValue;
        }
    }

    protected async _reconfigurePivots(): Promise<any> {
        if (!this._isConfigValid) return;

        this._reconfigureSubject.next();

        const context = [] as PivotContext[];

        this._selectedRows = [];

        await this._pivotTableDataService.getDataSource(this._config.dataSource);

        const pivots = [];
        this._config.tablesConfig.forEach((table, idx) => {
            context[idx] = { filters: this._filtersSet, selectedRow: null, pivot: null };
            const keyFields = table.dimensions.map(d => d.fieldId);
            const pivotInstance = this._createPivot(
                this._pivotTableDataService.scheme(),
                this._pageReferences.slots.length,
                keyFields,
                this._columnAndGroupsFlat,
                context[idx],
                table
            );
            pivots.push(pivotInstance);
        });
        this._pivotsDefinition = pivots;

        this._resubscribeToData();
    }

    // ----------------------------------------------------------------------------------

    protected _extractPivotState(pivot: LgPivotInstance): INodeStateStore | null {
        if (pivot.all == null) return null;
        return pivot.extractNodeState("$expanded");
    }

    protected _buildPivot(
        pivot: LgPivotInstance,
        data: unknown[],
        state: INodeStateStore | null
    ): void {
        pivot.build(data, false, true);

        if (state !== null) {
            pivot.applyNodesState("$expanded", state);
        }

        if (++this._pivotBuildCount === 1) {
            this._widgetHost.notifyWidgetReady(this._id!);
        }
    }

    markAsAutoConverted(): void {
        this._isAutoConverted = true;
    }

    setVisibleColumns(): void {
        this._columnAndGroupsFlat = [];
        for (const columnGroup of this._columnsAndGroups) {
            if ("columns" in columnGroup && columnGroup.columns) {
                this._columnAndGroupsFlat.push(
                    ...(columnGroup.columns as PivotTableWidgetColumn[])
                );
            } else {
                this._columnAndGroupsFlat.push(columnGroup as PivotTableWidgetColumn);
            }
        }
    }

    onVisibilityChange(isVisible: boolean): void {
        if (!isVisible) {
            this._dataSubscription.forEach(subscription => subscription.unsubscribe());
            this._dataSubscription = [];
        } else if (isVisible) {
            this._resubscribeToData();
        }
    }

    protected _getRequiredField(levels: string[]): string[] {
        // TODO: Maybe direct support for formulas
        return _.uniq([
            ...levels,
            ...this._columnAndGroupsFlat.flatMap(x => {
                switch (x.type) {
                    case "default":
                    case "difference":
                        return x.field;

                    case "widget":
                        return null;

                    case "formula":
                        return Object.values(x.variables).map(formulaVar =>
                            formulaVar.type === "constant" ? null : formulaVar.field
                        );

                    default:
                        throw Error(`Unsupported column type "${(x as any).type}"`);
                }
            })
        ]).filter((x): x is string => x !== null);
    }

    protected _checkLevelSorting(table: any) {
        if (!table.limitRows || table.limitRows === 0) return;

        if (table.orderBy && table.orderBy.length > 0) {
            const selectedColumnsFields = this._columnAndGroupsFlat.reduce(
                (selectedFields, column) =>
                    column.type === "default"
                        ? [...selectedFields, `${column.field}${column.referenceIdx}`]
                        : selectedFields,
                []
            );

            table.orderBy = table.orderBy.filter(sorting =>
                selectedColumnsFields.includes(sorting.item)
            );
        }
    }

    protected _onReferenceSelectedChange(reference: {
        slotIdx: number;
        value: string;
        columnGroupId: string;
    }): void {
        const { slotIdx, value, columnGroupId } = reference;

        this._config.columns.forEach((columnGroup, columnGroupIndex) => {
            if ((columnGroup as any).id !== columnGroupId) return;

            const references = this._pivotTableDataService.isDefaultDataSource()
                ? this._pageReferences.references
                : this._pivotTableDataService.references();
            const reference = references.find(s => s.code === value);

            columnGroup.referenceCode = reference?.code;
        });

        if (this._pivotTableDataService.isDefaultDataSource()) {
            const selected = this._pageReferences.selected;
            selected.splice(slotIdx, 1, value);
            this._pageReferences.selected = selected;
        } else {
            const selected = this._config.selectedReferences;
            selected.splice(slotIdx, 1, { referenceCode: value, isLocked: false });
            this._config.selectedReferences = selected;
        }

        this._reconfigurePivots();
    }

    protected _getFlexDatasetArguments(): FlexibleDatasetDataArgumentsGetter {
        const currentLevelIdx = this._selectedLevelKeys.length;
        let args = this._flexDatasetArgumentsCache[currentLevelIdx];
        if (args !== undefined) return args;

        const currentLevelConfig = this._config.tablesConfig[currentLevelIdx];
        this._checkLevelSorting(currentLevelConfig);

        const originalArgs = this._flexDataClient.dataset.dataArguments;
        const currentLevel = currentLevelConfig.dimensions.map(d => d.fieldId);
        const currentLimitRows =
            currentLevelConfig.limitRows && currentLevelConfig.limitRows > 0
                ? currentLevelConfig.limitRows
                : originalArgs.limit();
        const currentOrderBy = currentLevelConfig.orderBy
            ? currentLevelConfig.orderBy.map(o => (o.type === "desc" ? `-${o.item}` : o.item))
            : [];

        if (currentLevelIdx === 0) {
            this._limitRowsCount.set(currentLevelConfig.limitRows);
        }

        if (originalArgs.references) {
            originalArgs.references = () =>
                !this._pivotTableDataService.isDefaultDataSource()
                    ? this._getSelectedReferencesCodes()
                    : this._pageReferences.selected;
        }

        args = {
            ...originalArgs,
            limit: () => currentLimitRows,
            orderBy: () => currentOrderBy,
            filters: () => {
                let filters = originalArgs.filters() || {};

                if (currentLevelConfig.ignoreOwnFilters) {
                    // the exact logic with drilldown has to be agreed upon, but for now ignoreOwnFilters can be active
                    // only with single level, and there this should work fine
                    filters = _.omit(filters, ...currentLevel);
                }

                _.each(_.flatten(this._selectedLevelKeys), x => {
                    filters[x.fieldName] = [x.value];
                });

                return this._pivotTableDataService.dataset().getValidFilters(filters);
            }
        };

        // Cache and return arguments
        this._flexDatasetArgumentsCache[currentLevelIdx] = args;
        return args;
    }

    protected _setConditionalFormattingData(
        level: string | number,
        data: Array<Record<string, unknown>>
    ): void {
        this._columnAndGroupsFlat
            .filter((column: PivotTableWidgetColumn) => column.conditionalFormatting !== undefined)
            .forEach((column: PivotTableWidgetColumn) => {
                this._conditionalFormattingService.setConditionalFormatting(
                    column.conditionalFormatting!,
                    level,
                    getColumnFieldName(column as PivotTableColumn),
                    data
                );
            });
    }

    async showConfigurationUi(): Promise<void> {
        // TODO: This configuration dialog keeps hanging when is closed and configuration is not changed. ( JIRA: FADP-100 )
        const config = await this._configurator.show(this._config);

        const configVersion = this._widgetTypes.getWidgetConfigVersion(this);
        await this._widgetHost.updateWidgetConfiguration(
            this._id!,
            this.getWidgetType(),
            configVersion,
            config
        );

        this.setConfig(config);
    }

    _onDifferenceColumnChange(event: DifferenceColumnChangeEvent): void {
        this._columnsAndGroups = this._columnsAndGroups.map(group => {
            if ("columns" in group) {
                return {
                    ...group,
                    columns: group.columns.map(column => {
                        if (
                            (column as Column & { id: string }).id ===
                            (event.column as Column & { id: string }).id
                        ) {
                            return {
                                ...column,
                                mode: event.changes.mode
                            };
                        }
                        return column;
                    })
                };
            } else if (
                (group as Column & { id: string }).id ===
                (event.column as Column & { id: string }).id
            ) {
                return {
                    ...group,
                    mode: event.changes.mode
                };
            }
            return { ...group };
        });

        this.setVisibleColumns();

        this._initialTableState = this._table?.getState() ?? null;

        // Reactivate
        this._reconfigurePivots();
    }

    async _export(option: ExportFormat): Promise<void> {
        if (option === ExportFormat.SERVER) {
            await this._serverExport();
            return;
        }

        if (this._pivotsDefinition == null) throw Error("Pivots shouldn't be undefined.");
        const currentLevel = this._config.tablesConfig[this._selectedLevelKeys.length];

        if (currentLevel.type === "table") {
            this._tableExport();
        } else {
            this._pivotExport();
        }
    }

    _pivotExport(): void {
        this._exportService.export(
            this._pivotsDefinition[this._selectedLevelKeys.length],
            this._pivotTableDataService.scheme(),
            this._levels[this._selectedLevelKeys.length],
            this._columnAndGroupsFlat as PivotTableColumn[],
            this._pageReferences.selectedReferences,
            this._config.title,
            this._levelProperties
        );
    }

    _tableExport(): void {
        this._lgXlsx.saveArray(
            {
                filename: getExportFilename(this._config.title || "").slice(0, MAX_FILENAME_LENGTH),
                sheetName: this._config.title?.slice(0, MAX_SHEET_NAME_LENGTH),
                bigHeader: this._config.title,
                itemName: "cell"
            },
            this._getColumnDefinitions("cell"),
            this._filtersSet.getExportDefinition(),
            this._pivotTableDataService.scheme(),
            this._pivotsDefinition[this._selectedLevelKeys.length].filtered
        );
    }

    async _serverExport(): Promise<void> {
        // Define helper functions
        const transformFormula = (
            formulaType: ColumnFormulaType,
            variables: { [V in ColumnFormulaVariableNames]?: ColumnFormulaInput<V> }
        ): string => {
            const getVariableValue = (variable: ColumnFormulaInput): string => {
                if (variable.type === "constant") return variable.constant.toString();
                if (variable.type === "variable") {
                    const calculate = this._pivotTableDataService
                        .scheme()
                        .find(x => x.field === variable.field)?.calculate;
                    return calculate
                        ? `(${calculateToFormula(calculate, variable.reference)})`
                        : variable.field + variable.reference;
                }
                throw Error("Unknown variable type");
            };

            switch (formulaType) {
                case ColumnFormulaType.A:
                    return getVariableValue(variables.a);
                case ColumnFormulaType.AMinusB:
                    return `${getVariableValue(variables.a)} - ${getVariableValue(variables.b)}`;
                case ColumnFormulaType.APlusB:
                    return `${getVariableValue(variables.a)} + ${getVariableValue(variables.b)}`;
                case ColumnFormulaType.ADividedB:
                    return `${getVariableValue(variables.a)} / ${getVariableValue(variables.b)}`;
                case ColumnFormulaType.AMinusBMinusC:
                    return `${getVariableValue(variables.a)} - ${getVariableValue(variables.b)} - ${getVariableValue(variables.c)}`;
                case ColumnFormulaType.AMinusBPlusC:
                    return `${getVariableValue(variables.a)} - ${getVariableValue(variables.b)} + ${getVariableValue(variables.c)}`;
                case ColumnFormulaType.ADividedB_MinusC:
                    return `(${getVariableValue(variables.a)} / ${getVariableValue(variables.b)}) - ${getVariableValue(variables.c)}`;
                case ColumnFormulaType.AMultiplyBMinusC:
                    return `(${getVariableValue(variables.a)} * ${getVariableValue(variables.b)}) - ${getVariableValue(variables.c)}`;
                case ColumnFormulaType.PercentOfParent:
                    return `${getVariableValue(variables.a)} / PARENT(${getVariableValue(variables.a)})`;
                case ColumnFormulaType.PercentOfTotal:
                    return `${getVariableValue(variables.a)} / TOTAL(${getVariableValue(variables.a)})`;
                case ColumnFormulaType.ADivideBDivideC:
                    return `${getVariableValue(variables.a)} / ${getVariableValue(variables.b)} / ${getVariableValue(variables.c)}`;
                case ColumnFormulaType.ADivideBMultiplyC:
                    return `${getVariableValue(variables.a)} / ${getVariableValue(variables.b)} * ${getVariableValue(variables.c)}`;
                default:
                    throw Error("Unknown formula type");
            }
        };

        // Get group names
        let groupStartIndex = 0;
        const groups: ExportGroupInfo[] = this._config.columns
            .map((x, i) => {
                if ("columns" in x && x.columns.length > 0) {
                    let displayName = x.title;
                    if (x.columnType === "referenceSlot") {
                        const referenceIdx = x.columnType === "referenceSlot" && x.referenceIdx;
                        const referenceInfo = this._selectedReferences()[referenceIdx];
                        displayName =
                            referenceInfo.name ??
                            this._lgTranslate.translate(referenceInfo.nameLc) ??
                            "-";
                    }
                    // TODO: Create columns type handler. The handler for widget columns should say that it is not exportable,
                    //  handler for the difference columns should say that it is two columns and provide the data for both columns.
                    const groupLength =
                        x.columns.length +
                        x.columns.filter(col => col.type === "difference").length -
                        x.columns.filter(col => col.type === "widget").length;
                    const output = {
                        displayName,
                        startIndex: groupStartIndex,
                        length: groupLength
                    } as ExportGroupInfo;
                    groupStartIndex += groupLength;
                    return output;
                } else if (!("columns" in x) && (x as Column).type === "difference") {
                    groupStartIndex += 2; //  Difference column is exported as abs and % columns
                } else if (!("columns" in x) && (x as Column).type !== "widget") {
                    groupStartIndex++;
                }
                return null;
            })
            .filter(x => x !== null);

        // Get dimensions and values for export
        const schemaLookup = _.keyBy(this._pivotTableDataService.scheme(), x => x.field);

        const dimensions = this._config.tablesConfig.flatMap(x =>
            x.dimensions.map(d => {
                const displayName =
                    d.name ??
                    schemaLookup[d.fieldId].name ??
                    this._lgTranslate.translate(schemaLookup[d.fieldId].nameLc);

                return <ExportFieldInfo>{
                    isValueField: false,
                    displayName,
                    value: d.fieldId
                };
            })
        );

        const columnsForExport = this._config.columns
            .flatMap(x => {
                if ("columns" in x) return x.columns;
                return x;
            })
            .filter(x => ["default", "formula", "difference"].includes(x.type));

        const values = columnsForExport.map((x: Column): ExportFieldInfo => {
            let value: string;
            let decimalPlaces: number;
            let isDifference: boolean | undefined;
            let formatType: ColumnFormulaFormatting | undefined;

            if (x.type === "default") {
                value = x.field + x.referenceIdx;
                const fieldInfo = schemaLookup[x.field];
                const fieldCalculation = fieldInfo?.calculate;
                if (fieldCalculation != null) {
                    value += ` = ${calculateToFormula(fieldCalculation, x.referenceIdx)}`;
                }
            }
            if (x.type === "formula") {
                value = `${x.type + x.id} = ${transformFormula(x.formula, x.variables)}`;
                decimalPlaces = x.formatPrecision;
                formatType = x.formatType;
            }
            if (x.type === "difference") {
                value = `${x.type + x.id} = ${x.field + x.referenceRight} - ${x.field + x.referenceLeft}`;
                isDifference = true;
            }

            return {
                isValueField: true,
                displayName: x.title,
                value,
                formatType,
                decimalPlaces,
                isDifference,
                aggregatedTotals: x.aggregatedTotals
            };
        });

        await this._flexDataClient.triggerExport(
            this._config.title,
            this._context.pageName,
            this._context.layoutName + " ― " + this._config.title,
            this._context.clientId,
            this._context.clientName,
            this._context.currency,
            groups,
            [...dimensions, ...values]
        );
    }

    private _getSelectedReferencesCodes(): string[] {
        return this._config.selectedReferences
            ? this._config.selectedReferences.map(reference => reference.referenceCode)
            : this._pageReferences.selected;
    }

    private _getColumnDefinitions(itemName?: string): IColumnExportDefinition[] {
        const definitions: IColumnExportDefinition[] = [];
        this._levels[this._selectedLevelKeys.length].forEach(lvl => {
            const levelInfo = this._pivotTableDataService.scheme().find(x => x.field === lvl);
            if (levelInfo === undefined) throw Error(`Level ${lvl} not found in schema.`);
            definitions.push(
                ...this._exportService._getLevelExportDefinitions(
                    itemName,
                    levelInfo,
                    this._levelProperties && this._levelProperties[levelInfo.field]
                )
            );
        });
        definitions.push(
            ...this._exportService._getOtherExportDefinitions(
                this._columnAndGroupsFlat as PivotTableColumn[],
                this._pivotTableDataService.scheme(),
                this._pageReferences.selectedReferences,
                itemName
            )
        );
        return definitions;
    }
}
