import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnChanges,
    Output
} from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { PivotTableColumnFormulaType, PivotTableColumnFormulaVariableNames } from "../../../types";

import { columnFormulaTypeLabels, commonFormulasTypes } from "../../../types/configuration";

import {
    ConfigurationDialogColumnFormula,
    ConfigurationDialogColumnFormulaType
} from "./configuration-dialog-formula.types";

@Component({
    standalone: false,
    selector: "lgflex-configuration-dialog-formula",
    templateUrl: "./configuration-dialog-formula.component.html",
    styleUrls: ["./configuration-dialog-formula.component.scss"],
    providers: [...useTranslationNamespace("_Flexible.ConfigurationDialogFormula")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfigurationDialogFormulaComponent implements OnChanges {
    private _lgTranslate = inject(LgTranslateService);

    @Input() column!: ConfigurationDialogColumnFormula;
    @Output() readonly columnChange = new EventEmitter<ConfigurationDialogColumnFormula>();

    @Input() defaultTitle: string | undefined;
    @Input() defaultTitleLc: string | undefined;
    @Input() fieldsDropdownDefinition: IDropdownDefinition<string> | undefined;
    @Input() areReferencesAllowed: boolean | undefined;
    @Input() referencesDropdownDefinition: IDropdownDefinition<number> | undefined;
    @Input() noFormatting = false;
    @Input() formulas: ConfigurationDialogColumnFormulaType[] = commonFormulasTypes;

    protected _currentFormulaVariables = new Set<PivotTableColumnFormulaVariableNames>();
    protected _formulaTypeDropdownDef:
        | IDropdownDefinition<ConfigurationDialogColumnFormulaType>
        | undefined;

    protected _formulaInputTypeDropdownDef: IDropdownDefinition<"variable" | "constant"> =
        dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: [
                {
                    id: "variable",
                    name: this._lgTranslate.translate(".FieldAndReference")
                },
                {
                    id: "constant",
                    name: this._lgTranslate.translate(".Constant")
                }
            ]
        });

    // ----------------------------------------------------------------------------------
    //
    ngOnChanges(): void {
        this._formulaTypeDropdownDef = dropdownFlat({
            entryId: "type",
            entryName: "name",
            entries: this.formulas.map(val => ({
                type: val,
                name: columnFormulaTypeLabels[val]
            }))
        });

        this.column.title =
            this.defaultTitle ||
            (this.defaultTitleLc && this._lgTranslate.translate(this.defaultTitleLc)) ||
            (this.column?.title != null && this._lgTranslate.translate(this.column.title)) ||
            "";

        this._setFormulaType(this.column.formula as ConfigurationDialogColumnFormulaType);
    }

    private _setFormulaType(formulaType: ConfigurationDialogColumnFormulaType): void {
        switch (formulaType) {
            case PivotTableColumnFormulaType.A:
                this._currentFormulaVariables = new Set(["a"]);
                break;
            case PivotTableColumnFormulaType.AMinusB:
            case PivotTableColumnFormulaType.APlusB:
            case PivotTableColumnFormulaType.ADividedB: {
                this._currentFormulaVariables = new Set(["a", "b"]);
                break;
            }
            case PivotTableColumnFormulaType.AMinusBMinusC:
            case PivotTableColumnFormulaType.AMinusBPlusC:
            case PivotTableColumnFormulaType.AMultiplyBMinusC:
            case PivotTableColumnFormulaType.ADividedB_MinusC:
            case PivotTableColumnFormulaType.ADivideBDivideC:
            case PivotTableColumnFormulaType.ADivideBMultiplyC: {
                this._currentFormulaVariables = new Set(["a", "b", "c"]);
                break;
            }
            case PivotTableColumnFormulaType.PercentOfParent:
            case PivotTableColumnFormulaType.PercentOfTotal: {
                this._currentFormulaVariables = new Set(["a"]);
                this.column.formatType = "percentage";
                break;
            }
            default: {
                return;
            }
        }
        this._changeFormulaVariablesConfiguration(this._currentFormulaVariables);
    }

    _onFormulaTypeChange(formulaType: ConfigurationDialogColumnFormulaType): void {
        this._setFormulaType(formulaType);
        this.columnChange.emit(this.column);
    }

    _changeFormulaVariablesConfiguration(
        formulaVariables: Set<PivotTableColumnFormulaVariableNames>
    ): void {
        if (this.column.variables == null) {
            this.column.variables = {};
        } else {
            Object.keys(this.column.variables).forEach(key => {
                if (!formulaVariables.has(key as PivotTableColumnFormulaVariableNames)) {
                    delete this.column.variables[key as PivotTableColumnFormulaVariableNames];
                }
            });
        }

        formulaVariables.forEach(formulaVar => {
            if (this.column.variables[formulaVar] === undefined) {
                this.column.variables[formulaVar] = {
                    variable: formulaVar,
                    type: "variable",
                    field: null,
                    reference: undefined
                } as any;
            } else if (this.column.variables[formulaVar]!.type === undefined) {
                this.column.variables[formulaVar]!.type = "variable";
            }
        });
    }

    _onColumnChanged(column: ConfigurationDialogColumnFormula): void {
        this.columnChange.emit(column);
    }
}
