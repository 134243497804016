import { Provider } from "@angular/core";
import { FADP_MODULE_INITIALIZERS } from "./types";
import { ExportsNotificationsService } from "./exports/services/exports-notifications.service";
import { ExportModuleInitializer } from "./exports/export-module-initializer";

interface FadpProvidersConfiguration {
    enableExportsModule?: boolean;
}

export const fadpProviders = (config?: FadpProvidersConfiguration) => {
    const providers: Provider[] = [];

    if (config?.enableExportsModule === true) {
        providers.push({
            provide: FADP_MODULE_INITIALIZERS,
            useExisting: ExportModuleInitializer,
            multi: true
        });
    }

    return providers;
};
