import { PartialBy } from "@logex/framework/types";
import { LayoutWidget, Overwrite, PageLayoutConfiguration } from "../../../types";

export enum FlexibleLayoutLibraryEnum {
    PERSONAL = 1,
    CLIENT = 2,
    SHARED = 3
}

export type FlexibleLayoutLibrary = {
    id: number;
    name: string;
    typeId: FlexibleLayoutLibraryEnum;
    readOnly?: boolean;
};

export type FlexibleLayout = {
    id: number;
    name: string;
    description: string;
    isDefault: boolean;
    isTemporary: boolean;
    parentId: number | null;
    libraryId: number;

    isShared?: boolean;
    isPersonal?: boolean;

    // Extra properties
    hasTemporary: boolean;
    config?: PageLayoutConfiguration;
};

export type CategorizedLayouts = {
    libraryName: string;
    readOnly: boolean;
    layouts: FlexibleLayout[];
};

export type SaveFlexibleLayoutArguments = Overwrite<
    FlexibleLayout,
    {
        id: number | null;
        libraryId: number | null;
        clientId?: number;
        scenarioId?: number;
        page: string;
        type: FlexibleLayoutLibraryEnum;
    }
>;

export type SaveFlexibleLayoutResponse = {
    id: number;
};

export type DeleteFlexibleLayoutArguments = {
    clientId?: number | undefined;
    id: number;
};

export type GetFlexibleLayoutConfigArguments = {
    clientId?: number | undefined;
    id: number;
};

export type GetFlexibleLayoutConfigResponse = {
    config: Overwrite<
        PageLayoutConfiguration,
        {
            widgets: Array<PartialBy<LayoutWidget, "configVersion">>;
        }
    >;
};

export type GetFlexibleLayoutArguments = {
    clientId?: number;
    scenarioId?: number;
    page: string;
};

export type GetFlexibleLayoutLibraries = {
    clientId?: number;
    scenarioId?: number;
    page: string;
};

export type GetFlexibleLayoutResponse = Overwrite<
    FlexibleLayout,
    {
        type: FlexibleLayoutLibraryEnum;
        libraryId: number | null;
        hasTemporary?: boolean;
    }
>;

export type GetLibrariesResponse = Overwrite<
    FlexibleLayoutLibrary,
    {
        id: null | number;
    }
>;

export interface LayoutNavigation {
    page: string;
    name: string;
    layoutId?: number;
    children?: LayoutNavigation[];
    expandedInNavigation?: boolean;
}
