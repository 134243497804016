import { Routes } from "@angular/router";

/**
 * Standalone route definition for the calculation service UI.
 * Use this with the new provideRouter() approach in Angular.
 *
 * Example:
 * ```typescript
 * // In your routes configuration:
 * const routes: Routes = [
 *   {
 *     path: 'calculations',
 *     loadChildren: () => import('@logex/fadp/calculation-service').then(m => m.CALCULATION_SERVICE_ROUTES)
 *   }
 * ];
 * ```
 */
export const CALCULATION_SERVICE_ROUTES: Routes = [
    {
        path: "",
        // Import using reference to avoid eager loading
        loadComponent: () =>
            import("./components/calculation-state-panel/calculation-state-panel.component").then(
                m => m.CalculationStatePanelComponent
            )
    }
];
