import { Directive, inject, OnDestroy, ViewContainerRef } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import * as _ from "lodash";
import {
    asyncScheduler,
    BehaviorSubject,
    catchError,
    combineLatest,
    concat,
    map,
    Observable,
    of,
    ReplaySubject,
    scheduled,
    shareReplay,
    Subject,
    tap
} from "rxjs";

import {
    formatServerException,
    LoaderArgumentsMap,
    LoaderConfiguration,
    LoadManager,
    STALE_DATA_SERVICE
} from "@logex/load-manager";
import { memoizeByKey } from "@logex/framework/utilities";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { omitFilters } from "../../helpers";
import { IFilterDataGateway, PageBlock, PageBlockExt } from "./types";
import { PageComponentBase } from "../page-component-base";

@Directive()
export abstract class PageComponentLoadManagerBase<TAppDefinitions>
    extends PageComponentBase<TAppDefinitions>
    implements OnDestroy
{
    protected _loadManager = inject(LoadManager, { self: true });
    protected _staleDataService = inject(STALE_DATA_SERVICE, { self: true });

    protected _bootstrapDataLoaded$ = new BehaviorSubject(false);
    private _isPageBlockLoadingCache$: Record<string, Observable<boolean>> = {};
    private _isLoadingCache$: Record<string, Observable<boolean>> = {};

    protected _filterOptions$: Record<string, Subject<any[]>> = {};
    protected _pageBlocks: Record<string, PageBlockExt>;
    protected _gateway: IFilterDataGateway;

    protected override _prepare(): Observable<any[]> {
        this._configureLoadManager();
        this._configurePageBlocks();

        return super._prepare();
    }

    protected _activate(): void {
        // Load data
        this._loadBootstrapData()
            .pipe(
                catchError(err => {
                    this._lgConsole.error("Error loading bootstrap data", err);
                    this._onServerFailure(err);
                    throw err;
                })
            )
            .subscribe(() => {
                this._onBootstrapDataLoaded();
            });
    }

    protected _configureLoadManager(): void {
        this._addLoaders(this._getLoaderArguments());
    }

    protected abstract _getLoaderArguments(): LoaderArgumentsMap;

    protected abstract _addLoaders(args: LoaderArgumentsMap): void;

    /**
     * Loads one or several data sets.
     *
     * @param toLoad Names of data sets to load.
     */
    protected _load(...toLoad: string[]): Observable<any> {
        return this._loadDataSets(toLoad, false);
    }

    private _loadDataSets(sets: string[], force: boolean): Subject<string[]> {
        if (_.isEmpty(sets)) {
            throw Error("Missing data sets to load");
        }

        const fn: (...names: string[]) => Observable<any[]> = !force
            ? this._loadManager.load.bind(this._loadManager)
            : this._loadManager.reload.bind(this._loadManager);

        const subj = new Subject<string[]>();

        const onError = (err): void => {
            this._lgConsole.error("load: loading has failed.", sets, err);
            this._onServerFailure(err);
            subj.error(err);
        };

        fn(...sets).subscribe({
            next: data => {
                // Check if some of the requests failed w/o throwing an exception
                const errorResponse = _.find(data, x => x != null && x.ok === false);
                if (errorResponse != null) {
                    onError(errorResponse);
                    return;
                }

                this._lgConsole.debug("load: loading has completed.", sets);
                subj.next(data);
            },
            error: onError,
            complete: () => {
                subj.complete();
            }
        });
        return subj;
    }

    /**
     * If not data set names are specified, clears the arguments cache in LoadManager and reloads all visible page blocks.
     * Otherwise reloads only specified sets.
     *
     * @param toLoad Optional names of data sets to load.
     */
    protected _reload(...toLoad: string[]): Observable<any> {
        if (toLoad.length === 0) {
            this._loadManager.flush();
            return this._loadVisibleDataSets();
        } else {
            return this._loadDataSets(toLoad, true);
        }
    }

    /**
     * Triggers load of all visible page blocks
     */
    protected _loadVisibleDataSets(): Observable<any> {
        const sets = _.uniq([
            ...this._getVisibleDataSets(),
            ...this._loadManager.getObservedLoaders()
        ]);

        if (_.isEmpty(sets)) return scheduled(of([]), asyncScheduler);

        return this._load(...sets);
    }

    /**
     * Cancels loading of a specified data set. If it is not loading now, method just
     * won't do anything.
     *
     * @param name Name of the data set to cancel.
     */
    protected _cancelLoading(name: string): void {
        this._loadManager.cancel(name);
    }

    /**
     * Returns true if a specified data set is being loaded now.
     *
     * @param name Name of the data set to check.
     */
    isLoading(name: string): boolean {
        return this._loadManager.isLoading(name);
    }

    isLoading$(...names: string[]): Observable<boolean> {
        if (names.length === 0) return of(false);

        return memoizeByKey(this._isLoadingCache$, names.join(","), () =>
            combineLatest(_.map(names, name => this._loadManager.isLoadingAsObservable(name))).pipe(
                map((values: boolean[]) => _.reduce(values, (a, x) => a || x, false)),
                shareReplay(1)
            )
        );
    }

    /**
     * Returns true if a specified data set is being loaded now.
     *
     * @param name Name of the data set to check.
     */
    isLoaded(name: string): boolean {
        return this._loadManager.isLoaded(name);
    }

    /**
     * Returns observable for obtaining a specified data set's data.
     *
     * @param name Name of the data set.
     */
    dataAsObservable(name: string): Observable<any> {
        return this._loadManager.dataAsObservable(name);
    }

    /**
     * Triggers load of the data sets with assigned observables.
     */
    protected _loadObservedDataSets(): void {
        this._load(...this._loadManager.getObservedLoaders());
    }

    /**
     * Callback for loading additional data the page depends on. It got triggered on page landing.
     */
    protected _loadBootstrapData(): Observable<any> {
        return this._loadVisibleDataSets();
    }

    protected _onBootstrapDataLoaded(): void {
        this._bootstrapDataLoaded$.next(true);
        this._bootstrapDataLoaded$.complete();
    }

    // ----------------------------------------------------------------------------------
    // Filter options loading

    protected _loadFilterOptions(filterUid, argsOverride?: LoaderArgumentsMap): Observable<any[]> {
        this._loadManager
            .loadParamsOverride(this._filterOptionsLoaderName(filterUid), [argsOverride])
            .pipe(
                tap({
                    next: data => {
                        if (data != null && data.ok === false) {
                            this._onServerFailure(data);
                            throw data;
                        }
                    },
                    error: err => {
                        this._onServerFailure(err);
                        throw err;
                    }
                })
            );

        return this._filterOptions$[filterUid].asObservable();
    }

    protected _filterOptionsLoader(
        filterUid: string,
        args: LoaderArgumentsMap
    ): Record<string, LoaderConfiguration> {
        const subject = new ReplaySubject<any[]>(1);
        this._filterOptions$[filterUid] = subject;

        return {
            ...this._staleDataService.configureLoader(
                this._filterOptionsLoaderName(filterUid),
                {
                    args: [omitFilters(args, filterUid)],
                    loader: (args, subscription) =>
                        this._gateway.selectFilter(filterUid, args, subscription)
                },
                (data, isStale) => {
                    subject.next(data);
                    return data;
                }
            )
        };
    }

    protected _filterOptionsLoaderName(filterName: string): string {
        return `${filterName}Filter`;
    }

    // ----------------------------------------------------------------------------------
    // Page blocks

    protected _configurePageBlocks(): void {
        const makePageBlockIsLoadingFunction = (block: PageBlock): (() => boolean) => {
            const tests = _.map(
                block.dataSets,
                datasetName => `this.isLoading( "${datasetName}" )`
            );
            const body = `return ${tests.join(" || ")};`;
            const func = new Function(body); // eslint-disable-line no-new-func
            return func.bind(this);
        };

        const makePageBlockIsLoadedFunction = (block: PageBlock) => {
            const tests = _.map(block.dataSets, y => `this.isLoaded( "${y}" )`);
            const body = `return ${tests.join(" && ")};`;
            const func = new Function(body); // eslint-disable-line no-new-func
            return func.bind(this);
        };

        const pageBlocks = this._getPageBlocks();
        this._pageBlocks = _.mapValues(pageBlocks, x => ({
            visible: x.visible,
            dataSets: x.dataSets,
            isLoading: makePageBlockIsLoadingFunction(x),
            isLoading$: this.isLoading$(...x.dataSets),
            isLoaded: makePageBlockIsLoadedFunction(x)
        }));
    }

    /**
     * When overridden should return the page UI blocks. UI block has a name
     * and it could be visible or invisible now depending on some criteria.
     * If block is visible we can load the data it depends on by requesting
     * all data sets defined in "dataSets". If block is not visible, then
     * it is not necessary to load data.
     *
     * Usage example:
     * ```typescript
     * protected getPageBlocks() {
     *     return {
     *         specialisms: {
     *             visible: () => this.isProductTab() && !this.selectedProduct,
     *             dataSets: ["specialisms"]
     *         },
     *         ...
     *      };
     * }
     * ```
     */
    protected _getPageBlocks(): Record<string, PageBlock> {
        return {};
    }

    /**
     * Returns if the given page UI block is visible at the moment.
     *
     * @param name Name of the UI block.
     */
    _isPageBlockVisible(name: string): boolean {
        if (!this._pageBlocks) {
            return false;
        }

        const block = this._getPageBlock(name);
        return block.visible();
    }

    protected _getVisibleDataSets(): string[] {
        const visibleDatasets = Object.values(this._pageBlocks)
            .filter(x => x.visible())
            .map((x: PageBlockExt) => x.dataSets)
            .flat();
        return [...new Set(visibleDatasets)];
    }

    protected _getPageBlock(name: string): PageBlockExt {
        const block = this._pageBlocks[name];

        if (!block) {
            throw Error(`isPageBlockLoading: Unknown block "${name}" requested.`);
        }

        return block;
    }

    /**
     * Check if any data set of a given page block is still loading.
     *
     * @param name
     */
    _isPageBlockLoading(name: string): boolean {
        if (!this._bootstrapDataLoaded$.value) return true;

        const block = this._getPageBlock(name);
        return block.isLoading();
    }

    /**
     * Check if any data set of a given page block is still loading. Observable variant.
     */
    _isPageBlockLoading$(...names: string[]): Observable<boolean> {
        if (_.isEmpty(names)) return of(false);

        // Check the cache
        return memoizeByKey(this._isPageBlockLoadingCache$, names.join(","), () =>
            concat(
                this._bootstrapDataLoaded$.pipe(map(value => !value)),
                combineLatest(_.map(names, name => this._getPageBlock(name).isLoading$)).pipe(
                    map((values: boolean[]) => _.reduce(values, (a, x) => a || x, false))
                )
            ).pipe(shareReplay(1))
        );
    }

    /**
     * Check if any data set of a given page block is still loading.
     *
     * @param name
     */
    _isPageBlockLoaded(name: string): boolean {
        if (!this._bootstrapDataLoaded$.value) return false;

        const block = this._getPageBlock(name);
        return block.isLoaded();
    }

    override ngOnDestroy(): void {
        // eslint-disable-next-line @angular-eslint/no-lifecycle-call
        this._loadManager?.ngOnDestroy();
        this._loadManager = null;

        _.each(this._filterOptions$, x => x.complete());
        this._filterOptions$ = null;

        super.ngOnDestroy();
    }
}
