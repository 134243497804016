import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    Output
} from "@angular/core";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { OrderType } from "../../widgets/pivot-table/components/pivot-table-configuration-dialog/pivot-table-configuration-dialog.types";
import { dropdownFlat } from "@logex/framework/utilities";
import { CdkDragDrop } from "@angular/cdk/drag-drop";

type LevelSortingItem = { item: string; type: "asc" | "desc" };
@Component({
    standalone: false,
    selector: "lgflex-level-sorting-form",
    templateUrl: "./level-sorting-form.component.html",
    styleUrls: ["./level-sorting-form.component.scss"],
    providers: [...useTranslationNamespace("_Flexible._LevelSortingForm")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LevelSortingFormComponent {
    private _lgTranslate = inject(LgTranslateService);

    protected _sortingByTypeDropdown: IDropdownDefinition<OrderType> = dropdownFlat({
        entryId: "code",
        entryName: "name",
        entries: [
            {
                code: "asc",
                name: this._lgTranslate.translate(".Ascending")
            },
            {
                code: "desc",
                name: this._lgTranslate.translate(".Descending")
            }
        ]
    });

    @Input("sortingDropdown") sortingDropdown: any;
    @Input("levelSortingItems") levelSortingItems: LevelSortingItem[];
    @Input("addSortingBtnDisabled") addSortingBtnDisabled = false;
    @Input("oneItemMode") oneItemMode = false;

    @Output() readonly sortingAdded = new EventEmitter<void>();
    @Output() readonly sortingRemoved = new EventEmitter<number>();

    @Output() readonly sortingItemUpdated = new EventEmitter<{
        item: LevelSortingItem;
        index: number;
    }>();

    @Output() readonly sortingItemDrop = new EventEmitter<
        CdkDragDrop<{ item: string; type: string }>
    >();
}
