import { computed, inject, Injectable, Signal, signal, WritableSignal } from "@angular/core";
import { FlexDataClientService } from "../../services/flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { FlexiblePivotDefinitionFactory } from "../../services/flexible-pivot/flexible-pivot-definition-factory";
import { LgPivotInstance, LgPivotInstanceFactory } from "@logex/framework/lg-pivot";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { BehaviorSubject, endWith, merge, Observable, of, Subject, Subscription } from "rxjs";
import { switchMap, take, takeUntil } from "rxjs/operators";
import * as _ from "lodash-es";
import { mixins } from "@logex/mixin-flavors";
import { HandleErrorsMixin, NgOnDestroyMixin } from "@logex/mixins";
import { ChartData, ChartRenderBaseDatum, ChartValue } from "./components/chart/chart.types";
import { getItemNameFromField } from "../../utilities/getItemNameFromField";
import { LgConsole } from "@logex/framework/core";
import { DefinitionDisplayMode, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import {
    ChartDimensionRow,
    ChartLevel,
    ChartWidgetConfig,
    LevelState
} from "./chart-widget.configuration.types";
import {
    FieldInfo,
    MAX_LIMIT_ROWS,
    PivotContext,
    PivotTableColumn,
    ReferenceSlot
} from "../../types";
import {
    FlexibleDataset,
    FlexibleDatasetDataArgumentsGetter
} from "../../services/flexible-dataset";
import { ChartWidgetStateBase } from "../base/chart-widget-base/chart-widget-base.types";
import { getColumnFieldName } from "../../utilities/getColumnFieldName";
import { FlexibleLayoutDataSourcesService } from "../../services/flexible-layout-data-sources";
import { CalculationError, CalculationProgress } from "@logex/load-manager";

export interface ChartWidgetLevelDataService extends HandleErrorsMixin, NgOnDestroyMixin {}

@Injectable()
@mixins(HandleErrorsMixin, NgOnDestroyMixin)
export class ChartWidgetLevelDataService {
    private readonly _filtersSet = inject(LgFilterSet);
    private readonly _flexDataClient = inject(FlexDataClientService);
    private readonly _flexiblePivotFactory = inject(FlexiblePivotDefinitionFactory);
    private readonly _pivotInstanceFactory = inject(LgPivotInstanceFactory);
    private readonly _pageReferences = inject(PageReferencesService);
    private readonly _lgConsole = inject(LgConsole);
    private readonly _definitions = inject(LG_APP_DEFINITIONS);
    private readonly _layoutDataSource = inject(FlexibleLayoutDataSourcesService);
    readonly _promptDialog = inject(LgPromptDialog);
    readonly _lgTranslate = inject(LgTranslateService);

    // ----------------------------------------------------------------------------------

    private _pivots: Array<LgPivotInstance<unknown, unknown>> | null = null;
    private _reconfigureSubject = new Subject<void>();
    private _dataSubscription: Subscription[] = [];
    private _levelStack: ChartLevel[] = [];
    private _stackedBarDimension: Signal<string | undefined> = computed(() => {
        return this._config.tablesConfig[this._currentTableLevelSignal()]?.stackedBarDimension;
    });

    private readonly _chartLabelDisplayMode = computed<DefinitionDisplayMode>(() => {
        const properties = this._config.tablesConfig.map(t => t.dimensions);
        const tableLevelProperties = properties[this._currentTableLevelSignal()];
        const currentLevelProperty =
            tableLevelProperties && tableLevelProperties[this._currentLevelSignal()];
        return currentLevelProperty?.displayMode || "code";
    });

    private _tableLevels: string[][] = [];
    private _pendingState: ChartWidgetStateBase | null = null;
    private _currentKeyField: FieldInfo | undefined;
    private _totalsAsTopLevelSignal = signal(false);
    private _pivotBuildCount = 0;
    private _notifyDataReadyCallback: () => void;
    private _config: ChartWidgetConfig;
    private _sourceData = [];
    private _chartFields: string[] = [];
    private _sortField: WritableSignal<string | undefined> = signal(undefined);
    private _schemeLookup: Record<string, FieldInfo> = {};
    private _sortDescending = true;
    private _compareFnMap = new Map();
    private _currentLevelData = computed(
        () => this._config?.tablesConfig[this._currentTableLevelSignal()]
    );

    private _flexDatasetArgumentsCache: FlexibleDatasetDataArgumentsGetter[] = [];

    private _columns: WritableSignal<PivotTableColumn[]> = signal([]);
    private _currentTableLevelSignal: WritableSignal<number> = signal(0);
    private _currentLevelSignal: WritableSignal<number> = signal(0);
    private _isDataCompleteSignal: WritableSignal<boolean> = signal(true);
    private _levelDataSignal: WritableSignal<ChartData<Record<string, number | string>>> = signal(
        []
    );

    private _isChartBuildingSignal: WritableSignal<boolean> = signal(false);
    private _dataSource = signal(null);
    public scheme = signal(null);
    private _references = signal(null);
    private _dataset: WritableSignal<FlexibleDataset> = signal(null);

    protected _isDefaultDataSource = computed(
        () => this._layoutDataSource.defaultLayoutDataSourceCode() === this._dataSource()
    );

    private _isLoadingSubject: Subject<Observable<boolean>> = new BehaviorSubject(of(false));
    private _isStaleSubject: Subject<Observable<boolean>> = new BehaviorSubject(of(false));
    private _isCalculatingSubject: Subject<Observable<boolean>> = new BehaviorSubject(of(false));
    private _calculationProgressSubject: Subject<Observable<CalculationProgress | null>> =
        new BehaviorSubject(of(null));

    private _calculationErrorSubject: Subject<Observable<CalculationError[] | null>> =
        new BehaviorSubject(of(null));

    public readonly currentTableLevel = this._currentTableLevelSignal.asReadonly();
    public readonly currentLevel = this._currentLevelSignal.asReadonly();
    public readonly columns = this._columns.asReadonly();
    public readonly levelData = computed(() => {
        return this._sortChartData(this._levelDataSignal());
    });

    public readonly isDataComplete = this._isDataCompleteSignal.asReadonly();
    public readonly sortField = this._sortField.asReadonly();
    public readonly totalsAsTopLevel = this._totalsAsTopLevelSignal.asReadonly();
    public readonly isChartBuilding = this._isChartBuildingSignal.asReadonly();
    public readonly levels: Signal<string[]> = computed(() => {
        const tableLevel = this._tableLevels[this._currentTableLevelSignal()];
        return tableLevel ? [...tableLevel] : [];
    });

    public readonly limitRows: Signal<number | undefined> = computed(() => {
        return this._config?.tablesConfig[this.currentTableLevel()]?.limitRows;
    });

    public readonly currentLevelDimension: Signal<ChartDimensionRow | undefined> = computed(() => {
        const tableLevel = this._config?.tablesConfig[this.currentTableLevel()];
        const currentLevelDimension = tableLevel?.dimensions[this.currentLevel()];
        return currentLevelDimension ? { ...currentLevelDimension } : undefined;
    });

    public readonly pivot: Signal<LgPivotInstance<unknown, unknown>> = computed(() => {
        return this._pivots[this._currentTableLevelSignal()];
    });

    public readonly isLoading$: Observable<boolean> = this._isLoadingSubject.pipe(
        switchMap(o => o)
    );

    public readonly isStale$: Observable<boolean> = this._isStaleSubject.pipe(switchMap(o => o));

    public readonly isCalculating$: Observable<boolean> = this._isCalculatingSubject.pipe(
        switchMap(o => o)
    );

    public readonly calculationProgress$: Observable<CalculationProgress | undefined> =
        this._calculationProgressSubject.pipe(
            switchMap(o => o.pipe(endWith(<CalculationProgress | undefined>undefined)))
        );

    public readonly calculationError$: Observable<CalculationError[] | undefined> =
        this._calculationErrorSubject.pipe(
            switchMap(o => o.pipe(endWith(<CalculationError[] | undefined>undefined)))
        );

    // ----------------------------------------------------------------------------------

    constructor() {
        this._initMixins();
    }

    /*
    <--------------------------------Initialization--------------------------------------->
    */
    async init(
        config: ChartWidgetConfig,
        notifyDataReadyCallback: () => void,
        id: string
    ): Promise<void> {
        this._config = {
            ...config,
            tablesConfig: [
                ...config.tablesConfig.map(table => {
                    const updatedTable = { ...table };
                    if (updatedTable.dimensions.length > 0) {
                        updatedTable.dimensions = [
                            ...updatedTable.dimensions.map(d => {
                                return {
                                    ...d,
                                    name: d.name || this._getDimensionTitle(d.fieldId)
                                };
                            })
                        ];
                    }
                    return updatedTable;
                })
            ]
        };

        await this.updateDataSource(this._config.dataSource);

        this.setSelectedReferences(config.selectedReferences, id);

        this._schemeLookup = _.keyBy(this.scheme(), "field");

        this._notifyDataReadyCallback = notifyDataReadyCallback;
        this._totalsAsTopLevelSignal.set(false); // this._totalsAsTopLevelSignal.set(config.totalsAsTopLevel); // disable totalsAsTopLevel functionality
        this._columns.set([...config.columns]);
        this._tableLevels =
            this._config.tablesConfig.map(t => t.dimensions.map(d => d.fieldId)) || [];
        this._currentTableLevelSignal.set(0);
        this._currentLevelSignal.set(0);
        this._levelStack = [];

        this.reconfigurePivots();
    }

    setChartFields(): void {
        this._chartFields = this._columns()
            .filter(x => x.type === "formula")
            .map(x => getColumnFieldName(x));
    }

    setSorting(sortField: string, sortDescending: boolean): void {
        this._sortField.set(sortField);
        this._sortDescending = sortDescending;
    }

    setLevelState(state: LevelState): void {
        this._currentTableLevelSignal.set(state.table);
        this._currentLevelSignal.set(state.level);
        this._levelStack = state.stack;
    }

    getLevelState(): LevelState {
        return {
            level: this.currentLevel(),
            table: this.currentTableLevel(),
            stack: this._levelStack
        };
    }

    /*
    <--------------------------------Configuration--------------------------------------->
    */
    public reconfigurePivots() {
        const context = [] as PivotContext[];
        this._pivots = [];
        this._config.tablesConfig.forEach((table, idx) => {
            context[idx] = { filters: this._filtersSet, selectedRow: null, pivot: null };

            const keyFields = table.dimensions.map(d => d.fieldId);
            if (table.stackedBarDimension) {
                keyFields.push(table.stackedBarDimension);
            }

            const pivotInstance = this._createPivot(
                this.scheme(),
                this._pageReferences.slots.length,
                keyFields,
                this._columns(),
                context[idx]
            );
            this._pivots!.push(pivotInstance);
        });

        this.resubscribeToData();
    }

    public resubscribeToData(): void {
        this._isChartBuildingSignal.set(true);
        this._reconfigureSubject.next();

        const resubscribeHandler = merge(this._destroyed$, this._reconfigureSubject);

        this.pivot().refilter();

        const currentLevelFields = this._currentLevelData().dimensions.map(d => d.fieldId);

        let fields: string[];
        let args: FlexibleDatasetDataArgumentsGetter | null;

        const stackedBar = this._stackedBarDimension();
        if (stackedBar && this._currentLevelData().limitRows > 0) {
            fields = this._getRequiredField(currentLevelFields);
            args = this._getFlexDatasetArguments();
            this._updateDataArguments(args);

            const stackedBarKeyFields = [...currentLevelFields, stackedBar];
            const stackedBarFields = this._getRequiredField(stackedBarKeyFields);
            let appliedLevelFilters: Record<string, Array<string | number | boolean>> = {};
            const stackedBarArgs = {
                ...args,
                limit: () => MAX_LIMIT_ROWS,
                filters: () => ({
                    ...args.filters(),
                    ...() => appliedLevelFilters
                })
            };
            let appliedLevelsData: any | null = null;

            this._dataSubscription[this._currentTableLevelSignal()] = this._dataset()
                .dataAsObservable(fields, args)
                .pipe(takeUntil(resubscribeHandler))
                .subscribe({
                    next: data => {
                        // Update data in the context
                        appliedLevelsData = data;
                        appliedLevelFilters = this._getLevelFilters(stackedBarKeyFields, data.data);

                        // Trigger loading of the second dataset
                        this._dataset().load(stackedBarFields, stackedBarArgs);
                    },
                    error: err => {
                        this._onServerFailure(err);
                        this._isDataCompleteSignal.set(true);
                        this._isChartBuildingSignal.set(false);
                    }
                });

            // Second dataset is for data used to show stacked bar data. Its loading is triggered when main dataset is loaded
            this._dataset()
                .dataAsObservable(stackedBarFields, stackedBarArgs)
                .pipe(takeUntil(resubscribeHandler))
                .subscribe({
                    next: stackedBarData => {
                        if (!appliedLevelsData) return;

                        this._sourceData = this._getCalculatedLevelsData(stackedBarData.data, [
                            stackedBar
                        ]);
                        this._buildPivot(this.pivot(), appliedLevelsData);
                    },
                    error: err => {
                        this._onServerFailure(err);
                        this._isDataCompleteSignal.set(true);
                        this._isChartBuildingSignal.set(false);
                    }
                });
        } else {
            if (stackedBar) {
                currentLevelFields.push(stackedBar);
            }

            fields = this._getRequiredField(currentLevelFields);
            args = this._getFlexDatasetArguments();
            this._updateDataArguments(args);

            this._dataSubscription[this._currentTableLevelSignal()] = this._dataset()
                .dataAsObservable(fields, args)
                .pipe(takeUntil(resubscribeHandler))
                .subscribe({
                    next: data => {
                        this._sourceData = data.data;
                        this._buildPivot(this.pivot(), data);
                    },
                    error: err => {
                        this._onServerFailure(err);
                        this._isDataCompleteSignal.set(true);
                        this._isChartBuildingSignal.set(false);
                    }
                });
        }

        this._isLoadingSubject.next(this._dataset().isLoading$(fields, args));

        this._isStaleSubject.next(this._dataset().isStale$(fields, args));

        this._isCalculatingSubject.next(this._dataset().isCalculating$(fields, args));

        this._calculationProgressSubject.next(this._dataset().calculationProgress$(fields, args));

        this._calculationErrorSubject.next(this._dataset().calculationError$(fields, args));
    }

    public unsubscribe(): void {
        this._dataSubscription.forEach(subscription => subscription.unsubscribe());
        this._dataSubscription = [];
    }

    private _getCalculatedLevelsData<T>(data: any[], levels: string[]): T[] {
        const pivotInstance = this._createPivot(
            this.scheme(),
            this._pageReferences.slots.length,
            levels,
            this._columns(),
            { filters: this._filtersSet, selectedRow: null, pivot: null }
        );
        pivotInstance.build(data, false, false);
        return pivotInstance.all;
    }

    public prepareLevelData(disableLevelSorting = false): void {
        if (this._totalsAsTopLevelSignal()) {
            this._overrideRepresentationWithTotals();
        } else {
            this._setCurrentLevelData(this.pivot().filtered, false, disableLevelSorting);
        }
    }

    /*
    <---------------------------------Navigation----------------------------------->
    */
    public navigateToNextLevel(item: ChartRenderBaseDatum<Record<string, any>>): void {
        const isLevelExceeded = this._currentLevelSignal() === this.levels().length - 1;
        const isTableLevelExceeded =
            this._currentTableLevelSignal() === this._tableLevels.length - 1;
        if (isLevelExceeded && isTableLevelExceeded) {
            return;
        }

        this._addLevelStack(item.column.item);

        if (isLevelExceeded) {
            this._currentTableLevelSignal.update(value => value + 1);
            this._currentLevelSignal.set(0);
            this.resubscribeToData();
        } else {
            this._currentLevelSignal.update(value => value + 1);
            this._setCurrentLevelData(item.column.item.children || [], true);
        }
    }

    public navigateToPrevLevel(): void {
        if (this._currentLevelSignal() < 1) {
            if (this._currentTableLevelSignal() > 0) {
                this._currentTableLevelSignal.update(value => value - 1);
                this._currentLevelSignal.set(
                    this._tableLevels[this._currentTableLevelSignal()].length - 1
                );
                this._popLevelStack();
                this.resubscribeToData();
            }
            return;
        } else {
            this._currentLevelSignal.update(value => value - 1);
        }

        this._popLevelStack();
        this._setCurrentLevelData(this.pivot().filtered);
    }

    private _addLevelStack(item: Record<string, any>): void {
        const currentLevelFieldCode = this.levels()[this._currentLevelSignal()];
        this._levelStack.push({
            table: this._currentTableLevelSignal(),
            code: currentLevelFieldCode,
            value: item[currentLevelFieldCode]
        });
    }

    private _popLevelStack(): ChartLevel {
        return this._levelStack.pop();
    }

    private _createPivot(
        scheme: FieldInfo[],
        numReferences: number,
        keyFields: string[],
        valueColumns: PivotTableColumn[],
        context: PivotContext
    ): LgPivotInstance {
        const pivotDef = this._flexiblePivotFactory.getPivotDefinition({
            scheme,
            numReferences,
            levels: keyFields,
            columns: valueColumns
        });

        // Apply defaultOrderBy per pivot row level
        let pivotRowDefinition = pivotDef;
        while (pivotRowDefinition != null) {
            pivotRowDefinition.defaultOrderBy = this._config.defaultOrderBy;
            pivotRowDefinition = pivotRowDefinition.children;
        }

        return this._pivotInstanceFactory.create(pivotDef, context);
    }

    private _getRequiredField(levels: string[]): string[] {
        return _.uniq([
            ...levels,
            ...this._columns().flatMap(x => {
                switch (x.type) {
                    case "default":
                    case "difference":
                        return x.field;

                    case "widget":
                        return null;

                    case "formula":
                        return Object.values(x.variables).map(formulaVar =>
                            formulaVar.type === "constant" ? null : formulaVar.field
                        );

                    default:
                        throw Error(`Unsupported column type "${(x as any).type}"`);
                }
            })
        ]).filter((x): x is string => x !== null);
    }

    protected _getFlexDatasetArguments(): FlexibleDatasetDataArgumentsGetter | null {
        const currentLevelIdx = this._currentTableLevelSignal();
        let args = this._flexDatasetArgumentsCache[currentLevelIdx];
        if (args !== undefined) return args;

        const originalArgs = this._flexDataClient.dataset.dataArguments;
        const currentLevel = this._config.tablesConfig[this._currentTableLevelSignal()];

        if (originalArgs.references) {
            originalArgs.references = () =>
                !this._isDefaultDataSource()
                    ? this._getSelectedReferencesCodes()
                    : this._pageReferences.selected;
        }

        args = {
            ...originalArgs,
            limit: currentLevel.limitRows ? () => currentLevel.limitRows : originalArgs.limit,
            orderBy: () =>
                currentLevel.orderBy
                    ? currentLevel.orderBy.map(o => (o.type === "desc" ? `-${o.item}` : o.item))
                    : [],
            filters: () => {
                const filters = originalArgs.filters() || {};

                const tableLevelFilters = this._applyTableLevelFilters();

                return this._dataset().getValidFilters({
                    ...filters,
                    ...tableLevelFilters
                });
            }
        };

        // Cache and return arguments
        this._flexDatasetArgumentsCache[currentLevelIdx] = args;
        return args;
    }

    private _getSelectedReferencesCodes(): string[] {
        return this._config.selectedReferences
            ? this._config.selectedReferences.map(reference => reference.referenceCode)
            : this._pageReferences.selected;
    }

    private _applyTableLevelFilters(): Record<string, Array<string | number | boolean>> {
        return this._levelStack.reduce((newFilters, level) => {
            if (
                !this.levels().includes(level.code) &&
                level.table !== this._currentTableLevelSignal()
            ) {
                newFilters[level.code] = [level.value];
            }
            return newFilters;
        }, {});
    }

    private _getLevelFilters(
        levels: string[],
        data: any[]
    ): Record<string, Array<string | number | boolean>> {
        const filters = new Map<string, Set<string | number | boolean>>();
        for (const record of data) {
            for (const code of levels) {
                const value = record[code];
                if (value !== undefined) {
                    if (!filters.has(code)) {
                        filters.set(code, new Set());
                    }
                    filters.get(code).add(value);
                }
            }
        }

        const result: Record<string, Array<string | number | boolean>> = {};
        for (const [key, value] of filters.entries()) {
            result[key] = Array.from(value);
        }

        return result;
    }

    protected _buildPivot(pivot: LgPivotInstance, data: any): void {
        if (this._pendingState && pivot != null) {
            pivot.orderBy = _.cloneDeep(this._pendingState.orderBy);
        }

        pivot.build(data.data, false, true);

        if (++this._pivotBuildCount === 1) {
            this._notifyDataReadyCallback?.();
        }

        this.prepareLevelData();

        this._pendingState = null;
        this._isDataCompleteSignal.set(data.isComplete);
        this._isChartBuildingSignal.set(false);
    }

    private _setCurrentLevelData(
        data: Array<Record<string, any>>,
        filteredByLevel = false,
        disableLevelSorting = false
    ): void {
        this._setCurrentKeyField();

        if (!disableLevelSorting) {
            this._applyLevelSorting();
        }

        if (data?.length === 0) {
            this._levelDataSignal.set([]);
            return;
        }

        const levelData = filteredByLevel ? data : this._getLevelData(data);
        this._levelDataSignal.set(levelData != null ? this._getChartData(levelData) : []);
    }

    private _getChartData(
        level: Array<Record<string, any>>
    ): ChartData<Record<string, number | string>> {
        const chartData: ChartData<Record<string, number | string>> = [];
        for (const item of level) {
            let stackBar: any[] | undefined = undefined;
            if (this._stackedBarDimension() !== undefined) {
                stackBar = this._getLevelStackedBar(item);
            }

            const values: Record<string, ChartValue> = {};
            this._chartFields.forEach(field => {
                values[field] = {
                    value: item[field],
                    subValues:
                        stackBar
                            ?.map(subItem => ({
                                name: this._getStackedBarSubItemName(subItem),
                                value: subItem[field]
                            }))
                            .sort((a, b) => Math.abs(b.value) - Math.abs(a.value)) ?? []
                };
            });

            chartData.push({
                column: getItemNameFromField(
                    item[this.levels()[this._currentLevelSignal()]],
                    this._currentKeyField.type,
                    this._definitions,
                    this._chartLabelDisplayMode()
                ),
                values,
                item
            });
        }
        return chartData;
    }

    private _getStackedBarSubItemName(item: any): string {
        if (this._stackedBarDimension() === undefined) return "-";
        const field = this._schemeLookup[this._stackedBarDimension()];
        if (field === undefined) {
            this._lgConsole.error("Stacked bar dimension field is not in scheme.");
            return "-";
        }

        return getItemNameFromField(
            item[this._stackedBarDimension()],
            field.type,
            this._definitions,
            this._chartLabelDisplayMode()
        );
    }

    private _getLevelStackedBar(level: Record<string, any>) {
        const appliedLevels = this._getCurrentChartLevels();

        const currentLevelCode = this.levels()[this._currentLevelSignal()];
        appliedLevels.push({
            table: this._currentTableLevelSignal(),
            code: currentLevelCode,
            value: level[currentLevelCode]
        });

        const filteredDataByLevels = this._filterDataByLevels(this._sourceData, appliedLevels);

        return _.chain(filteredDataByLevels)
            .groupBy(this._stackedBarDimension())
            .map((value, key) => {
                const result = {
                    [this._stackedBarDimension()]: key
                };
                this._chartFields.forEach(costField => {
                    result[costField] = value.reduce((sum, item) => {
                        sum += item[costField];
                        return sum;
                    }, 0);
                });
                return result;
            })
            .value();
    }

    private _filterDataByLevels<T>(data: T[], levels: ChartLevel[]): T[] {
        const fields = levels.map(level => level.code);
        const compareFn = this._generateFnCompareRecords(fields);

        const levelCodeValues = {};
        for (const { code, value } of levels) {
            levelCodeValues[code] = value;
        }

        const result = [];
        for (const item of data) {
            if (compareFn(item, levelCodeValues)) {
                result.push(item);
            }
        }

        return result;
    }

    private _generateFnCompareRecords(fields: string[]): (a: unknown, b: unknown) => boolean {
        let compareFn;
        const compareFnKey = fields.toString();
        if (!this._compareFnMap.has(compareFnKey)) {
            const body = `return ${_.map(fields, field => `a.${field} === b.${field}`).join(
                "&&"
            )};`;
            // eslint-disable-next-line no-new-func
            compareFn = new Function("a", "b", body);
            this._compareFnMap.set(compareFnKey, compareFn);
        } else {
            compareFn = this._compareFnMap.get(compareFnKey);
        }

        return compareFn;
    }

    private _getLevelData(data: Array<Record<string, any>>): Array<Record<string, any>> {
        const chartLevels = this._getCurrentChartLevels();
        return this._findLevelData(data, chartLevels);
    }

    private _findLevelData(
        data: Array<Record<string, any>>,
        levels: ChartLevel[]
    ): Array<Record<string, any>> {
        if (levels.length === 0) {
            return data;
        } else {
            const level = levels.shift();
            const filteredData = data.find(item => item[level.code] === level.value);

            return filteredData === undefined
                ? []
                : this._findLevelData(filteredData.children, levels);
        }
    }

    private _getCurrentChartLevels(): ChartLevel[] {
        const fromIdx = this._levelStack.findIndex(
            level =>
                level.code === this.levels()[0] && level.table === this._currentTableLevelSignal()
        );
        return fromIdx >= 0 ? this._levelStack.slice(fromIdx) : [];
    }

    private _setCurrentKeyField(): void {
        const currentLevelFieldCode = this.levels()[this._currentLevelSignal()];
        this._currentKeyField = this._schemeLookup[currentLevelFieldCode];
    }

    private _overrideRepresentationWithTotals(): void {
        const totals = this.pivot()?.totals as any;

        if (totals == null) {
            return;
        }

        const data = [];

        if (this._chartFields.length === 2) {
            data.push({
                ...totals,
                diff: totals[this._chartFields[0]] - totals[this._chartFields[1]]
            });
        } else {
            data.push(totals);
        }

        this._levelDataSignal.set(data);
    }

    private _sortChartData(
        data: ChartData<Record<string, number | string>>
    ): ChartData<Record<string, number | string>> {
        return data.sort((a, b) => {
            const sortField = this._sortField();
            const sortFieldType = this._schemeLookup[sortField] ? "field" : "formula";

            switch (sortFieldType) {
                case "field":
                    const fieldA = a.item[sortField];
                    const fieldB = b.item[sortField];

                    if (typeof fieldA === "string" && typeof fieldB === "string") {
                        return this._sortDescending
                            ? fieldB.localeCompare(fieldA)
                            : fieldA.localeCompare(fieldB);
                    } else if (typeof fieldA === "number" && typeof fieldB === "number") {
                        return this._sortDescending ? fieldB - fieldA : fieldA - fieldB;
                    } else {
                        return 0;
                    }

                case "formula":
                default:
                    return this._sortDescending
                        ? b.values[sortField].value - a.values[sortField].value
                        : a.values[sortField].value - b.values[sortField].value;
            }
        });
    }

    private _applyLevelSorting(): void {
        const sorting =
            this._config.tablesConfig[this._currentTableLevelSignal()]?.dimensions[
                this._currentLevelSignal()
            ].orderBy[0];
        this.setSorting(sorting.item, sorting.type === "desc");
    }

    private _getDimensionTitle(fieldId: string | undefined): string {
        if (fieldId == null) return "";

        const field = this._schemeLookup[fieldId];

        if (field === undefined) {
            this._lgConsole.error(`Field ${fieldId} does not exist in the flexible dataset scheme`);
            return fieldId;
        }

        return (
            field.name ??
            (field.nameLc != null ? this._lgTranslate.translate(field.nameLc) : fieldId)
        );
    }

    async updateDataSource(dataSourceCode?: string): Promise<any> {
        if (!dataSourceCode) {
            dataSourceCode = this._layoutDataSource.defaultLayoutDataSourceCode();
        }

        this._dataSource.set(dataSourceCode);
        const { scheme, references, dataset } =
            await this._layoutDataSource.getDataSource(dataSourceCode);

        this.scheme.set(scheme);
        this._references.set(references);
        this._dataset.set(dataset);

        return { scheme, references };
    }

    setSelectedReferences(references: ReferenceSlot[], widgetId: string): void {
        this._layoutDataSource.updateDataSourceSelectedReferences(
            widgetId,
            this._dataSource(),
            references
        );
    }

    private _updateDataArguments(dataArguments: FlexibleDatasetDataArgumentsGetter): void {
        this._dataset.update(dataset => {
            dataset.dataArguments = dataArguments;
            return dataset;
        });
    }
}
