import { Component, Injectable } from "@angular/core";
import {
    AbstractControl,
    FormControl,
    FormGroup,
    NonNullableFormBuilder,
    ValidationErrors,
    ValidatorFn,
    Validators
} from "@angular/forms";

import {
    getDialogFactoryBase,
    IDialogOptions,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { TBookmarkUiModel } from "@logex/framework/lg-layout";
import { LayoutBookmark } from "../../types";

export type LayoutBookmarkUiModel = TBookmarkUiModel & Pick<LayoutBookmark, "isDefault">;

@Component({
    standalone: false,
    selector: "lgflex-layout-bookmark-edit-dialog",
    templateUrl: "./layout-bookmark-edit-dialog.component.html",
    viewProviders: [useTranslationNamespace("FW._Directives._FiltersetSlideout._StateDialogs")]
})
export class LayoutBookmarkEditDialogComponent {
    _options!: IDialogOptions;
    _title = "";
    _dialogClass = "bookmark-edit-dialog";
    dialogType = "normal";

    _newBookmarkForm!: FormGroup<{
        name: FormControl<string>;
        isDefault: FormControl<boolean>;
    }>;

    readonly MAX_LENGTH = 64;
    private _resolve!: (result: LayoutBookmarkUiModel) => void;

    constructor(
        private _dialogRef: LgDialogRef<LayoutBookmarkEditDialogComponent>,
        private _translateService: LgTranslateService,
        private _formBuilder: NonNullableFormBuilder
    ) {}

    show(
        existingStatesNames: string[],
        existingBookmark: LayoutBookmarkUiModel
    ): Promise<LayoutBookmarkUiModel> {
        this._newBookmarkForm = this._formBuilder.group({
            name: this._formBuilder.control(
                existingBookmark.name,
                Validators.compose([
                    Validators.required,
                    nameAlreadyExistsValidator(existingStatesNames, existingBookmark.name),
                    Validators.maxLength(this.MAX_LENGTH)
                ])
            ),
            isDefault: existingBookmark.isDefault
        });

        this._title = this._translateService.translate(
            existingBookmark.name === "" ? ".Save_new_title" : ".Update_title"
        );

        return new Promise<LayoutBookmarkUiModel>(resolve => {
            this._resolve = resolve;
        });
    }

    _onSave(): void {
        if (this._newBookmarkForm.valid) {
            const raw = this._newBookmarkForm.getRawValue();
            this._resolve({ ...raw });
            this._dialogRef.close(1, true);
        }
    }

    _onCancel(): void {
        this._dialogRef.close(0, true);
    }
}

function nameAlreadyExistsValidator(existingNames: string[], allowedName: string): ValidatorFn {
    allowedName = allowedName.toLowerCase();
    const existingSet = new Set(existingNames.map(name => name.toLowerCase()));

    return (control: AbstractControl): ValidationErrors | null => {
        const lower = (control.value ?? "").toLowerCase();
        if (lower !== "" && lower === allowedName) return null;

        return existingSet.has(lower) ? { nameAlreadyExists: { value: control.value } } : null;
    };
}

@Injectable()
export class LayoutBookmarkEditDialog extends getDialogFactoryBase(
    LayoutBookmarkEditDialogComponent,
    "show"
) {
    constructor(factory: LgDialogFactory) {
        super(factory);
    }
}
