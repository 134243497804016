/* eslint-disable @angular-eslint/no-output-on-prefix */
import { ChangeDetectionStrategy, Component, input, output } from "@angular/core";
import { Layout } from "./lg-flexible-layouts-item.types";
@Component({
    standalone: false,
    selector: "lgflex-flexible-layouts-item",
    templateUrl: "./lg-flexible-layouts-item.component.html",
    styleUrls: ["./lg-flexible-layouts-item.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgFlexibleLayoutsItemComponent {
    layout = input<Layout>();
    isLayoutInProgress = input<boolean>();
    isLayoutReadOnly = input<boolean>();

    readonly onLayoutLoad = output<number>();
    readonly onLayoutEdit = output<number>();
    readonly onLayoutDuplicate = output<number>();
}
