import { inject, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import ldIsEqual from "lodash-es/isEqual";
import { distinctUntilChanged, firstValueFrom, Observable, of, takeUntil } from "rxjs";

import { LgPromptDialog } from "@logex/framework/ui-core";
import { HandleErrorsMixin } from "@logex/mixins";
import { mixins } from "@logex/mixin-flavors";
import { LgNavigationService } from "@logex/framework/lg-application";

import { LgConfigurationLayoutGatewayBase, RequestArguments } from "../services";
import { PageComponentBase } from "../../../bases";

export type ToFormControl<T> = {
    [K in keyof T]: FormControl<T[K]>;
};

// ----------------------------------------------------------------------------------
/**
 * Base class for section of configuration page, which contain form`s row controls and navigation part.
 *
 * @example ScenarioSettingsComponent in fadp-app-frontend
 */
// ----------------------------------------------------------------------------------
export interface LgConfigurationLayoutPageBase<
    TForm extends { [K in keyof TForm]: any },
    TAppDefinitions
> extends HandleErrorsMixin {}

@mixins(HandleErrorsMixin)
export abstract class LgConfigurationLayoutPageBase<
        TForm extends { [K in keyof TForm]: any },
        TAppDefinitions
    >
    extends PageComponentBase<TAppDefinitions>
    implements OnInit, OnDestroy
{
    protected _formBuilder = inject(FormBuilder);
    protected _navigationService = inject(LgNavigationService);
    protected _gateway: LgConfigurationLayoutGatewayBase<any, any>;
    protected _unsavedChangesCount = 0;
    protected _form: FormGroup<ToFormControl<TForm>> | undefined = undefined;
    protected _isLoading$: Observable<boolean>;
    protected _formInitialValues: TForm | undefined = undefined;
    protected abstract _rootNavigationNodeId: string;
    protected async _activate(): Promise<void> {
        this._isLoading$ = of(true);
        await this._loadData();
        if (this._session.scenario.isLocked) this._form.disable();
        this._isLoading$ = of(false);
    }

    protected async _loadData(args?: RequestArguments): Promise<void> {
        const data = await firstValueFrom(
            this._gateway.selectData(
                args
                    ? args
                    : {
                          clientId: this._session.clientId,
                          scenarioId: this._session.scenarioId
                      }
            )
        );
        this._formInit(data);
        this._formInitialValues = this._form.getRawValue() as TForm;
        this.addDirtyValueListener();
    }

    protected abstract _formInit(data?: any): void;

    // Here implementation of this._gateway.save(args) is needed.
    protected abstract _saveOnServer(): Promise<any>;

    protected async _save(): Promise<any> {
        this._isLoading$ = of(true);
        try {
            await this._saveOnServer();
            this._formInitialValues = this._form.getRawValue() as TForm;
            this._resetForm();
        } catch (e: any) {
            this._onServerFailure(e);
        }
        this._isLoading$ = of(false);
    }

    protected _resetForm(): void {
        this._form.reset(this._formInitialValues);
    }

    protected addDirtyValueListener(): void {
        this._form.valueChanges
            .pipe(distinctUntilChanged(), takeUntil(this._destroyed$))
            .subscribe(() => {
                let dirty = 0;
                Object.keys(this._form.controls).forEach(name => {
                    const currentControl = this._form.controls[name];
                    if (
                        currentControl.dirty &&
                        ldIsEqual(currentControl.value, this._formInitialValues[name])
                    ) {
                        currentControl.markAsPristine();
                    } else if (currentControl.dirty) dirty++;
                });
                this._unsavedChangesCount = dirty;
            });
    }
}
