import { Component, EventEmitter, Input, Output } from "@angular/core";
import { useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import {
    FormulaConfigurationType,
    FormulaConfiguration,
    formulasTypes
} from "./pivot-table-formula-configuration.types";

@Component({
    standalone: false,
    selector: "lgflex-pivot-table-formula-configuration",
    templateUrl: "./pivot-table-formula-configuration.component.html",
    providers: [...useTranslationNamespace("_Flexible._PivotConfigurationDialog")]
})
export class PivotTableFormulaConfigurationComponent {
    @Input() column!: FormulaConfiguration;
    @Input() fieldsDropdownDefinition!: IDropdownDefinition<string>;
    @Input() referencesDropdownDefinition: IDropdownDefinition<number> | undefined;
    @Input() areReferencesAllowed: boolean | undefined;
    @Output() readonly columnPropertyChange = new EventEmitter<void>();

    protected _formulas = [
        ...formulasTypes,
        FormulaConfigurationType.PercentOfParent,
        FormulaConfigurationType.PercentOfTotal
    ];
}
