import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    standalone: false,
    selector: "lgflex-form-section-header",
    templateUrl: "./form-section-header.component.html",
    styleUrls: ["./form-section-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormSectionHeaderComponent {}
