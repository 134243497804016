import { Observable } from "rxjs";
import { JobStatus } from "../gateways/stale-data-service-gateway.types";

export interface MaybeStaleData<T> {
    data: T | null;
    isStale: boolean;
}

// ----------------------------------------------------------------------------------
// MaybeStaleDataV1

export interface MaybeStaleDataV1<T> extends MaybeStaleData<T> {
    jobs: string[];
}

export function isMaybeStaleDataV1<T>(data: MaybeStaleData<T>): data is MaybeStaleDataV1<T> {
    return "jobs" in data;
}

// ----------------------------------------------------------------------------------
// MaybeStaleDataV2

export interface MaybeStaleDataV2<T> extends MaybeStaleData<T> {
    subscriptions: string[];
    isStalePerSubscription: Map<string, boolean>;
}

export function isMaybeStaleDataV2<T>(data: MaybeStaleData<T>): data is MaybeStaleDataV2<T> {
    return "subscriptions" in data;
}
