import { PivotTableWidgetConfig } from "../pivot-table-widget.configuration.types";
import { generateId } from "../../../utilities";

export const migrateVersion8To9 = (config: PivotTableWidgetConfig) => {
    config.columns = config.columns.map(column => {
        if ("columns" in column) {
            if (column.id == null) {
                column.id = generateId();
            }
        }

        return column;
    });
    return 9;
};
