import { DrilldownPivotTableWidgetBaseConfig } from "./pivot-table-conversion.types";
import { Dictionary } from "lodash";
import { PivotTableColumn, PivotTableColumnDefault, PivotTableLevel } from "../../../types";
import { GroupOrColumn } from "../../../types/configuration";
import { PivotTableWidgetConfig } from "../pivot-table-widget.configuration.types";
import { pivotTableMigrationProvider } from "./index";

const convertDefaultColumn = (column: PivotTableColumnDefault) => ({
    type: "default",
    referenceIdx: column.referenceIdx,
    field: column.field,
    isEnabled: column.isEnabled
});

export const convertColumns = (originalColumns: PivotTableColumn[]): GroupOrColumn[] => {
    let currentReference = null;
    let currentGroup = null;

    const convertedColumns: GroupOrColumn[] = originalColumns.reduce((acc, column) => {
        if (column.type !== "default") {
            if (currentGroup && currentGroup.columns.length > 0) {
                acc.push(currentGroup);
            }
            currentGroup = null;
            acc.push({ ...column });

            return acc;
        }

        if (currentReference !== column.referenceIdx) {
            if (currentGroup) acc.push(currentGroup);
            currentReference = column.referenceIdx;
            currentGroup = {
                columnType: "referenceSlot",
                referenceIdx: currentReference,
                columns: []
            };
        }

        if (currentGroup) currentGroup.columns.push(convertDefaultColumn(column));

        return acc;
    }, []);

    if (currentGroup && currentGroup.columns.length > 0) {
        convertedColumns.push(currentGroup);
    }

    return convertedColumns;
};

const getDimensionOrderBy = (defaultOrderBy: string) => {
    let type = "asc";
    let item = defaultOrderBy;

    if (defaultOrderBy.startsWith("-")) {
        type = "desc";
        item = defaultOrderBy.slice(1);
    }

    return { type, item };
};

const convertLevels = (
    levels: string[][],
    levelProperties: Dictionary<PivotTableLevel>,
    type: string,
    defaultOrderBy: string,
    ignoreOwnFilters: boolean
) => {
    return levels.map((l, levelInx) => {
        return {
            type,
            name: `Table ${levelInx + 1}`,
            ignoreOwnFilters,
            dimensions: l.map((d, idx) => {
                const { displayMode, title, width } = levelProperties[d] ?? {};
                const dimensionOrderBy = {
                    orderBy: {},
                    overrideFromParent: false
                };

                if (type === "table" && idx === 0 && levelInx === 0 && defaultOrderBy) {
                    dimensionOrderBy.orderBy = getDimensionOrderBy(defaultOrderBy);
                    dimensionOrderBy.overrideFromParent = true;
                }

                if (type === "pivot" && levelInx === 0 && defaultOrderBy) {
                    dimensionOrderBy.orderBy = getDimensionOrderBy(defaultOrderBy);
                    dimensionOrderBy.overrideFromParent = true;
                }

                return {
                    fieldId: d,
                    name: title,
                    displayMode,
                    width,
                    ...dimensionOrderBy
                };
            })
        };
    });
};

function convertToPivotTable(config: DrilldownPivotTableWidgetBaseConfig, type: string) {
    const { levels, levelProperties, columns, defaultOrderBy, ignoreOwnFilters } = config;

    const newConfig = {
        title: config.title,
        description: config.description,
        tooltip: config.tooltip,
        tooltipLink: config.tooltipLink,
        tablesConfig: convertLevels(
            levels,
            levelProperties,
            type,
            defaultOrderBy,
            ignoreOwnFilters
        ),
        columns: columns ? convertColumns(columns.filter(c => c.isEnabled)) : []
    } as PivotTableWidgetConfig;
    const migrations = pivotTableMigrationProvider.useValue.migrations;
    let currentVersion = Math.min(...Object.keys(migrations).map(x => +x));
    const targetVersion = Math.max(...Object.keys(migrations).map(x => +x)) + 1; // We need to make sure the last migrator runs, for which increasing it by 1 will work

    while (currentVersion < targetVersion) {
        const migrator = migrations[currentVersion];
        migrator(newConfig);
        currentVersion++;
    }
    return newConfig;
}

export const convertPivotToPivotTable = (config: DrilldownPivotTableWidgetBaseConfig) =>
    convertToPivotTable(config, "pivot");

export const convertTableToPivotTable = (config: DrilldownPivotTableWidgetBaseConfig) =>
    convertToPivotTable(config, "table");
