<ng-container *lgPanelHeader="let context; order: -1">
    <div
        *ngIf="_chartCurrentLevel > 0"
        class="lg-reference-dropdown-side-icon lg-reference-dropdown-side-icon--round-corners"
    >
        <lg-icon
            [class.disabled]="_isLoading$ | async"
            icon="icon-go-back"
            (click)="_chartGoUp()"
            [clickable]="true"
        ></lg-icon>
    </div>
</ng-container>

<ng-container *lgPanelHeader="let context; order: 1">
    <lgflex-widget-common-header
        [config]="_config"
        [isDataComplete]="_isDataComplete"
        [isStale$]="_isStale$"
        [calculationProgress$]="_calculationProgress$"
        [calculationError$]="_calculationError$"
    ></lgflex-widget-common-header>
</ng-container>

<ng-container *lgPanelIcons="let context; order: 1; whenMinimized: false">
    <lgflex-widget-common-icons
        [exportDisabled]="_chartLevelData.length === 0"
        [isLoading$]="_isLoading$"
        [isAutoConverted]="_isAutoConverted"
        [isConfigValid]="_isConfigValid"
        (export)="_export($event)"
        (edit)="showConfigurationUi()"
    ></lgflex-widget-common-icons>
</ng-container>

<ng-container *ngIf="_isConfigValid">
    <lg-stale-data-handler [isCalculating]="_isCalculating$ | async" />

    <div class="flexcol" *ngIf="_pivot != null; else pivotIsNull">
        <lg-stale-data-handler [isCalculating]="_isCalculating$ | async" />

        <lg-rectangle
            class="flex-flexible"
            #rect
            style="overflow: hidden; display: flex; align-items: center; justify-content: center"
            [lgLoaderOverlay]="(_isLoading$ | async) ?? false"
            [lgLoaderOverlayDelay]="100"
        >
            <lg-bubble-chart
                style="display: block"
                #lgBubbleChart
                *ngIf="_chartLevelData.length > 0 && rect.width > 0 && rect.height > 0"
                [data]="_chartLevelData"
                [width]="rect.width || 800"
                [height]="rect.height || 600"
                (itemClick)="_chartOnClick($event)"
                [xAxisLabel]="_xAxisTitle"
                [yAxisLabel]="_yAxisTitle"
                [groupNameFn]="_chartGetGroupName"
                [clickable]="true"
                [tooltipTemplate]="tooltip"
                [yAxisLabelsWidth]="60"
                [usePositiveNegativeColors]="true"
                [chartType]="_chartType"
                [selectedGroups]="_filteredItems"
                [xAxisFormatterOptions]="_xAxisFormatterOptions"
                [xAxisFormatterType]="_xDimensionFormatter?.format ?? 'float'"
                [yAxisFormatterOptions]="_yAxisFormatterOptions"
                [yAxisFormatterType]="_yDimensionFormatter?.format ?? 'float'"
            ></lg-bubble-chart>

            <div *ngIf="_chartLevelData?.length === 0">
                {{ "_Flexible.Widgets.NoDataWarning" | lgTranslate }}
            </div>
        </lg-rectangle>

        <ng-template #tooltip [lgChartTemplateContextType]="lgBubbleChart" let-context>
            <div class="lg-bubble-chart__tooltip-container">
                <table class="lg-tooltip__table">
                    <tr class="lg-tooltip__table-row">
                        <td
                            class="lg-tooltip__table-column lg-tooltip__table-column--name lg-tooltip__title"
                        >
                            {{
                                _chartCurrentLevelKeyField?.name ??
                                    (_chartCurrentLevelKeyField?.nameLc
                                        ? (_chartCurrentLevelKeyField!.nameLc! | lgTranslate)
                                        : "")
                            }}
                        </td>
                        <td
                            class="lg-tooltip__table-column lg-tooltip__table-column--value-title lg-tooltip__title"
                        >
                            {{ _xAxisTitle }}
                        </td>
                        <td
                            class="lg-tooltip__table-column lg-tooltip__table-column--value-title lg-tooltip__title"
                        >
                            {{ _yAxisTitle }}
                        </td>
                        <td
                            class="lg-tooltip__table-column lg-tooltip__table-column--value-title lg-tooltip__title"
                        >
                            {{ _sizeTitle }}
                        </td>
                    </tr>
                    <tr
                        *ngFor="let item of context.displayingItems"
                        class="lg-chart__tooltip__table__item"
                    >
                        <td class="lg-tooltip__table-column lg-tooltip__table-column--name">
                            {{ _chartGetGroupName(item.datum) }}
                        </td>
                        <td class="lg-tooltip__table-column lg-tooltip__table-column--value">
                            <span
                                [innerHTML]="
                                    item.datum.xValue
                                        | fmtType
                                            : _xDimensionFormatter!.format
                                            : _xDimensionFormatter!.decimals
                                            : _xDimensionFormatter!.forceSign
                                        | lgMarkSymbols
                                "
                            ></span>
                        </td>
                        <td class="lg-tooltip__table-column lg-tooltip__table-column--value">
                            <span
                                [innerHTML]="
                                    item.datum.yValue
                                        | fmtType
                                            : _yDimensionFormatter!.format
                                            : _yDimensionFormatter!.decimals
                                            : _yDimensionFormatter!.forceSign
                                        | lgMarkSymbols
                                "
                            ></span>
                        </td>
                        <td class="lg-tooltip__table-column lg-tooltip__table-column--value">
                            <span
                                [innerHTML]="
                                    item.datum.size
                                        | fmtType
                                            : _sizeDimensionFormatter!.format
                                            : _sizeDimensionFormatter!.decimals
                                            : _sizeDimensionFormatter!.forceSign
                                        | lgMarkSymbols
                                "
                            ></span>
                        </td>
                    </tr>
                </table>
            </div>
        </ng-template>
    </div>

    <ng-template #pivotIsNull>
        <div class="flexcol" [lgLoaderOverlay]="(_isLoading$ | async) ?? false"></div>
    </ng-template>
</ng-container>

<div
    class="flexcol flexcol--full flexcol--center"
    *ngIf="!_isConfigValid"
    style="justify-content: center"
>
    {{ "_Flexible.Widgets.WidgetValidationError" | lgTranslate }}
</div>
