import { Component, Inject, Injectable } from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDialogComponent,
    IDropdownDefinition,
    LgPromptDialog,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import {
    DefinitionDisplayMode,
    IDefinitions,
    LG_APP_DEFINITIONS
} from "@logex/framework/lg-application";
import { dropdownFlat } from "@logex/framework/utilities";

import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";

import { WidgetConfigurationDialogResponse } from "../../../../types";
import { WidgetTypesRegistry } from "../../../../services/widget-types-registry/widget-types-registry.service";
import { PieChartWidgetConfig } from "../../../../widgets/pie-chart/pie-chart-widget.types";
import {
    ChartConfigurationDialogBase,
    ChartWidgetConfigurationDialogArgumentsBase,
    ChartWidgetConfigurationDialogResponseBase
} from "../../../../components/base/chart-configuration-dialog-base/chart-configuration-dialog.base";
import { validateChartWidgetConfigBase } from "../../../../components/base/chart-configuration-dialog-base";
import { PageReferencesService } from "../../../../services/page-references/page-references.service";

interface ReferenceSlot {
    reference: number;
    name: string;
}

export interface PieChartWidgetConfigurationDialogArguments
    extends ChartWidgetConfigurationDialogArgumentsBase {
    labelDisplayMode: DefinitionDisplayMode;
}

export interface PieChartWidgetConfigurationDialogResponse
    extends ChartWidgetConfigurationDialogResponseBase {
    labelDisplayMode: DefinitionDisplayMode;
}

export interface PieChartConfigurationDialogComponent<
    TArgs extends PieChartWidgetConfigurationDialogArguments,
    TRes extends PieChartWidgetConfigurationDialogResponse
> extends ChartConfigurationDialogBase<TArgs, TRes> {}

@Component({
    standalone: false,
    selector: "lgflex-pie-chart-configuration-dialog",
    templateUrl: "./pie-chart-configuration-dialog.component.html",
    styleUrls: [
        "../../../../components/base/chart-configuration-dialog-base/chart-configuration-dialog-base.component.scss"
    ],
    providers: [...useTranslationNamespace("_Flexible.PieChartWidgetDialog")]
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class PieChartConfigurationDialogComponent<
        TArgs extends PieChartWidgetConfigurationDialogArguments,
        TRes extends PieChartWidgetConfigurationDialogResponse
    >
    extends ChartConfigurationDialogBase<TArgs, TRes>
    implements
        IDialogComponent<
            PieChartConfigurationDialogComponent<TArgs, TRes>,
            WidgetConfigurationDialogResponse
        >
{
    constructor(
        @Inject(LG_APP_DEFINITIONS) _definitions: IDefinitions<any>,
        _lgTranslate: LgTranslateService,
        _dialogRef: LgDialogRef<PieChartConfigurationDialogComponent<TArgs, TRes>>,
        _promptDialog: LgPromptDialog,
        _widgetTypes: WidgetTypesRegistry
    ) {
        super(_definitions, _lgTranslate, _dialogRef, _promptDialog, _widgetTypes);

        this._initMixins();
    }

    _labelDisplayMode: DefinitionDisplayMode = "code";
    _labelDisplayModeDropdown: IDropdownDefinition<DefinitionDisplayMode> | undefined;

    // ----------------------------------------------------------------------------------
    //
    override async _activate(): Promise<void> {
        await super._activate();

        this._initializeLabelDisplayMode();
    }

    // ----------------------------------------------------------------------------------
    //
    _initializeLabelDisplayMode(): void {
        this._labelDisplayMode = this._args.labelDisplayMode;
        this._labelDisplayModeDropdown = dropdownFlat({
            entryId: "mode",
            entryName: "name",
            entries: [
                {
                    mode: "code",
                    name: this._lgTranslate.translate(".Code")
                },
                {
                    mode: "name",
                    name: this._lgTranslate.translate(".Name")
                },
                {
                    mode: "codeAndName",
                    name: this._lgTranslate.translate(".CodeAndName")
                }
            ]
        });
    }

    override async _save(): Promise<boolean> {
        const response: PieChartWidgetConfigurationDialogResponse = {
            dataSource: this._dataSourceCode(),
            selectedReferences: this._selectedReferences(),
            widgetName: this._widgetName,
            widgetDescription: this._widgetDescription,
            widgetTooltip: this._widgetTooltip,
            widgetTooltipLink: this._widgetTooltipLink,
            levels: this._selectedLevelsIds(),
            columns: this._getResponseColumns()!,
            labelDisplayMode: this._labelDisplayMode,
            ignoreOwnFilters: this._ignoreOwnFilters
        };
        this._resolve(response as TRes);

        return true;
    }

    override _isValid(): boolean {
        const columns = this._getResponseColumns();
        if (columns === null) return false;

        return validateChartWidgetConfigBase<PieChartWidgetConfig>(
            {
                title: this._widgetName,
                description: this._widgetDescription,
                levels: this._selectedLevelsIds(),
                columns,
                labelDisplayMode: this._labelDisplayMode,
                ignoreOwnFilters: this._ignoreOwnFilters
            },
            this._isDefaultDataSource()
                ? this._args.pageReferences
                : ({
                      isAllowed: () => true,
                      slots: this._selectedReferences()
                  } as unknown as PageReferencesService),
            this._scheme()
        );
    }
}

class PieChartConfigurationDialogComponentWithArgumentType extends PieChartConfigurationDialogComponent<
    PieChartWidgetConfigurationDialogArguments,
    PieChartWidgetConfigurationDialogResponse
> {}

@Injectable()
export class PieChartConfigurationDialog extends getDialogFactoryBase(
    PieChartConfigurationDialogComponentWithArgumentType,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
