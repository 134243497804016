<div class="flexcol" *ngIf="!(_initialized$ | async)" [lgLoaderOverlay]="true"></div>

<ng-container *ngIf="_initialized$ | async">
    <ng-container *lgPlaceholderContent="slideoutPanelId; localInjector: true">
        <lgflex-flexible-layouts-panel
            [pageReferences]="_pageReferences"
            [flexDataClientArguments]="flexDataClientArguments"
            [layoutVariants]="layoutVariants"
            [layoutId]="_service.layoutId ?? null"
            [page]="pageId"
            (layoutConfigurationChange)="_changeLayout($event, true)"
            (layoutSelected)="_changeLayout($event, false)"
            [isLoading]="(_service.dataLoading$ | async) ?? false"
            [filtersLayout]="filtersLayout"
        ></lgflex-flexible-layouts-panel>

        <ng-container *ngIf="_filtersPanelTemplate; else filtersPanelDefault">
            <ng-container *ngTemplateOutlet="_filtersPanelTemplate"></ng-container>
        </ng-container>
        <ng-template #filtersPanelDefault>
            <lg-filters-panel></lg-filters-panel>
        </ng-template>
    </ng-container>

    <lgflex-flexible-layout
        #flexibleLayout
        [configuration]="(_service.layoutConfiguration$ | async) ?? null"
        [layoutState]="_service.layoutState$ | async"
        [lgLoaderOverlay]="(_service.dataLoading$ | async) ?? false"
        (widgetsChange)="_service.onWidgetsChange($event)"
        [context]="context"
    ></lgflex-flexible-layout>
</ng-container>
