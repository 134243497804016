import {
    AfterViewInit,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    inject,
    Input,
    NgZone,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    ViewEncapsulation
} from "@angular/core";
import {
    ChartColumnDatum,
    ChartData,
    ChartGroup,
    ChartOrientation,
    ChartRenderBaseDatum,
    ChartRenderColumnDatum,
    ChartRenderLineGroup,
    ChartRenderStackedBarDatum,
    ChartTooltipContext
} from "./chart.types";
import { rgb, ScaleOrdinal, scaleOrdinal, select, Selection } from "d3";
import {
    ILgFormatter,
    ILgFormatterOptions,
    LgFormatterFactoryService
} from "@logex/framework/core";
import {
    D3TooltipApi,
    getColor,
    getDefaultLegendOptions,
    getRecommendedPosition,
    ID3TooltipOptions,
    LegendItem,
    LegendOptions,
    LgColorLightnessIdentifier,
    LgColorPaletteIdentifiers,
    LgD3TooltipService
} from "@logex/framework/lg-charts";
import { LgSimpleChanges } from "@logex/framework/types";
import { TemplatePortal } from "@angular/cdk/portal";
import { BehaviorSubject, fromEvent, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { VerticalChartStrategy } from "./vertical-chart.strategy";
import { HorizontalChartStrategy } from "./horizontal-chart.strategy";
import { MARGIN } from "./chart-strategy";

const DEFAULT_FORMATTER_OPTIONS = {
    decimals: 0
};

const SPACE_FOR_LEGEND_BELOW = 30;
const LINE_STROKE = 2;
const LINE_POINT_RADIUS = 4;
const LINE_POINT_STROKE = 2;
// To make the bars higher than limit to show correctly labels above limit
const LIMITED_VALUE_OFFSET = 0.01;

@Component({
    standalone: false,
    selector: "lgflex-chart",
    templateUrl: "./chart.component.html",
    styleUrls: ["./chart.component.scss"],
    encapsulation: ViewEncapsulation.None
})
export class ChartComponent<CustomDataItem> implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    private readonly _formatterFactory: LgFormatterFactoryService =
        inject(LgFormatterFactoryService);

    private readonly _tooltipService: LgD3TooltipService = inject(LgD3TooltipService);
    private readonly _elementRef: ElementRef = inject(ElementRef);
    private readonly _viewContainerRef: ViewContainerRef = inject(ViewContainerRef);
    private readonly _ngZone: NgZone = inject(NgZone);
    private readonly _changeDetectorRef: ChangeDetectorRef = inject(ChangeDetectorRef);
    private readonly _lgTranslate: LgTranslateService = inject(LgTranslateService);

    // ----------------------------------------------------------------------------------

    @ViewChild("chart", { static: true }) private _chartDivRef: ElementRef | undefined;
    @ViewChild("defaultTooltip", { static: true }) private _defaultTooltipTemplate:
        | TemplateRef<any>
        | undefined;

    /**
     * @property values - Keys must be equal to ids provided in groups
     */
    @Input() data: ChartData<CustomDataItem> = [];
    @Input() groups: ChartGroup[] = [];

    @Input() width = 800;
    @Input() height = 200;

    @Input() yAxisTitle = "";
    @Input() xAxisTitle = "";

    @Input() formatterType = "float";
    @Input() formatterOptions: ILgFormatterOptions = DEFAULT_FORMATTER_OPTIONS;

    @Input() stackedColumnLimit = 3;

    @Input() tooltipTemplate: TemplateRef<any> | undefined;
    @Input() tooltipOptions: Partial<ID3TooltipOptions> | undefined;
    @Input() showTooltip = true;

    @Input() showLabels = false;
    @Input() legendOptions: LegendOptions = getDefaultLegendOptions();

    @Input() chartOrientation: ChartOrientation = "vertical";

    /**
     *  Defines absolute minimum and maximum value that will be rendered in the chart.
     *  Bars will not go above or below this limit.
     */
    @Input() valueLimit: number | undefined;

    @Output() readonly tooltipContext = new EventEmitter<ChartTooltipContext | null>();

    @Output() readonly itemClick = new EventEmitter<ChartRenderBaseDatum<CustomDataItem>>();

    private _svg: Selection<any, any, any, any> | undefined;

    protected _formatter: ILgFormatter<unknown> = this._getFormatter(
        this.formatterType,
        this.formatterOptions
    );

    private _axisLabelsFormatter: ILgFormatter<unknown> = this._getFormatter(
        this.formatterType,
        this.formatterOptions
    );

    private _chartColors: ScaleOrdinal<string, LgColorPaletteIdentifiers> = scaleOrdinal();
    private _stackedBarColors: ScaleOrdinal<string, string> = scaleOrdinal();

    protected _tooltipContext$ = new BehaviorSubject<ChartTooltipContext<CustomDataItem> | null>(
        null
    );

    private _tooltip: D3TooltipApi | undefined;
    private _lastMouseX = 0;
    private _lastMouseY = 0;
    private _tooltipGroupColors: Record<string, string> = {};

    private _min = 0;
    private _max = 0;

    private _initialized = false;
    private _destroyed$ = new Subject<void>();

    protected _groupsDictionary: Record<string, ChartGroup> = {};
    protected _labelsGroups: {
        firstId?: string;
        lastId?: string;
    } = {};

    protected _legendDefinition: LegendItem[] = [];
    protected _spaceForLegend = SPACE_FOR_LEGEND_BELOW;
    protected _margin = MARGIN;

    private _limitedData: ChartData<CustomDataItem> = [];
    private _renderColumnsData: Array<ChartRenderColumnDatum<CustomDataItem>> = [];
    private _chartStrategy: HorizontalChartStrategy | VerticalChartStrategy;

    // --------------------------------------------------------------------------------

    ngOnInit() {
        this._trackMousePosition();

        this._tooltipContext$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(item => this.tooltipContext.emit(item));
    }

    ngAfterViewInit(): void {
        this._svg = select(this._chartDivRef?.nativeElement).append("svg");
        this._initialize();
    }

    ngOnChanges(changes: LgSimpleChanges<ChartComponent<CustomDataItem>>): void {
        if (!this._initialized) return;

        if (changes.chartOrientation) {
            this._svg.remove();

            this._svg = select(this._chartDivRef?.nativeElement).append("svg");
            this._initialize();

            return;
        }

        let limitData = false;
        let setMinMax = false;
        let updateSize = false;
        let updateLegend = false;
        let updateData = false;

        if (changes.data) {
            limitData = true;
            setMinMax = true;
            updateSize = true;
            updateData = true;
        }
        if (changes.groups) {
            limitData = true;
            updateSize = true;
            updateLegend = true;
            updateData = true;
        }
        if (changes.width || changes.height) {
            limitData = true;
            setMinMax = true;
            updateSize = true;
            updateData = true;
            this._chartStrategy.setSize(this.width, this.height);
        }

        if (changes.xAxisTitle || changes.yAxisTitle) {
            this._chartStrategy.setTitles(this.xAxisTitle, this.yAxisTitle);
        }

        if (changes.formatterType || changes.formatterOptions) {
            this._chartStrategy.setAxisLabelFormatter(this._axisLabelsFormatter);
        }

        if (limitData) {
            this._setLimitedData();
        }
        if (setMinMax) {
            this._setMinMax();
        }
        if (updateData) {
            this._updateData();
        }
        if (updateSize) {
            this._updateSize();
        }
        if (updateLegend) {
            this._updateLegend();
        }
        this._render();
    }

    private _initialize(): void {
        this._initChartStrategy();
        this._initializeFormatters();
        this._initializeColorScales();
        this._initializeTooltip();
        this._setLimitedData();
        this._setMinMax();
        this._updateData();
        this._updateSize();
        this._updateLegend();
        this._render();

        this._initialized = true;
    }

    private _initChartStrategy(): void {
        this._chartStrategy =
            this.chartOrientation === "vertical"
                ? new VerticalChartStrategy()
                : new HorizontalChartStrategy();
        this._chartStrategy
            .setSize(this.width, this.height)
            .setTitles(this.xAxisTitle, this.yAxisTitle)
            .initialize(this._svg);
    }

    private _initializeFormatters(): void {
        this.formatterType = this.formatterType || "int";
        this._formatter = this._getFormatter(this.formatterType, this.formatterOptions);
        this._axisLabelsFormatter = this._getFormatter(this.formatterType, {
            ...this.formatterOptions,
            min: undefined,
            max: undefined
        });
        this._chartStrategy.setAxisLabelFormatter(this._axisLabelsFormatter);
    }

    private _getFormatter(type: string, formatterOptions: ILgFormatterOptions): ILgFormatter<any> {
        return this._formatterFactory.getFormatter(type, {
            ...DEFAULT_FORMATTER_OPTIONS,
            ...formatterOptions
        });
    }

    private _initializeColorScales(): void {
        const colors = [
            LgColorPaletteIdentifiers.Cobalt,
            LgColorPaletteIdentifiers.Mint,
            LgColorPaletteIdentifiers.Grey,
            LgColorPaletteIdentifiers.ValuePurple,
            LgColorPaletteIdentifiers.SalmonRed,
            LgColorPaletteIdentifiers.Pink
        ];
        this._chartColors = scaleOrdinal(colors);
    }

    private _initializeTooltip(): void {
        const templatePortal = new TemplatePortal(
            this.tooltipTemplate ?? this._defaultTooltipTemplate!,
            this._viewContainerRef
        );

        const commonOptions: ID3TooltipOptions = {
            stay: false,
            trapFocus: false,
            position: "top-left",
            tooltipClass: "lg-tooltip lg-tooltip--d3",
            panelClass: "chart-overlay",
            content: templatePortal,
            delayHide: 150,
            target: this._elementRef
        };
        this._tooltip = this._tooltipService.create({
            ...commonOptions,
            ...this.tooltipOptions
        });

        if (this._svg !== undefined) {
            this._svg.on("mouseleave", (_event: MouseEvent) => this._tooltip!.hide());
        }
    }

    private _trackMousePosition(): void {
        this._ngZone.runOutsideAngular(() => {
            fromEvent<MouseEvent>(this._elementRef.nativeElement, "mousemove")
                .pipe(takeUntil(this._destroyed$))
                .subscribe((event: MouseEvent) => {
                    this._lastMouseX = event.clientX;
                    this._lastMouseY = event.clientY;
                    this._updateTooltipPosition();
                });
        });
    }

    private _updateTooltipPosition(): void {
        if (!this._tooltip || !this._tooltip.visible) return;
        this._tooltip.setPositionAt(
            this._lastMouseX,
            this._lastMouseY,
            getRecommendedPosition(
                { x: this._lastMouseX, y: this._lastMouseY },
                this._tooltip.getOverlayElement()
            )
        );
    }

    private _setLimitedData(): void {
        const limit = this._chartStrategy.getLimit();
        this._limitedData = this.data.slice(0, limit);
    }

    private _setMinMax(): void {
        this._min = 0;
        this._max = 0;
        this._limitedData.forEach(datum => {
            this.groups.forEach(group => {
                const subValues = datum.values[group.id]?.subValues;
                if (subValues.length > 0) {
                    subValues.forEach(subValue => {
                        this._compareMinMaxValue(subValue.value);
                    });
                }
                this._compareMinMaxValue(datum.values[group.id].value);
            });
        });

        if (this.valueLimit !== undefined && this._max > this.valueLimit) {
            this._max = this.valueLimit;
        }
        if (this.valueLimit !== undefined && this._min < -this.valueLimit) {
            this._min = -this.valueLimit;
        }

        this._chartStrategy.setMinMaxValues(this._min, this._max);
    }

    private _compareMinMaxValue(value: number): void {
        if (value < this._min) this._min = value;
        if (value > this._max) this._max = value;
    }

    private _defineBarAxis() {
        const columnGroupIds = this.groups
            .filter(group => group.type !== "line")
            .map(group => group.id);

        this._chartStrategy.defineBarAxis(columnGroupIds);
        this._chartColors.domain(columnGroupIds);
    }

    private _defineStackedBarColors(): void {
        const stackedGroupKeys = [
            ...new Set(
                this._renderColumnsData.reduce((acc, column) => {
                    const stackedBars = column.stackedBars.map(bar => bar.name);
                    return [...acc, ...stackedBars];
                }, [])
            )
        ];

        const colors = this._generateUniqueColors(stackedGroupKeys);

        this._stackedBarColors = scaleOrdinal(colors);
        this._stackedBarColors.domain(stackedGroupKeys);
    }

    private _generateUniqueColors(stackedBarNames: string[]): string[] {
        const stepSize = 1 / stackedBarNames.length;

        return stackedBarNames.map((_, index) => {
            const lightness: LgColorLightnessIdentifier = (((index % 4) + 3) *
                10) as LgColorLightnessIdentifier; // 30, 40, 50, 60
            const groupColor = getColor(LgColorPaletteIdentifiers.Cobalt, lightness);
            return rgb(groupColor)
                .brighter(index * stepSize)
                .toString();
        });
    }

    private _updateSize(): void {
        this._chartStrategy.defineScale(this._min, this._max, this._limitedData);
        this._defineBarAxis();
        this._defineStackedBarColors();
        this._chartStrategy.defineAxisValuesPosition();
        this._chartStrategy.defineTitleAxis();
    }

    protected _updateLegend(): void {
        this._legendDefinition = this.groups.map(group => {
            return {
                color: this._getGroupColor(group.id, false),
                name: group.name
            };
        });

        this._changeDetectorRef.detectChanges();
    }

    protected _getGroupColor(group: string, hover: boolean, colorIndex = 0): string {
        const baseLightness: LgColorLightnessIdentifier = 50;
        let groupColor = getColor(this._chartColors(group), baseLightness);

        if (colorIndex) {
            groupColor = getColor(
                this._chartColors(group),
                (baseLightness - colorIndex * 10) as LgColorLightnessIdentifier
            );
        }

        if (hover) {
            return rgb(groupColor).darker(0.2).toString();
        }

        return groupColor;
    }

    protected _getStackedBarColor(group: string, hover: boolean): string {
        const groupColor = this._stackedBarColors(group);

        if (hover) {
            return rgb(groupColor).darker(0.2).toString();
        }

        return groupColor;
    }

    // --------------------------------------------------------------------------------
    private _updateData(): void {
        this._groupsDictionary = {};
        this.groups.forEach(group => (this._groupsDictionary[group.id] = group));

        const barGroups = this.groups.filter(group => group.type !== "line");
        if (barGroups.length > 1) {
            this._labelsGroups.firstId = barGroups[0].id;
            this._labelsGroups.lastId = barGroups[barGroups.length - 1].id;
        }

        this._renderColumnsData = this._limitedData.map(column => {
            const simpleBars: Array<ChartRenderBaseDatum<CustomDataItem>> = [];
            const stackedBars: Array<ChartRenderStackedBarDatum<CustomDataItem>> = [];
            const labels: Array<ChartRenderBaseDatum<CustomDataItem>> = [];

            this.groups.forEach(group => {
                if (group.type === "line") return;

                const barDatum: ChartRenderBaseDatum<CustomDataItem> = {
                    column,
                    group,
                    value: this._getLimitedValue(column.values[group.id].value)
                };

                if (group.type === "simpleBar") {
                    simpleBars.push(barDatum);
                }

                if (group.type === "stackedBar") {
                    stackedBars.push(...this.getStackedBars(column, group));
                }

                labels.push(barDatum);
            });

            return {
                column: column.column,
                simpleBars,
                stackedBars,
                labels
            };
        });
    }

    private _setMinMaxForStackBars(stackedBars: Array<ChartRenderStackedBarDatum<CustomDataItem>>) {
        const [min, max] = stackedBars.reduce(
            (sum, { value }) => {
                if (value > 0) {
                    sum[1] += value;
                } else {
                    sum[0] += value;
                }
                return sum;
            },
            [0, 0]
        );
        this._compareMinMaxValue(min);
        this._compareMinMaxValue(max);
    }

    getStackedBars(
        column: ChartColumnDatum<CustomDataItem>,
        group: ChartGroup
    ): Array<ChartRenderStackedBarDatum<CustomDataItem>> {
        if (group.type !== "stackedBar") return [];

        const currentGroupValue = column.values[group.id];

        if (currentGroupValue.subValues === undefined)
            throw Error("Sub-values for stacked chart are not specified!");

        if (
            this.valueLimit !== undefined &&
            (currentGroupValue.value > this.valueLimit ||
                currentGroupValue.value < -this.valueLimit)
        ) {
            return [
                {
                    name: "all",
                    value: this._getLimitedValue(currentGroupValue.value),
                    index: 0,
                    column,
                    group,
                    yValueOffset: 0,
                    relatedStackedBars: []
                }
            ];
        }

        if (currentGroupValue.subValues.length === 0) {
            return [
                {
                    name: "all",
                    value: currentGroupValue.value,
                    index: 0,
                    column,
                    group,
                    yValueOffset: 0,
                    relatedStackedBars: []
                }
            ];
        }

        const allStackedBarData: Array<ChartRenderStackedBarDatum<CustomDataItem>> = [];
        const positiveStackedBarData: Array<ChartRenderStackedBarDatum<CustomDataItem>> = [];
        const negativeStackedBarData: Array<ChartRenderStackedBarDatum<CustomDataItem>> = [];

        let index = 0;
        let subValuesSum = 0;
        const sortedSubValues = currentGroupValue.subValues.sort(
            (a, b) => Math.abs(b.value) - Math.abs(a.value)
        );

        for (const currentValue of sortedSubValues) {
            let currentStackedBarData: Array<ChartRenderStackedBarDatum<CustomDataItem>>;
            let previousDatum: ChartRenderStackedBarDatum<CustomDataItem> | undefined;
            if (currentValue.value >= 0) {
                currentStackedBarData = positiveStackedBarData;
                previousDatum = positiveStackedBarData.at(-1);
            } else {
                currentStackedBarData = negativeStackedBarData;
                previousDatum = negativeStackedBarData.at(-1);
            }

            if (index === this.stackedColumnLimit) {
                const othersValue = currentGroupValue.value - subValuesSum;
                if (othersValue >= 0) {
                    currentStackedBarData = positiveStackedBarData;
                    previousDatum = positiveStackedBarData.at(-1);
                } else {
                    currentStackedBarData = negativeStackedBarData;
                    previousDatum = negativeStackedBarData.at(-1);
                }

                let previousValue = 0;
                let previousYValueOffset = 0;
                if (previousDatum !== undefined) {
                    previousValue = previousDatum.value;
                    previousYValueOffset = previousDatum!.yValueOffset;
                }

                currentStackedBarData.push({
                    name: this._lgTranslate.translate("_Flexible._.Others"),
                    value: othersValue,
                    index,
                    column,
                    group,
                    yValueOffset: previousValue + previousYValueOffset,
                    relatedStackedBars: allStackedBarData
                });
                break;
            } else {
                subValuesSum = subValuesSum + currentValue.value;
            }

            const nextValue: ChartRenderStackedBarDatum<CustomDataItem> = {
                ...currentValue,
                index,
                column,
                group,
                yValueOffset:
                    previousDatum === undefined
                        ? 0
                        : previousDatum.value + previousDatum.yValueOffset,
                relatedStackedBars: allStackedBarData
            };
            currentStackedBarData.push(nextValue);
            index++;
        }

        allStackedBarData.splice(
            0,
            allStackedBarData.length,
            ...[...positiveStackedBarData.reverse(), ...negativeStackedBarData]
        );

        this._setMinMaxForStackBars(allStackedBarData);

        return allStackedBarData;
    }

    _render(): void {
        if (this._limitedData.length === 0 || this._svg === undefined) {
            return;
        }

        this._setTooltipColors();
        this._chartStrategy.drawAxes(this._limitedData.length);

        const columnGroupsMerged = this._chartStrategy.getColumnGroups(this._renderColumnsData);

        this._renderSimpleBars(columnGroupsMerged);
        this._renderStackedBars(columnGroupsMerged);
        this._renderLines();
        this._renderLabels(columnGroupsMerged);
    }

    private _setTooltipColors(): void {
        this._tooltipGroupColors = {};
        this.groups.forEach(group => {
            this._tooltipGroupColors[group.id] = this._getGroupColor(group.id, false);
        });
    }

    private _renderSimpleBars(
        columnGroupsMerged: Selection<SVGGElement, ChartRenderColumnDatum<CustomDataItem>, any, any>
    ): void {
        const bars = columnGroupsMerged
            .selectAll<SVGRectElement, ChartRenderBaseDatum<CustomDataItem>>("rect.simple-bar")
            .data(
                d => d.simpleBars.filter(datum => datum.group.type === "simpleBar"),
                d => d.column.column
            );

        bars.exit().remove();

        const newBars = bars.enter().append("rect");
        newBars
            .attr("class", "simple-bar")
            .style("cursor", "pointer")
            .on("mouseover", (event, d) => {
                select(event.target).style("fill", this._getGroupColor(d.group.id, true));

                this._showTooltip(event, {
                    column: d.column,
                    group: d.group,
                    groupColors: this._tooltipGroupColors
                });
            })
            .on("mouseleave", (event, d) => {
                select(event.target).style("fill", this._getGroupColor(d.group.id, false));
                this._tooltip!.hide();
            })
            .on("click", (_event, d) => {
                this._onChartItemClick(d);
            });

        const mergedBars = newBars
            .merge(bars)
            .style("fill", d => this._getGroupColor(d.group.id, false));

        this._chartStrategy.renderSimpleBars(mergedBars);
    }

    private _renderStackedBars(
        columnGroupsMerged: Selection<SVGGElement, ChartRenderColumnDatum<CustomDataItem>, any, any>
    ): void {
        const stackedBars = columnGroupsMerged
            .selectAll<SVGRectElement, ChartRenderStackedBarDatum<CustomDataItem>>(".stacked-bar")
            .data(
                d => d.stackedBars,
                d => d.column.column
            );
        stackedBars.exit().remove();

        const newStackedBars = stackedBars.enter().append("rect");
        newStackedBars
            .attr("class", "stacked-bar")
            .style("cursor", "pointer")
            .on("mouseover", (event, d) => {
                select(event.target).style("fill", this._getStackedBarColor(d.name, true));
                this._showTooltip(event, {
                    column: d.column,
                    group: d.group,
                    groupColors: this._tooltipGroupColors,
                    currentStackedBar: { value: d.value, name: d.name },
                    stackedBars: d.relatedStackedBars
                });
            })
            .on("mouseleave", (event, d) => {
                select(event.target).style("fill", this._getStackedBarColor(d.name, false));
                this._tooltip!.hide();
            })
            .on("click", (_event, d) => {
                this._onChartItemClick(d);
            });

        const mergedStackedBars = newStackedBars.merge(stackedBars).style("fill", d => {
            return this._getStackedBarColor(d.name, false);
        });
        this._chartStrategy.renderStackedBars(mergedStackedBars);

        const stackedBarsSeparators = stackedBars
            .enter()
            .append("rect")
            .attr("class", "stacked-bar stacked-bar--separator")
            .style("fill", "white");
        this._chartStrategy.renderStackedBarSeparators(stackedBarsSeparators);
    }

    private _renderLines(): void {
        const dataGroups = this.groups.filter(group => group.type === "line");
        const mergedLineGroups = this._chartStrategy.getMergedLineGroups(
            dataGroups,
            this._limitedData,
            this._getLimitedValue.bind(this)
        );

        const lines = mergedLineGroups.selectAll<
            SVGPathElement,
            ChartRenderLineGroup<CustomDataItem>
        >("path");
        lines.remove();

        const newLines = mergedLineGroups
            .append("path")
            .attr("fill", "none")
            .attr("stroke-width", LINE_STROKE)
            .attr("stroke", d => this._getGroupColor(d.id, false))
            .datum(d => d.data);

        this._chartStrategy.renderLines(newLines);

        // --------------------------------------------------------------------------------
        const circlesGroup = mergedLineGroups.selectAll<
            SVGGElement,
            ChartRenderLineGroup<CustomDataItem>
        >("g");
        circlesGroup.remove();
        const newCirclesGroup = mergedLineGroups.append("g");

        // const circleShadows = newCirclesGroup
        //     .selectAll<SVGCircleElement, VerticalChartRenderLineDatum<CustomDataItem>>(
        //         "circle.shadow"
        //     )
        //     .data(
        //         d => d.data,
        //         d => d.column.column
        //     );
        // circleShadows.exit().remove();
        //
        // const newCircleShadows = circleShadows
        //     .enter()
        //     .append("circle")
        //     .attr("class", "shadow")
        //     .attr("fill", "transparent")
        //     .style("opacity", 0.8)
        //     .attr("r", LINE_POINT_SHADOW_RADIUS)
        //     .attr("stroke-width", LINE_POINT_SHADOW_STROKE);
        //
        // const newCircleShadowsMerged = newCircleShadows.merge(circleShadows);
        // newCircleShadowsMerged
        //     .attr("cx", d => this._calculateLineXPosition(d.column.column))
        //     .attr("cy", d => this._yScale(d.value))
        //     .on("mousemove", function (event: MouseEvent) {
        //         self._onMouseOverPointShadow(select(this), pointer(event));
        //     })
        //     .on("mouseout", function (_event: MouseEvent) {
        //         select(this).attr("fill", "transparent");
        //     });

        const circles = newCirclesGroup
            .selectAll<SVGCircleElement, ChartRenderBaseDatum<CustomDataItem>>("circle.point")
            .data(
                d => d.data,
                d => d.column.column
            );
        circles.exit().remove();

        const newCircles = circles
            .enter()
            .append("circle")
            .attr("class", "point")
            .attr("fill", "white")
            .attr("r", LINE_POINT_RADIUS)
            .attr("stroke-width", LINE_POINT_STROKE)
            .style("cursor", "pointer")
            .on("mouseover", (event, d) => {
                select(event.target).attr("fill", this._getGroupColor(d.group.id, true));
                this._showTooltip(event, {
                    column: d.column,
                    group: d.group,
                    groupColors: this._tooltipGroupColors
                });
            })
            .on("mouseleave", event => {
                select(event.target).attr("fill", "white");
                this._tooltip!.hide();
            })
            .on("click", (_event, d) => {
                this._onChartItemClick(d);
            });

        const mergedCircles = newCircles
            .merge(circles)
            .attr("stroke", d => this._getGroupColor(d.group.id, false));
        this._chartStrategy.renderCircles(mergedCircles);
    }

    private _renderLabels(
        columnGroupsMerged: Selection<SVGGElement, ChartRenderColumnDatum<CustomDataItem>, any, any>
    ): void {
        if (!this.showLabels) return;

        const labels = columnGroupsMerged
            .selectAll<SVGTextElement, ChartRenderBaseDatum<CustomDataItem>>("text")
            .data(
                d => d.labels,
                d => d.column.column
            );
        labels.exit().remove();

        const newLabels = labels.enter().append("text");
        newLabels.attr("class", "label");

        const mergedLabels = labels.merge(newLabels);
        this._chartStrategy.renderLabels(mergedLabels, this._formatter, this._labelsGroups);
    }

    private _getLimitedValue(value: number): number {
        if (this.valueLimit === undefined) return value;
        if (value > this.valueLimit) return this.valueLimit + LIMITED_VALUE_OFFSET;
        if (value < -this.valueLimit) return -this.valueLimit - LIMITED_VALUE_OFFSET;
        return value;
    }

    // --------------------------------------------------------------------------------
    private _onChartItemClick(item: ChartRenderBaseDatum<CustomDataItem>): void {
        this.itemClick.emit(item);
    }

    private _showTooltip(event: MouseEvent, context: ChartTooltipContext<CustomDataItem>): void {
        this._lastMouseX = event.clientX;
        this._lastMouseY = event.clientY;

        this._tooltipContext$.next(context);

        if (this.showTooltip) {
            this._tooltip!.show();
        }

        this._updateTooltipPosition();
    }

    _onLegendItemClick(item: unknown): void {
        // TODO
    }

    // --------------------------------------------------------------------------------
    ngOnDestroy(): void {
        this._tooltip?.destroy();

        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
