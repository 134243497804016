import { uniq as ldUniq } from "lodash-es";
import { CalculationProgress } from "../types";

export function combineCalculationProgress(values: Array<CalculationProgress | null>): CalculationProgress | null {
    if (values.length === 1) return values[0];

    let executingSteps: string[] = [];
    let totalPercentage = 0;
    let numValues = 0;
    let isFinished = true;
    for (const value of values) {
        if (value == null) continue;

        executingSteps = executingSteps.concat(value.executingSteps);
        totalPercentage += value.percentage;
        numValues++;
        isFinished = isFinished && value.isFinished;
    }

    if (numValues === 0) return null;

    return {
        percentage: totalPercentage / numValues,
        executingSteps: ldUniq(executingSteps),
        isFinished,
    }
}
