@if (_status() != "hidden") {
    <div class="calculation-progress__container">
        <div
            class="calculation-progress__button lg-panel__header-icon"
            [class.calculation-progress__button--calculating]="_status() === 'calculating'"
            [class.calculation-progress__button--done]="_status() === 'done'"
        >
            @if (_status() === 'waiting') {
                <lg-icon
                    icon="icon-data-changed"
                    width="20"
                    height="20"
                    type="disabled"
                    [lgTooltip]="tooltip"
                ></lg-icon>

            } @else if (_status() === 'calculating') {
                <svg
                    height="24"
                    width="24"
                    [lgTooltip]="tooltip"
                >
                    <path [attr.d]="_pathRest()" id="path" stroke="#D6E3F0" stroke-width="2" fill="none"/>
                    <path [attr.d]="_path()" id="path" stroke="#005de4" stroke-width="2" fill="none"/>
                </svg>

            } @else if (_status() === 'done') {
                <lg-icon
                    icon="icon-circle-check"
                ></lg-icon>
            }

            @else if (_status() === 'error') {
                <lg-icon
                    icon="icon-warning"
                    width="20"
                    height="20"
                    class="calculation-progress__error-icon"
                    lgTooltip="{{'_LoadManager.CalculationErrorTooltip' | lgTranslate}}"
                    (click)="_showCalculationError()"
                ></lg-icon>

            }

            <ng-template #tooltip>
                <div class="recalculation-container">
                    @if (_status() === 'calculating') {
                        <span>{{ '_LoadManager.CalculationInProgress' | lgTranslate }} ({{ _progressInPercent() }}%)</span>
                        <span class="inline-status">
                            <lg-icon
                                icon="icon-running-state"
                                width="20"
                                height="20"
                            /> {{ '_LoadManager.Calculating' | lgTranslate }}
                        </span>
                    } @else if (_status() === 'waiting') {
                        @if (recalculationQueued) {
                            <span>{{ '_LoadManager.CalculationQueued' | lgTranslate }}</span>
                            <span class="inline-status">
                                <lg-icon
                                    icon="icon-queued-state"
                                    width="20"
                                    height="20"
                                /> {{ '_LoadManager.InQueue' | lgTranslate }}
                            </span>
                        } @else {
                            <span>{{ '_LoadManager.DataIsStale' | lgTranslate }}</span>
                            <span class="inline-status">
                                <lg-button
                                    [text]="'_LoadManager.QueueRecalculation' | lgTranslate"
                                    [icon]="'icon-calculator'"
                                    [buttonClass]="'button--condensed button--on-blue'"
                                    (click)="_reload()"
                                ></lg-button>
                            </span>
                        }
                    }
                </div>
                @if (_staleTables().length > 0) {
                    <br><b>{{'_LoadManager.StaleTables' | lgTranslate}}:</b><br>
                    @for (table of _staleTables().slice(0, 3); track table) {
                        {{table}}<br />
                    }
                    @if (_staleTables().length > 3) {
                        & {{ _staleTables().length - 3 }} {{ '_LoadManager.MoreTables' | lgTranslate }}
                    }
                }
                @if (_executingSteps().length > 0) {
                    <br><br><b>{{'_LoadManager.ExecutingSteps' | lgTranslate}}:</b><br>
                    @for (step of _executingSteps().slice(0, 3); track step) {
                        {{step | lgTranslate}}<br />
                    }
                    @if (_executingSteps().length > 3) {
                        & {{ _executingSteps().length - 3 }} {{ '_LoadManager.MoreSteps' | lgTranslate }}
                    }
                }
            </ng-template>
        </div>
    </div>
}
