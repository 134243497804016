import { EmbeddedWidget } from "../widget.types";
import { ConditionalFormattingConfig } from "../../components/conditional-formatting/conditional-formattings.types";

import { DefinitionDisplayMode } from "@logex/framework/lg-application";

export type PivotTableType = "table" | "pivot";

export interface TableConfig {
    type: PivotTableType;
    ignoreOwnFilters: boolean;
    name: string;
    limitRows: number;
    orderBy: Sorting[];
    dimensions: DimensionConfig[];
}

export interface DimensionConfig {
    fieldId: string;
    name: string;
    disabled: boolean | undefined;
    displayMode: DefinitionDisplayMode;
    width: number | undefined;
    overrideFromParent?: boolean | undefined;
    orderBy?: Sorting;
}

export interface Sorting {
    type: "desc" | "asc";
    item: string;
}

export type ColumnGroupType = "simple" | "referenceSlot" | "column";
export type ColumnType = "default" | "formula" | "difference" | "widget";

export interface ColumnVisualizationWidget extends EmbeddedWidget {}

export type GroupOrColumn = ColumnsGroup | Column;

export interface ColumnsGroup {
    id?: string;
    title: string;
    columnType: ColumnGroupType;
    columns: Column[];
    referenceIdx?: number;
}

export type Column = ColumnDefault | ColumnDifference | ColumnWidget | ColumnFormula;

// @TODO isEnabled needed for pivot service
export interface ColumnBase {
    title: string;
    width: number | undefined;
    type: ColumnType | undefined;
    isEnabled?: boolean;
    isValueField?: boolean;
    visualizationWidget?: ColumnVisualizationWidget;
    conditionalFormatting?: ConditionalFormattingConfig;
    aggregatedTotals?: boolean;
}

export interface ColumnDefault extends ColumnBase {
    type: "default";
    field: string;
    referenceIdx?: number;
}

export interface ColumnFormula extends ColumnBase {
    id: string;
    type: "formula";
    field?: string;
    formula: ColumnFormulaType;
    variables: {
        [V in ColumnFormulaVariableNames]?: ColumnFormulaInput<V>;
    };
    formatType: ColumnFormulaFormatting;
    formatPrecision: number;
    formatForceSign?: boolean;
}

export type ColumnDifferenceMode = "diff" | "growth";

export interface ColumnDifference extends ColumnBase {
    id: string;
    type: "difference";
    field: string;
    referenceLeft: number;
    referenceRight: number;
    mode: ColumnDifferenceMode;
}

export interface ColumnWidget extends ColumnBase {
    type: "widget";
    widgetType: string | undefined;
    config?: object;
}

export type ColumnFormulaFormatting = "float" | "money" | "percentage";

export type ColumnFormulaVariableNames = "a" | "b" | "c";

export type ColumnFormulaInput<V = ColumnFormulaVariableNames> =
    | ColumnFormulaVariable<V>
    | ColumnFormulaConstant<V>;

export enum ColumnFormulaType {
    A = "A",
    AMinusB = "AMinusB",
    APlusB = "APlusB",
    ADividedB = "ADividedB",
    AMinusBMinusC = "AMinusBMinusC",
    AMinusBPlusC = "AMinusBPlusC",
    ADividedB_MinusC = "ADividedB_MinusC",
    AMultiplyBMinusC = "AMultiplyBMinusC",
    PercentOfParent = "PercentOfParent",
    PercentOfTotal = "PercentOfTotal",
    ADivideBDivideC = "ADivideBDivideC",
    ADivideBMultiplyC = "ADivideBMultiplyC"
}

export const columnFormulaTypeLabels: Partial<Record<ColumnFormulaType, string>> = {
    [ColumnFormulaType.A]: "A",
    [ColumnFormulaType.AMinusB]: "A - B",
    [ColumnFormulaType.APlusB]: "A + B",
    [ColumnFormulaType.ADividedB]: "A / B",
    [ColumnFormulaType.AMinusBMinusC]: "A - B - C",
    [ColumnFormulaType.AMinusBPlusC]: "A - B + C",
    [ColumnFormulaType.ADividedB_MinusC]: "(A / B) - C",
    [ColumnFormulaType.AMultiplyBMinusC]: "A * (B - C)",
    [ColumnFormulaType.PercentOfParent]: "A / Parent(A)",
    [ColumnFormulaType.PercentOfTotal]: "A / Total(A)",
    [ColumnFormulaType.ADivideBDivideC]: "A / B / C",
    [ColumnFormulaType.ADivideBMultiplyC]: "A / B * C"
};

export const commonFormulasTypes = [
    ColumnFormulaType.A,
    ColumnFormulaType.AMinusB,
    ColumnFormulaType.APlusB,
    ColumnFormulaType.ADividedB,
    ColumnFormulaType.AMinusBMinusC,
    ColumnFormulaType.AMinusBPlusC,
    ColumnFormulaType.ADividedB_MinusC,
    ColumnFormulaType.AMultiplyBMinusC,
    ColumnFormulaType.ADivideBDivideC,
    ColumnFormulaType.ADivideBMultiplyC
];

export type ColumnFormulaConstant<V = ColumnFormulaVariableNames> = ColumnFormulaInputBase<V> & {
    type: "constant";
    constant: number;
};

export type ColumnFormulaInputBase<V = ColumnFormulaVariableNames> = {
    variable: V;
};

export type ColumnFormulaVariable<V = ColumnFormulaVariableNames> = ColumnFormulaInputBase<V> & {
    type?: "variable";
    field: string;
    reference?: number; // must be optional
};
