<lgflex-form-row class="input-with-help-info" [label]="'.Title' | lgTranslate">
    <input
        class="input input--wide"
        type="text"
        [(ngModel)]="column.title"
        (ngModelChange)="columnChange.emit(column)"
    />
    <lg-help-info
        header="{{ '.Title' | lgTranslate }}"
        description="{{ '_Flexible.ConfigurationDialogCommon.WidgetNameHelpInfo' | lgTranslate }}"
    />
</lgflex-form-row>

<lgflex-form-row [label]="'.Type' | lgTranslate">
    <lg-dropdown
        [definition]="_formulaTypeDropdownDef"
        [(current)]="column.formula"
        (currentChange)="_onFormulaTypeChange($event)"
    />
</lgflex-form-row>

<div class="formula-divider"></div>

@for (formulaVar of _currentFormulaVariables; track $index) {
    @if (column.variables[formulaVar]; as formulaVariable) {
        <lgflex-form-row [label]="formulaVariable.variable | modFormulaTypeLabel">
            <lg-dropdown
                [definition]="_formulaInputTypeDropdownDef"
                [(current)]="formulaVariable.type"
                (currentChange)="_onColumnChanged(column)"
                [itemTooltips]="true"
            />
        </lgflex-form-row>
        @if (formulaVariable.type === "variable") {
            <lgflex-form-row [label]="'.Field' | lgTranslate">
                <lg-dropdown
                    class="formulaVariableDropdown"
                    [definition]="fieldsDropdownDefinition"
                    [(current)]="formulaVariable.field!"
                    [isValid]="formulaVariable.field != null"
                    (currentChange)="_onColumnChanged(column)"
                    [itemTooltips]="true"
                />
            </lgflex-form-row>
            @if (areReferencesAllowed && referencesDropdownDefinition != null) {
                <lgflex-form-row [label]="'.Reference' | lgTranslate">
                    <lg-dropdown
                        class="formulaVariableDropdown"
                        [definition]="referencesDropdownDefinition"
                        [current]="formulaVariable.reference!"
                        (currentChange)="
                            formulaVariable.reference = $event; columnChange.emit(column)
                        "
                        [itemTooltips]="true"
                    />
                </lgflex-form-row>
            }

            <div class="formula-divider"></div>
        }

        @if (formulaVariable.type === "constant") {
            <lgflex-form-row [label]="'.Constant' | lgTranslate">
                <input
                    class="input input--wide"
                    type="number"
                    [(ngModel)]="formulaVariable.constant"
                    (ngModelChange)="_onColumnChanged(column)"
                    [required]="true"
                />
            </lgflex-form-row>

            <div class="formula-divider"></div>
        }
    }
}

@if (!noFormatting) {
    <lgflex-configuration-dialog-format-and-precision
        [(formatType)]="column.formatType"
        [(formatPrecision)]="column.formatPrecision"
        [(formatForceSign)]="column.formatForceSign"
        [(aggregatedTotals)]="column.aggregatedTotals"
        (formatTypeChange)="columnChange.emit(column)"
        (formatForceSignChange)="columnChange.emit(column)"
        (aggregatedTotalsChange)="columnChange.emit(column)"
        (formatPrecisionChange)="columnChange.emit(column)"
    />
}
