import { NgModule } from "@angular/core";

import { CommonModule } from "@angular/common";
import { UiCoreModule } from "@logex/framework/ui-core";
import { LgLayoutModule } from "@logex/framework/lg-layout";
import { LgLocalizationModule } from "@logex/framework/lg-localization";
import { LgPivotTableModule } from "@logex/framework/lg-pivot-table";
import { LoadManagerModule, StaleDataService } from "@logex/load-manager";

import { ExportsNotificationsService } from "./services/exports-notifications.service";
import { ExportsNotificationsGateway } from "./gateways/exports-notifications-gateway.service";
import { DownloadExportService } from "./services/download-export.service";
import { FormatMsToTimePipe } from "./pipes/format-ms-to-time.pipe";
import { ExportComponent } from "./components/exports/export.component";
import { ExportRoutingModule } from "./components/exports/export-routing.module";
import { ExportPivot } from "./components/exports/pivots/exports-pivot.service";
import { QueuePivot } from "./components/exports/pivots/queue-pivot";
import { ExportGateway } from "./components/exports/gateways/export-gateway";
import { CounterComponent } from "./components/counter/counter.component";
import { ExportModuleInitializer } from "./export-module-initializer";

@NgModule({
    declarations: [ExportComponent, CounterComponent, FormatMsToTimePipe],
    imports: [
        CommonModule,
        UiCoreModule,
        LgLayoutModule,
        LgLocalizationModule,
        LgPivotTableModule,
        LoadManagerModule,

        ExportRoutingModule
    ],
    providers: [
        ExportModuleInitializer,

        ExportPivot,
        QueuePivot,
        ExportGateway,

        ExportsNotificationsGateway,
        ExportsNotificationsService,
        DownloadExportService
    ]
})
export class ExportsModule {}
