import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
    standalone: false,
    selector: "lgflex-form-row",
    templateUrl: "./form-row.component.html",
    styleUrls: ["./form-row.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FormRowComponent {
    @Input() label = "";
}
