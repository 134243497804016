import { migrateVersion3To4 } from "./v3-v4";
import { migrateVersion4To5 } from "./v4-v5";
import { migrateVersion5To6 } from "./v5-v6";
import { migrateVersion6To7 } from "./v6-v7";
import { migrateVersion7To8 } from "./v7-v8";
import { migrateVersion8To9 } from "./v8-v9";
import { migrateVersion9To10 } from "./v9-v10";
import { provideWidgetMigration } from "../../../services/widget-types-registry";
import { PIVOT_TABLE_WIDGET_2 } from "../pivot-table-widget.configuration.types";

export * from "./deprecated-widgets-conversions";

export const pivotTableMigrationProvider = provideWidgetMigration(PIVOT_TABLE_WIDGET_2, {
    3: migrateVersion3To4,
    4: migrateVersion4To5,
    5: migrateVersion5To6,
    6: migrateVersion6To7,
    7: migrateVersion7To8,
    8: migrateVersion8To9,
    9: migrateVersion9To10
});
