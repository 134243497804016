import { cloneDeep } from "lodash-es";
import {
    DrilldownPivotTableWidgetBaseConfig,
    ChartWidgetConfigBase
} from "./pivot-table-conversion.types";
import { migrateVersion1To2 } from "./v1-v2";
import { migrateVersion2To3 } from "./v2-v3";

export function convertPivotTableToDrilldownPivotTable(
    config: ChartWidgetConfigBase
): DrilldownPivotTableWidgetBaseConfig {
    const newConfig = {
        title: config.title,
        description: config.description,
        levels: [[...config.levels]],
        levelProperties: {},
        columns: cloneDeep(config.columns),
        ignoreOwnFilters: config.ignoreOwnFilters,
        defaultOrderBy: config.defaultOrderBy
    };
    migrateVersion1To2(newConfig);
    migrateVersion2To3(newConfig);
    return newConfig;
}
