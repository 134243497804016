import { inject, Injectable, signal, WritableSignal } from "@angular/core";
import { firstValueFrom } from "rxjs";
import {
    DataSource,
    DataSourcesServiceConfiguration,
    IFlexDataSources
} from "./flex-data-sources.types";
import { FlexDataSourcesServiceGateway } from "./gateway/flex-data-sources-gateway";
import { LgConsole } from "@logex/framework/core";

@Injectable()
export class FlexDataSourcesService implements IFlexDataSources {
    private _lgConsole = inject(LgConsole);
    private _gateway = inject(FlexDataSourcesServiceGateway);

    // ----------------------------------------------------------------------------------
    private _dataSources: DataSource[] = [];
    private _isInitialized = false;
    private _dataSourcesSignal: WritableSignal<DataSource[]> = signal([]);

    public dataSourcesSignal = this._dataSourcesSignal.asReadonly();

    async init(config: DataSourcesServiceConfiguration): Promise<void> {
        try {
            const dataSources = await firstValueFrom(
                this._gateway.getDataSources(config.url, config.metadataArgs)
            );
            if (dataSources == null) throw Error("DataSources should be loaded.");

            this._dataSourcesSignal.set(dataSources);
            this._dataSources = dataSources;
            this._isInitialized = true;
        } catch (e: any) {
            this._lgConsole.error("Error loading flexible data sources", e);
            throw e;
        }
    }

    private _assertIsInitialized(): void {
        if (!this._isInitialized) {
            throw Error("DataSourcesService must be initialized before use");
        }
    }

    get dataSources(): DataSource[] {
        this._assertIsInitialized();
        return this._dataSources;
    }

    set dataSources(value: DataSource[]) {
        this._dataSources = value;
    }
}
