import * as _ from "lodash-es";
import {
    AfterViewInit,
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    StaticProvider,
    TemplateRef
} from "@angular/core";
import { mixins } from "@logex/mixin-flavors";

import { LgSimpleChanges } from "@logex/framework/types";
import { dropdownFlat } from "@logex/framework/utilities";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { LgConsole } from "@logex/framework/core";
import { NgOnDestroyMixin } from "@logex/mixins";
import { PageReferencesService } from "../../services/page-references/page-references.service";

import { Constructor, PageWidgetConfigBase, WidgetComponent, WidgetState } from "../../types";
import {
    FlexibleLayoutConfiguration,
    FlexibleLayoutState,
    IconsTemplateRegistration,
    PageContext
} from "./flexible-layout.types";
import { extractPanelIds } from "../../utilities";
import { WidgetTypesRegistry } from "../../services/widget-types-registry/widget-types-registry.service";
import { FlexibleLayoutUpgraderService } from "../../services/flexible-layout-upgrader";
import { FlexibleLayoutWidgetHostChangeEvent } from "./flexible-layout-widget-host.directive";
import { ModReferenceVariablePipe } from "../../pipes/mod-reference-variable.pipe";

// ----------------------------------------------------------------------------------
interface Panel {
    id: string;
    icons?: TemplateRef<any>;
    widgets: PanelWidget[];
    selected: PanelWidget;
    selectorConfig: IDropdownDefinition<string> | null;
    isVisible: boolean;
}

interface PanelWidget {
    id: string;
    type: Constructor<any> | undefined;
    providers: StaticProvider[] | undefined;
    config: PageWidgetConfigBase;
    title: string;
    panel: string;
    typeName: string;
    deprecated: boolean;
    deprecatedReplacementId: string | undefined;
    autoConverted: boolean;
    nameLc: string | undefined;
}

export interface WidgetInfo {
    id: string;
    config?: PageWidgetConfigBase;
    instance: WidgetComponent;
}

// ----------------------------------------------------------------------------------
export interface FlexibleLayoutComponent extends NgOnDestroyMixin {}

@Component({
    standalone: false,
    selector: "lgflex-flexible-layout",
    templateUrl: "./flexible-layout.component.html",
    host: {
        class: "flex-flexible flexcol  flexcol--full"
    }
})
@mixins(NgOnDestroyMixin)
export class FlexibleLayoutComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
    constructor(
        private _widgetTypes: WidgetTypesRegistry,
        private _upgrader: FlexibleLayoutUpgraderService,
        protected _lgConsole: LgConsole,
        private _pageReferences: PageReferencesService,
        private _referenceVariablePipe: ModReferenceVariablePipe
    ) {
        this._initMixins();
    }

    // ----------------------------------------------------------------------------------
    @Input() configuration: FlexibleLayoutConfiguration | null = null;
    @Input() layoutState: FlexibleLayoutState | null | undefined = null;
    @Input() context: PageContext | null = null;
    @Output() readonly widgetsChange = new EventEmitter<WidgetInfo[]>(true);

    // ----------------------------------------------------------------------------------
    _panels: Panel[] = [];
    _widgetStates: Record<string, WidgetState> = {};

    private _widgets: Map<string, PanelWidget> | undefined;
    private _widgetInstances: Map<string, WidgetComponent> | undefined;
    private _iconsTemplates: Record<string, TemplateRef<any>> = {};
    private _configurationUpdated = false;

    _showReferenceSlotsError = false;

    // ----------------------------------------------------------------------------------
    ngOnInit(): void {
        // Empty
    }

    ngOnChanges(changes: LgSimpleChanges<FlexibleLayoutComponent>): void {
        // if ( this._widgetTemplates == null ) {
        //     // This is the first run, we don't know the templates yet
        //     return;
        // }

        if (changes.configuration) {
            this._updateConfiguration();
        }
    }

    ngAfterViewInit(): void {
        this._updateConfiguration();
    }

    // ----------------------------------------------------------------------------------
    // registerWidgetTemplate(
    //     widgetType: string,
    //     headerTemplate: TemplateRef<any>,
    //     iconsTemplate: TemplateRef<any>,
    //     bodyTemplate: TemplateRef<any>
    // ): WidgetTemplateRegistration {
    //     if ( this._widgetTemplates == null ) {
    //         this._widgetTemplates = {};
    //     }
    //
    //     const widgetTemplates = {
    //         body: bodyTemplate,
    //         icons: iconsTemplate,
    //         header: headerTemplate,
    //     };
    //     this._widgetTemplates[widgetType] = widgetTemplates;
    //
    //     return {
    //         update: ( newType: string ) => {
    //             delete this._widgetTemplates[widgetType];
    //             this._widgetTemplates[newType] = widgetTemplates;
    //             this._updateConfiguration();
    //         },
    //
    //         remove: () => {
    //             delete this._widgetTemplates[widgetType];
    //             // this._processPanelsConfiguration();
    //         },
    //     };
    // }

    registerIconsTemplate(panel: string, template: TemplateRef<any>): IconsTemplateRegistration {
        this._iconsTemplates[panel] = template;

        return {
            update: (newPanel: string) => {
                delete this._iconsTemplates[panel];
                this._iconsTemplates[newPanel] = template;
                this._updateConfiguration();
            },

            remove: () => {
                delete this._iconsTemplates[panel];
                // this._processPanelsConfiguration();
            }
        };
    }

    private _updateConfiguration(): void {
        this._configurationUpdated = false;

        requestAnimationFrame(() => {
            if (this._configurationUpdated) return;

            this._showReferenceSlotsError = false;

            if (this.configuration !== null) {
                if (this._pageReferences.isAllowed() && this.configuration.referenceSlots == null) {
                    this.configuration = null;
                    this._showReferenceSlotsError = true;
                    return;
                }

                const converted = _.cloneDeep(this.configuration);
                this._upgrader.migrateVersions(converted.widgets);
                // Deactivate selected widgets
                // _.each( this._panels, x => x.selected.widget.deactivate() );

                // Get new panels config
                this._panels = this._getPanelsConfiguration(converted);

                // Store map of all widgets
                const widgets = new Map<string, PanelWidget>();
                for (const panel of this._panels) {
                    for (const widget of panel.widgets) {
                        widgets.set(widget.id, widget);
                    }
                }
                this._widgets = widgets;

                // Activate selected widgets
                // _.each( this._panels, x => x.selected.widget.activate() );
            } else {
                this._panels = [];
            }

            this._widgetStates = {};
            this._widgetInstances = new Map<string, WidgetComponent>();
            this.widgetsChange.emit([]);

            if (this.layoutState) {
                try {
                    this.setState(this.layoutState);
                } catch (e) {
                    console.error(e);
                }
            }

            this._configurationUpdated = true;
        });
    }

    private _getPanelsConfiguration(configuration: FlexibleLayoutConfiguration): Panel[] {
        // if ( this._widgetTemplates == null ) {
        //     throw Error( "Widget templates are not defined" );
        // }

        const widgetsByPanel = _.groupBy(configuration.widgets, "panel");

        const panelIds = [...extractPanelIds(configuration.layout)];

        return panelIds
            .map((id: string, i: number) => {
                const widgets = widgetsByPanel[id];

                if (!widgets) return null;

                // Panel icons that do not depend on widgets
                let icons = this._iconsTemplates[id];

                // Try to find default icon - should be displayed in the first panel
                if (icons === undefined && i === 0) {
                    icons = this._iconsTemplates["null"];
                }

                // Panel widgets
                const panelWidgets: PanelWidget[] = _.map(widgets, (x): PanelWidget => {
                    let widgetType = this._widgetTypes.tryGet(x.type);
                    let autoConverted = false;

                    if (!widgetType) {
                        const replacement = this._upgrader.findBestAutomaticReplacement(x.type);
                        if (replacement) {
                            const newConfig = this._upgrader.replaceWidget(x, replacement);
                            if (typeof newConfig === "string") {
                                this._lgConsole.warn("Automatic conversion failed ", newConfig);
                            } else {
                                x.config = newConfig;
                                x.type = replacement;
                                widgetType = this._widgetTypes.tryGet(x.type);
                                autoConverted = true;
                            }
                        }
                    }

                    return {
                        id: x.id,
                        type: widgetType?.type,
                        providers: widgetType?.providers,
                        config: x.config,
                        title: x.config.title ?? x.id,
                        panel: x.panel,
                        typeName: x.type,
                        deprecated: widgetType?.deprecated ?? false,
                        deprecatedReplacementId: widgetType?.deprecatedReplacementId
                            ? this._widgetTypes.tryGet(widgetType.deprecatedReplacementId)?.nameLc
                            : undefined,
                        autoConverted,
                        nameLc: widgetType?.nameLc
                    };
                });

                // Remember last selected widget
                let selected = _.first(panelWidgets)!;
                const oldPanel = this._panels.find(panel => panel.id === id);
                if (oldPanel !== undefined) {
                    const selectedWidget = panelWidgets.find(
                        widget => widget.id === oldPanel.selected.id
                    );
                    if (selectedWidget) {
                        selected = selectedWidget;
                    }
                }

                const panel: Panel = {
                    id,
                    icons,
                    widgets: panelWidgets,
                    selected,
                    selectorConfig:
                        panelWidgets.length > 1
                            ? dropdownFlat({
                                  entryId: "id",
                                  entryName: "title",
                                  entries: [
                                      ..._.map(panelWidgets, x => ({
                                          id: x.id,
                                          title: this._referenceVariablePipe.transform(x.title)
                                      }))
                                  ]
                              })
                            : null,
                    isVisible: true
                };
                return panel;
            })
            .filter((x): x is Panel => x != null);
    }

    _setPanelWidget(panel: Panel, widgetId: string): void {
        const widget = _.find(panel.widgets, { id: widgetId });

        if (widget === undefined) {
            throw Error(`Widget "${widgetId}" is not found in the panel`);
        }

        if (this._widgetInstances !== undefined) {
            const instance = this._widgetInstances.get(panel.selected.id);
            if (instance?.getState) {
                const state = instance.getState();
                this._widgetStates[panel.selected.id] = state;
            }
        }

        // if ( panel.selected != null ) {
        //     panel.selected.widget.deactivate();
        // }

        panel.selected = widget;
        // panel.selected.widget.activate();
    }

    _onWidgetInstanceChange({ id, instance }: FlexibleLayoutWidgetHostChangeEvent<unknown>): void {
        // todo: remove? Shouldn't happen
        if (this._widgetInstances === undefined) {
            this._lgConsole.error("_widgetInstances collection is empty");
            return;
        }

        if (instance != null) {
            this._widgetInstances.set(id, instance);
        } else {
            this._widgetInstances.delete(id);
        }

        // Notify on widgets change
        const res: WidgetInfo[] = [];
        this._widgetInstances.forEach((value, key) => {
            const pw = this._widgets!.get(key);
            res.push({
                id: key,
                config: pw?.config,
                instance: value
            });
        });

        this.widgetsChange.emit(res);
    }

    _onPanelVisibilityChange(isVisible: boolean, panelId: string) {
        queueMicrotask(() => {
            const panel = this._panels.find(panel => panel.id === panelId);
            if (panel !== undefined) {
                panel.isVisible = isVisible;
            }
        });
    }

    getState(): FlexibleLayoutState | null {
        if (!this._panels.length || !this._widgetInstances) return null;

        const selectedWidgets: Record<string, string> = {};
        const widgetTypes: Record<string, string | undefined> = {};
        this._panels.forEach(panel => {
            selectedWidgets[panel.id] = panel.selected.id;
            const instance = this._widgetInstances!.get(panel.selected.id);
            const state = instance?.getState?.();
            if (state) this._widgetStates[panel.selected.id] = state;
        });

        if (this._widgets !== undefined) {
            const widgets = this._widgets;
            Object.keys(this._widgetStates).forEach(
                id => (widgetTypes[id] = widgets.get(id)?.typeName)
            );
        } else {
            throw Error("Widgets shouldn't be undefined.");
        }

        return {
            version: 1,
            widgetStates: _.cloneDeep(this._widgetStates),
            selectedWidgets,
            widgetTypes
        };
    }

    setState(state: FlexibleLayoutState | null): boolean {
        if (!this._widgets) return false;
        if (!state) return true;
        if (state.version !== 1) return false; // handle if needed
        this._widgetStates = {};
        Object.entries(state.widgetStates).forEach(([id, widgetState]) => {
            const widget = this._widgets!.get(id);
            if (!widget || widget.typeName !== state.widgetTypes[id]) {
                return;
            }
            this._widgetStates[id] = widgetState;
        });
        this._panels.forEach(panel => {
            const selectId = state.selectedWidgets[panel.id];
            if (!selectId) return;
            const target = _.find(panel.widgets, { id: selectId });
            if (!target) return;
            if (panel.selected !== target) {
                this._setPanelWidget(panel, selectId);
            } else {
                const instance = this._widgetInstances!.get(selectId);
                const state = this._widgetStates[selectId];
                if (instance && state) {
                    instance.setState?.(state);
                }
            }
        });
        return true;
    }

    ngOnDestroy(): void {
        // empty
    }
}
