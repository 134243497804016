import { Component, Input, OnDestroy, OnInit, inject } from "@angular/core";
import { Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { LgNavigationService, ProcessedNavNode } from "@logex/framework/lg-application";
import { LgPromptDialog } from "@logex/framework/ui-core";

@Component({
    selector: "lg-configuration-layout-navigation",
    templateUrl: "./lg-configuration-layout-navigation.component.html",
    standalone: false,
    providers: [useTranslationNamespace("FADP._SettingsConfigLayout")]
})
export class LgConfigurationLayoutNavigationComponent implements OnInit, OnDestroy {
    private _destroyed$ = new Subject<void>();
    private _promptDialog = inject(LgPromptDialog);
    private _lgTranslate = inject(LgTranslateService);

    protected _navigationService = inject(LgNavigationService);
    protected _router = inject(Router);
    protected _navigationMenuTitle = "";
    protected _currentNodeId = "";
    protected _menu: ProcessedNavNode[] | undefined = undefined;

    @Input({ required: true }) rootNavigationNodeId: string;
    @Input({ required: true }) unsavedChangesCount: number;

    ngOnInit(): void {
        this._navigationService
            .getNavigation$(this.rootNavigationNodeId)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(menu => {
                this._menu = menu;
            });
        this._navigationMenuTitle = this._navigationService.getNodeByIdSync(
            this.rootNavigationNodeId
        ).name;
        this._currentNodeId = this._navigationService.getCurrentNodeSync().id;
    }

    protected async _navigate(navNode: ProcessedNavNode): Promise<void> {
        if (navNode.id == null) return;
        if (this.unsavedChangesCount > 0) {
            const response = await this._promptDialog.confirm(
                this._lgTranslate.translate(".ConfirmDiscard.Title"),
                this._lgTranslate.translate(".ConfirmDiscard.Body"),
                {
                    buttons: [
                        {
                            id: "leave",
                            name: this._lgTranslate.translate(".ConfirmDiscard.Leave"),
                            isConfirmAction: true
                        },
                        {
                            id: "cancel",
                            name: this._lgTranslate.translate("FW.CANCEL"),
                            isCancelAction: true
                        }
                    ]
                }
            );
            if (response === "cancel") {
                return;
            }
        }
        await this._navigationService.navigateTo(navNode);
        this._currentNodeId = navNode.id;
    }

    protected _isCurrent(navNode: ProcessedNavNode): boolean {
        return this._currentNodeId === navNode.id;
    }

    ngOnDestroy(): void {
        this._destroyed$.next();
        this._destroyed$.complete();
    }
}
