import { Component, EventEmitter, Input, Output } from "@angular/core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import {
    FORMAT_PRECISION_MAX,
    FORMAT_PRECISION_MIN,
    PivotTableColumnFormulaFormatting
} from "../../../types";
import { dropdownFlat } from "@logex/framework/utilities";

@Component({
    standalone: false,
    selector: "lgflex-configuration-dialog-format-and-precision",
    templateUrl: "./configuration-dialog-format-and-precision.component.html",
    styleUrls: ["./configuration-dialog-format-and-precision.component.scss"],
    providers: [...useTranslationNamespace("_Flexible.ConfigurationDialogFormatAndPrecision")]
})
export class ConfigurationDialogFormatAndPrecisionComponent {
    constructor(public _lgTranslate: LgTranslateService) {}

    @Input() horizontalLayout = false;

    @Input() formatType!: PivotTableColumnFormulaFormatting;
    @Output() readonly formatTypeChange = new EventEmitter<PivotTableColumnFormulaFormatting>();

    @Input() formatPrecision!: number;
    @Output() readonly formatPrecisionChange = new EventEmitter<number>();

    @Input() formatForceSign: boolean | undefined;

    @Output() readonly formatForceSignChange = new EventEmitter<boolean>();

    @Input() aggregatedTotals: boolean | undefined;
    @Output() readonly aggregatedTotalsChange = new EventEmitter<boolean>();

    readonly _formatPrecisionPattern: RegExp = /^[0-9]+$/;

    readonly _formattingDropdownDef: IDropdownDefinition<PivotTableColumnFormulaFormatting> =
        dropdownFlat({
            entryId: "type",
            entryName: "name",
            entries: [
                { type: "float", name: this._lgTranslate.translate(".Number") },
                { type: "money", name: this._lgTranslate.translate(".Currency") },
                { type: "percentage", name: this._lgTranslate.translate(".Percentage") }
            ]
        });

    minFormatPrecision = FORMAT_PRECISION_MIN;
    maxFormatPrecision = FORMAT_PRECISION_MAX;
}
