import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    standalone: false,
    selector: "lgflex-dialog-grid-panel",
    templateUrl: "./panel.component.html",
    styleUrls: ["./panel.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogGridPanelComponent {}
