import { ChangeDetectionStrategy, Component, computed, inject, input, output } from "@angular/core";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { CalculationError, CalculationProgress } from "../../types";

@Component( {
    standalone: false,
    selector: "lg-stale-data-indicator",
    templateUrl: "./lg-stale-data-indicator.component.html",
    styleUrls: ["./lg-stale-data-indicator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
} )
export class StaleDataIndicatorComponent {
    private _promptDialog = inject(LgPromptDialog);
    private _lgTranslate = inject(LgTranslateService);

    // Inputs
    isStale = input<boolean>(false);
    staleTables = input<Set<string>>(new Set());
    calculationProgress = input<CalculationProgress | null>();
    calculationError = input<CalculationError[] | null>();

    // Outputs
    readonly reloadClick = output();

    // Fields
    protected _progress = computed(() => {
        const percentage = this.calculationProgress()?.percentage;
        return  Math.max(percentage !== undefined ? percentage / 100 : 0, 0.01);
    });

    protected _progressInPercent = computed(() =>
        Math.round(this.calculationProgress()?.percentage ?? 0));

    protected _path = computed(() => describeArc( 12, 12, 7, 0, this._progress() * 360 ));
    protected _pathRest = computed(() => describeArc( 12, 12, 7, this._progress() * 360, 360 ));

    protected _status = computed(() => {
        if (this.calculationError() != null) {
            this.recalculationQueued = false;
            return "error";
        }

        const isStale = this.isStale();
        const calculationProgress = this.calculationProgress();

        if (calculationProgress != null) {
            if (calculationProgress.isFinished || calculationProgress.percentage === 100) {
                this.recalculationQueued = false;
                return "done";
            } else {
                return "calculating";
            }
        }

        if (isStale) {
            return "waiting";
        } else {
            return "hidden";
        }
    });

    protected _executingSteps = computed(() => {
        const calculationProgress = this.calculationProgress();
        return calculationProgress != null ? calculationProgress.executingSteps : [];
    });

    protected _staleTables = computed(() => {
        return Array.from(this.staleTables());
    });

    protected _showCalculationError() {
        const errors = this.calculationError();

        if (errors == null || errors.length === 0) return;

        const errorMessage = errors.map(x =>
            `${this._lgTranslate.translate(x.stepName)}: ${x.errorMessage}`).join("<br>");

        this._promptDialog.alert(
            this._lgTranslate.translate("_LoadManager.CalculationErrorTitle"),
            errorMessage,
            {
                columns: 5,
                buttons: [LgPromptDialog.CLOSE_BUTTON]
            }
        );
    }

    protected recalculationQueued = false;

    protected _reload() {
        this.recalculationQueued = true;
        this.reloadClick.emit();
    }
}



function describeArc( x: number, y: number, radius: number, startAngle: number, endAngle: number ): string {
    const fullCircle = endAngle - startAngle === 360;
    const start = polarToCartesian( x, y, radius, endAngle - 0.01 );
    const end = polarToCartesian( x, y, radius, startAngle );
    const arcSweep = endAngle - startAngle <= 180 ? "0" : "1";

    const d = [
        "M", start.x, start.y,
        "A", radius, radius, 0, arcSweep, 0, end.x, end.y,
    ];
    if ( fullCircle ) d.push( "z" );

    return d.join( " " );
}

function polarToCartesian( centerX: number, centerY: number, radius: number, angleInDegrees: number ): { x: number; y: number; } {
    const angleInRadians = ( angleInDegrees - 90 ) * Math.PI / 180.0;

    return {
        x: centerX + ( radius * Math.cos( angleInRadians ) ),
        y: centerY + ( radius * Math.sin( angleInRadians ) )
    };
}
