import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    Output
} from "@angular/core";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { dropdownFlat } from "@logex/framework/utilities";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";

@Component({
    standalone: false,
    selector: "lgflex-pivot-table-columns-group-properties",
    templateUrl: "./pivot-table-columns-group-properties.component.html",
    providers: [...useTranslationNamespace("_Flexible.PivotTableConfiguration.GroupSelection")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class ColumnsGroupPropertiesComponent {
    _lgTranslate = inject(LgTranslateService);
    @Input("currentGroup") currentGroup = null;
    @Input("referenceDropdown") referenceDropdown: IDropdownDefinition<string> | undefined;
    @Output() public readonly currentGroupUpdate = new EventEmitter<any | null>();
    protected _groupTypeDropdown: IDropdownDefinition<string> = dropdownFlat({
        entryId: "columnType",
        entryName: "name",
        entries: [
            {
                columnType: "simple",
                name: this._lgTranslate.translate(".SimpleType")
            },
            {
                columnType: "referenceSlot",
                name: this._lgTranslate.translate(".ReferenceType")
            }
        ]
    });

    protected _onGroupReferenceChange(referenceTitle: string) {
        const { groups } = this.referenceDropdown;
        const [firstGroup] = groups;

        for (let i = 0; i < firstGroup.entries.length; i++) {
            const entry = firstGroup.entries[i];

            if (entry.name === referenceTitle) {
                this.currentGroup.referenceIdx = entry.reference;
            }
        }

        this.currentGroup.title = referenceTitle;
        this.currentGroupUpdate.emit(this.currentGroup);
    }

    protected _onGroupPropertyChange(value: string | number, property: any): void {
        this.currentGroup[property as string] = value;
        this.currentGroupUpdate.emit(this.currentGroup);
    }
}
