import { DestroyRef, inject, OnDestroy, Provider } from "@angular/core";
import { IStaleDataService, STALE_DATA_SERVICE, STALE_DATA_SERVICE_VERSION } from "../types/stale-data-service.interface";
import { StaleDataService } from "../stale-data-service";
import { StaleDataServiceV2 } from "../stale-data-service-v2";

export function useStaleData(): Provider {
    return {
        provide: STALE_DATA_SERVICE,
        useFactory: () => {
            const version = inject(STALE_DATA_SERVICE_VERSION);

            let instance: IStaleDataService;
            switch (version) {
                case "V1":
                    instance = new StaleDataService();
                    break;

                case "V2":
                    instance = new StaleDataServiceV2();
                    break;

                default: throw new Error("Unknown stale data service version");
            }

            // Destroy the instance when the injection scope is destroyed
            inject(DestroyRef).onDestroy(() => {
                instance.release();
            });

            return instance;
        },
    }
}
