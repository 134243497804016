import {
    Component,
    EventEmitter,
    inject,
    Input,
    Output,
    ViewChild,
    ViewChildren,
    QueryList,
    input,
    computed
} from "@angular/core";
import { LgDropdownComponent, LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { generateId } from "../../../../utilities";

import {
    AvailableValue,
    ColumnGroupOrColumnRow,
    ColumnRow,
    ColumnGroupRow,
    ColumnGroupOrColumnInitial
} from "./pivot-table-columns-groups.types";

@Component({
    standalone: false,
    selector: "lgflex-pivot-table-columns-groups",
    templateUrl: "./pivot-table-columns-groups.component.html",
    styleUrls: ["./pivot-table-columns-groups.component.scss"],
    providers: [...useTranslationNamespace("_Flexible.PivotTableGroupValuesSelector")]
})
export class PivotTableColumnsGroupsComponent {
    _promptDialog = inject(LgPromptDialog);
    _lgTranslate = inject(LgTranslateService);

    allAvailableFields = input<AvailableValue[]>();

    groupsConfig = input<ColumnGroupOrColumnInitial[] | ColumnGroupOrColumnRow[]>([]);

    @Input() hideBorder = false;
    @Input("buttonPlaceholderName") public buttonPlaceholderName =
        "pivotLevelsSelector.dropdownButtonContainer";

    @Output() public readonly valueSelectChange = new EventEmitter<any>();
    @Output() public readonly groupSelectChange = new EventEmitter<any | null>();
    @Output() public readonly groupsConfigChange = new EventEmitter<any | null>();

    @ViewChild("valuesDropdown") valuesDropdown: LgDropdownComponent<string> | null = null;
    @ViewChildren("valuesGroupLevelDropdown") valuesGroupLevelDropdown: QueryList<
        LgDropdownComponent<string>
    > | null = null;

    @ViewChildren("valuesGroupDropdown") valuesGroupDropdown: QueryList<
        LgDropdownComponent<string>
    > | null = null;

    _isValuesGroupLevelDropdownActive = false;
    _isValuesGroupDropdownActive = false;
    _isValuesDropdownActive = false;

    _currentSelection: string | null = null;

    private _currentGroup: ColumnGroupOrColumnRow = null;
    private _groupCounter = 1;
    protected _columnsAndGroups = computed<{ value: ColumnGroupOrColumnRow[] }>(() => {
        return { value: this.groupsConfig() as ColumnGroupOrColumnRow[] };
    });

    protected _valuesDropdownDefinition = computed(() => {
        return {
            entryId: "type",
            entryName: "title",
            groups: [{ entries: this._allAvailableValues() }]
        };
    });

    private _allAvailableValues = computed(() => {
        return this.allAvailableFields().map(value => ({
            ...value,
            schemaName: value.title
        }));
    });

    resetSelection(): void {
        this._currentSelection = null;
    }

    protected _showValuesDropdown(): void {
        this.valuesDropdown?.triggerSelect(
            () => null /* parameter has to be there, otherwise value must be picked*/
        );
    }

    protected _showValuesGroupLevelDropdown(groupId: string, index: number): void {
        this._currentSelection = groupId;
        const dropdown = this.valuesGroupLevelDropdown.get(index);
        dropdown.triggerSelect(
            () => null /* parameter has to be there, otherwise value must be picked*/
        );
    }

    protected _showValuesGroupDropdown(valueId: string): void {
        this._currentSelection = valueId;
        let dropdownIndex = 0;

        for (const group of this._columnsAndGroups().value) {
            if ("columnLevel" in group && group.columnLevel === "field") {
                continue;
            }

            if ("columns" in group) {
                const valueIndex = group.columns.findIndex((v: ColumnRow) => v.id === valueId);

                if (valueIndex !== -1) {
                    dropdownIndex += valueIndex;
                    break;
                }

                dropdownIndex += group.columns.length;
            }
        }

        this.valuesGroupDropdown
            .get(dropdownIndex)
            .triggerSelect(
                () => null /* parameter has to be there, otherwise value must be picked*/
            );
    }

    protected _valuesGroupsLevelDropdownActiveChange(isActive: boolean): void {
        this._isValuesGroupLevelDropdownActive = isActive;
    }

    protected _valuesGroupsDropdownActiveChange(isActive: boolean): void {
        this._isValuesGroupDropdownActive = isActive;
    }

    protected _valuesDropdownActiveChange(isActive: boolean): void {
        this._isValuesDropdownActive = isActive;
    }

    protected _copyGroup(groupId: string): void {
        this._columnsAndGroups().value.forEach(g => {
            if (g.id === groupId) {
                if ("columns" in g && g.columns) {
                    this._columnsAndGroups().value.push({
                        ...structuredClone(g),
                        columns: g.columns.map(c => ({ ...c, id: generateId() })),
                        id: generateId(),
                        title: g.title + " Copy"
                    } as ColumnGroupOrColumnRow);
                } else {
                    this._columnsAndGroups().value.push({
                        ...structuredClone(g),
                        id: generateId(),
                        title: g.title + " Copy"
                    } as ColumnGroupOrColumnRow);
                }
            }
        });
        this.groupsConfigChange.emit(this._columnsAndGroups().value);
    }

    protected _copyValue(valueId: string): void {
        this._columnsAndGroups().value.forEach(g => {
            if ("columns" in g && g.columns) {
                const valueToCopy = g.columns.find((v: ColumnRow) => v.id === valueId);

                if (valueToCopy) {
                    g.columns.push({
                        ...structuredClone(valueToCopy),
                        id: generateId(),
                        title: valueToCopy.title + " Copy"
                    } as ColumnRow);
                }
            }
        });
        this.groupsConfigChange.emit(this._columnsAndGroups().value);
    }

    protected _addGroup(): void {
        const groupRow = this._getNewGroupRow();

        if (this._currentSelection) {
            const currentValueIndex = this._columnsAndGroups().value.indexOf(this._currentGroup);

            this._columnsAndGroups().value.splice(currentValueIndex + 1, 0, groupRow);
        } else {
            this._columnsAndGroups().value.push(groupRow);
            this._currentGroup = groupRow;
        }

        this.groupsConfigChange.emit(this._columnsAndGroups().value);
    }

    protected _onValueSelect(valueField: string): void {
        if (this._columnsAndGroups.length === 0) {
            this._onValueAsGroupSelect(valueField);
        } else {
            this._addSelectedValue(valueField);
        }

        this.groupsConfigChange.emit(this._columnsAndGroups().value);
    }

    protected _onValueAsGroupSelect(valueFieldId: string): void {
        const valueConfig = this._allAvailableValues().find(value => value.type === valueFieldId);
        const newGroupRow = {
            ...structuredClone(valueConfig),
            id: generateId(),
            columnLevel: "field",
            schemaName: valueConfig?.title ?? ""
        };

        if (this._currentSelection) {
            const currentValueIndex = this._columnsAndGroups().value.indexOf(this._currentGroup);
            this._columnsAndGroups().value.splice(
                currentValueIndex + 1,
                0,
                newGroupRow as ColumnGroupOrColumnRow
            );
        } else {
            this._columnsAndGroups().value.push(newGroupRow as ColumnGroupOrColumnRow);
        }
        this.groupsConfigChange.emit(this._columnsAndGroups().value);
    }

    protected _onValueInGroupSelect(valueFieldId: string, groupId: string, valueId: string): void {
        this._columnsAndGroups().value.forEach(g => {
            if ("columns" in g && g.id === groupId && g.columns) {
                const indexBeforeValue = g.columns.findIndex((v: ColumnRow) => v.id === valueId);

                const valueConfig = this._allAvailableValues().find(
                    value => value.type === valueFieldId
                );

                const newValueRow = {
                    ...valueConfig,
                    id: generateId(),
                    schemaName: valueConfig?.title ?? ""
                };

                g.columns.splice(indexBeforeValue + 1, 0, newValueRow as ColumnRow);
            }
        });
        this.groupsConfigChange.emit(this._columnsAndGroups().value);
    }

    protected _onGroupClick(groupRow: ColumnGroupOrColumnRow) {
        this._currentSelection = groupRow.id;
        this._currentGroup = groupRow;

        if ("columns" in this._currentGroup) {
            this.groupSelectChange.emit(this._currentGroup);
        } else {
            this.valueSelectChange.emit(this._currentGroup);
        }
    }

    protected _onValueClick($event: any, value: ColumnRow, group: ColumnGroupOrColumnRow) {
        this._currentSelection = value.id;
        this._currentGroup = group;

        this.valueSelectChange.emit(value);
    }

    protected _removeValueFromGroup(group: ColumnGroupOrColumnRow, removeIdx: number): void {
        if ("columns" in group) {
            this._columnsAndGroups().value = this._columnsAndGroups().value.map(columnGroup => {
                if (columnGroup.id === group.id) {
                    columnGroup.columns.splice(removeIdx, 1);
                }
                return columnGroup;
            });

            this._currentGroup = group;
            if (group.columns.length === 0) {
                this._currentSelection = this._currentGroup.id;
                this.groupSelectChange.emit(this._currentGroup);
            } else {
                const currentColumn =
                    this._currentGroup.columns[removeIdx - 1] || this._currentGroup.columns[0];
                this._currentSelection = currentColumn.id;
                this.valueSelectChange.emit(currentColumn);
            }
        }
        this.groupsConfigChange.emit(this._columnsAndGroups().value);
    }

    protected async _removeGroup(groupId: string, removeIdx: number): Promise<void> {
        const group = this._columnsAndGroups().value.find(groupRow => groupRow.id === groupId);
        if ("columns" in group && group.columns.length > 0 && !(await this._confirmRemoveGroup())) {
            return;
        }

        // constrain growth of the counter if the last group is removed
        const groupNumber = Number(group.title.split(" ").pop());
        if (groupNumber === this._groupCounter - 1) {
            this._groupCounter--;
        }

        this._columnsAndGroups().value.splice(removeIdx, 1);

        if (this._columnsAndGroups().value.length === 0) {
            this._currentGroup = null;
            this._currentSelection = null;
            this.groupSelectChange.emit(null);
        } else if (this._currentGroup?.id === groupId) {
            this._currentGroup =
                this._columnsAndGroups().value[this._columnsAndGroups().value.length - 1];
            this._currentSelection = this._currentGroup.id;

            if ("columns" in this._currentGroup) {
                this.groupSelectChange.emit(this._currentGroup);
            } else {
                this.valueSelectChange.emit(this._currentGroup);
            }
        }

        this.groupsConfigChange.emit(this._columnsAndGroups().value);
    }

    protected _onDragDrop(event: CdkDragDrop<ColumnRow>) {
        const flatGroupsConfig = this._convertToFlat(this._columnsAndGroups().value);

        moveItemInArray(flatGroupsConfig, event.previousIndex, event.currentIndex);

        if ("columnType" in flatGroupsConfig[event.currentIndex]) {
            this._columnsAndGroups().value = flatGroupsConfig.filter(
                t => "columnType" in t || t.columnLevel === "field"
            );
        } else {
            this._afterDragDrop(flatGroupsConfig, event.currentIndex);
            this._columnsAndGroups().value = this._convertFlatToNested(flatGroupsConfig);
        }

        this.groupsConfigChange.emit(this._columnsAndGroups().value);
    }

    private _convertToFlat(groupsConfig: ColumnGroupOrColumnRow[]): ColumnGroupOrColumnRow[] {
        return groupsConfig.reduce((result, group) => {
            if ("columns" in group) {
                result.push(group);
                result.push(...group.columns);
            } else {
                result.push(group);
            }

            return result;
        }, []);
    }

    private _afterDragDrop(flatGroupsConfig: ColumnGroupOrColumnRow[], currentIndex: number) {
        const currentGroup = flatGroupsConfig[currentIndex];
        const previousGroup = flatGroupsConfig[currentIndex - 1];
        const nextGroup = flatGroupsConfig[currentIndex + 1];

        if (
            (currentGroup as ColumnRow).columnLevel !== "field" &&
            previousGroup &&
            (previousGroup as ColumnRow).columnLevel !== "field" &&
            nextGroup &&
            ((nextGroup as ColumnRow).columnLevel === "field" ||
                (nextGroup as ColumnGroupRow).columns)
        ) {
            return;
        }

        if (currentIndex === 0 && !("columnType" in currentGroup)) {
            (currentGroup as ColumnRow).columnLevel = "field";
        }

        if (previousGroup) {
            if ("columnLevel" in previousGroup && previousGroup.columnLevel === "field") {
                (currentGroup as ColumnRow).columnLevel = "field";
            }

            if ("columns" in previousGroup && previousGroup.columns.length === 0) {
                (currentGroup as ColumnRow).columnLevel = "group";
            }

            if (!nextGroup) return;

            if (
                (nextGroup as ColumnGroupRow).columns ||
                (nextGroup as ColumnRow).columnLevel === "field"
            )
                return;

            if (
                ("columnType" in previousGroup || previousGroup.columnLevel !== "field") &&
                !("columnType" in currentGroup)
            ) {
                currentGroup.columnLevel = "group";
            }
        }
    }

    private _convertFlatToNested(flatGroupsConfig: ColumnGroupOrColumnRow[]) {
        let group: ColumnGroupRow;
        return flatGroupsConfig.reduce((result, t, index) => {
            if ("columnLevel" in t && t.columnLevel === "field") {
                result.push(t);
                return result;
            }
            if ("columnType" in t && t.columnType) {
                group = {
                    ...t,
                    columns: []
                };
                result.push(group);
            }
            if (!("columnType" in t)) {
                group.columns.push(t);
            }

            return result;
        }, []);
    }

    private async _confirmRemoveGroup(): Promise<boolean> {
        const dialogResult = await this._promptDialog.confirm(
            this._lgTranslate.translate(".RemoveTableDialogTitle"),
            this._lgTranslate.translate(".RemoveTableDialogBody"),
            {
                buttons: [
                    {
                        id: "delete",
                        name: this._lgTranslate.translate(".RemoveTableDialogDelete"),
                        class: "button--primary",
                        isConfirmAction: true
                    },
                    {
                        id: "cancel",
                        name: this._lgTranslate.translate(".RemoveTableDialogCancel"),
                        isCancelAction: true
                    }
                ],
                columns: 2
            }
        );

        return dialogResult === "delete";
    }

    private _getNewGroupRow(): ColumnGroupRow {
        return {
            id: generateId(),
            columnType: "simple",
            title: `Group  ${this._groupCounter++}`,
            columns: []
        };
    }

    private _addSelectedValue(valueFieldId: string) {
        const currentGroup =
            this._currentGroup ??
            this._columnsAndGroups().value[this._columnsAndGroups().value.length - 1];
        if (currentGroup && "columns" in currentGroup) {
            const valueConfig = this._allAvailableValues().find(
                value => value.type === valueFieldId
            );

            currentGroup.columns.push({
                ...valueConfig,
                id: generateId(),
                schemaName: valueConfig?.title ?? ""
            } as ColumnRow);
        } else {
            this._onValueAsGroupSelect(valueFieldId);
        }
    }
}
