import * as _ from "lodash-es";
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    Output
} from "@angular/core";
import { Overlay, ScrollDispatcher } from "@angular/cdk/overlay";
import { mixins } from "@logex/mixin-flavors";

import { LgOverlayService } from "@logex/framework/ui-core";
import { LgSimpleChanges } from "@logex/framework/types";
import { NgOnDestroyMixin, PopupHostMixin } from "@logex/mixins";

import { FieldInfo, PivotTableColumnDifference, PivotTableColumnDifferenceMode } from "../../types";
import { FlexiblePivotDifferenceColumnHeaderPopupComponent } from "./difference-column-header-popup.component";
import { ReferenceInSlot } from "../../services/page-references/page-references.types";
import { DifferenceColumnConfiguration, PopupResult } from "./difference-column-header.types";

export interface FlexiblePivotDifferenceColumnHeaderComponent
    extends NgOnDestroyMixin,
        PopupHostMixin {}

@Component({
    standalone: false,
    selector: "lgflex-flexpivot-difference-column-header",
    templateUrl: "./difference-column-header.component.html",
    host: {
        class: "flexpivot-difference-column-header lg-comparison-source-menu lg-multilevel-reference"
    }
    // changeDetection: ChangeDetectionStrategy.OnPush,
    // viewProviders: [useTranslationNamespace( "FW._Dialogs._ComparisonSource" )]
})
@mixins(PopupHostMixin, NgOnDestroyMixin)
export class FlexiblePivotDifferenceColumnHeaderComponent implements OnDestroy, OnChanges {
    constructor(
        public _elementRef: ElementRef,
        public _changeDetectorRef: ChangeDetectorRef,
        public _overlay: Overlay,
        public _scrollDispatcher: ScrollDispatcher,
        public _overlayService: LgOverlayService // private _translateService: LgTranslateService
    ) {
        this._initMixins();
    }

    @Input() fields: FieldInfo[] = [];
    @Input() selectedReferences: ReferenceInSlot[] = [];
    @Input() column!: PivotTableColumnDifference;
    @Input() title: string | undefined;
    @Input() formula: string | undefined;
    @Output() readonly configurationChange = new EventEmitter<
        Partial<DifferenceColumnConfiguration>
    >();

    _symbolIconClass = "";
    _popupIsActive = false;

    ngOnChanges(changes: LgSimpleChanges<FlexiblePivotDifferenceColumnHeaderComponent>): void {
        if (changes.column) {
            if (this.column == null) throw Error("Column shouldn't be undefined.");
            this._symbolIconClass = this._getClassForSymbol(this.column.mode);
        }
    }

    private _getClassForSymbol(mode: PivotTableColumnDifferenceMode): string {
        switch (mode) {
            case "growth":
                return "icon-relativefigures";

            case "diff":
                return "icon-absolutefigures";

            default:
                return "";
        }
    }

    _isFormulaEmpty(): boolean {
        return this.formula == null || this.formula.length === 0;
    }

    _onClick(event: MouseEvent): void {
        event.stopImmediatePropagation();
        event.preventDefault();

        this._showMenuPopup();
        this._popupIsActive = true;
    }

    private async _showMenuPopup(): Promise<void> {
        const result = await this._doShowPopup<
            FlexiblePivotDifferenceColumnHeaderPopupComponent,
            PopupResult
        >({
            componentType: FlexiblePivotDifferenceColumnHeaderPopupComponent,

            anchorElementRef: this._elementRef,
            anchorPositions: [
                { originX: "end", originY: "bottom", overlayX: "end", overlayY: "top" },
                { originX: "end", originY: "top", overlayX: "end", overlayY: "bottom" },
                { originX: "start", originY: "bottom", overlayX: "start", overlayY: "top" },
                { originX: "start", originY: "top", overlayX: "start", overlayY: "bottom" }
            ],

            initPopupComponent: instance => {
                if (
                    !_.isNumber(this.column.referenceLeft) ||
                    !_.isNumber(this.column.referenceRight)
                ) {
                    throw Error("Left and right reference indices must be specified");
                }

                const fields = _.filter(this.fields, x => x.isValueField && x.isReferenceBound);

                return instance.init({
                    fields,
                    selectedReferences: this.selectedReferences,
                    mode: this.column.mode,
                    field: this.column.field,
                    referenceLeft: this.column.referenceLeft,
                    referenceRight: this.column.referenceRight,
                    showOkButton: false
                });
            }
        });

        if (result.type === "submit") {
            this.configurationChange.emit(_.omit(result, "type"));
        }

        this._popupIsActive = false;
    }

    ngOnDestroy(): void {
        this._doClosePopup(true);
    }
}
