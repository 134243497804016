import {
    ChangeDetectionStrategy,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output
} from "@angular/core";
import { IDropdownDefinition } from "@logex/framework/ui-core";
import { ExportFormat } from "../../types";
import { dropdownFlat } from "@logex/framework/utilities";
import { Observable } from "rxjs";
import { LG_FLEX_PAGE_AUTHORIZATION } from "../../services/flexible-page-authorization/flexible-page-authorization.types";
import { FlexibleAnalyticsService } from "../../services/flexible-analytics/flexible-analytics.service";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Component({
    standalone: false,
    selector: "lgflex-widget-common-icons",
    templateUrl: "./widget-common-icons.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetCommonIconsComponent implements OnInit {
    private _flexibleAnalyticService = inject(FlexibleAnalyticsService);
    private _flexPageAuthorization = inject(LG_FLEX_PAGE_AUTHORIZATION);
    private _lgTranslate = inject(LgTranslateService);

    @Input() exportFormats: ExportFormat[] = [
        ExportFormat.XLSX,
        ExportFormat.SVG,
        ExportFormat.PNG
    ];

    @Input() exportDisabled = false;
    @Input() isLoading$: Observable<boolean> | undefined;
    @Input() isAutoConverted = false;
    @Input() isConfigValid = false;

    @Output() readonly export = new EventEmitter<ExportFormat>();
    @Output() readonly edit = new EventEmitter();

    protected _userCanEdit = this._flexPageAuthorization.canEditWidget();
    protected _userCanExport = this._flexPageAuthorization.canExportWidget();

    protected _exportDropdownDefinition: IDropdownDefinition<ExportFormat> | undefined;

    ngOnInit(): void {
        this._exportDropdownDefinition = dropdownFlat({
            entryId: "id",
            entryName: "name",
            entries: this.exportFormats.map(option => ({
                id: option,
                name: this._getExportName(option)
            }))
        });
    }

    private _getExportName(exportFormat: ExportFormat): string {
        if (exportFormat === ExportFormat.SERVER) {
            return this._lgTranslate.translate("_Flexible.Widgets.ServerSideExport");
        }

        return exportFormat.toString();
    }
}
