/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, input, InputSignal, output } from "@angular/core";
import {
    FdpColumnHeader,
    FdpDifferenceColumnChangeEvent,
    FdpLevel,
    ReferenceInfo
} from "../../../types";
import { IDropdownDefinition, LgColDefinitionComponent } from "@logex/framework/ui-core";
import { LgPivotTableBodyComponent } from "@logex/framework/lg-pivot-table";

@Component({
    standalone: false,
    selector: "lgflex-pivot-header-main-level",
    templateUrl: "./pivot-header-main-level.html"
})
export class PivotHeaderMainLevelComponent {
    level = input<FdpLevel>();
    pivotBody = input<LgPivotTableBodyComponent>();
    colDefinition = input<LgColDefinitionComponent>();
    referencesDropdown = input<IDropdownDefinition<string> | undefined>();
    widgetReferences = input<ReferenceInfo[]>();
    selectedReferences = input<ReferenceInfo[]>();
    columnSortBy = input<string>();
    headerRow: InputSignal<FdpColumnHeader[]> = input([]);
    pivotWrapperWidth = input(0);

    // --------------------------------------------------
    readonly onOrderByChange = output<string>();
    readonly onReferenceChange = output<{
        slotIdx: number;
        value: string;
        columnGroupId: string;
    }>();

    readonly onDifferenceColumnChange = output<FdpDifferenceColumnChangeEvent>();
    readonly onLevelsOrderByChange = output<any>();

    _onReferenceChange(reference: { slotIdx: number; value: string }, columnGroupId: string) {
        this.onReferenceChange.emit({ ...reference, columnGroupId });
    }
}
