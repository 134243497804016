import { inject, Injectable, Injector } from "@angular/core";
import { IFadpModuleInitializer } from "../types";
import { ExportsNotificationsService } from "./services/exports-notifications.service";

@Injectable()
export class ExportModuleInitializer implements IFadpModuleInitializer {
    protected _injector = inject(Injector);

    initialize() {
        this._injector.get(ExportsNotificationsService); // Must be injected anywhere to be initialized
    }
}
