import { PivotTableWidgetConfig } from "../pivot-table-widget.configuration.types";

export const migrateVersion9To10 = (config: PivotTableWidgetConfig) => {
    config.columns = config.columns.map(column => {
        if (!("columns" in column) && column.type === "formula") {
            if (column.variables.a != null && column.variables.a.type == null) {
                column.variables.a.type = "variable";
            }
            if (column.variables.b != null && column.variables.b.type == null) {
                column.variables.b.type = "variable";
            }
            if (column.variables.c != null && column.variables.c.type == null) {
                column.variables.c.type = "variable";
            }
        }

        return column;
    });
    return 10;
};
