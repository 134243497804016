import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    ViewChild
} from "@angular/core";
import { LgSimpleChanges } from "@logex/framework/types";

@Component({
    standalone: false,
    selector: "lgflex-single-value-chart",
    templateUrl: "./single-value-chart.component.html",
    styleUrls: ["./single-value-chart.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class SingleValueChartComponent implements OnChanges {
    @Input() value: number | undefined;
    @Input() limit: number | undefined;
    @Input() textSpace: number | undefined;
    @Input() formatFn: ((value: number | undefined) => string) | undefined;

    @ViewChild("axis") axis: ElementRef | undefined;

    protected _axisValueLeft = "";
    protected _axisValueWidth = "";

    protected _getFormattedValue(): string {
        return this.formatFn !== undefined
            ? this.formatFn(this.value)
            : (this.value?.toString() ?? "-");
    }

    ngOnChanges(changes: LgSimpleChanges<SingleValueChartComponent>): void {
        if (changes.value || changes.limit) {
            this.calcAxisDimensions();
        }
    }

    protected calcAxisDimensions(): void {
        if (this.value === undefined || !isFinite(this.value) || isNaN(this.value)) return;

        const limit = this.limit !== undefined && this.limit > 0 ? this.limit : this.value;
        let value = Math.abs(this.value);
        if (value > limit) {
            value = limit;
        }

        const axisValue = `${value / limit} * 50%`;
        this._axisValueWidth = `calc(${axisValue})`;

        if (this.value > 0) {
            this._axisValueLeft = "50%";
        } else {
            this._axisValueLeft = `calc(50% - ${axisValue})`;
        }
    }
}
