/* eslint-disable @angular-eslint/no-output-on-prefix */
import * as _ from "lodash-es";
import {
    Component,
    Input,
    ViewEncapsulation,
    computed,
    input,
    inject,
    output
} from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { generateId, translateNullableName } from "../../utilities";

export interface LevelLegacy {
    field: string;
    name: string | null;
    nameLc: string | null;
    isBlocked?: boolean;
    allowDuplicates?: boolean;
}

@Component({
    standalone: false,
    selector: "lgflex-pivot-levels-selector-legacy",
    templateUrl: "./lg-pivot-levels-selector-legacy.component.html",
    styleUrls: ["./lg-pivot-levels-selector-legacy.component.scss"],
    encapsulation: ViewEncapsulation.Emulated
})
export class LgPivotLevelsSelectorLegacyComponent {
    readonly _lgTranslate = inject(LgTranslateService);
    levels = input<LevelLegacy[]>([]);
    selectedLevelIds = input<string[]>([]);
    readonly onSelectedLevelIdsChange = output<string[]>();

    @Input("maxLevels") public maxLevels = Infinity;
    @Input("minRowNum") public minRowNum = 1;

    // ----------------------------------------------------------------------------------
    //
    _levelsDropdowns = computed(() => {
        const levels: Array<string | null> = _.clone(this.selectedLevelIds());

        if (levels.length < this.maxLevels) {
            levels.push(null);
        }

        return levels.map(() => ({
            id: generateId(),
            entryId: "id",
            entryName: "name",
            groups: [
                {
                    entries: [
                        {
                            id: null,
                            name: "-",
                            disabled: false
                        },
                        ...this.levels().map(x => ({
                            id: x.field,
                            name: translateNullableName(this._lgTranslate, x.name, x.nameLc),
                            disabled: x.isBlocked
                        }))
                    ]
                }
            ]
        }));
    });

    protected _levelsDict = computed(() => _.keyBy(this.levels(), x => x.field));

    // ----------------------------------------------------------------------------------
    //
    _levelText(level: number): string | null {
        return level === this.selectedLevelIds().length
            ? this._lgTranslate.translate("_Flexible.LgPivotLevelsSelectorLegacy.SelectLevel")
            : null;
    }

    _onValueChange(level: number, value: string): void {
        const levels: Array<string | null> = _.clone(this.selectedLevelIds());
        // Remove existing level if any
        if (this._levelsDict() != null && !this._levelsDict()[value]?.allowDuplicates) {
            const alreadyExistingIdx = levels.indexOf(value);
            if (alreadyExistingIdx >= 0) {
                levels.splice(alreadyExistingIdx, 1);
                if (alreadyExistingIdx < level) {
                    level--;
                }
            }
        }

        if (value != null) {
            levels[level] = value;
        } else {
            levels.splice(level, 1);
        }

        this.onSelectedLevelIdsChange.emit(levels);
    }
}
