import { Directive, effect, ElementRef, input, Input, OnChanges, OnDestroy } from "@angular/core";

@Directive({
    standalone: false,
    selector: "lg-stale-data-handler"
})
export class StaleDataHandlerDirective implements OnDestroy {
    isCalculating = input<boolean>(false);

    constructor(private _elRef: ElementRef) {
        effect(() => {
            const isCalculating = this.isCalculating();

            const bodyEl = this._elRef.nativeElement.closest(".lg-panel__body");
            if (bodyEl == null) return;

            if (isCalculating) {
                bodyEl.classList.add("stale-data-container");
            } else {
                bodyEl.classList.remove("stale-data-container");
            }
        });
    }

    ngOnDestroy(): void {
        const bodyEl = this._elRef.nativeElement.closest(".lg-panel__body");
        if (bodyEl == null) return;
        bodyEl.classList.remove("stale-data-container");
    }
}
