import * as _ from "lodash-es";
import { Dictionary } from "lodash";
import { Component, Inject, Injectable } from "@angular/core";
import { CdkDragDrop, copyArrayItem, moveItemInArray } from "@angular/cdk/drag-drop";
import { mixins } from "@logex/mixin-flavors";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import {
    getDialogFactoryBase,
    IDialogComponent,
    LgPromptDialog,
    LgDialogFactory,
    LgDialogRef
} from "@logex/framework/ui-core";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";
import { DialogMixin, ModalResultDialogMixin } from "@logex/mixins";

import { FieldInfo } from "../../types";
import { translateNullableName } from "../../utilities";

export interface PivotLevelsSelectorDialogArguments {
    availableFields: FieldInfo[];
    isReadonly?: boolean;
    selectedFieldIds: string[];
}

export interface PivotLevelsSelectorDialogResponse {
    selectedFieldIds: string[];
}

export interface PivotLevelsSelectorDialogComponent
    extends DialogMixin<PivotLevelsSelectorDialogComponent>,
        ModalResultDialogMixin<
            PivotLevelsSelectorDialogArguments,
            PivotLevelsSelectorDialogResponse
        > {}

@Component({
    standalone: false,
    selector: "lgflex-pivot-levels-selector-dialog",
    templateUrl: "./pivot-levels-selector-dialog.component.html",
    styleUrls: ["./pivot-levels-selector-dialog.scss"],
    providers: [...useTranslationNamespace("_Flexible._PivotLevelsSelectorDialog")]
})
@mixins(DialogMixin, ModalResultDialogMixin)
export class PivotLevelsSelectorDialogComponent
    implements
        IDialogComponent<PivotLevelsSelectorDialogComponent, PivotLevelsSelectorDialogResponse>
{
    constructor(
        @Inject(LG_APP_DEFINITIONS) public _definitions: IDefinitions<any>,
        public _lgTranslate: LgTranslateService,
        public _dialogRef: LgDialogRef<PivotLevelsSelectorDialogComponent>,
        public _promptDialog: LgPromptDialog
    ) {
        this._initMixins();
    }

    _dialogClass = "lg-dialog lg-dialog--4col";
    _title = this._lgTranslate.translate(".DialogTitle");

    _maxLevels = 5;

    _availableLevelsDict: Dictionary<Partial<FieldInfo>> = {};
    _availableLevels: string[] = [];
    _selectedLevels: string[] = [];

    _draggingToHierarchy = false;

    // ----------------------------------------------------------------------------------
    //
    _activate(): Promise<void> {
        this._availableLevels = this._args.availableFields.map(x => x.field);
        this._selectedLevels = [...this._args.selectedFieldIds];
        this._availableLevelsDict = _.keyBy(this._args.availableFields, x => x.field);

        // TODO: Fix the mixin
        return null;
    }

    // ----------------------------------------------------------------------------------
    //
    _renderItemName(itemId: string): string {
        const item = this._availableLevelsDict[itemId]!;
        return translateNullableName(this._lgTranslate, item.name, item.nameLc);
    }

    _isSelected(itemId: string): boolean {
        return _.includes(this._selectedLevels, itemId);
    }

    // ----------------------------------------------------------------------------------
    //
    _drop(event: CdkDragDrop<string[]>): void {
        if (event.previousContainer === event.container) {
            moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
        } else {
            const prevValue = event.previousContainer.data[event.previousIndex];
            if (!event.container.data.includes(prevValue)) {
                copyArrayItem(
                    event.previousContainer.data,
                    event.container.data,
                    event.previousIndex,
                    event.currentIndex
                );
            } else {
                _.pull(event.previousContainer.data, prevValue);
            }
        }
    }

    _isFullHierarchySelected(): boolean {
        return false;
    }

    _isMaximumReached(): boolean {
        return this._selectedLevels.length >= this._maxLevels;
    }

    _getNewArray(size: number): any[] {
        return new Array(size);
    }

    _onCheckboxClick(newValue: boolean, item: string): void {
        if (newValue) {
            this._selectedLevels.push(item);
        } else {
            _.pull(this._selectedLevels, item);
        }
    }

    _removeItem(item: string): void {
        _.pull(this._selectedLevels, item);
    }

    _getCounterTranslateParams(): any {
        return {
            current: this._selectedLevels.length,
            max: this._maxLevels
        };
    }

    _isValid(): boolean {
        return this._selectedLevels.length > 0;
    }

    async _save(): Promise<boolean> {
        this._resolve({
            selectedFieldIds: this._selectedLevels
        });

        return true;
    }
}

@Injectable()
export class PivotLevelsSelectorDialog extends getDialogFactoryBase(
    PivotLevelsSelectorDialogComponent,
    "show"
) {
    constructor(_factory: LgDialogFactory) {
        super(_factory);
    }
}
