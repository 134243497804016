import { LayoutVariant } from "./page-layout.types";
import { FlexibleIcons } from "./flexible-icons";

export const MAX_LIMIT_ROWS = 10000;

// TODO: Make this variable configurable
export const PERCENTAGE_FORMATTER_LIMIT = 150;

export const MAX_FILENAME_LENGTH = 195;
export const MAX_SHEET_NAME_LENGTH = 31;

export enum ExportFormat {
    XLSX = "XLSX",
    SVG = "SVG",
    PNG = "PNG",
    CSV = "CSV",
    SERVER = "SERVER"
}

export const DEFAULT_LAYOUTS: LayoutVariant[] = [
    {
        code: "onePanel",
        nameLc: "_Flexible.StandardLayouts.OnePanel",
        icon: FlexibleIcons.OneLayout,
        layout: {
            size: 12,
            rows: [{ size: 12, id: "one" }]
        }
    },
    {
        code: "splitHorizontal",
        nameLc: "_Flexible.StandardLayouts.TwoHorizontalPanels",
        icon: FlexibleIcons.TwoHorizontalPanelsLayout,
        layout: {
            size: 12,
            rows: [
                { size: 6, id: "one" },
                { size: 6, id: "two" }
            ]
        }
    },
    {
        code: "splitVertical",
        nameLc: "_Flexible.StandardLayouts.TwoVerticalPanels",
        icon: FlexibleIcons.TwoVerticalPanelsLayout,
        layout: {
            size: 12,
            columns: [
                { size: 6, id: "one" },
                { size: 6, id: "two" }
            ]
        }
    },
    {
        code: "threePanels",
        nameLc: "_Flexible.StandardLayouts.ThreePanelsBottom",
        icon: FlexibleIcons.ThreePanelsBottomLayout,
        layout: {
            size: 12,
            rows: [
                { size: 8, id: "one" },
                {
                    size: 4,
                    columns: [
                        { size: 6, id: "two" },
                        { size: 6, id: "three" }
                    ]
                }
            ]
        }
    },
    {
        code: "threePanelsTop",
        nameLc: "_Flexible.StandardLayouts.ThreePanelsTop",
        icon: FlexibleIcons.ThreePanelsTopLayout,
        layout: {
            size: 12,
            rows: [
                {
                    size: 4,
                    columns: [
                        { size: 6, id: "two" },
                        { size: 6, id: "three" }
                    ]
                },
                { size: 8, id: "one" }
            ]
        }
    },
    {
        code: "threePanelsAlt",
        nameLc: "_Flexible.StandardLayouts.ThreePanelsRight",
        icon: FlexibleIcons.ThreePanelsRightLayout,
        layout: {
            size: 12,
            columns: [
                { size: 6, id: "one" },
                {
                    size: 6,
                    rows: [
                        { size: 6, id: "two" },
                        { size: 6, id: "three" }
                    ]
                }
            ]
        }
    },
    {
        code: "ThreePanelsLeft",
        nameLc: "_Flexible.StandardLayouts.ThreePanelsLeft",
        icon: FlexibleIcons.ThreePanelsLeftLayout,
        layout: {
            size: 12,
            columns: [
                {
                    size: 6,
                    rows: [
                        { size: 6, id: "two" },
                        { size: 6, id: "three" }
                    ]
                },
                { size: 6, id: "one" }
            ]
        }
    },
    {
        code: "fourPanels",
        nameLc: "_Flexible.StandardLayouts.FourPanels",
        icon: FlexibleIcons.FourPanelsLayout,
        layout: {
            size: 12,
            rows: [
                {
                    size: 6,
                    columns: [
                        { size: 6, id: "one" },
                        { size: 6, id: "two" }
                    ]
                },
                {
                    size: 6,
                    columns: [
                        { size: 6, id: "three" },
                        { size: 6, id: "four" }
                    ]
                }
            ]
        }
    }
];
