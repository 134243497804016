// @ts-expect-error Module has no default export.
import _ from "lodash-es";
import {
    isMaybeStaleDataV1,
    isMaybeStaleDataV2,
    MaybeStaleData,
    MaybeStaleDataV1,
    MaybeStaleDataV2
} from "../types/stale-data-types";

export function combineMaybeStaleData<T1, T2, TRes>(
    data: [MaybeStaleData<T1>, MaybeStaleData<T2>],
    aggregate: (data: [T1, T2]) => TRes
): MaybeStaleData<TRes>;

export function combineMaybeStaleData<T1, T2, T3, TRes>(
    data: [MaybeStaleData<T1>, MaybeStaleData<T2>, MaybeStaleData<T3>],
    aggregate: (data: [T1, T2, T3]) => TRes
): MaybeStaleData<TRes>;

export function combineMaybeStaleData<T1, T2, T3, T4, TRes>(
    data: [MaybeStaleData<T1>, MaybeStaleData<T2>, MaybeStaleData<T3>, MaybeStaleData<T4>],
    aggregate: (data: [T1, T2, T3, T4]) => TRes
): MaybeStaleData<TRes>;

export function combineMaybeStaleData<TRes>(
    data: Array<MaybeStaleData<any>>,
    aggregate: (data: any[]) => any
): MaybeStaleData<TRes>;

export function combineMaybeStaleData<TRes>(
    data: Array<MaybeStaleData<any>>,
    aggregate: (data: any) => any
): MaybeStaleData<TRes> {
    // Check that all data is either V1 or V2
    let isV1= false;
    let isV2 = false;
    for (const x of data) {
        if (isMaybeStaleDataV2(x)) {
            isV2 = true;
        } else {
            isV1 = true;
        }
    }

    if (isV1 && isV2) throw new Error("Cannot combine stale data V1 and V2");

    if (isV1) {
        // MaybeStaleDataV1
        let jobs: string[] | null = _.flatten(_.filter(_.map(data as Array<MaybeStaleDataV1<any>>, x => x.jobs)));
        jobs = !_.isEmpty(jobs) ? jobs : null;
        return {
            data: aggregate(_.map(data, x => x.data)),
            isStale: _.reduce(data, (a, x) => a || x.isStale, <boolean>false),
            jobs
        } as MaybeStaleDataV1<TRes>;

    } else {
        // MaybeStaleDataV2
        const subscriptions: string[] = [];
        const isStalePerSubscription = new Map<string, boolean>();
        for (const item of data as Array<MaybeStaleDataV2<any>>) {
            subscriptions.push(...item.subscriptions);
            for (const [key, value] of item.isStalePerSubscription) {
                isStalePerSubscription.set(key, value);
            }
        }

        return {
            data: aggregate(_.map(data, x => x.data)),
            isStale: _.reduce(data, (a, x) => a || x.isStale, <boolean>false),
            subscriptions,
            isStalePerSubscription
        } as MaybeStaleDataV2<TRes>;
    }
}
