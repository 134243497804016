import { inject, Injectable } from "@angular/core";
import { ILogexPivotDefinition, PivotProviderBase } from "@logex/framework/lg-pivot";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { ExportPivotLevel2 } from "./export-pivot.types";
import { ExportTypeItem } from "../../../gateways/exports-notifications-gateway.types";
import { ExportStatus } from "../../../services/exports-notifications.types";

@Injectable()
export class ExportPivot extends PivotProviderBase {
    protected _lgTranslate = inject(LgTranslateService);

    protected getPivotDefinition(): ILogexPivotDefinition {
        return {
            column: "categoryName",
            levelId: "row1",
            defaultOrderBy: "sortBy",

            mergedKey: (row: ExportTypeItem) => {
                if (row.categoryName != null) return row.categoryName;
                return this._lgTranslate.translate(row.categoryNameLc);
            },

            calculateOnce: {
                steps: ["$expanded = EVAL( (true) ) at NODES"],
                thisLevelOnly: true
            },

            children: {
                column: "exportName",
                store: "children",
                levelId: "row2",
                defaultOrderBy: "sortBy",

                mergedKey: (row: ExportTypeItem) => {
                    if (row.name != null) return row.name;
                    return this._lgTranslate.translate(row.nameLc);
                },

                attachedColumns: [
                    "canRequest",
                    "sortBy",
                    "categoryName",
                    "categoryNameLc",
                    "exportTypeCode",
                    "isAsync",
                    "name",
                    "nameLc",
                    "url"
                ],

                calculate: {
                    steps: [
                        (nodes: ExportPivotLevel2[]) => {
                            for (const row2 of nodes) {
                                row2.canRequest = row2.exportTypeCode !== "widgetExport";
                                if (row2.children.some(x => x.status === ExportStatus.InProgress))
                                    row2.status = ExportStatus.InProgress;
                                else if (row2.children.some(x => x.status === ExportStatus.Queued))
                                    row2.status = ExportStatus.Queued;
                            }
                        }
                    ],
                    thisLevelOnly: true
                },

                children: {
                    column: "exportId",
                    store: "children",
                    levelId: "row3",
                    defaultOrderBy: "-createdAt",

                    calculateOnce: {
                        steps: [
                            "isExpandable = SOME( element.exportId != null )",
                            "$expanded = IDENTITY( isExpandable ) AT PARENT"
                        ],
                        thisLevelOnly: true
                    }
                }
            }
        };
    }
}
