import * as _ from "lodash";
import { Dictionary } from "lodash";
import { inject, Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from "rxjs";
import { map, shareReplay } from "rxjs/operators";

import { LgConsole } from "@logex/framework/core";
import { memoizeByKey } from "@logex/framework/utilities";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { LgTranslateService } from "@logex/framework/lg-localization";

import { ILoadersCollection, LoaderArguments, LoaderConfiguration, LoadManager } from "../load-manager";
import formatServerException from "../utils/helpers/formatServerException";

import { isMaybeStaleDataV1, MaybeStaleData, CalculationError, CalculationProgress, IStaleDataService } from "./types";

import { JobStatus } from "./gateways/stale-data-service-gateway.types";
import { StaleDataServiceGateway } from "./gateways/stale-data-service-gateway";
import { CalculationJobsMonitoringService } from "./calculation-jobs-monitoring.service";

export class DatasetInfo {
    constructor(
        public id: string,
        private _loaders: ILoadersCollection,
        private _lgConsole: LgConsole,
        private _promptDialog: LgPromptDialog,
        private _lgTranslate: LgTranslateService,
        private _gateway: StaleDataServiceGateway,
        private _jobStatusMonitor: CalculationJobsMonitoringService
    ) {
        this._isCalculatingSubject = new BehaviorSubject<boolean>(false);
        this._isCalculating = false;
        this._calculationProgressSubject = new BehaviorSubject(0);

        this.setJobs();
    }

    requiredDatasets: (() => DatasetInfo[]) | null = null;
    jobs: string[] = [];

    private _isCalculating: boolean;
    private _isCalculatingSubject: Subject<boolean>;
    private _calculationProgressSubject: BehaviorSubject<number>;
    private _notificationsSubscription: Subscription | null = null;
    private _jobStatusSub: Subscription | null = null;

    // ----------------------------------------------------------------------------------
    onLoadingStart(): void {
        this.cancel();
        this._calculationProgressSubject.next(0);
    }

    onDataReceived(data: MaybeStaleData<any>, args: LoaderArguments): void {
        // Sanity check
        if (this._isCalculating) {
            this._lgConsole.error(
                "Data is received, but some jobs did not finish yet. This should not happen.",
                { jobs: this.jobs }
            );
        }

        let jobs: string[] = [];

        if (data.isStale && isMaybeStaleDataV1(data)) {
            this._lgConsole.debug(`Stale data received by "${this.id}"`, { jobs: data.jobs });
            jobs = data.jobs ?? [];
        }

        // Check if required datasets are calculating
        const required: DatasetInfo[] = _.filter(this.requiredDatasets?.(), x => x.isCalculating());

        // If any of the required datasets are being recalculated,
        // then add their jobs to the wait list of this dataset too
        if (!_.isEmpty(required)) {
            jobs = [...jobs, ..._.flatten(_.map(required, x => x.jobs))];
        }

        if (!_.isEmpty(jobs)) {
            this.setJobs(...jobs);

            // Wait until the jobs won't finish
            this._notificationsSubscription = this._isCalculatingSubject.subscribe(
                isCalculating => {
                    if (!isCalculating) {
                        this._loaders.loadDatasets([this.id], true, args);
                        this._notificationsSubscription!.unsubscribe();
                    }
                }
            );

            // Listen to jobs updates
            this._jobStatusSub = this._jobStatusMonitor.watchJobs(jobs).subscribe(statuses => {
                this._processGetJobsStatusResponse(statuses);
            });
        }
    }

    private _processGetJobsStatusResponse(status: JobStatus[]): void {
        // this._lgConsole.debug( `${this.id}. Received jobs status report`, _.cloneDeep( status ) );

        status.sort(a => (a.isSuccess ? -1 : 0));

        let startedJobs = 0,
            currentStep = 0,
            totalSteps = 0,
            maxTotalSteps = 0;
        for (const jobStatus of status) {
            if (jobStatus.isStarted) {
                startedJobs++;
                currentStep += jobStatus.currentStep;
                totalSteps += jobStatus.totalSteps;
                if (jobStatus.totalSteps > maxTotalSteps) maxTotalSteps = jobStatus.totalSteps;
            }

            // If this.jobs does not contain the jobId, then this job was already processed
            if (!_.includes(this.jobs, jobStatus.jobId)) continue;

            if (jobStatus.isSuccess) {
                _.pull(this.jobs, jobStatus.jobId);
                this._updateStatus();
            } else if (jobStatus.isCancelled) {
                this.cancel();
                return;
            } else if (jobStatus.error != null) {
                this._promptDialog.alert(
                    this._lgTranslate.translate("_LoadManager.Error"),
                    formatServerException(jobStatus.error),
                    {
                        columns: 5,
                        buttons: [LgPromptDialog.OK_BUTTON]
                    }
                );

                this.cancel();
                return;
            }
        }

        // For jobs that are not started yet we don't know total steps. Take max value from already started jobs.
        if (startedJobs > 0) {
            const nonStartedJobs = status.length - startedJobs;
            if (nonStartedJobs > 0) {
                totalSteps += maxTotalSteps * nonStartedJobs;
            }
        }

        // Report calculation progress
        const progress = totalSteps !== 0 ? currentStep / totalSteps : 0;
        this._calculationProgressSubject.next(progress);
    }

    setJobs(...jobs: string[]): void {
        this.jobs = [];
        this.addJobs(...jobs);
    }

    addJobs(...jobs: string[]): void {
        this.jobs = _.uniq([...this.jobs, ...jobs]);
        this._updateStatus();
    }

    isCalculating(): boolean {
        return this._isCalculating;
    }

    get isCalculating$(): Observable<boolean> {
        return this._isCalculatingSubject.asObservable();
    }

    get calculationProgress$(): Observable<number> {
        return this._calculationProgressSubject.asObservable();
    }

    cancel(): void {
        if (this._isCalculating && this.jobs?.length > 0) {
            this._lgConsole.debug("Cancelling jobs", this.jobs);
            this._gateway.cancelJobs(this.jobs).subscribe();
        }

        this._unsubscribe();
        this.setJobs();
    }

    private _updateStatus(): void {
        const isCalculating = !_.isEmpty(this.jobs);

        if (isCalculating !== this._isCalculating) {
            this._isCalculatingSubject.next(isCalculating);

            if (!isCalculating) {
                this._unsubscribe();
            }
        }

        this._isCalculating = isCalculating;
    }

    private _unsubscribe(): void {
        this._notificationsSubscription?.unsubscribe();
        this._notificationsSubscription = null;

        this._jobStatusSub?.unsubscribe();
        this._jobStatusSub = null;
    }
}

// ----------------------------------------------------------------------------------
@Injectable()
export class StaleDataService implements IStaleDataService {
    private _loaders: ILoadersCollection = inject(LoadManager);
    private readonly _lgConsole = inject(LgConsole).withSource("StaleDataService");
    private readonly _promptDialog = inject(LgPromptDialog);
    private readonly _lgTranslate = inject(LgTranslateService);
    private readonly _gateway = inject(StaleDataServiceGateway);
    private readonly _jobStatusMonitor = inject(CalculationJobsMonitoringService);

    // ----------------------------------------------------------------------------------
    // Fields
    private _datasets: Dictionary<DatasetInfo> = {};
    private _isCalculatingCache: Dictionary<Observable<boolean>> = {};
    private _calculationProgressCache: Dictionary<Observable<CalculationProgress | null>> ={};

    // ----------------------------------------------------------------------------------
    // Public fields and properties

    /**
     * Automatically recalculate stale datasets
     */
    // automaticRecalculation = true;

    // ----------------------------------------------------------------------------------
    configureLoader<TData>(
        datasetId: string,
        cfg: LoaderConfiguration<MaybeStaleData<TData>>,
        project: (data: TData | null, isStale: boolean, args: any[]) => unknown = (data, isStale) =>
            ({ data, isStale } as MaybeStaleData<TData>)
    ): Dictionary<LoaderConfiguration> {
        const dataset = this._getDatasetInfo(datasetId);

        // Required datasets could be a function returning an array of names
        dataset.requiredDatasets = _.isFunction(cfg.require)
            ? () => _.map((cfg.require as () => string[])(), x => this._getDatasetInfo(x))
            : () => _.map(cfg.require, x => this._getDatasetInfo(x));

        return {
            [datasetId]: {
                ...cfg,

                loader: (...args) => {
                    dataset.onLoadingStart();
                    return cfg.loader(...args).pipe(
                        map(data => {
                            if (data == null) return project(null, false, args);

                            dataset.onDataReceived(data, args);

                            return project(data.data, data.isStale, args);
                        })
                    );
                },

                onCancel: () => {
                    dataset.cancel();
                }
            }
        };
    }

    isStale$(...datasets: string[]): Observable<boolean> {
        return this.isCalculating$(...datasets);
    }

    staleTables$(...datasets: string[]): Observable<Set<string>> {
        return of(new Set<string>());
    }

    isCalculating(...datasets: string[]): boolean {
        if (datasets.length === 0) return false;

        return _.reduce(
            _.map(datasets, x => this._getDatasetInfo(x).isCalculating()),
            (a, x) => a || x,
            <boolean>false
        );
    }

    isCalculating$(...datasets: string[]): Observable<boolean> {
        if (datasets.length === 0) return of(false);

        return memoizeByKey(this._isCalculatingCache, datasets.join(","), () =>
            combineLatest(_.map(datasets, x => this._getDatasetInfo(x).isCalculating$)).pipe(
                map((values: boolean[]) => _.reduce(values, (a, x) => a || x, false)),
                shareReplay(1)
            )
        );
    }

    calculationProgress$(...datasets: string[]): Observable<CalculationProgress | null> {
        if (datasets.length === 0) return of(null);

        return memoizeByKey(this._calculationProgressCache, datasets.join(","), () => {
            const datasetInfos = _.map(datasets, x => this._getDatasetInfo(x));
            return combineLatest(datasetInfos.map(x => x.calculationProgress$)).pipe(
                map((values) => {
                    let sum = 0;
                    let count = 0;
                    for (let i = 0; i < values.length; i++) {
                        const datasetInfo = datasetInfos[i];
                        if (datasetInfo.isCalculating()) {
                            sum += values[i];
                            count++;
                        }
                    }

                    if (count === 0) return null;

                    const percentage = sum / count * 100;
                    return {
                        percentage,
                        executingSteps: [],
                        isFinished: percentage >= 100,
                    };
                }),
                shareReplay(1)
            );
        });
    }

    calculationError$(...datasets: string[]): Observable<CalculationError[] | null> {
        return of(null);
    }

    private _getDatasetInfo(datasetId: string): DatasetInfo {
        let dataSet = this._datasets[datasetId];

        if (dataSet == null) {
            dataSet = new DatasetInfo(
                datasetId,
                this._loaders,
                this._lgConsole,
                this._promptDialog,
                this._lgTranslate,
                this._gateway,
                this._jobStatusMonitor
            );
            this._datasets[datasetId] = dataSet;
        }

        return dataSet;
    }

    setLoadersOverride(value: ILoadersCollection) {
        this._loaders = value;
    }

    /***
     * This method is called from `useStaleData`. It is deliberately not `ngOnDestroy` because we don't want to
     * get double destruction.
     */
    release(): void {
        _.each(this._datasets, x => x.cancel());
    }
}
