import { Observable } from "rxjs";
import { InjectionToken } from "@angular/core";
import { ILoadersCollection, LoaderConfiguration } from "../../load-manager";
import { MaybeStaleData } from "./stale-data-types";

export const STALE_DATA_SERVICE_VERSION = new InjectionToken<"V1"|"V2">(
    "STALE_DATA_SERVICE_VERSION", {
        factory: () => "V2" // Use V2 as default, when not configured
    }
);

export const STALE_DATA_SERVICE = new InjectionToken<IStaleDataService>(
    "STALE_DATA_SERVICE"
);

export interface IStaleDataService {
    configureLoader<TData>(
        datasetId: string,
        cfg: LoaderConfiguration<MaybeStaleData<TData>>,
        project: (data: TData | null, isStale: boolean, args: any[]) => unknown,
        queryReload?: (wasStale: boolean, isStale: boolean) => boolean
    ): Record<string, LoaderConfiguration>;

    isStale$(...datasets: string[]): Observable<boolean>;

    staleTables$(...datasets: string[]): Observable<Set<string>>;

    isCalculating(...datasets: string[]): boolean;

    isCalculating$(...datasets: string[]): Observable<boolean>;

    calculationProgress$(...datasets: string[]): Observable<CalculationProgress | null>;

    calculationError$(...datasets: string[]): Observable<CalculationError[] | null>;

    setLoadersOverride(value: ILoadersCollection): void;

    release(): void;
}

export interface CalculationProgress {
    percentage: number;
    executingSteps: string[];
    isFinished: boolean;
}

export interface CalculationError {
    stepName: string;
    errorMessage: string;
}
