import * as _ from "lodash-es";
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    computed,
    EventEmitter,
    inject,
    Input,
    OnDestroy,
    OnInit,
    Output,
    signal,
    Signal,
    TemplateRef,
    ViewChild,
    ViewContainerRef,
    WritableSignal
} from "@angular/core";
import { Subscription } from "rxjs";

import { LgTranslateService, useTranslationNamespace } from "@logex/framework/lg-localization";
import { IDropdownDefinition, LgPromptDialog } from "@logex/framework/ui-core";
import { LgSlideoutService, LgSlideoutVariant } from "@logex/framework/lg-layout";
import { dropdownFlat } from "@logex/framework/utilities";

import { LayoutEditorDialog } from "../flexible-layout-editor-dialog/flexible-layout-editor-dialog.component";
import { PageReferencesService } from "../../services/page-references/page-references.service";
import { translateNullableName } from "../../utilities";
import {
    CategorizedLayouts,
    FlexibleLayoutLibraryEnum,
    FlexibleLayoutStorageService
} from "../../services/flexible-layout-storage";
import { LibraryConfirmDialog } from "../flexible-layout-editor-dialog/components/library-confirm-dialog/library-confirm-dialog.component";
import { FiltersLayoutGroup } from "../../services/flexible-filter-factory/flexible-filter-factory.types";
import { LG_FLEX_PAGE_AUTHORIZATION } from "../../services/flexible-page-authorization";
import { FlexDataClientMetadataArguments } from "../../services/flex-data-client/types/types";
import { toSignal } from "@angular/core/rxjs-interop";
import { LayoutVariant } from "../../types";
import { LayoutManagementDialog } from "../layout-management-dialog";
import { CatalogGroup, CatalogGroupLayout, CatalogLayout } from "./lg-flexible-layouts-panel.types";
import { FLEXIBLE_LAYOUTS_NAVIGATION } from "../../services/flexible-layouts-navigation/flexible-layouts-navigation.service";
import { LgNavigationService } from "@logex/framework/lg-application";
import { FLEXIBLE_PAGE_DEFAULT_DATA_SOURCE_CODE } from "../../services/flex-data-sources/flex-data-sources.types";

// TODO: Think of a better name for this component. Now we have also "flexible analytics panel" and this is something completely different.

// ----------------------------------------------------------------------------------
@Component({
    standalone: false,
    selector: "lgflex-flexible-layouts-panel",
    templateUrl: "./lg-flexible-layouts-panel.component.html",
    styleUrls: ["./lg-flexible-layouts-panel.component.scss"],
    providers: [...useTranslationNamespace("_Flexible._LayoutEditorSidebar")],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LgFlexibleLayoutsPanelComponent implements OnInit, OnDestroy, AfterViewInit {
    private _layoutManagementDialog = inject(LayoutManagementDialog).bindViewContainerRef();
    private _layoutEditorDialog = inject(LayoutEditorDialog).bindViewContainerRef();
    private _flexibleLayoutStorage = inject(FlexibleLayoutStorageService);
    private _lgTranslate = inject(LgTranslateService);
    private _promptDialog = inject(LgPromptDialog);
    private _slideoutService = inject(LgSlideoutService);
    private viewContainerRef = inject(ViewContainerRef);
    private _flexPageAuthorization = inject(LG_FLEX_PAGE_AUTHORIZATION);
    private _layoutManagementNavigationService = inject(FLEXIBLE_LAYOUTS_NAVIGATION);
    private _cdr = inject(ChangeDetectorRef);
    private _libraryConfirmDialog = inject(LibraryConfirmDialog);
    private _navigationService = inject(LgNavigationService);

    // ----------------------------------------------------------------------------------

    @Input() pageReferences!: PageReferencesService;
    @Input() layoutVariants: LayoutVariant[] = [];
    @Input() flexDataClientArguments: FlexDataClientMetadataArguments = {};
    @Input() layoutId: number | null = null;
    @Input() isLoading = true;
    @Input() variant: LgSlideoutVariant = "right";
    @Input() filtersLayout!: FiltersLayoutGroup[];
    @Input({ required: true }) page!: string;

    @Output() readonly layoutConfigurationChange = new EventEmitter<number | null>();
    @Output() readonly layoutSelected = new EventEmitter<number>();

    @ViewChild("layoutSlideout") private _panelTemplate!: TemplateRef<void>;
    @ViewChild("layoutSlideoutHeaderIcons") private _headerTemplate!: TemplateRef<void>;

    protected _libraries: Signal<CategorizedLayouts[]> = toSignal(
        this._flexibleLayoutStorage.getCategorizedLayouts()
    );

    protected _sharedLayouts: Signal<CatalogGroupLayout[]> = toSignal(
        this._flexibleLayoutStorage.getSharedCategorizedGroupLayouts()
    );

    protected _temporaryLayoutInProgress = this._flexibleLayoutStorage.temporaryLayoutInProgress;
    private _expandedSectionsSignal: WritableSignal<Record<string, boolean>> = signal({});
    protected _sections: Signal<Record<string, boolean>> = computed(() => {
        const expandedSectionsMap = this._expandedSectionsSignal();
        const sharedLayouts = this._sharedLayouts();

        const layoutIds = sharedLayouts.reduce((acc, item) => {
            return [
                ...acc,
                ...(this._isCatalogGroup(item) ? item.layouts.map(l => l.id) : [item.id])
            ];
        }, []);
        const isSharedLayoutActive = new Set(layoutIds).has(this.layoutId);

        const sectionsMap = sharedLayouts.reduce(
            (acc, layout) => {
                if (this._isCatalogGroup(layout)) {
                    acc[layout.name] =
                        layout.layouts.find(l => l.id === this.layoutId) !== undefined;
                }
                return acc;
            },
            {
                personalLayouts: !isSharedLayoutActive,
                sharedLayouts: true, // should be always opened
                references: false
            }
        );

        return { ...sectionsMap, ...expandedSectionsMap };
    });

    protected _referencesDropdown: IDropdownDefinition<string> | undefined;
    protected _userCanCreateLayout = this._flexPageAuthorization.canCreateLayout();
    protected _userCanEditLayoutGroups = this._flexPageAuthorization.canEditLayoutGroups();
    private _updateReferencesHandler: Subscription | undefined;
    private _panelVariant = "layouts";

    ngOnInit(): void {
        // since this will be used by apps and angular doesn't yet have input presence validation, assert the inputs
        if (this.pageReferences == null) throw Error("Page references should be defined.");

        this._updateReferencesHandler = this.pageReferences.stateChange.subscribe(() => {
            this._updateReferences();
            this._cdr.detectChanges();
        });
    }

    ngAfterViewInit(): void {
        this._slideoutService.addAvailablePanel({
            panelVariant: this._panelVariant,
            nameLc: "_Flexible._LayoutEditorSidebar.Layouts",
            order: 5,
            icon: "icon-layout-change",
            panelTemplate: this._panelTemplate,
            headerIconsTemplate: this._headerTemplate,
            isActive: this._panelVariant === this._slideoutService.currentPanelVariant
        });
    }

    protected _toggleWrapper(wrapper: string): void {
        this._expandedSectionsSignal.update(expandedSections => {
            return { ...expandedSections, [wrapper]: !this._sections()[wrapper] };
        });
    }

    protected _isCatalogGroup(layout: CatalogGroupLayout): layout is CatalogGroup {
        return (
            (layout as CatalogLayout).description === undefined &&
            (layout as CatalogLayout).isTemporary === undefined &&
            (layout as CatalogLayout).readonly === undefined
        );
    }

    protected _isCatalogLayout(layout: CatalogGroupLayout): layout is CatalogLayout {
        return (layout as CatalogLayout).readonly !== undefined;
    }

    protected async _openLayoutManagementDialog(event: MouseEvent): Promise<void> {
        event.stopPropagation();
        const dialogResponse = await this._layoutManagementDialog.show({
            page: this.page,
            metadataArguments: this.flexDataClientArguments
        });

        if (dialogResponse.isLayoutsSaved) {
            await this._flexibleLayoutStorage.loadSharedCategorizedGroupLayouts();
            this._layoutManagementNavigationService.signalLayoutsNavigationChanged();
        }
    }

    protected async _duplicateLayout(layoutId: number): Promise<any> {
        if (this.pageReferences == null) return;

        await this._flexibleLayoutStorage.loadLayoutConfig(layoutId);

        const layoutConfig = this._flexibleLayoutStorage.getLayout(layoutId);
        const libraries = this._flexibleLayoutStorage.libraries$.getValue();
        const defaultLibrary = libraries.find(
            lib => lib.typeId === FlexibleLayoutLibraryEnum.PERSONAL
        );
        const response = await this._libraryConfirmDialog.show({
            libraries,
            libraryId: defaultLibrary?.id ?? undefined,
            name: layoutConfig.name + " Copy",
            description: layoutConfig.description
        });

        const libraryId = response.libraryId ?? null;
        const name = response.name;
        const description = response.description;

        const newLayoutId = await this._flexibleLayoutStorage.saveLayout({
            ...layoutConfig,
            id: null,
            libraryId,
            name,
            description,
            isTemporary: false,
            parentId: null
        });

        await this._reloadLayouts();

        this.layoutConfigurationChange.emit(newLayoutId);
    }

    protected async _editLayout(layoutId: number): Promise<void> {
        if (this.pageReferences == null) return;

        await this._flexibleLayoutStorage.loadLayoutConfig(layoutId);

        const response = await this._layoutEditorDialog.show({
            metadataArguments: this.flexDataClientArguments,
            layouts: this.layoutVariants,
            layoutId: layoutId ?? this.layoutId,
            filtersLayout: this.filtersLayout
        });

        await this._reloadLayouts();

        this.layoutConfigurationChange.emit(response.layoutId);
    }

    protected async _createLayout(): Promise<void> {
        if (this.pageReferences == null) return;

        const response = await this._layoutEditorDialog.show({
            metadataArguments: this.flexDataClientArguments,
            layouts: this.layoutVariants,
            filtersLayout: this.filtersLayout,
            defaultDataSource: this._getDefaultDataSourceFromNavigation()
        });

        await this._reloadLayouts();

        this.layoutConfigurationChange.emit(response.layoutId);
    }

    protected async _loadLayout(layoutId: number): Promise<void> {
        if (this._temporaryLayoutInProgress()) {
            if (layoutId === this._flexibleLayoutStorage.getTemporaryLayout()?.id) {
                return;
            }

            const result = await this._promptDialog.confirm(
                this._lgTranslate.translate(".ConfirmUnsavedChangesTitle"),
                this._lgTranslate.translate(".ConfirmUnsavedChangesText")
            );

            if (result === "cancel") {
                return;
            } else {
                await this._flexibleLayoutStorage.revertTemporaryLayout();
            }
        }

        this.layoutSelected.emit(layoutId);
    }

    private _updateReferences(): void {
        this._referencesDropdown = dropdownFlat({
            entryId: "code",
            entryName: "name",
            entries: [
                ..._.chain(this.pageReferences?.references)
                    .orderBy(x => x.code)
                    .map(x => ({
                        ...x,
                        name: translateNullableName(this._lgTranslate, x.name, x.nameLc)
                    }))
                    .value()
            ]
        });
    }

    protected _isReferenceLocked(slotIndex: number): boolean {
        return this.pageReferences.selectedReferences[slotIndex]?.isLocked ?? true;
    }

    protected async _onReferenceSelected(slotIdx: number, value: string): Promise<void> {
        if (this.pageReferences == null) return;

        const selected = this.pageReferences.selected.map(x => x ?? "");
        selected.splice(slotIdx, 1, value);
        this.pageReferences.selected = selected;
    }

    private async _reloadLayouts(): Promise<void> {
        await this._flexibleLayoutStorage.loadSharedCategorizedGroupLayouts();
        this._layoutManagementNavigationService.signalLayoutsNavigationChanged();
    }

    private _getDefaultDataSourceFromNavigation(): string | undefined {
        const currentNodePathArr = this._navigationService.getCurrentNodePathSync();
        let currentNode = currentNodePathArr[currentNodePathArr.length - 1];
        while (
            currentNode.parent !== null &&
            currentNode.data[FLEXIBLE_PAGE_DEFAULT_DATA_SOURCE_CODE] === undefined
        ) {
            currentNode = currentNode.parent;
        }
        return currentNode.data[FLEXIBLE_PAGE_DEFAULT_DATA_SOURCE_CODE];
    }

    ngOnDestroy(): void {
        this._updateReferencesHandler?.unsubscribe();
        this._slideoutService.deletePanel(this._panelVariant);
    }
}
