import { Inject } from "@angular/core";
import { IDefinitions, LG_APP_DEFINITIONS } from "@logex/framework/lg-application";

import {
    ICalculatorCallbackDefinition,
    ILogexPivotDefinition,
    ILogexPivotDefinitionOptions,
    INormalizedLogexPivotDefinition,
    LogexPivotService
} from "@logex/framework/lg-pivot";
import { Dictionary } from "lodash";
import * as _ from "lodash-es";

import {
    FieldInfo,
    PivotTableColumn,
    PivotTableColumnDefault,
    PivotTableColumnDifference,
    PivotTableColumnFormula,
    PivotTableColumnFormulaInput,
    PivotTableColumnFormulaType
} from "../../types";
import {
    CalcAstNode,
    CalcAstNodeFunctionCall,
    getColumnFieldName,
    parseCalculate
} from "../../utilities";
import {
    getElementParam,
    getStoreParam,
    validateABC,
    validateAB
} from "./flexible-pivot-definition-factory-base.utils";
import { regularComparer } from "@logex/framework/utilities";

// ----------------------------------------------------------------------------------
export interface Config {
    scheme: FieldInfo[];
    numReferences: number;
    levels: string[];
    columns: PivotTableColumn[];
    bottomLevelExtension?: ILogexPivotDefinition;
}

export type CalculationStep = string | ICalculatorCallbackDefinition;

export type CalculationStepDefinitions = CalculationStep[][];

// ----------------------------------------------------------------------------------
export abstract class FlexiblePivotDefinitionFactoryBase {
    constructor(
        protected _pivotService: LogexPivotService,
        @Inject(LG_APP_DEFINITIONS) protected _definitions: IDefinitions<any>
    ) {}

    abstract _buildLevels(
        config: Config,
        fieldsLookup: Dictionary<FieldInfo>
    ): { readonly root: ILogexPivotDefinition; readonly leaf: ILogexPivotDefinition };

    getPivotDefinition(config: Config): INormalizedLogexPivotDefinition {
        if (_.isEmpty(config.levels)) {
            throw Error("Pivot has no levels specified");
        }

        const fieldsLookup = _.keyBy(config.scheme, x => x.field);

        // Build levels
        const { root, leaf } = this._buildLevels(config, fieldsLookup);

        // Process value fields
        const calculationStepDefinitions = this._getCalculationStepDefinitions(
            config,
            fieldsLookup
        );

        calculationStepDefinitions.forEach((steps, idx) => {
            leaf[`calculate${idx || ""}`] = {
                steps
            };
        });

        // Extend bottom level definition
        if (config.bottomLevelExtension !== undefined) {
            Object.assign(leaf, config.bottomLevelExtension);
        }

        return this._pivotService.prepareDefinition(
            root,
            this._getPivotOptions(calculationStepDefinitions)
        );
    }

    protected _getLevelDefinition(
        i: number,
        keyFields: string[],
        definition: string[],
        valueFields?: string[]
    ): ILogexPivotDefinition {
        if (_.isEmpty(keyFields)) {
            throw Error("Pivot has no keys specified");
        }

        const res: ILogexPivotDefinition = {
            store: "children",
            levelId: `row${i + 1}`,
            defaultOrderBy: keyFields
        };

        if (keyFields.length === 1) {
            res.column = keyFields[0];
        } else {
            res.column = "__rowid";
            res.mergedKey = keyFields;
        }

        keyFields.forEach((field, definitionIndex) => {
            const currentDef = definition[definitionIndex];
            // If this field is bound to a definition, then define sorting function based on definition sorting rules
            if (currentDef && currentDef !== "string" && currentDef !== "number") {
                if (res.sorters === undefined) res.sorters = {};
                res.sorters![field] = {
                    extract: (row: any) => this._definitions.getOrderBy(currentDef, row[field])
                };
            }
        });

        if (valueFields?.length)
            valueFields.forEach(field => {
                res.sorters = res.sorters || {};
                res.sorters[field] = {
                    comparer: (a: any, b: any) => regularComparer(a ?? 0, b ?? 0) // nulls are treated as zeros
                };
            });

        return res;
    }

    private _getCalculationStepDefinitions(
        config: Config,
        fieldsLookup: Dictionary<FieldInfo>
    ): CalculationStepDefinitions {
        const calculateABC = (
            column: PivotTableColumnFormula,
            paramsCallback: (varA: string, varB: string, varC: string) => string[]
        ) => {
            validateABC(column);

            const columnAConfig = getColumnVariable(column.variables.a);
            const columnBConfig = getColumnVariable(column.variables.b);
            const columnCConfig = getColumnVariable(column.variables.c);

            const elementParamA = getElementParam(columnAConfig);
            const elementParamB = getElementParam(columnBConfig);
            const elementParamC = getElementParam(columnCConfig);
            const storeParamA = getStoreParam(columnAConfig);
            const storeParamB = getStoreParam(columnBConfig);
            const storeParamC = getStoreParam(columnCConfig);

            return concatCalculations(
                getMapColumn(columnAConfig),
                getMapColumn(columnBConfig),
                getMapColumn(columnCConfig),
                // TODO: Eliminate double calculation
                [
                    [
                        renderFuncCall({
                            type: "functionCall",
                            func: "EVAL",
                            target: getColumnFieldName(column),
                            params: paramsCallback(elementParamA, elementParamB, elementParamC),
                            at: { nodes: true, parent: false }
                        }),
                        renderFuncCall({
                            type: "functionCall",
                            func: "EVAL",
                            target: getColumnFieldName(column),
                            params: paramsCallback(storeParamA, storeParamB, storeParamC),
                            at: { nodes: false, parent: true }
                        })
                    ]
                ]
            );
        };

        const getColumnVariable = (columnFormulaInput: PivotTableColumnFormulaInput) => {
            if (columnFormulaInput.type === "constant") {
                return { variable: columnFormulaInput.constant.toString() };
            } else {
                const field = getFieldFromLookup(columnFormulaInput.field);
                const column: PivotTableColumnDefault = {
                    type: "default",
                    field: field.field,
                    referenceIdx: columnFormulaInput.reference
                };

                const variable = getColumnFieldName(column);
                return {
                    field,
                    column,
                    variable
                };
            }
        };

        function mapColumn(column: PivotTableColumn): CalculationStepDefinitions | null {
            switch (column.type) {
                case "default":
                    return getDefaultColumnCalculation(column);

                case "difference":
                    return getDifferenceColumnCalculation(column);

                case "formula":
                    return getFormulaColumnCalculation(column);

                default:
                    return null;
            }
        }

        function getMapColumn(columnConfig: {
            field?: FieldInfo;
            column?: PivotTableColumnDefault;
            variable?: string;
        }): CalculationStepDefinitions | null {
            return columnConfig.column ? mapColumn(columnConfig.column) : null;
        }

        function getDefaultColumnCalculation(
            column: PivotTableColumnDefault
        ): CalculationStepDefinitions | null {
            const field = getFieldFromLookup(column.field);

            const fieldName = getColumnFieldName(column);

            let parsed: CalcAstNode;

            if (field.calculate !== null) {
                parsed = parseCalculate(field.calculate);
            } else if (field.aggregate !== null) {
                parsed = parseAggregate(field.aggregate, field.field);
            } else {
                return null;
            }

            switch (parsed.type) {
                case "functionCall":
                    // Set the target field
                    parsed.target = fieldName;

                    const paramCalculations = parsed.params
                        .filter(x => x !== field.field)
                        .map(x => {
                            const paramColumn = {
                                type: "default",
                                field: x,
                                referenceIdx: column.referenceIdx
                            } as PivotTableColumnDefault;
                            return mapColumn(paramColumn);
                        })
                        .filter((x): x is CalculationStepDefinitions => x !== null);

                    // If column is reference-bound, update parameter names to include index
                    if (field.isReferenceBound) {
                        parsed.params = _.map(
                            parsed.params,
                            param => `${param}${column.referenceIdx}`
                        );
                    }

                    return concatCalculations(
                        // Add calculations for source fields if necessary
                        ...paramCalculations,
                        [[renderFuncCall(parsed)]]
                    );

                case "error":
                    throw Error(parsed.message);

                default:
                    throw Error();
            }
        }

        function getDifferenceColumnCalculation(
            column: PivotTableColumnDifference
        ): CalculationStepDefinitions {
            getFieldFromLookup(column.field);

            // Make sure that we calculate source fields for this difference column
            const sourceColumnLeft = {
                type: "default",
                field: column.field,
                referenceIdx: column.referenceLeft
            } as PivotTableColumnDefault;
            const sourceColumnRight = {
                type: "default",
                field: column.field,
                referenceIdx: column.referenceRight
            } as PivotTableColumnDefault;

            return concatCalculations(
                mapColumn(sourceColumnLeft),
                mapColumn(sourceColumnRight),

                // Calculate both absolute and relative values
                [
                    [
                        renderFuncCall({
                            type: "functionCall",
                            func: "DIFF",
                            target: getColumnFieldName({ ...column, mode: "diff" }),
                            params: [
                                getColumnFieldName(sourceColumnRight),
                                getColumnFieldName(sourceColumnLeft)
                            ],
                            at: { nodes: true, parent: true }
                        }),
                        renderFuncCall({
                            type: "functionCall",
                            func: "GROWTH",
                            target: getColumnFieldName({ ...column, mode: "growth" }),
                            params: [
                                getColumnFieldName(sourceColumnRight),
                                getColumnFieldName(sourceColumnLeft)
                            ],
                            at: { nodes: true, parent: true }
                        })
                    ]
                ]
            );
        }

        function getFormulaColumnCalculation(
            column: PivotTableColumnFormula
        ): CalculationStepDefinitions {
            // Make sure that we calculate source fields for this difference column
            switch (column.formula) {
                case PivotTableColumnFormulaType.A: {
                    if (column.variables.a === undefined)
                        throw Error("Formula variable A is undefined.");

                    if (column.variables.a.type === "constant") {
                        return concatCalculations([
                            [
                                renderFuncCall({
                                    type: "functionCall",
                                    func: "IDENTITY",
                                    target: getColumnFieldName(column),
                                    params: [column.variables.a.constant.toString()],
                                    at: { nodes: true, parent: true }
                                })
                            ]
                        ]);
                    }

                    const fieldA = getFieldFromLookup(column.variables.a.field);
                    const columnA: PivotTableColumnDefault = {
                        type: "default",
                        field: fieldA.field,
                        referenceIdx: column.variables.a.reference
                    };
                    const fieldAName = getColumnFieldName(columnA);
                    return concatCalculations(mapColumn(columnA), [
                        [
                            renderFuncCall({
                                type: "functionCall",
                                func: "IDENTITY",
                                target: getColumnFieldName(column),
                                params: [fieldAName],
                                at: { nodes: true, parent: true }
                            })
                        ]
                    ]);
                }
                case PivotTableColumnFormulaType.AMinusB: {
                    validateAB(column);

                    const columnA = getColumnVariable(column.variables.a);
                    const columnB = getColumnVariable(column.variables.b);

                    return concatCalculations(getMapColumn(columnA), getMapColumn(columnB), [
                        [
                            renderFuncCall({
                                type: "functionCall",
                                func: "DIFF",
                                target: getColumnFieldName(column),
                                params: [columnA.variable, columnB.variable],
                                at: { nodes: true, parent: true }
                            })
                        ]
                    ]);
                }

                case PivotTableColumnFormulaType.APlusB: {
                    validateAB(column);

                    const columnAConfig = getColumnVariable(column.variables.a);
                    const columnBConfig = getColumnVariable(column.variables.b);

                    return concatCalculations(
                        getMapColumn(columnAConfig),
                        getMapColumn(columnBConfig),
                        [
                            [
                                renderFuncCall({
                                    type: "functionCall",
                                    func: "ADD",
                                    target: getColumnFieldName(column),
                                    params: [columnAConfig.variable, columnBConfig.variable],
                                    at: { nodes: true, parent: true }
                                })
                            ]
                        ]
                    );
                }

                case PivotTableColumnFormulaType.ADividedB: {
                    validateAB(column);

                    const columnAConfig = getColumnVariable(column.variables.a);
                    const columnBConfig = getColumnVariable(column.variables.b);

                    return concatCalculations(
                        getMapColumn(columnAConfig),
                        getMapColumn(columnBConfig),
                        [
                            [
                                renderFuncCall({
                                    type: "functionCall",
                                    func: "DIV",
                                    target: getColumnFieldName(column),
                                    params: [columnAConfig.variable, columnBConfig.variable],
                                    at: { nodes: true, parent: true }
                                })
                            ]
                        ]
                    );
                }

                case PivotTableColumnFormulaType.AMinusBMinusC: {
                    return calculateABC(column, (varA, varB, varC) => [
                        `${varA} - ${varB} - ${varC}`
                    ]);
                }

                case PivotTableColumnFormulaType.AMinusBPlusC: {
                    return calculateABC(column, (varA, varB, varC) => [
                        `${varA} - ${varB} + ${varC}`
                    ]);
                }

                case PivotTableColumnFormulaType.AMultiplyBMinusC: {
                    return calculateABC(column, (varA, varB, varC) => [
                        `(${varA} * ( ${varB} - ${varC}))`
                    ]);
                }
                case PivotTableColumnFormulaType.ADivideBMultiplyC: {
                    return calculateABC(column, (varA, varB, varC) => [
                        `${varB} ? (${varA} / ${varB}) * ${varC} : 0`
                    ]);
                }
                case PivotTableColumnFormulaType.ADivideBDivideC: {
                    return calculateABC(column, (varA, varB, varC) => [
                        `${varB} ? (${varA} / ${varB}) / ${varC} : 0`
                    ]);
                }
                case PivotTableColumnFormulaType.ADividedB_MinusC: {
                    return calculateABC(column, (varA, varB, varC) => [
                        `${varB} ? (${varA} / ${varB}) - ${varC} : 0`
                    ]);
                }
                case PivotTableColumnFormulaType.PercentOfParent: {
                    if (column.variables.a === undefined || column.variables.a.type === "constant")
                        throw Error("Formula variable A is undefined.");

                    const columnAConfig = getColumnVariable(column.variables.a);

                    return concatCalculations(mapColumn(columnAConfig.column), [
                        [],
                        [
                            renderFuncCall({
                                type: "functionCall",
                                func: "DIV",
                                target: getColumnFieldName(column),
                                params: [columnAConfig.variable, `store.${columnAConfig.variable}`],
                                at: { nodes: true, parent: false, totals: true }
                            })
                        ]
                    ]);
                }

                case PivotTableColumnFormulaType.PercentOfTotal: {
                    if (column.variables.a === undefined || column.variables.a.type === "constant")
                        throw Error("Formula variable A is undefined.");

                    const columnAConfig = getColumnVariable(column.variables.a);

                    return concatCalculations(mapColumn(columnAConfig.column), [
                        [],
                        [
                            // 2nd calculation pass steps
                            renderFuncCall({
                                type: "functionCall",
                                func: "DIV",
                                target: getColumnFieldName(column),
                                params: [
                                    columnAConfig.variable,
                                    `context.pivot.totals.${columnAConfig.variable}`
                                ],
                                at: { nodes: true, parent: false, totals: true }
                            })
                        ]
                    ]);
                }

                default:
                    throw Error();
            }
        }

        function getFieldFromLookup(fieldName: string): FieldInfo {
            const field = fieldsLookup[fieldName];

            if (field === undefined) {
                throw fieldNotFoundError(fieldName);
            }

            if (!field.isValueField) {
                throw Error(`Field ${fieldName} is not a value field`);
            }

            return field;
        }

        function parseAggregate(aggregate: string, colName: string): CalcAstNode {
            const funcName = aggregate.toLowerCase();
            switch (funcName) {
                case "sum":
                case "min":
                case "max":
                case "first":
                    return {
                        type: "functionCall",
                        func: funcName,
                        params: [colName],
                        at: {}
                    };

                default:
                    return {
                        type: "error",
                        message: `Don't know how aggregate function "${aggregate}"`
                    };
            }
        }

        function renderFuncCall(node: CalcAstNodeFunctionCall): string {
            const targetClause = node.target !== undefined ? `${node.target} = ` : "";
            const at: string[] = [];
            if (node.at.parent) at.push("PARENT");
            if (node.at.nodes) at.push("NODES");
            if (node.at.totals) at.push("TOTALS");
            const atClause = at.length > 0 ? ` AT ${at.join(",")}` : "";

            return `${targetClause}${node.func}( ${node.params.join(",")} )${atClause}`;
        }

        function concatCalculations(
            ...calcStepDefinitions: Array<CalculationStepDefinitions | null>
        ): CalculationStepDefinitions {
            return calcStepDefinitions
                .filter((x): x is CalculationStepDefinitions => x !== null)
                .reduce((acc, calcStepDefinition) => {
                    calcStepDefinition.forEach((steps, calculationPassIdx) => {
                        acc[calculationPassIdx] = acc[calculationPassIdx] || [];
                        acc[calculationPassIdx] = _.uniq(acc[calculationPassIdx].concat(steps));
                    });
                    return acc;
                }, []);
        }

        // We need to combine steps for each calculation pass generated by each column into common array.
        // result array should look like: [[ 1. calculation pass steps of each column ], [ 2. calculation pass steps of each column]...]
        const stepDefinitions = concatCalculations(
            ...config.columns
                .filter(x => x.isEnabled)
                .map(col => mapColumn(col))
                .filter(x => x) // remove nulls from array
        );

        return stepDefinitions;
    }

    private _getPivotOptions(
        calculationStepDefinitions: CalculationStepDefinitions
    ): ILogexPivotDefinitionOptions | undefined {
        if (calculationStepDefinitions.length > 1) {
            const options: { calculations: string[]; calculationChains: Record<string, string> } = {
                calculations: ["calculate1"],
                calculationChains: {
                    calculate: "calculate1"
                }
            };
            for (let idx = 2; idx < calculationStepDefinitions.length; idx++) {
                options.calculations.push(`calculate${idx}`);
                options.calculationChains[`calculate${idx - 1}`] = `calculate${idx}`;
            }
            return options;
        }
        return undefined;
    }
}

export function fieldNotFoundError(field: string): Error {
    return Error(`Field ${field} is not found in the scheme`);
}
