import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    standalone: false,
    selector: "lgflex-dialog-grid-panel-body",
    templateUrl: "./panel-body.component.html",
    styleUrls: ["./panel-body.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogGridPanelBodyComponent {}
