import { LgTranslateService } from "@logex/framework/lg-localization";
import { PivotTableColumnFormula, PivotTableColumnFormulaType } from "../types";

export function translateFormulaColumn(
    translation: LgTranslateService,
    formula: PivotTableColumnFormula,
    chartFieldsNameLc: Map<string, string>,
    referenceName: Map<number, string>
): string {
    let a = `${translation.translate(
        chartFieldsNameLc.get(
            (formula.variables.a?.type !== "constant" ? formula.variables.a?.field : "") ?? ""
        ) ?? ""
    )}`;
    if (formula.variables.a?.type !== "constant" && formula.variables.a?.reference !== undefined) {
        a = `${a}(${referenceName.get(formula.variables.a.reference)})`;
    }
    let b = `${translation.translate(
        chartFieldsNameLc.get(
            (formula.variables.b?.type !== "constant" ? formula.variables.b?.field : "") ?? ""
        ) ?? ""
    )}`;
    if (formula.variables.b?.type !== "constant" && formula.variables.b?.reference !== undefined) {
        b = `${b}(${referenceName.get(formula.variables.b.reference)})`;
    }
    let c = `${translation.translate(
        chartFieldsNameLc.get(
            (formula.variables.c?.type !== "constant" ? formula.variables.c?.field : "") ?? ""
        ) ?? ""
    )}`;
    if (formula.variables.c?.type !== "constant" && formula.variables.c?.reference !== undefined) {
        c = `${c}(${referenceName.get(formula.variables.c.reference)})`;
    }
    switch (formula.formula) {
        case PivotTableColumnFormulaType.A:
            return a;
        case PivotTableColumnFormulaType.AMinusB:
            return `${a} - ${b}`;
        case PivotTableColumnFormulaType.APlusB:
            return `${a} + ${b}`;
        case PivotTableColumnFormulaType.ADividedB:
            return `${a} / ${b}`;
        case PivotTableColumnFormulaType.AMinusBMinusC:
            return `${a} - ${b} - ${c}`;
        case PivotTableColumnFormulaType.AMinusBPlusC:
            return `${a} - ${b} + ${c}`;
        case PivotTableColumnFormulaType.PercentOfParent:
            return `${a} / ${translation.translate("Parent")}(${a})`;
        case PivotTableColumnFormulaType.AMultiplyBMinusC:
            return `${a} * (${b} - ${c})`;
        case PivotTableColumnFormulaType.ADividedB_MinusC:
            return `(${a} / ${b}) - ${c}`;
        default:
            return "";
    }
}
