import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    standalone: false,
    selector: "lgflex-dialog-grid-column",
    templateUrl: "./column.component.html",
    styleUrls: ["./column.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogGridColumnComponent {}
