import { Pipe, PipeTransform } from "@angular/core";
import { PageReferencesService } from "../services/page-references/page-references.service";
import { LgTranslateService } from "@logex/framework/lg-localization";

@Pipe({ name: "modReferenceVariable", pure: false, standalone: false })
export class ModReferenceVariablePipe implements PipeTransform {
    constructor(
        private _pageReferences: PageReferencesService,
        private _lgTranslate: LgTranslateService
    ) {}

    transform(value: string | null | undefined): string {
        if (value == null) return "";

        // Matches $REFx occurrences and is case insensitive. For example $REF1, $Ref2, $ref3
        const referenceRegex = /\$REF\d+/gi;
        return value.replace(referenceRegex, refVar => {
            const referenceNumber = parseInt(refVar.match(/\d+/)![0]);
            const reference = this._pageReferences.selectedReferences.at(referenceNumber - 1);

            if (reference === undefined) return refVar;
            if (reference.name != null) return reference.name;
            if (reference.nameLc != null) return this._lgTranslate.translate(reference.nameLc);
            return "-";
        });
    }
}
