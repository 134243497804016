/* eslint-disable @angular-eslint/no-output-on-prefix */
import { Component, computed, input, output } from "@angular/core";
import {
    COLUMN_WIDTH_DEFAULT,
    FdpColumnSimpleHeaderGroup,
    FdpDifferenceColumnChangeEvent,
    ReferenceInfo
} from "../../../types";
import { IDropdownDefinition } from "@logex/framework/ui-core";

@Component({
    standalone: false,
    selector: "lgflex-reference-slot",
    templateUrl: "./reference-slot.component.html",
    styleUrls: ["./reference-slot.component.scss"]
})
export class ReferenceSlotComponent {
    headerCell = input<FdpColumnSimpleHeaderGroup>();
    referencesDropdown = input<IDropdownDefinition<string> | undefined>();
    columnSortBy = input<string | null>();
    widgetReferences = input<ReferenceInfo[]>([]);
    selectedReferences = input<ReferenceInfo[]>([]);

    // --------------------------------------------------
    readonly onReferenceChange = output<{ slotIdx: number; value: string }>();
    readonly onOrderByChange = output<string>();
    readonly onDifferenceColumnChange = output<FdpDifferenceColumnChangeEvent>();

    // --------------------------------------------------
    protected _currentReference = computed(
        () => this.selectedReferences()[this.headerCell().referenceIdx]
    );

    protected _dropdownMinWidth = computed(() => {
        if (this.headerCell().children.length > 0) {
            return `${this.headerCell().children[0].column.width ?? 100}px`;
        }

        return `${COLUMN_WIDTH_DEFAULT}px`;
    });
}
