<lg-panel-grid *ngIf="configuration != null" [definition]="configuration.layout">
    <lg-panel
        *ngFor="let panel of _panels"
        id="{{ panel.id }}"
        location="{{ panel.id }}"
        (visibilityChange)="_onPanelVisibilityChange($event, panel.id)"
    >
        <ng-container *lgPanelHeader>
            <h2 *ngIf="panel.selectorConfig == null" class="flexible-layout__title">
                {{ panel.selected.title | modReferenceVariable }}
            </h2>
            <lg-dropdown
                *ngIf="panel.selectorConfig != null"
                [definition]="panel.selectorConfig"
                [current]="panel.selected.id"
                (currentChange)="_setPanelWidget(panel, $event)"
                class="reference-drop-outer reference-drop-outer--header flexible-layout__dropdown"
                className="lg-reference-dropdown lg-reference-dropdown--header dm-panel__header--dropdown--header"
                popupClassName="lg-dropdown-popup lg-dropdown-popup--header"
                [matchWidth]="false"
                [hideSearch]="true"
                [showTitle]="true"
                icon=""
            ></lg-dropdown>
            <lg-icon
                *ngIf="
                    panel.selected.deprecated &&
                    panel.selected.deprecatedReplacementId &&
                    !panel.selected.autoConverted
                "
                icon="icon-warning"
                class="lg-panel__header-information-icon"
                type="warning"
                lgSimpleTooltip="_Flexible.Widgets.DeprecatedWidgetWithReplacement"
                [tooltipParams]="{
                    replacement: panel.selected.deprecatedReplacementId | lgTranslate
                }"
            ></lg-icon>
            <lg-icon
                *ngIf="
                    panel.selected.deprecated &&
                    !panel.selected.deprecatedReplacementId &&
                    !panel.selected.autoConverted
                "
                icon="icon-warning"
                type="warning"
                class="lg-panel__header-information-icon"
                lgSimpleTooltip="_Flexible.Widgets.DeprecatedWidget"
            ></lg-icon>
        </ng-container>
        <!--TODO: fuctionality to preserve and restore state of the widgets or always render all of the widgets -->
        <ng-container
            *ngIf="panel.selected.type; else invalidWidget"
            lgflexWidgetHost
            [widgetId]="panel.selected.id"
            [widgetType]="panel.selected.type"
            [dependencyProviders]="panel.selected.providers"
            [widgetConfig]="panel.selected.config"
            [widgetVisibility]="panel.isVisible"
            [autoConverted]="panel.selected.autoConverted"
            [widgetState]="_widgetStates[panel.selected.id]"
            (widgetInstanceChange)="_onWidgetInstanceChange($event)"
            [context]="context"
        ></ng-container>
        <ng-template #invalidWidget>
            <div
                class="lg-flexible-layout__missing-widget"
                lgTranslate="_Flexible.Widgets.UnknownWidgetType"
                [translateParams]="{ type: panel.selected.typeName }"
            ></div>
        </ng-template>
        <!-- Additional icons -->
        <ng-container *ngIf="panel.icons != null">
            <ng-container *lgPanelIcons="let context; order: 0; whenMinimized: false">
                <ng-container *ngTemplateOutlet="panel.icons"></ng-container>
            </ng-container>
        </ng-container>
    </lg-panel>
</lg-panel-grid>

<div *ngIf="_showReferenceSlotsError" class="lg-flexible-layout__missing-widget">
    {{ "_Flexible.Errors.ReferenceSlotsError" | lgTranslate }}
</div>
