import { each, every, find, first, isEmpty, mapValues, omitBy, pull } from "lodash-es";
import { computed, inject, Injectable } from "@angular/core";
import { BehaviorSubject, filter, Observable, Subject, Subscription } from "rxjs";
import { takeUntil } from "rxjs/operators";

import {
    IDefinitions,
    LG_APP_DEFINITIONS,
    LG_LAST_FILTER_STORAGE
} from "@logex/framework/lg-application";
import { LgConsole } from "@logex/framework/core";
import { LgBookmarkModel, LgBookmarksStateService } from "@logex/framework/lg-layout";
import { LgFilterSet } from "@logex/framework/lg-filterset";
import { mixins } from "@logex/mixin-flavors";
import { NgOnDestroyMixin } from "@logex/mixins";
import { LgPromptDialog } from "@logex/framework/ui-core";

import { FlexibleLayout, FlexibleLayoutStorageService } from "../flexible-layout-storage";
import { FlexibleLayoutStateStorage } from "../flexible-layout-state-storage/flexible-layout-state-storage.service";
import { WidgetTypesRegistry } from "../widget-types-registry";
import {
    DataSource,
    LG_FLEX_DATA_SOURCES_SERVICE
} from "../flex-data-sources/flex-data-sources.types";
import { FlexDataClientService } from "../flex-data-client/flex-data-client.service";
import { PageReferencesService } from "../page-references/page-references.service";
import { FlexibleFilterFactory } from "../flexible-filter-factory/flexible-filter-factory";
import {
    FlexibleLayoutConfiguration,
    FlexibleLayoutState
} from "../../components/flexible-layout/flexible-layout.types";
import {
    FilterPatchCallback,
    IWidgetHost,
    LayoutVariant,
    LG_FLEX_LAYOUT_WIDGET_HOST,
    PageLayoutConfiguration
} from "../../types";
import {
    FlexibleLayoutComponent,
    WidgetInfo
} from "../../components/flexible-layout/flexible-layout.component";
import { LayoutBookmarksStore } from "../layout-bookmarks-store";
import { getAllFilters, PrimitiveArray } from "../../utilities";
import { FiltersLayoutGroup } from "../flexible-filter-factory/flexible-filter-factory.types";
import { FlexDataClientMetadataArguments } from "../flex-data-client/types/types";
import { FlexibleLayoutDataSourcesService } from "../flexible-layout-data-sources";

// ----------------------------------------------------------------------------------
export interface FlexibleAnalyticsService extends NgOnDestroyMixin {}

@Injectable()
@mixins(NgOnDestroyMixin)
export class FlexibleAnalyticsService {
    private _lgConsole = inject(LgConsole);
    private _layoutDataSources = inject(FlexibleLayoutDataSourcesService);
    private _flexibleLayoutStorage = inject(FlexibleLayoutStorageService);
    private _flexibleLayoutStateStorage = inject(FlexibleLayoutStateStorage<FlexibleLayoutState>);
    private _bookmarkState = inject(LgBookmarksStateService);
    private _widgetTypes = inject(WidgetTypesRegistry);
    private _lastFilterStorage = inject(LG_LAST_FILTER_STORAGE, { optional: true });
    private _dataSourcesService = inject(LG_FLEX_DATA_SOURCES_SERVICE);
    private _definitions = inject<IDefinitions<any>>(LG_APP_DEFINITIONS);
    private _flexDataClient = inject(FlexDataClientService);
    private _pageReferences = inject(PageReferencesService);
    private _flexibleFilterFactory = inject(FlexibleFilterFactory);
    private _promptDialog = inject(LgPromptDialog);

    constructor() {
        this._initMixins();

        this._layoutDataSources.dataSourceLoaded$
            .pipe(takeUntil(this._destroyed$))
            .subscribe(() => this._reconfigureFilters());
    }

    // ----------------------------------------------------------------------------------
    // Fields
    private _filterStateStorageKey = "empty";
    private _panelLayoutsVariants: LayoutVariant[] = [];
    private _filtersLayout: FiltersLayoutGroup[] = [];
    private _metadataArguments: FlexDataClientMetadataArguments = {};
    private _defaultReferences: Array<string | null> = [];
    private _maxRecordsLimit = 0;

    private _currentDataSource: DataSource | undefined = undefined;
    private _currentDataSourceCode: string | undefined = undefined;

    private _layoutId: number | undefined = undefined;
    private _layout: FlexibleLayout | undefined = undefined;

    private readonly _pageLayoutConfigurationSubject = new BehaviorSubject<
        FlexibleLayoutConfiguration | undefined
    >(undefined);

    readonly layoutConfiguration$ = this._pageLayoutConfigurationSubject.asObservable();

    private readonly _pageLayoutStateSubject = new BehaviorSubject<FlexibleLayoutState | undefined>(
        undefined
    );

    readonly layoutState$ = this._pageLayoutStateSubject.asObservable();

    private readonly _dataLoadingSubject = new BehaviorSubject<boolean>(true);
    readonly dataLoading$ = this._dataLoadingSubject.asObservable();

    private _widgets: WidgetInfo[] = [];
    private _readyWidgets = new Set<string>();
    private _filterPatches: FilterPatchCallback[] = [];

    private _layoutBookmarksStore = this._bookmarkState
        ._getBookmarkStores()
        .find((s): s is LayoutBookmarksStore => s instanceof LayoutBookmarksStore);

    private _currentBookmark: LgBookmarkModel | undefined = undefined;

    private _filters: LgFilterSet<any, any> | undefined = undefined;

    private _isStarted = false;

    private _reloadSub: Subscription | undefined = undefined;

    // ----------------------------------------------------------------------------------
    // Properties
    flexibleLayoutComponent: FlexibleLayoutComponent | undefined = undefined;

    get layoutId(): number | undefined {
        return this._layoutId;
    }

    get layout(): FlexibleLayout | undefined {
        return this._layout;
    }

    get filters(): LgFilterSet<any, any> | undefined {
        return this._filters;
    }

    // ----------------------------------------------------------------------------------
    // Events
    private _allWidgetsReadySubject = new Subject<void>();
    readonly allWidgetsReady$ = this._allWidgetsReadySubject.asObservable();

    private _layoutIdChangeSubject = new Subject<number | undefined>();
    readonly layoutIdChange$ = this._layoutIdChangeSubject.asObservable();

    private _filterChangeSubject = new Subject<LgFilterSet<any, any>>();
    readonly filterChange$ = this._filterChangeSubject.asObservable();

    // ----------------------------------------------------------------------------------
    // Methods

    /**
     * Start the service. Before calling this method, host page should:
     * - Configure data sources service
     * - Configure widget types service
     *
     * Service is not meant to be "restarted", and it is likely not needed in our use cases. If we'll need to
     * support restarting then the service needs to be updated.
     *
     * @param args
     */
    async start(args: {
        pageId: string;
        filterStateStorageKey: string;
        layoutVariants: LayoutVariant[];
        filtersLayout: FiltersLayoutGroup[];
        metadataArguments: FlexDataClientMetadataArguments;
        defaultReferences?: Array<string | null>;
        maxRecordsLimit?: number;
    }): Promise<void> {
        if (this._isStarted) {
            throw new Error("Service is already started. Restarting is not supported now.");
        }

        this._panelLayoutsVariants = args.layoutVariants;
        this._filtersLayout = args.filtersLayout;
        this._metadataArguments = args.metadataArguments;
        this._defaultReferences = args.defaultReferences ?? [];
        this._maxRecordsLimit = args.maxRecordsLimit ?? 10000;

        // Add widgets hosts object to type providers
        this._widgetTypes.addProviders([
            { provide: LG_FLEX_LAYOUT_WIDGET_HOST, useValue: this._getWidgetHostObject() },
            { provide: LgFilterSet, useFactory: () => this._filters }
        ]);

        // Always keep a references to currently selected bookmark
        this._filterStateStorageKey = args.filterStateStorageKey;
        this._bookmarkState
            .getCurrentBookmark(args.filterStateStorageKey)
            .pipe(takeUntil(this._destroyed$))
            .subscribe(bookmark => {
                this._currentBookmark = bookmark;
            });

        // Load layouts
        await this._flexibleLayoutStorage.load(args.pageId, this._metadataArguments);

        this._isStarted = true;
        this._layoutDataSources.updateDefaultMetadataArgs(this._metadataArguments);
    }

    async setLayout(
        layoutId: number | null,
        defaultLayoutId: number | null,
        layoutUpdated: boolean
    ): Promise<void> {
        if (!layoutUpdated && layoutId === this._layoutId) return;

        // Check that layout with given ID exists
        const layout = layoutId != null ? this._flexibleLayoutStorage.getLayout(layoutId) : null;

        if (layout != null) {
            await this._setLayoutImp(layout.id);
        } else {
            await this._selectStartupLayout(defaultLayoutId);
        }
    }

    private async _selectStartupLayout(defaultLayoutId: number | null): Promise<void> {
        let layoutId = this._flexibleLayoutStorage.temporaryLayoutId ?? defaultLayoutId;

        // Check that layout exists
        if (layoutId !== null && this._flexibleLayoutStorage.getLayout(layoutId) === undefined) {
            this._lgConsole.warn(`Page layout ${layoutId} does not exist`);
            layoutId = null;
        }

        // Get default layout if last saved is not set
        if (layoutId === null) {
            layoutId = this._flexibleLayoutStorage.getDefaultLayoutId();
        }

        // Latest or default layout was found
        if (layoutId !== null) {
            // Load the layout
            await this._setLayoutImp(layoutId);
        } else {
            // To not end up with empty references
            await this._setDataSource(this._dataSourcesService.dataSources[0]?.code);
        }
    }

    private async _setLayoutImp(layoutId: number): Promise<void> {
        this._storeCurrentState();

        this._layoutId = layoutId;
        const layout = this._flexibleLayoutStorage.getLayout(layoutId);
        this._layout = layout;

        if (layout === undefined) {
            if (layoutId !== undefined) {
                this._lgConsole.warn(`Page layout ${layoutId} does not exist`);
            }

            // Show nothing when layout is not found
            this._pageLayoutConfigurationSubject.next(undefined);
            this._pageLayoutStateSubject.next(undefined);
            this._layoutIdChangeSubject.next(undefined);
            return;
        }

        // Check if layout referenced by layoutId is being edited. Layout to be shown is in effectiveLayoutId
        let effectiveLayoutId: number | null;
        if (layout.hasTemporary) {
            effectiveLayoutId = this._flexibleLayoutStorage.findTemporaryLayout(layoutId);
            if (effectiveLayoutId == null) {
                throw Error(`Cannot fine temporary layout for page layout ${layoutId}`);
            }

            this._layoutId = effectiveLayoutId;
            this._layout = this._flexibleLayoutStorage.getLayout(effectiveLayoutId);
        } else {
            effectiveLayoutId = layoutId;
        }

        await this._flexibleLayoutStorage.loadLayoutConfig(effectiveLayoutId);
        const layoutConfiguration = this._layout?.config;

        if (layoutConfiguration == null) {
            throw Error(`Page layout configuration for layout ${effectiveLayoutId} is null`);
        }

        if (this._filters?.isAnyActive() ?? false) {
            this.storeFilterState();
        }

        // Configure flexible dataset to use a correct data source
        if (layoutConfiguration.dataSource != null) {
            try {
                await this._setDataSource(layoutConfiguration.dataSource);
            } catch (e) {
                this._lgConsole.error(
                    `Cannot set data source "${layoutConfiguration.dataSource}". Setting the first from the data source service.`,
                    e
                );
                await this._setDataSource(first(this._dataSourcesService.dataSources)?.code);
            }
        } else {
            await this._setDataSource(first(this._dataSourcesService.dataSources)?.code);
        }

        this._setupLayoutBookmarksStore(layoutConfiguration);
        await this._applyDefaultFilters();

        const layoutVariant = find(this._panelLayoutsVariants, {
            code: layoutConfiguration.panelsLayout
        });

        if (layoutVariant == null) {
            throw Error(`Panels layout variant "${layoutConfiguration.panelsLayout}" is not found`);
        }

        const layoutState = this._flexibleLayoutStateStorage.getState(
            this._filterStateStorageKey,
            effectiveLayoutId.toString()
        );

        this._pageLayoutConfigurationSubject.next({
            layout: layoutVariant.layout,
            widgets: layoutConfiguration?.widgets,
            referenceSlots: layoutConfiguration.referenceSlots
        });
        this._pageLayoutStateSubject.next(layoutState);
        this._layoutIdChangeSubject.next(layoutId);
    }

    private async _setDataSource(dataSourceCode: string): Promise<void> {
        this._layoutDataSources.updateDefaultLayoutDataSource(dataSourceCode);
        this._currentDataSource = find(
            this._dataSourcesService.dataSources,
            source => source.code === dataSourceCode
        );

        if (this._currentDataSource == null) {
            await this._promptDialog.alertLc(
                "_Flexible.Errors.DataSourceNotFoundError.Title",
                "_Flexible.Errors.DataSourceNotFoundError.Description"
            );
            throw Error(`Data source ${dataSourceCode} is not found `);
        }

        this._currentDataSourceCode = this._currentDataSource.code;
        await this._loadLayoutDataSources();
        await this._configureFlexibleDataset();

        // Change reference slots
        const layoutConfiguration = this._layout?.config;
        if (layoutConfiguration != null) {
            if (this._pageReferences.isAllowed() && layoutConfiguration.referenceSlots != null) {
                this._pageReferences.slots = layoutConfiguration.referenceSlots;
                this._pageReferences.selected = layoutConfiguration.referenceSlots.map(
                    (slot, i) => {
                        if (slot.isLocked || slot.referenceCode != null) {
                            return slot.referenceCode;
                        }
                        if (this._defaultReferences != null) {
                            return this._defaultReferences[i];
                        }
                        return null;
                    }
                );
            }
        }
    }

    private async _loadLayoutDataSources(): Promise<void> {
        // Skip if no layouts present
        if (this._layout == null) return;

        const dataSources = this._layout.config.widgets.map(
            w => w.config.dataSource ?? this._currentDataSource.code
        );

        await this._layoutDataSources.loadMultipleDataSources([
            ...new Set([...dataSources, this._currentDataSource.code])
        ]);
    }

    private async _configureFlexibleDataset(): Promise<void> {
        this._dataLoadingSubject.next(true);

        try {
            await this._flexDataClient.init({
                dataSourceCode: this._currentDataSource.code,
                url: this._currentDataSource!.rootUrl,
                metadataArguments: this._metadataArguments,
                maxRecordsLimit: this._maxRecordsLimit
            });

            // Load definitions referenced by fields schema
            await this._flexDataClient.loadRequiredDefinitions(this._definitions);
        } finally {
            this._dataLoadingSubject.next(false);
        }

        // Configure filters
        this._reconfigureFilters();

        // Bind dataset arguments with current references and filters
        const filters = this._filters!;
        this._flexDataClient.dataset.metadataArguments = {
            ...mapValues(this._metadataArguments!, x => () => x)
        };
        this._flexDataClient.dataset.dataArguments = {
            references: () => this._pageReferences.selected,
            filters: () => {
                const nonEmptyFilters = omitBy(getAllFilters(filters), x => x == null) as Record<
                    string,
                    PrimitiveArray
                >;

                each(this._filterPatches, patch => {
                    try {
                        patch(nonEmptyFilters);
                    } catch (e) {
                        this._lgConsole.error("Filter patching has failed", e);
                    }
                });

                return !isEmpty(nonEmptyFilters) ? nonEmptyFilters : {};
            }
        };

        // Set references loaded from the database to PageReference service
        this._pageReferences.references = this._flexDataClient.references;

        if (this._pageReferences.isAllowed()) {
            // When references change, data should be reloaded
            this._pageReferences.stateChange.subscribe(() => {
                this._flexDataClient.dataset.loadObservedDatasets();
            });
        }

        this._layoutDataSources.updateDatasetDefaultArgs(
            this._flexDataClient.dataset.dataArguments
        );
    }

    private _reconfigureFilters() {
        const scheme = this._layoutDataSources.getAllDataSourcesSchemes();

        this._filters = this._flexibleFilterFactory
            .define(this._layoutDataSources)
            .configure(scheme, this._filtersLayout)
            .create(this);
        this._filters.onChanged.subscribe(filters => this._refilter(filters));

        this._filterChangeSubject.next(this._filters);
    }

    private _setupLayoutBookmarksStore(layoutConfiguration: PageLayoutConfiguration): void {
        if (this._layoutBookmarksStore === undefined) return;

        const layoutFilters = layoutConfiguration?.filterSets;
        if (!layoutFilters) return;

        this._layoutBookmarksStore.setLayoutFilterSets(layoutFilters);
    }

    private async _applyDefaultFilters(): Promise<void> {
        if (this._layoutBookmarksStore === undefined) return;

        const defaultBookmark = this._layoutBookmarksStore.getDefaultLayoutBookmark();
        if (defaultBookmark) {
            this._filters!.deserialize(defaultBookmark.parts, true);
            await this._bookmarkState.selectBookmark(
                this._filterStateStorageKey,
                this._bookmarkState.findStoreIndexByType(LayoutBookmarksStore),
                defaultBookmark.stateId!
            );
        } else if (this._lastFilterStorage != null) {
            this._filters!.deserialize(
                this._lastFilterStorage.get(this._filterStateStorageKey),
                true
            );
        }
    }

    protected _getWidgetHostObject(): IWidgetHost {
        return {
            // ----------------------------------------------------------------------------------
            updateWidgetConfiguration: async (
                widgetId: string,
                widgetType: string,
                configVersion: number,
                config: any
            ): Promise<void> => {
                const layoutWidget = this._layout!.config?.widgets?.find(w => w.id === widgetId);

                if (layoutWidget == null) {
                    throw Error(`Cannot find widget ${widgetId} in the current page layout`);
                }

                layoutWidget.configVersion = configVersion;
                layoutWidget.config = config;
                layoutWidget.type = widgetType;
                const newLayoutId = await this._flexibleLayoutStorage.applyTemporaryLayout(
                    this._layout!
                );

                if (newLayoutId !== this._layoutId) {
                    this._storeCurrentState();
                    // this._pageSettings.pageLayoutId = newLayoutId;
                    this._layoutIdChangeSubject.next(newLayoutId);
                }
                const state = this.flexibleLayoutComponent!.getState() ?? undefined;

                this._layoutId = newLayoutId;
                const tempPageLayout = this._flexibleLayoutStorage.getLayout(newLayoutId)!;
                this._layout = { ...tempPageLayout, config: this._layout!.config };

                const layoutVariant = find(this._panelLayoutsVariants, {
                    code: this._layout!.config!.panelsLayout
                })!;
                this._pageLayoutConfigurationSubject.next({
                    layout: layoutVariant.layout,
                    widgets: this._layout!.config!.widgets,
                    referenceSlots: this._layout!.config!.referenceSlots
                });

                this._pageLayoutStateSubject.next(state);
            },

            // ----------------------------------------------------------------------------------
            addFilterPatchCallback: (patch: FilterPatchCallback): (() => void) => {
                this._filterPatches.push(patch);
                return () => {
                    pull(this._filterPatches, patch);
                };
            },

            // ----------------------------------------------------------------------------------
            notifyWidgetReady: (widgetId: string): void => {
                this._readyWidgets.add(widgetId);

                // Check that all known widgets are ready
                if (every(this._widgets, x => this._readyWidgets.has(x.id))) {
                    this._allWidgetsReadySubject.next();
                }
            }
        };
    }

    protected _storeCurrentState(): void {
        if (this._layout && this.flexibleLayoutComponent) {
            const state = this.flexibleLayoutComponent.getState();
            this._flexibleLayoutStateStorage.setState(
                this._filterStateStorageKey,
                this._layoutId!.toString(),
                state
            );
        }
    }

    private _refilter(filters?: Array<string | number | symbol>): void {
        const field = filters[0];
        const datasets = this._layoutDataSources.getDatasetForField(field as string);

        datasets.forEach(dataset => dataset.loadObservedDatasets());

        // needed for widgets that use default datasource and dataset
        this._flexDataClient.dataset.dataArguments.references = () => this._pageReferences.selected;
        this._flexDataClient.dataset.loadObservedDatasets();
    }

    onWidgetsChange(widgets: WidgetInfo[]): void {
        this._widgets = widgets;
    }

    setReloadTrigger(trigger?: Observable<void> | undefined): void {
        this._reloadSub?.unsubscribe();
        this._reloadSub = trigger?.subscribe(() => {
            this._flexDataClient.dataset.reloadObservedDatasets();
        });
    }

    storeFilterState() {
        if (this._lastFilterStorage == null) return;

        // Store the current filter state only if it is not defined by a bookmark
        if (this._currentBookmark?.isExisting === false && this._filters !== undefined) {
            this._lastFilterStorage.store(this._filterStateStorageKey, this._filters.serialize());
        }
    }

    isActiveLayoutReadOnly(): boolean {
        if (this._layout === null) return true;

        const library = this._flexibleLayoutStorage.getLibrary(this._layout!.libraryId);
        return library ? library.readOnly : true;
    }
}
