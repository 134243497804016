export type CalcAstNode = CalcAstNodeFunctionCall | CalcAstNodeError;

export interface CalcAstNodeFunctionCall {
    type: "functionCall";
    func: string;
    target?: string;
    params: string[];
    at: CalcAstFunctionCallAt;
}

export interface CalcAstFunctionCallAt {
    parent?: boolean;
    nodes?: boolean;
    totals?: boolean;
}

export interface CalcAstNodeError {
    type: "error";
    message: string;
}

export function parseCalculate(calculate: string): CalcAstNode {
    const divParsed = /^\s*div\(\s*([a-z]\w*)\s*,\s*([a-z]\w*)\s*\)\s*$/gi.exec(calculate);
    if (divParsed != null) {
        return {
            type: "functionCall",
            func: "div",
            params: [divParsed[1], divParsed[2]],
            at: { parent: true, nodes: true }
        };
    }

    return {
        type: "error",
        message: `Don't know how to parse "${calculate}"`
    };
}

export function calculateToFormula(calculate: string, referenceSuffix?: number): string {
    const parsed = parseCalculate(calculate);

    if (parsed.type === "error") {
        throw new Error(parsed.message);
    }

    switch (parsed.func) {
        case "div":
            return `${parsed.params[0] + (referenceSuffix ?? "")} / ${parsed.params[1] + (referenceSuffix ?? "")}`;
    }

    throw new Error(`Don't know how to convert "${calculate}"`);
}
