import { ChangeDetectionStrategy, Component } from "@angular/core";

@Component({
    standalone: false,
    selector: "lgflex-dialog-grid-panel-header",
    templateUrl: "./panel-header.component.html",
    styleUrls: ["./panel-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogGridPanelHeaderComponent {}
