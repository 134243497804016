import {
    DestroyRef,
    EnvironmentInjector,
    inject,
    Inject,
    Injectable,
    Injector,
    INJECTOR,
    OnDestroy
} from "@angular/core";
import { firstValueFrom } from "rxjs";
import { mixins } from "@logex/mixin-flavors";

import { urlConcat } from "@logex/framework/utilities";
import { LgTranslateService } from "@logex/framework/lg-localization";
import { IDefinitions } from "@logex/framework/lg-application";
import { LgPromptDialog } from "@logex/framework/ui-core";
import { HandleErrorsMixin } from "@logex/mixins";
import { useStaleData } from "@logex/load-manager";

import { FieldInfo, ReferenceInfo } from "../../types";
import { FlexDataClientConfiguration } from "./types/types";
import { FlexibleDataset } from "../flexible-dataset";
import { FlexDataClientGateway } from "./gateway/flex-data-client-gateway";
import { isDefinitionTypeField } from "../../utilities/isDefinitionTypeField";
import { ExportFieldInfo, ExportGroupInfo } from "./gateway/flex-data-client-gateway.types";
import { PageReferencesService } from "../page-references/page-references.service";

export interface FlexDataClientService extends HandleErrorsMixin {}

@Injectable()
@mixins(HandleErrorsMixin)
export class FlexDataClientService implements OnDestroy {
    constructor(
        @Inject(INJECTOR) private _injector: Injector,
        public _promptDialog: LgPromptDialog,
        public _lgTranslate: LgTranslateService,
        private _gateway: FlexDataClientGateway
    ) {
        this._initMixins();

        const destroyRef = this._injector.get(DestroyRef);
        destroyRef.onDestroy(() => {
            this._destroyNestedInjector();
        });
    }

    private _pageReferences = inject(PageReferencesService);
    private _config: FlexDataClientConfiguration | undefined;
    private _isInitialized = false;
    private _scheme: Array<Readonly<FieldInfo>> = [];
    private _references: ReferenceInfo[] = [];
    private _dataset: FlexibleDataset | null = null;
    private _datasetInjector: Injector | null = null;

    async init(config: FlexDataClientConfiguration): Promise<void> {
        this._config = config;

        // Destroy previous dataset injector if exists
        this._destroyNestedInjector();
        this._dataset = null;

        try {
            const scheme = await firstValueFrom(
                this._gateway.getScheme(config.url, config.metadataArguments)
            );

            this._scheme = scheme.fields ?? [];
            this._references = scheme.references ?? [];
            this._isInitialized = true;
        } catch (e: any) {
            this._onException(e);
        }
    }

    private _destroyNestedInjector() {
        if (this._datasetInjector != null) {
            (this._datasetInjector as EnvironmentInjector).destroy();
            this._datasetInjector = null;
        }
    }

    private _assertIsInitialized(): void {
        if (!this._isInitialized) {
            throw Error("FlexDataClient must be initialized before use");
        }
    }

    get scheme(): Array<Readonly<FieldInfo>> {
        this._assertIsInitialized();
        return this._scheme;
    }

    get references(): ReferenceInfo[] {
        this._assertIsInitialized();
        return this._references;
    }

    get dataset(): FlexibleDataset {
        if (this._dataset == null) {
            this._assertIsInitialized();

            this._datasetInjector = Injector.create({
                parent: this._injector,
                providers: [
                    useStaleData(),
                    {
                        provide: FlexibleDataset,
                        useClass: FlexibleDataset
                    }
                ]
            });

            this._dataset = this._datasetInjector.get<FlexibleDataset>(FlexibleDataset);

            this._dataset.configure({
                dataUrl: urlConcat(this._config!.url, "data"),
                fields: this._scheme,
                maxRecordsLimit: this._config!.maxRecordsLimit
            });
        }

        return this._dataset;
    }

    async loadRequiredDefinitions(definitions: IDefinitions<any>): Promise<void> {
        this._assertIsInitialized();

        const required = this.scheme
            .map(x => (!x.isValueField && isDefinitionTypeField(x) ? x.type : null))
            .filter((x): x is string => x != null);
        try {
            await firstValueFrom(definitions.load(...required));
        } catch (err: any) {
            this._onException(err);
            throw err;
        }
    }

    // TODO: Find a better place for this method
    public async triggerExport(
        title: string,
        pageName: string,
        layoutName: string,
        clientId: number,
        clientName: string,
        currency: string,
        groups: ExportGroupInfo[],
        fields: ExportFieldInfo[]
    ): Promise<void> {
        await firstValueFrom(
            this._gateway.triggerExport(this._config.url, this._config.metadataArguments, {
                title,
                dataSourceCode: this._config.dataSourceCode,
                pageName,
                layoutName,
                clientId,
                clientName,
                currency,
                groups,
                fields,
                selectedReferences: this._pageReferences.selected,
                filters: this.dataset.dataArguments.filters(),
                orderBy: []
            })
        );
    }

    public ngOnDestroy(): void {
        this._dataset = null;
    }
}
