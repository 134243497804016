import { ChangeDetectionStrategy, Component, input, Input } from "@angular/core";
import { PageWidgetConfigBase } from "../../types";
import { ITooltipOptions } from "@logex/framework/ui-core";
import { CalculationError, CalculationProgress } from "@logex/load-manager";
import { Observable, of } from "rxjs";

@Component({
    standalone: false,
    selector: "lgflex-widget-common-header",
    templateUrl: "./widget-common-header.component.html",
    styleUrls: ["./widget-common-header.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class WidgetCommonHeaderComponent {
    config = input<PageWidgetConfigBase>({});
    isDataComplete = input<boolean | undefined>(undefined);
    isLimitRowsEnabled = input<boolean | undefined>(false);
    limitRowsTooltipText = input<string | undefined>(undefined);
    isStale$ = input<Observable<boolean>>(of(false));
    calculationProgress$ = input<Observable<CalculationProgress | null>>(of(null));
    calculationError$ = input<Observable<CalculationError[] | null>>(null);

    protected _tooltipOptions: ITooltipOptions = { position: "bottom-right" };
}
