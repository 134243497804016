<div [style.min-height.px]="28 * minRowNum - 4">
    @for (
        definition of _levelsDropdowns();
        track definition.id;
        let i = $index;
        let isLast = $last
    ) {
        <div class="lg-pivot-levels-selector__item">
            <div class="lg-pivot-levels-selector__spacer" [style]="{ width: i * 23 + 'px' }"></div>
            <lg-dropdown
                [definition]="definition"
                [current]="selectedLevelIds()[i]"
                (currentChange)="_onValueChange(i, $event)"
                [emptyAcceptable]="true"
                [condensed]="true"
                [textOverride]="_levelText(i)"
                [isValid]="isLast || _levelsDict()[selectedLevelIds()[i]] !== undefined"
                className="lg-dropdown lg-dropdown--condensed"
            ></lg-dropdown>
        </div>
    }
</div>
