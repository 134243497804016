import { Injectable, inject } from "@angular/core";
import { LG_APP_CONFIGURATION, ServerGatewayBase } from "@logex/framework/lg-application";
import { Observable } from "rxjs";

export interface SubscriptionStatusResult {
    isStale: boolean;
}

@Injectable({ providedIn: "root" })
export class StaleDataServiceGatewayV2 extends ServerGatewayBase {
    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfiguration.applicationRoot);
    }

    cancelSubscriptions(subscriptionIds: string[]): Observable<any> {
        return this._post("calc-v2/unsubscribe", {
            body: { subscriptionIds }
        });
    }

    checkSubscriptions(subscriptionIds: string[]): Observable<Record<string, SubscriptionStatusResult>> {
        return this._post("calc-v2/check", {
            body: { subscriptionIds }
        });
    }
}
