import { inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { LG_APP_CONFIGURATION } from "@logex/framework/lg-application";
import {
    MaybeStaleData,
    MaybeStaleServerGatewayBase,
    STALE_DATA_SERVICE_VERSION
} from "@logex/load-manager";
import { FlexDataClientMetadataArguments } from "../../flex-data-client/types/types";
import { FlexibleDatasetDataArguments, SelectDataResponse } from "../types/types";

@Injectable()
export class FlexibleDatasetGateway extends MaybeStaleServerGatewayBase {
    private _staleDataVersion = inject(STALE_DATA_SERVICE_VERSION);

    constructor() {
        super();

        const appConfiguration = inject(LG_APP_CONFIGURATION);
        this._setBaseUrl(appConfiguration.applicationRoot);
    }

    // ----------------------------------------------------------------------------------
    selectData(
        url: string,
        metadataArgs: FlexDataClientMetadataArguments,
        dataArgs: FlexibleDatasetDataArguments,
        subscription?: string
    ): Observable<MaybeStaleData<SelectDataResponse>> {
        if (this._staleDataVersion === "V1") {
            return this._postMaybeStaleQuery<SelectDataResponse>(url, {
                params: metadataArgs,
                body: dataArgs
            });
        } else {
            return this._postMaybeStaleQueryV2<SelectDataResponse>(url, {
                subscriptionId: subscription,
                params: metadataArgs,
                body: dataArgs
            });
        }
    }
}
