import { inject, Pipe, PipeTransform } from "@angular/core";
import { LgTranslateService } from "@logex/framework/lg-localization";
import {
    DataSource,
    LG_FLEX_DATA_SOURCES_SERVICE
} from "../../../services/flex-data-sources/flex-data-sources.types";

@Pipe({
    // eslint-disable-next-line @angular-eslint/pipe-prefix
    name: "lgflexDataSourceNames",
    pure: true
})
export class LayoutManagementDataSourceNamesPipe implements PipeTransform {
    private readonly _lgTranslate = inject(LgTranslateService);
    private readonly _dataSources: DataSource[] = inject(LG_FLEX_DATA_SOURCES_SERVICE).dataSources;

    transform(dataSourceCodes: string[]): string {
        return dataSourceCodes
            .map(dataSourceCode => {
                const dataSource = this._dataSources.find(ds => ds.code === dataSourceCode);
                return this._lgTranslate.translate(dataSource.nameLc) || dataSourceCode;
            })
            .join(", ");
    }
}
